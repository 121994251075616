<div class="empty-wrapper">
    <img *ngIf="type == 'users'" src="../../../../../assets/images/empty-users.svg" alt="Empty" />
    <img *ngIf="type == 'tasks'" src="../../../../../assets/images/empty.svg" alt="Empty" />
    <img *ngIf="type == 'territories'" src="../../../../../assets/images/empty-territories.svg" alt="Empty" />
    <h3>No {{ type.charAt(0).toUpperCase() + type.slice(1) }}</h3>
    <p>
        This queue doesn’t have any assigned {{ type }}. <br />
        Add some to get started.
    </p>
    <button class="primary-btn" type="button" (click)="addHandler()">
        <mat-icon>add_circle</mat-icon>Add {{ type.charAt(0).toUpperCase() + type.slice(1) }}
    </button>
</div>
