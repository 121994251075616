import { Component, OnInit, ViewChild } from '@angular/core';
import { createImageFromBlob, setAge, setAgeColor } from '../../../../shared/helpers/utils';
import { enumToArray, toSearchString, deepCopy } from '../../../../shared/helpers/utils';
import { ManagerQueueIdentifyColumns, Roles } from '../../../../shared/enums/enums';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IManagerQueueIdentify } from '../queue.model';
import { QueueService } from './../queue.service';
import { throwError } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-manager-queue-identify',
    templateUrl: './identify.component.html',
    styleUrls: ['../queue.component.scss', './identify.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class ManagerQueueIdentifyComponent implements OnInit {
    setAge = setAge;
    setAgeColor = setAgeColor;
    columnsToDisplay = enumToArray(ManagerQueueIdentifyColumns);
    data: IManagerQueueIdentify[] = [];
    dataSource;
    isLoaded = false;
    maxPageCount;
    maxHoursCount;
    minutesInAgeType;
    isFiltersNotUsed = true;
    userRole: string;
    previousValue = null;

    settings = {
        itemPerPage: 20,
        paginationPageCount: null,
        activePage: 0,
        ageType: 'hours',
        filter: {
            show: {
                source: false,
                pageCount: false,
                receivedDate: false,
                age: false,
            },
            value: {
                source: null,
                pageCount: [0, 1],
                receivedDate: { start: '', end: '' },
                age: [0, 1],
            },
            initialValue: {
                source: null,
                pageCount: [0, 1],
                receivedDate: { start: '', end: '' },
                age: [0, 1],
            },
        },
    };

    onChangeRange(rangeSlider) {
        const rangeType = rangeSlider.replace('Slider', '');
        this[rangeSlider].slider.set(this.settings.filter.value[rangeType]);
    }

    openFilter(filter) {
        this.previousValue = deepCopy(this.settings.filter.value)
        this.closeAllFilters();
        this.settings.filter.show[filter] = true;
    }

    closeFilter(filter) {
        this.settings.filter.show[filter] = false;
        this.settings.filter.value = this.previousValue
    }

    closeAllFilters() {
        const shownFilters = this.settings.filter.show;
        Object.keys(shownFilters).forEach((k) => {
            shownFilters[k] = false;
        });
        this.settings.filter.value = deepCopy(this.previousValue)
    }

    clearFilter(filter) {
        switch (filter) {
            case 'receivedDate':
                this.settings.filter.value[filter] = { start: '', end: '' };
                break;
            default:
                this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
        }
        this.applyFilter(filter);
    }

    clearAllFilters() {
        this.settings.filter.value = {
            ...this.settings.filter.initialValue,
            receivedDate: { start: '', end: '' },
        };
        this.applyFilter('');
    }

    checkIsFilterUsed() {
        this.isFiltersNotUsed =
            !!this.settings.filter.value.source == !!this.settings.filter.initialValue.source &&
            this.settings.filter.value.pageCount[0] == this.settings.filter.initialValue.pageCount[0] &&
            this.settings.filter.value.pageCount[1] == this.settings.filter.initialValue.pageCount[1] &&
            !this.settings.filter.value.receivedDate.start &&
            !this.settings.filter.value.receivedDate.end &&
            this.settings.filter.value.age[0] == this.settings.filter.initialValue.age[0] &&
            this.settings.filter.value.age[1] == this.settings.filter.initialValue.age[1];
    }

    setPagination() {
        this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
    }

    applyFilter(filter) {
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSource.filter = this.settings.filter.value;
        this.settings.filter.show[filter] = false;
        this.checkIsFilterUsed();
        this.setPagination();
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            let shownFilter;
            for (const [key, value] of Object.entries(this.settings.filter.show)) {
                if (this.settings.filter.show[key]) {
                    shownFilter = key;
                }
            }
            shownFilter && this.applyFilter(shownFilter);
        }
    }

    constructor(public queueService: QueueService, private router: Router, private store: Store<any>) {
        this.store.select('user').subscribe((state) => {
            this.userRole = state.role;
        });
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('pageCountSlider') pageCountSlider;
    @ViewChild('ageSlider') ageSlider;

    setActivePage(index) {
        this.settings.activePage = index;
        this.paginator.pageIndex = index;
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    countAgeDuration() {
        this.minutesInAgeType = this.settings.ageType == 'days' ? 60 * 24 : 60;
        const maxAgeValue = Math.max(...this.data.map((a) => Math.floor(Number(a.age) / this.minutesInAgeType)));
        this.maxHoursCount = maxAgeValue;
        this.settings.filter.value.age = [0, maxAgeValue];
        this.settings.filter.initialValue.age = [0, maxAgeValue];
    }

    getAllItemsInQueue() {
        const data = { queueId: 2, take: 10000 };
        this.queueService.getAllItemsInQueue(data).subscribe(
            (response) => {
            this.data = response.map((queue) => {
                    return {
                        id: queue.id,
                        sourceType: queue.sourceType,
                        source: String(queue.id),
                        contact: String(queue.sourceString),
                        contactName: queue.sourceName,
                        pageCount: queue.pageCount,
                        receivedDate: queue.createDate,
                        age: queue.age.totalMinutes,
                        queueStatus: queue.queueStatus,
                        userImageS3Url: queue.userImageS3Url
                    };
                });
                this.dataSource = new MatTableDataSource(this.data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.setPagination();
                const maxPage = Math.max(...this.data.map((a) => a.pageCount));
                this.maxPageCount = maxPage;
                this.settings.filter.value.pageCount = [0, maxPage];
                this.countAgeDuration();
                this.settings.filter.initialValue = { ...this.settings.filter.value };
                this.isLoaded = true;
            },
            (error) => throwError(error)
        );
    }


    getUserImage(queue) {
        this.queueService.getUserImage(queue.userImageS3Url).subscribe(
            (image) => {
                createImageFromBlob(image, (reader) => {
                    let item = this.data.find(x => x.id == queue.id);
                    item.userImageS3Url = reader.result;
                });
            },
            (error) => throwError(error)
        );
    }

    openItem(queue) {
        if (this.isSupervisorOrManager() && queue.queueStatus != 1) {
            this.router.navigate(['/document-identify'], { queryParams: { id: queue.id } });
        }
    }

    isSupervisorOrManager() {
        return this.userRole == Roles.Supervisor || this.userRole == Roles.Manager;
    }

    ngOnInit() {
        this.getAllItemsInQueue();
    }

    customFilterPredicate() {
        const myFilterPredicate = (data, filter): boolean => {
            const sourceFilter = filter.source
                ? toSearchString(data.source).includes(toSearchString(filter.source))
                : true;

            const pageFilter = filter.pageCount
                ? data.pageCount >= filter.pageCount[0] && data.pageCount <= filter.pageCount[1]
                : true;

            var fullEndDate = new Date(filter.receivedDate.end);

            let receivedDateFilter;
            if (filter.receivedDate.start && filter.receivedDate.end) {
                receivedDateFilter =
                    new Date(data.receivedDate) >= new Date(filter.receivedDate.start) &&
                    new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else if (filter.receivedDate.start && !filter.receivedDate.end) {
                receivedDateFilter = new Date(data.receivedDate) >= new Date(filter.receivedDate.start);
            } else if (!filter.receivedDate.start && filter.receivedDate.end) {
                receivedDateFilter =
                    new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else {
                receivedDateFilter = true;
            }

            const ageFilter = filter.age
                ? data.age / this.minutesInAgeType >= filter.age[0] &&
                  data.age / this.minutesInAgeType <= filter.age[1] + 1
                : true;

            return sourceFilter && pageFilter && receivedDateFilter && ageFilter;
        };
        return myFilterPredicate;
    }
}
