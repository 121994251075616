import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { setIndicatorColor } from '../../../../../../../shared/helpers/utils';

@Component({
  selector: 'app-benefit-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['../../task.component.scss', '../benefit.component.scss', './pharmacy.component.scss'],
})
export class BenefitPharmacyComponent implements OnInit, OnChanges {
  pharmacyForm: FormGroup;
  setIndicatorColor = setIndicatorColor;
  @Input() settings;
  @Input() selectedTask;
  @Input() isDisabled;
  @Input() expanded;

  @Output() validateTaskHandler = new EventEmitter();
  @Output() openPanelHandler = new EventEmitter();
  @Output() closePanelHandler = new EventEmitter();
  @Output() currentSectionHandler = new EventEmitter();

  constructor() {
    this.pharmacyForm = new FormGroup({
      planInfo: new FormGroup({
        isCoverage: new FormControl(null, [Validators.required]),
        isSame: new FormControl(null, [Validators.required]),
        policyId: new FormControl(null, [Validators.required]),
        groupId: new FormControl(null, [Validators.required]),
        originalEffectiveDate: new FormControl(null, [Validators.required]),
        planEffectiveDateStart: new FormControl(null, [Validators.required]),
        planEffectiveDateEnd: new FormControl(null, [Validators.required]),
      }),
      policyLimits: new FormGroup({
        isSameAsPlanInfo: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        individualDeductibleAmount: new FormControl(null, [Validators.required]),
        isIndividualDeductibleMet: new FormControl(null, [Validators.required]),
        isIndividualDeductibleOOP: new FormControl(null, [Validators.required]),
        isCaryNextYear: new FormControl(null, [Validators.required]),
        familyDeductibleAmount: new FormControl(null, [Validators.required]),
        isFamilyDeductibleMet: new FormControl(null, [Validators.required]),
        isFamilyDeductibleOOP: new FormControl(null, [Validators.required]),
        isFamilyCaryNextYear: new FormControl(null, [Validators.required]),
        individualOOP: new FormControl(null, [Validators.required]),
        isIndividualOOPMet: new FormControl(null, [Validators.required]),
        individualOOPAmount: new FormControl(null, [Validators.required]),
        familyOOP: new FormControl(null, [Validators.required]),
        isFamilyOOPMet: new FormControl(null, [Validators.required]),
        familyOOPAmount: new FormControl(null, [Validators.required]),
        isIndividualLifetimeUnlimited: new FormControl(null, [Validators.required]),
        individualLifetimeMaximum: new FormControl(null, [Validators.required]),
        isIndividualLifetimeMaximumMet: new FormControl(null, [Validators.required]),
        isFamilyLifetimeUnlimited: new FormControl(null, [Validators.required]),
        familyLifetimeMaximum: new FormControl(null, [Validators.required]),
        isFamilyLifetimeMaximumMet: new FormControl(null, [Validators.required]),
        individualAnnualCap: new FormControl(null, [Validators.required]),
        isIndividualAnnualCapMet: new FormControl(null, [Validators.required]),
        familyAnnualCap: new FormControl(null, [Validators.required]),
        isFamilyAnnualCapMet: new FormControl(null, [Validators.required]),
      }),
      networkSpecialtyPharmacies: new FormGroup({
        specialty: new FormControl(null, [Validators.required]),
        phone: new FormControl(null, [Validators.required]),
        fax: new FormControl(null, [Validators.required]),
        isPreferred: new FormControl(null, [Validators.required]),
      }),
      drugStatus: new FormGroup({
        drugStatus: new FormControl(null, [Validators.required]),
        bin: new FormControl(null, [Validators.required]),
        pcn: new FormControl(null, [Validators.required]),
        quantity: new FormControl(null, [Validators.required]),
        per: new FormControl(null, [Validators.required]),
        override: new FormControl(null, [Validators.required]),
      }),
      authorization: new FormGroup({
        isPARequired: new FormControl(null, [Validators.required]),
        paPhone: new FormControl(null, [Validators.required]),
        needInitiateCall: new FormControl(null, [Validators.required]),
        paFax: new FormControl(null, [Validators.required]),
        needInitiateFax: new FormControl(null, [Validators.required]),
        isStepEditRequired: new FormControl(null, [Validators.required]),
        stepDrugs: new FormControl(null, [Validators.required]),
        isPAonFile: new FormControl(null, [Validators.required]),
        paReference: new FormControl(null, [Validators.required]),
        paValidDateStart: new FormControl(null, [Validators.required]),
        paValidDateEnd: new FormControl(null, [Validators.required]),
        isPreDRequired: new FormControl(null, [Validators.required]),
        preDPhone: new FormControl(null, [Validators.required]),
        preDFax: new FormControl(null, [Validators.required]),
        needCMInitiateFax: new FormControl(null, [Validators.required]),
        isStepPreDEditRequired: new FormControl(null, [Validators.required]),
        stepPreDDrugs: new FormControl(null, [Validators.required]),
        isLMNRequired: new FormControl(null, [Validators.required]),
        isLMNOnFile: new FormControl(null, [Validators.required]),
        isPreDFile: new FormControl(null, [Validators.required]),
        preDReference: new FormControl(null, [Validators.required]),
        preDValidDateStart: new FormControl(null, [Validators.required]),
        preDValidDateEnd: new FormControl(null, [Validators.required]),
        preDDoses: new FormControl(null, [Validators.required]),
        preDPer: new FormControl(null, [Validators.required]),
      }),
      exclusions: new FormGroup({
        isExclusions: new FormControl(null, [Validators.required]),
        exclusionsNote: new FormControl(null, [Validators.required]),
      }),
    });
  }

  openPanel(index) {
    if (!this.expanded) {
      this.openPanelHandler.emit({index, elementId: 'PharmacyPanel', sectionIndex: 3});
    }
  }

  closePanel(index) {
    this.closePanelHandler.emit(index);
  }

  currentSection(name: string): void {
    this.currentSectionHandler.emit(name);
  }

  validateTask(form) {
    const data = {
      form,
      formName: 'pharmacy',
    };
    this.validateTaskHandler.emit(data);
  }

  ngOnChanges(changes) {
    if (changes.isDisabled && changes.isDisabled.currentValue) {
      this.pharmacyForm.disable();
    } else if (changes.isDisabled && !changes.isDisabled.currentValue) {
      this.pharmacyForm.enable();
    }
  }

  ngOnInit(): void {
    this.pharmacyForm.valueChanges.subscribe(() => {
      this.validateTask(this.pharmacyForm);
    });
  }
}
