import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { User } from './shared/models/models';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user = new User();
  title = 'app';
  isAuthenticated: boolean;

  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router,
    private store: Store<any>,
  ) {
  }

  ngOnInit(): void {
  }
}
