import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ICase, IPatient, IResponse } from 'src/app/shared/interfaces/interfaces';
import { AbstractRestService } from '../../../../core/services/abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getCaseItem(id): Observable<ICase> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/${id}`;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getQueueCount(id: number): Observable<number> {
    this.url = this.apiUrl;
    return this.getItems<number>(`Queue/GetItemCountInQueue/${id}`);
  }

  public completeCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/CompleteTask`;
    return this.postItem<any, IResponse>(data);
  }

  public getPriorAuthorizations(id): Observable<any[]> {
    this.url = this.apiUrl + `/PriorAuthorizations/GetByCase/${id}`;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getFulfillments(id): Observable<any[]> {
    this.url = this.apiUrl + `/Fulfillments/GetByCase/${id}`;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSpecialtyPharmacies(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/SpecialtyPharmacies`;
    return this.postItem<any, IResponse>(data);
  }

  public putFulfillments(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Fulfillments`;
    return this.putItem<any, IResponse>(data);
  }

  public putPriorAuthorizations(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PriorAuthorizations`;
    return this.putItem<any, IResponse>(data);
  }

  public updatePatient(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Patients`;
    return this.updateItem<IPatient, IResponse>(data);
  }

  public getPhases(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PickList/Phases`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getReasons(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PickList/FollowUpReasons`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentConsents(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Consents`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentCaregivers(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentAddresses(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  createEnrollmentConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.putItem<any, IResponse>(data);
  }

  updateEnrollmentConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.updateItem<any, IResponse>(data);
  }

  createEnrollmentCaregiver(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.putItem<any, IResponse>(data);
  }

  updateEnrollmentCaregiver(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.updateItem<any, IResponse>(data);
  }

  createEnrollmentAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.putItem<any, IResponse>(data);
  }

  updateEnrollmentAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.updateItem<any, IResponse>(data);
  }

  getAddressHistory(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientAddressHistory`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
