import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { IManagerUser } from '../../../../../shared/interfaces/interfaces';
import { SupervisorAddUserColumns } from '../../../../../shared/enums/enums';
import { enumToArray, getQueueTypeName } from '../../../../../shared/helpers/utils';
import { QueueCreateService } from '../../../creation/creation.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-supervisor-user-add',
    templateUrl: './add.component.html',
    styleUrls: ['../users.component.scss', './add.component.scss'],
})
export class SupervisorAddUserComponent implements OnInit {

    @Output() usersSaved = new EventEmitter<IManagerUser[]>();

    searchValue = '';
    columnsToDisplay = enumToArray(SupervisorAddUserColumns);
    addedUsers: IManagerUser[] = [];
    queueId;
    data = [];
    dataSource;
    isLoading: boolean = false;
    isAllSelected = false;


    checkUser(user) {
        if (!this.isSelected(user.id)) {
            this.addedUsers.push(user);
        }
        else {
            this.addedUsers = this.addedUsers.filter(user => user.id !== user.id);
        }
    }

    isSelected(id) {
        return this.addedUsers.some(x => x.id == id);
    }    

    selectAll() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.addedUsers = this.data;
        } else {
            this.addedUsers = [];
        }
    }

    saveUsers() {
        this.usersSaved.emit(this.addedUsers);
    }

    clearSearch() {
        this.searchValue = '';
        this.getUsers();
    }

    getUsers() {
        const data = { take: 0, search: this.searchValue, queueType: getQueueTypeName(this.queueId) };
        this.isLoading = true;
        this.isLoading &&
            this.queueCreateService.getUsers(data).subscribe(
                (response) => {
                    this.data = response.value.map((user) => {
                        return {
                            id: user.id,
                            userName: `${user.firstName} ${user.lastName}`,
                            assignments: user.queueConfigurations ? user.queueConfigurations.join(', ') : '',
                            userRoles: user.userRoles
                        };
                    });

                    this.dataSource = new MatTableDataSource(this.data);
                },
                (error) => throwError(error),
                () => (this.isLoading = false)
            );
    }

    constructor(public queueCreateService: QueueCreateService, @Inject(MAT_DIALOG_DATA) public dialogData) {
        this.addedUsers = [...dialogData.addedUsers];
        this.queueId = dialogData.queueId;
    }

    ngOnInit(): void {
        this.getUsers();
    }
}
