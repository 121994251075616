import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-manage-empty-queue',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
})
export class EmptyManageQueueComponent implements OnInit {
    @Input() type: string;
    @Output() addFunction = new EventEmitter();

    addHandler(){
        this.addFunction.emit();
    }

    constructor() {}

    ngOnInit(): void {}
}
