import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export abstract class AbstractRestService {
  protected url: string;

  protected constructor(protected http: HttpClient) {
  }

  protected get apiUrl(): string {
    return environment.resourceServer.apiUrl;
  }

  public getItems<T>(id?: number | string): Observable<T> {
    return this.http.get<T>(`${this.url}/${id}`);
  }

  public postItem<T, TK>(entity?: T): Observable<TK> {
    return this.http.post<TK>(`${this.url}`, entity);
  }

  public updateItem<T, TK>(entity: T): Observable<TK> {
    return this.http.patch<TK>(`${this.url}`, entity);
  }

  public putItem<T, TK>(entity: T): Observable<TK> {
    return this.http.put<TK>(`${this.url}`, entity);
  }

  public getImage(imgUrl: string): Observable<Blob> {
    return this.http.get(`${this.url}/${imgUrl}`, {responseType: 'blob'});
  }

  public deleteItem<T, TK>(entity: T): Observable<TK> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {...entity}
    };
    return this.http.delete<TK>(`${this.url}`, options);
  }
}
