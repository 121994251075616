import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IResponse } from 'src/app/shared/interfaces/interfaces';

@Injectable({
    providedIn: 'root',
})
export class SearchService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public getSearchedCases(data: any): Observable<any[]> {
        this.url = this.apiUrl + `/CaseManagementQueueItem/Search`;
        return this.postItem<any, IResponse>(data).pipe(
            map((response) => {
                let cases = response.value.map((item) => {
                    return {
                        ...item,
                        payer:
                            item.patientInsurance && item.patientInsurance.payerName
                                ? item.patientInsurance.payerName
                                : '',
                        patient: item.patient
                            ? (item.patient.firstName || '') + ' ' + (item.patient.lastName || '')
                            : '',
                        physician: item.physician ? item.physician.firstName + ' ' + item.physician.lastName : '',
                        phase: item.phaseName ? item.phaseName : '',
                        territory: item.territory ? item.territory : '',
                    };
                });
                return cases;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
}
