import { Component, OnInit, Input } from '@angular/core';
import { Label } from 'ng2-charts';
import { Store } from '@ngrx/store';
import { QueueIDs } from '../../enums/enums';

@Component({
    selector: 'app-queue-item',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.scss'],
})
export class QueueItemComponent implements OnInit {
    @Input() data;
    @Input() beginRoute;
    @Input() viewRoute;
    @Input() viewParams;
    userRole: string;
    public lineChartLabels: Label[] = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su', 'M'];
    constructor(private store: Store<any>) {
        this.store.select('user').subscribe((state) => {
            this.userRole = state.role;
        });
    }

    public lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
            padding: {
                top: 5,
                bottom: 5,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            yAxes: [
                {
                    display: false,
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    display: false,
                    ticks: {
                        display: false,
                    },
                },
            ],
        },
    };

    public chartType = {
        inc: [
            {
                borderColor: '#e3745e',
                backgroundColor: 'rgba(227,116,94,0.3)',
            },
        ],
        desc: [
            {
                borderColor: '#5dcaa0',
                backgroundColor: 'rgba(93,202,160,0.3)',
            },
        ],
        stable: [
            {
                borderColor: '#345a93',
                backgroundColor: 'rgba(52,90,147,0.3)',
            },
        ],
    };
    public lineChartType = 'line';

    public showBegin() {
        if (
            this.beginRoute &&
            ((this.userRole == 'Supervisor' && this.data.id == QueueIDs.supervisor) ||
                (this.userRole == 'Intake' &&
                    (this.data.id == QueueIDs.identify || this.data.id == QueueIDs['data-entry'])) ||
                this.userRole == 'Manager')
        ) {
            return true;
        }
        return false;
    }

    public showViewList() {
        if (
            this.beginRoute &&
            ((this.userRole == 'Supervisor' && this.data.id == QueueIDs.supervisor) || this.userRole == 'Manager')
        ) {
            return true;
        }
        return false;
    }

    public showManage() {
        if (this.userRole == 'Supervisor' && this.data.id != QueueIDs.supervisor) {
            return true;
        }
        return false;
    }

    ngOnInit(): void { }
}
