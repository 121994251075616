import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-email-task',
    templateUrl: './email.component.html',
    styleUrls: ['../reschedule/reschedule.component.scss', './email.component.scss'],
})
export class EmailTaskComponent implements OnInit {
    patientFirstName;
    patientLastName;
    createDate;
    subject;
    body;
    case;
    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.case = data.case;
        this.patientFirstName = data.case.patient.firstName;
        this.patientLastName = data.case.patient.lastName;
        this.createDate = data.sendDate;
        this.subject = data.taskData.subject;
        this.body = interpolateTemplate(data.taskData.body, this.getBodyTemplateObject());
    }

    ngOnInit(): void { }

    getBodyTemplateObject() {
        let patientName = `${this.case.patient.firstName || ''} ${this.case.patient.lastName || ''}`;
        let physicianLastName = `${this.case.physician?.lastName || ''}`;
        return {
            patientName: patientName,
            caseManagerName: 'Heather',
            physicianLastName: physicianLastName
        };
    }
}
