import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { caseTasks } from '../../../../../shared/data/data';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContactMethod, HistoryTaskType, TargetNames, TaskContactMethodNames, TaskDirectionNames } from 'src/app/shared/enums/enums';
import * as moment from 'moment';
import { TextTaskComponent } from '../task/modals/text/text.component';
import { EmailTaskComponent } from '../task/modals/email/email.component';
import { MatDialog } from '@angular/material/dialog';
import { interpolateTemplate } from '../../../../../shared/helpers/utils';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-case-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements AfterViewInit {
  @Input() case;
  historyType = new FormControl();
  historyTypes = [
    {
      type: HistoryTaskType.task,
      name: 'Activity'
    },
    {
      type: HistoryTaskType.statusChange,
      name: 'Status Changes'
    }
  ];
  historyPhase = new FormControl();
  historyPhaseList: string[] = [
    'Enrollment',
    'Benefits Verification',
    'Prior Authorization',
    'Fulfillment',
    'Adherence',
  ];
  dataSource;
  data = caseTasks;
  columnsToDisplay = ['task', 'createDate', 'completed', 'outcome', 'action'];
  expandedElements = [];
  targetNames = TargetNames;
  contactMethodNames = TaskContactMethodNames;
  directionNames = TaskDirectionNames;
  contactMethod;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("phaseFilter") phaseFilter: MatSelect;

  _completedTasks = [];
  _historyTimeline = [];

  @Input()
  get historyTimeline() {
    return this._historyTimeline;
  }

  set historyTimeline(historyTimeline) {
    if (this._historyTimeline !== historyTimeline) {
      this._historyTimeline = historyTimeline;
    }
  }

  statusChanged(prev, current) {
    if (!prev) {
      return true;
    }
    if (current?.outcome == null) {
      return false;
    }
    if (prev?.status != current?.status && (current?.status ?? "" != "")) {
      return true;
    }
    if (prev?.subStatus != current?.subStatus && (current?.subStatus ?? "" != "")) {
      return true;
    }
    if (prev?.subStatusReason != current?.subStatusReason && (current?.subStatusReason ?? "" != "")) {
      return true;
    }
    return false;
  }


  @Input()
  get completedTasks() {
    return this._completedTasks;
  }

  set completedTasks(completedTasks) {
    if (this._completedTasks !== completedTasks) {
      const tasks = [];
      let prev = null;

      completedTasks.forEach((item, index) => {
        if (this.statusChanged(prev, item)) {
          const change = {
            ...item,
            type: HistoryTaskType.statusChange
          };
          const date = item.completed || item.createDate;
          const completed = moment(date);
          change.completed = completed.set('second', completed.get('second') - 1).format();
          tasks.push(change);
          prev = item;
        }
        if (item.outcome != null && item.taskId != 0) {
          this.populateAttachmentSubject(item);
          tasks.push({
            ...item,
            task: interpolateTemplate(item.task, {
              reason: item.followUpReason,
              contactMethod: TaskContactMethodNames[item.contactMethod] || '',
              entity: TargetNames[item.target] || ''
            }),
            type: HistoryTaskType.task
          });
        }
      });
      this._completedTasks = tasks;
      this.data = this._completedTasks;
      this.dataSource = new MatTableDataSource(this.data);
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
    }
  }

  constructor(public dialog: MatDialog) {
    this.historyType.setValue(this.historyTypes.map(x => x.type));
    this.historyPhase.setValue(this.historyPhaseList);
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  populateAttachmentSubject(task) {
    if (task.attachment && !task.attachment.subject) {
      task.attachment.subject = task.contactMethod === ContactMethod.text ? `${task.task} Text` : '';
    }
  }

  clearFilter() {
    this.historyPhase.setValue([]);
    this.applyFilter('phases');
    this.phaseFilter.close();
  }

  applyAllFilter() {
    this.historyPhase.setValue(this.historyPhaseList);
    this.applyFilter('phases');
    this.phaseFilter.close();
  }

  toggleRow(id) {
    if (this.expandedElements.includes(id)) {
      this.expandedElements = this.expandedElements.filter((item) => item !== id);
    } else {
      this.expandedElements.push(id);
    }
  }

  isTypeInList(type) {
    return this.historyType.value.includes(type);
  }

  isPhaseInList(phase) {
    return this.historyPhase.value.includes(phase);
  }

  isExpanded(id) {
    return this.expandedElements.includes(id);
  }

  selectionChanged(filter) {
    this.applyFilter(filter);
  }

  applyFilter(filter) {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = filter;
  }

  customFilterPredicate() {
    const myFilterPredicate = (data, filter): boolean => {
      let check = this.isTypeInList(data.type)
        && (data.type == HistoryTaskType.statusChange || data.type == HistoryTaskType.task && this.isPhaseInList(data.phase));
      return check;
    };
    return myFilterPredicate;
  }

  isStatusChange(index, item) {
    return item.type == HistoryTaskType.statusChange;
  }

  isActivity(index, item) {
    return item.type == HistoryTaskType.task;
  }

  openModal(element, attachment) {
    if (element.contactMethod === ContactMethod.email) {
      this.dialog.open(EmailTaskComponent, {
        data: { case: this.case, taskData: attachment.data, sendDate: element.completed },
      });
    } else if (element.contactMethod === ContactMethod.text) {
      this.dialog.open(TextTaskComponent, {
        data: { case: this.case, taskData: attachment.data, sendDate: element.completed },
      });
    } else if (attachment.data?.link) {
      const win = window.open(attachment.data.link, '_blank');
      win.focus();
    }
  }
}
