import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    transform(obj: any, includeMiddle: boolean): any {
        if (obj) {
            if (obj.firstName && obj.lastName) {
                if (includeMiddle && obj.middleName) {
                    return `${obj.firstName} ${obj.middleName} ${obj.lastName}`;
                }
                return `${obj.firstName} ${obj.lastName}`;
            }
            else if (obj.firstName) {
                return obj.firstName;
            }
            else if (obj.lastName) {
                return obj.lastName;
            }
        }
        return '';
    }
}
