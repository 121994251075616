import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, TaskOutcome } from 'src/app/shared/enums/enums';
import { CaseService } from '../../../services/case.service';
import { throwError } from 'rxjs';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
    selector: 'app-task-triage',
    templateUrl: './triage.component.html',
    styleUrls: ['../task.component.scss', './triage.component.scss'],
})
export class TriageTaskComponent implements OnInit {
    @Input() selectedTask;
    @Input() case;
    @Output() minimizeTaskHandler = new EventEmitter();
    @Output() setSelectedTaskHandler = new EventEmitter();
    @Output() rescheduleTaskHandler = new EventEmitter();
    @Output() submitTaskHandler = new EventEmitter();
    triageForm: FormGroup;
    selectedPharmacy = null;
    pharmacies;

    constructor(public caseService: CaseService) {
        this.triageForm = new FormGroup({
            note: new FormControl(null, [Validators.required]),
        });
    }

    minimizeTask(boolean) {
        this.selectedTask.formData = this.triageForm.getRawValue();
        this.selectedTask.selectedPharmacy = this.selectedPharmacy;
        this.minimizeTaskHandler.emit(boolean);
    }

    setSelectedTask(task) {
        this.setSelectedTaskHandler.emit(task);
    }

    selectPharmacy(pharmacy) {
        this.selectedPharmacy = pharmacy;
    }

    submitTask() {
        const data = {
            queueItemId: this.case.id,
            queueTaskId: this.selectedTask.id,
            taskOutcome: TaskOutcome.success,
            outcomeNote: this.triageForm.value.note,
        };
        this.submitTaskHandler.emit(data);
        this.setFulfillments();
    }

    getSpecialtyPharmacies() {
        const data = {
            take: 0,
        };
        this.caseService.getSpecialtyPharmacies(data).subscribe(
            (response) => {
                this.pharmacies = response.value.map((pharmacy) => {
                    return {
                        id: pharmacy.id,
                        name: pharmacy.name,
                        phone: pharmacy.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
                            .contactString,
                        fax: pharmacy.contactInfos.find((contact) => contact.contactMethod == ContactMethod.fax)
                            .contactString,
                    };
                });
                console.log(this.pharmacies);
            },
            (error) => throwError(error)
        );
    }

    setFulfillments() {
        const data = {
            fulfillment: {
                specialtyPharmacyId: this.selectedPharmacy.id,
                caseId: this.case.id,
            },
        };
        this.caseService.putFulfillments(data).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => throwError(error)
        );
    }

    ngOnInit(): void {
        this.getSpecialtyPharmacies();
        this.selectedPharmacy = this.selectedTask.selectedPharmacy;
        if (this.selectedTask.formData) {
            this.triageForm.patchValue(this.selectedTask.formData);
        }
    }
}
