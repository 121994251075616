import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Store } from '@ngrx/store';
import { LoginService } from '../../../features/login/login.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../search/modal/modal.component';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../core/classes/UnsubscribeOnDestroy';
import { IUser } from '../../interfaces/user.interface';
import { ICase } from '../../interfaces/case.interface';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideComponent extends UnsubscribeOnDestroy implements OnInit {

  case$: BehaviorSubject<ICase> = new BehaviorSubject<ICase>({} as ICase);
  user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({});
  isSearchModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public oktaAuth: OktaAuthService,
    private store: Store<any>,
    public loginService: LoginService,
    public dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select('user')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        this.user$.next(state);
      });

    this.store.select('case')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => (this.case$.next(state)));
  }

  logout(): void {
    this.loginService.logout().subscribe(
      () => {
        this.oktaAuth.logout('/');
      },
      (error) => throwError(error)
    );
  }

  openModal(): void {
    this.isSearchModalOpen$.next(true);

    this.dialog.open(SearchModalComponent, {
      data: {searchValue: ''},
      panelClass: 'searchDialog',
    }).afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.isSearchModalOpen$.next(false));
  }
}
