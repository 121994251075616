import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../../shared/interfaces/interfaces';
import { IUpdateData } from '../manage/update.models';

@Injectable({
    providedIn: 'root',
})
export class QueueUpdateService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public updateQueue(data: IUpdateData): Observable<IResponse> {
        this.url = this.apiUrl + `/QueueConfiguration`;
        return this.updateItem<IUpdateData, IResponse>(data);
    }

    public getQueue(id: number): Observable<IResponse> {
        this.url = this.apiUrl + `/QueueConfiguration`;
        return this.getItems<IResponse>(id);
    }
}
