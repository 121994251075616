import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-physician-form',
  templateUrl: './new-physician-form.component.html',
  styleUrls: ['./new-physician-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPhysicianFormComponent {
  physicianForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    npi: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
    specialty: new FormControl('', Validators.required),
  });

  @Input() set initName(value: string) {
    const slittedName = value.split(' ');
    this.physicianForm.get('firstName').patchValue(slittedName[0] ? slittedName[0] : '');
    this.physicianForm.get('lastName').patchValue(slittedName[1] ? slittedName[1] : '');
  }
  @Input() set getFocus(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.newPhysicianName.nativeElement.select();
      });
    }
  }

  @Output() create: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('newPhysicianName') newPhysicianName: ElementRef;

  constructor() {
  }

  cancelEvent(): void {
    this.physicianForm.reset();
    this.cancel.emit();
  }
}
