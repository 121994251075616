import { Component, OnInit, ViewChild } from '@angular/core';
import { enumToArray, toSearchString, getSuggestedCases } from '../../helpers/utils';
import { SearchOptions, AdvancedSearchOptions } from 'src/app/shared/models/models';
import { MatDialog } from '@angular/material/dialog';
import { cases } from './search.model';
import { SearchService } from './search.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SearchColumns, PhasesNames, CaseStatus, CaseSubStatus } from 'src/app/shared/enums/enums';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class SearchComponent implements OnInit {
    state;
    searchOptions = new SearchOptions();
    data = [];
    suggestedCases = [];
    dataSource;
    columnsToDisplay = enumToArray(SearchColumns);
    searchType = 0;
    isFiltersNotUsed = true;
    isLoaded = false;

    settings = {
        itemPerPage: 20,
        paginationPageCount: null,
        activePage: 0,
        ageType: 'hours',
        filter: {
            show: {
                patient: false,
                payer: false,
                physician: false,
                territory: false,
                phase: false,
                status: false,
                subStatus: false,
                updated: false,
            },
            value: {
                patient: null,
                payer: null,
                physician: null,
                territory: null,
                phase: {
                    enrollment: false,
                    benefitsVerification: false,
                    priorAuthorization: false,
                    fulfillment: false,
                },
                status: {
                    pending: false,
                    inProcess: false,
                    closed: false,
                    completed: false,
                },
                subStatus: {
                    missingInformation: false,
                    benefitsVerification: false,
                    followUpWithHCP: false,
                    priorAuthorization: false,
                    awaitingShipment: false,
                },
                updated: { start: '', end: '' },
            },
            initialValue: {
                patient: null,
                payer: null,
                physician: null,
                territory: null,
                phase: {
                    enrollment: false,
                    benefitsVerification: false,
                    priorAuthorization: false,
                    fulfillment: false,
                },
                status: {
                    pending: false,
                    inProcess: false,
                    closed: false,
                    completed: false,
                },
                subStatus: {
                    missingInformation: false,
                    benefitsVerification: false,
                    followUpWithHCP: false,
                    priorAuthorization: false,
                    awaitingShipment: false,
                },
                updated: { start: '', end: '' },
            },
        },
    };

    constructor(public dialog: MatDialog, public searchService: SearchService, private router: Router) {
        if (this.router.getCurrentNavigation().extras.state) {
            this.searchOptions.searchValue = this.router.getCurrentNavigation().extras.state.searchValue;
            let cases = this.router.getCurrentNavigation().extras.state.cases;
            if (cases && cases.length > 0) {
                this.setCases(cases);
            }
            else {
                this.searchCases();
            }
        }
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    searchCases() {
        const data = { take: 0, search: this.searchOptions.searchValue, type: this.searchType };
        this.isLoaded = false;
        this.searchService.getSearchedCases(data).subscribe(
            (response) => {
                this.isLoaded = true;
                this.setCases(response);
            },
            (error) => {
                throwError(error);
            }
        );
    }

    setCases(cases) {
        this.data = cases;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.settings.filter.initialValue = { ...this.settings.filter.value };
        this.setPagination();
    }

    setActivePage(index) {
        this.settings.activePage = index;
        this.paginator.pageIndex = index;
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    openFilter(filter) {
        this.settings.filter.show[filter] = true;
    }

    closeFilter(filter) {
        this.settings.filter.show[filter] = false;
    }

    clearFilter(filter) {
        switch (filter) {
            case 'phase':
                this.settings.filter.value[filter] = {
                    enrollment: false,
                    benefitsVerification: false,
                    priorAuthorization: false,
                    fulfillment: false,
                };
                break;
            case 'status':
                this.settings.filter.value[filter] = {
                    pending: false,
                    inProcess: false,
                    closed: false,
                    completed: false,
                };
                break;
            case 'subStatus':
                this.settings.filter.value[filter] = {
                    missingInformation: false,
                    benefitsVerification: false,
                    followUpWithHCP: false,
                    priorAuthorization: false,
                    awaitingShipment: false,
                };
                break;
            case 'updated':
                this.settings.filter.value[filter] = { start: '', end: '' };
                break;
            default:
                this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
        }
        this.applyFilter(filter);
    }

    clearAllFilters() {
        this.settings.filter.value = {
            ...this.settings.filter.initialValue,
            phase: {
                enrollment: false,
                benefitsVerification: false,
                priorAuthorization: false,
                fulfillment: false,
            },
            status: {
                pending: false,
                inProcess: false,
                closed: false,
                completed: false,
            },
            subStatus: {
                missingInformation: false,
                benefitsVerification: false,
                followUpWithHCP: false,
                priorAuthorization: false,
                awaitingShipment: false,
            },
            updated: { start: '', end: '' },
        };
        this.applyFilter('');
    }

    checkIsFilterUsed() {
        this.isFiltersNotUsed =
            !!this.settings.filter.value.patient == !!this.settings.filter.initialValue.patient &&
            !!this.settings.filter.value.payer == !!this.settings.filter.initialValue.payer &&
            !!this.settings.filter.value.physician == !!this.settings.filter.initialValue.physician &&
            !!this.settings.filter.value.territory == !!this.settings.filter.initialValue.territory &&
            !this.isCheckboxSelected('phase') &&
            !this.isCheckboxSelected('status') &&
            !this.isCheckboxSelected('subStatus') &&
            !this.settings.filter.value.updated.start &&
            !this.settings.filter.value.updated.end
    }

    setPagination() {
        this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
    }

    applyFilter(filter) {
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSource.filter = this.settings.filter.value;
        this.closeFilter(filter);
        this.checkIsFilterUsed();
        this.setPagination();
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            let shownFilter;
            for (const [key, value] of Object.entries(this.settings.filter.show)) {
                if (this.settings.filter.show[key]) {
                    shownFilter = key;
                }
            }
            shownFilter && this.applyFilter(shownFilter);
        }
    }

    isCheckboxSelected(type) {
        return Object.values(this.settings.filter.value[type]).includes(true);
    }

    ngOnInit(): void {
        this.searchCases();
        this.suggestedCases = getSuggestedCases();
    }

    addSearchHandler() {
        // this.addSearch.emit();
    }

    clearSearchHandler() {
        this.searchOptions = new SearchOptions();
        // this.clearSearch.emit();
    }

    showCasesHandler() {
        // this.showCases.emit({
        //     search: this.searchOptions.searchValue,
        //     status: this.searchOptions.statusValue,
        // });
    }

    openCase(caseId) {
        this.router.navigate(['/case'], { queryParams: { id: caseId } })
    }

    customFilterPredicate() {
        const myFilterPredicate = (data, filter): boolean => {
            const patientFilter = filter.patient
                ? toSearchString(data.patient).includes(toSearchString(filter.patient))
                : true;
            const payerFilter = filter.payer ? toSearchString(data.payer).includes(toSearchString(filter.payer)) : true;
            const physicianFilter = filter.physician
                ? toSearchString(data.physician).includes(toSearchString(filter.physician))
                : true;
            const territoryFilter = filter.territory
                ? toSearchString(data.territory).includes(toSearchString(filter.territory))
                : true;

            let phaseFilter = false;
            if (Object.values(filter.phase).includes(true)) {
                phaseFilter = phaseFilter || filter.phase[PhasesNames[data.phase]];
            } else {
                phaseFilter = true;
            }

            let statusFilter = false;
            if (Object.values(filter.status).includes(true)) {
                statusFilter = statusFilter || filter.status[CaseStatus[data.status]];
            } else {
                statusFilter = true;
            }

            let subStatusFilter = false;
            if (Object.values(filter.subStatus).includes(true)) {
                subStatusFilter = subStatusFilter || filter.subStatus[CaseSubStatus[data.subStatus]];
            } else {
                subStatusFilter = true;
            }

            var fullEndDate = new Date(filter.updated.end);
            let updatedFilter;
            if (filter.updated.start && filter.updated.end) {
                updatedFilter =
                    new Date(data.updated) >= new Date(filter.updated.start) &&
                    new Date(data.updated) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else if (filter.updated.start && !filter.updated.end) {
                updatedFilter = new Date(data.updated) >= new Date(filter.updated.start);
            } else if (!filter.updated.start && filter.updated.end) {
                updatedFilter = new Date(data.updated) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else {
                updatedFilter = true;
            }

            return (
                patientFilter &&
                payerFilter &&
                physicianFilter &&
                territoryFilter &&
                phaseFilter &&
                updatedFilter &&
                statusFilter &&
                subStatusFilter
            );
        };
        return myFilterPredicate;
    }
}
