<div class="task-wrapper">
    <div class="task-title">
        <h2>Benefit Verification</h2>
    </div>
    <div class="task-description-wrapper">
        <div class="task-description">
            <div class="task-description-item">
                <h5>Outcome</h5>
                <p>Covered</p>
            </div>
            <div class="task-description-item">
                <h5>Phase Started</h5>
                <p>{{started | date: "M/d/yyyy \'at\' h:mm aaaaa\'m\'" | empty}}</p>
            </div>
            <div class="task-description-item">
                <h5>Phase Completed</h5>
                <p>{{completed | date: "M/d/yyyy \'at\' h:mm aaaaa\'m\'" | empty}}</p>
            </div>
            <div class="task-description-item">
                <h5>Time in Phase</h5>
                <p>{{timeInPhase | empty}} (Avg. is 2 days)</p>
            </div>
        </div>
        <div class="task-actions" *ngIf="showEdit">
            <div class="primary-btn" (click)="toggleDisabled()">
                <mat-icon>create</mat-icon> Edit
            </div>
            <div class="left-time"><span>{{leftHours}}</span> left to edit</div>
        </div>
    </div>
</div>
<app-task-benefit [isDisabled]="isDisabled" [case]="case"></app-task-benefit>