import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
    failed: Boolean;
    message: '';

    constructor(public oktaAuth: OktaAuthService, private http: HttpClient) {
        this.message = '';
    }

    async ngOnInit() {
        const accessToken = await this.oktaAuth.getAccessToken();
        this.http
            .get(`${environment.resourceServer.apiUrl}/test`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                },
            })
            .subscribe(
                (data: any) => {
                    this.message = data.message;
                },
                (err) => {
                    console.error(err);
                    this.failed = true;
                }
            );
    }
}
