import { Action } from '@ngrx/store'
import { ICaseInfo } from "../../shared/interfaces/interfaces"

export enum CaseTypes {
    SET_CASE = 'SET_CASE',
    REMOVE_CASE = 'REMOVE_CASE',
}

export class SetCase implements Action {
    readonly type = CaseTypes.SET_CASE
    constructor(public payload: ICaseInfo){}
}

export class RemoveCase implements Action {
    readonly type = CaseTypes.REMOVE_CASE
}

export type CaseActions = SetCase | RemoveCase