import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
    @Input() settings;
    @Output() setActivePageHandler = new EventEmitter();
    pageCount: number = 0;
    pages = [];

    setActivePage(index) {
        this.setActivePageHandler.emit(index);
    }

    counter(i: number) {
        return new Array(i);
    }

    constructor() {}

    ngOnInit(): void {}
}
