import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { IManagerUser, ISupervisorTask, ITerritory } from 'src/app/shared/interfaces/interfaces';
import { region } from '../../../shared/data/data';
import { QueueUpdateService } from './update.service';
import { IUpdateData } from './update.models';

@Component({
    selector: 'app-supervisor-manage-queue',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.scss'],
})
export class SupervisorManageQueueComponent implements OnInit, AfterViewInit {
    activePage: string = 'Users';
    region = region;
    queueConfiguration = {
        id: '',
        users: [],
        territories: [],
        tasks: [],
        name: '',
        description: '',
        queueId: '',
        userCanPull: 'pull',
        active: 'disable'
    };
    isLoaded = true;

    actions = {
        'infoSaved': this.onInfoSaved,
        'userRemoved': this.onUserRemoved,
        'usersSaved': this.onUsersSaved,
        'territoryRemoved': this.onTerritoryRemoved,
        'territoriesSaved': this.onTerritoriesSaved,
        'taskRemoved': this.onTaskRemoved,
        'tasksSaved': this.onTasksSaved,
        'settingsSaved': this.onSettingsSaved
    }

    constructor(public queueUpdateService: QueueUpdateService, private route: ActivatedRoute) {

    }

    setActivePage(active: string) {
        return (this.activePage = active);
    }

    updateQueue(arg, functionName) {
        this.actions[functionName].call(this, arg);
        this.saveQueue();
    }

    onInfoSaved(model) {
        this.queueConfiguration.name = model.name;
        this.queueConfiguration.description = model.description;
    }

    onUserRemoved(id: number) {
        this.queueConfiguration.users = this.queueConfiguration.users.filter(x => x.id != id);
    }

    onUsersSaved(users: IManagerUser[]) {
        this.queueConfiguration.users = [...users];
    }

    onTerritoryRemoved(id: number) {
        this.queueConfiguration.territories = this.queueConfiguration.territories.filter(x => x.id != id);
    }

    onTerritoriesSaved(territories: ITerritory[]) {
        this.queueConfiguration.territories = [...territories];
    }

    onTaskRemoved(id: number) {
        this.queueConfiguration.tasks = this.queueConfiguration.tasks.filter(x => x.id != id);
    }

    onTasksSaved(tasks: ISupervisorTask[]) {
        this.queueConfiguration.tasks = [...tasks];
    }

    onSettingsSaved(model) {
        this.queueConfiguration.userCanPull = model.userCanPull;
        this.queueConfiguration.active = model.active;
    }

    saveQueue() {
        let updateModel: IUpdateData = {
            queueConfiguration: {
                id: this.queueConfiguration.id,
                name: this.queueConfiguration.name,
                description: this.queueConfiguration.description,
                userCanPull: this.queueConfiguration.userCanPull == 'pull',
                active: this.queueConfiguration.active == 'enable',
                queueId: this.queueConfiguration.queueId,
                userIDs: this.queueConfiguration.users.map(item => item.id),
                territoryIDs: this.queueConfiguration.territories.map(item => item.id),
                queueItemTaskIDs: this.queueConfiguration.tasks.map(item => item.id)
            }
        };

        this.queueUpdateService.updateQueue(updateModel).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => throwError(error)
        );
    }

    getQueueConfiguration(id: number) {
        this.isLoaded = false;
        this.queueUpdateService.getQueue(id).subscribe(
            (response) => {
                this.queueConfiguration = {
                    ...response.value,
                    users: response.value.users.map((user) => {
                        return {
                            id: user.id,
                            userName: `${user.firstName} ${user.lastName}`,
                            active: user.active
                        };
                    }),
                    userCanPull: response.value.userCanPull ? 'pull' : 'push',
                    active: response.value.active ? 'enable' : 'disable'
                }
            },
            (error) => throwError(error),
            ()=> this.isLoaded = true
        );
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(queryParams => {
            let id: number = Number(queryParams.get('id'));
            this.getQueueConfiguration(id);
        });
    }

    ngAfterViewInit() {
    }
}
