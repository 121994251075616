import { Component, OnInit, ViewChild } from '@angular/core';
import { enumToArray, setAge, setAgeColor, deepCopy } from '../../../../shared/helpers/utils';
import { SupervisorQueueTaskColumns } from '../../../../shared/enums/enums';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supervisor-queue-task',
  templateUrl: './task.component.html',
  styleUrls: ['../../../manager/queue/queue.component.scss', './task.component.scss'],
  host: {
      '(document:keydown)': 'handleKeyboardEvents($event)',
  },
})
export class SupervisorQueueTaskComponent implements OnInit {
  setAge = setAge;
  setAgeColor = setAgeColor;
  columnsToDisplay = enumToArray(SupervisorQueueTaskColumns);
  data = [];
  dataSource;
  isLoaded = false;
  maxHoursCount;
  minutesInAgeType;
  previousValue = null;

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
    ageType: 'hours',
    filter: {
      show: {
        age: false,
      },
      value: {
        age: [0, 1],
      },
      initialValue: {
        age: [0, 1],
      },
    },
  };

  constructor(private router: Router) { }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('ageSlider') ageSlider;

  setActivePage(index) {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  onChangeRange(rangeSlider) {
    const rangeType = rangeSlider.replace('Slider', '');
    this[rangeSlider].slider.set(this.settings.filter.value[rangeType]);
  }

  openFilter(filter) {
    this.previousValue = deepCopy(this.settings.filter.value)
    this.closeAllFilters();
    this.settings.filter.show[filter] = true;
  }

  closeFilter(filter) {
    this.settings.filter.show[filter] = false;
    this.settings.filter.value = this.previousValue
  }

  closeAllFilters() {
      const shownFilters = this.settings.filter.show;
      Object.keys(shownFilters).forEach((k) => {
          shownFilters[k] = false;
      });
      this.settings.filter.value = deepCopy(this.previousValue)
  }

  clearFilter(filter) {
    switch (filter) {
      default:
        this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
    }
    this.applyFilter(filter);
  }

  setPagination() {
    this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
  }

  applyFilter(filter) {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = this.settings.filter.value;
    this.settings.filter.show[filter] = false;
    this.setPagination();
  }  

    handleKeyboardEvents(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            let shownFilter;
            for (const [key, value] of Object.entries(this.settings.filter.show)) {
                if (this.settings.filter.show[key]) {
                    shownFilter = key;
                }
            }
            shownFilter && this.applyFilter(shownFilter);
        }
    }

  clearAllFilters() {
    this.settings.filter.value = {
      ...this.settings.filter.initialValue,
    };
    this.applyFilter('');
  }

  countAgeDuration() {
    this.minutesInAgeType = this.settings.ageType == 'days' ? 60 * 24 : 60;
    const maxAgeValue = Math.max(...this.data.map((a) => Math.floor(Number(a.age) / this.minutesInAgeType)));
    this.maxHoursCount = maxAgeValue;
    this.settings.filter.value.age = [0, maxAgeValue];
    this.settings.filter.initialValue.age = [0, maxAgeValue];
  }

  openItem(queue) {
    this.router.navigate(['/case'], { queryParams: { id: queue.id } });
  }

  getAllItemsInQueue(id) {
    const data = { queueId: 3, take: 0 };
    setTimeout(() => {
      this.data = this.dummyData.map((queue) => {
        return {
          id: queue.id,
          queue: queue.queue,
          task: queue.task,
          escalated: queue.escalated,
          reason: queue.reason,
          receivedDate: queue.receivedDate,
          age: queue.age.totalMinutes,
        };
      });
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.setPagination();
      this.countAgeDuration();
      this.settings.filter.initialValue = { ...this.settings.filter.value };
      this.isLoaded = true;
    }, 300);
  }

  customFilterPredicate() {
    const myFilterPredicate = (data, filter): boolean => {
      const ageFilter = filter.age
        ? data.age / this.minutesInAgeType >= filter.age[0] &&
        data.age / this.minutesInAgeType <= filter.age[1] + 1
        : true;

      return ageFilter;
    };
    return myFilterPredicate;
  }

  ngOnInit(): void {
    this.getAllItemsInQueue(1);
  }
  getMinutesBetweenDates(date) {
    var currentDate = new Date();
    var taskCreatedDate = new Date(date);
    var diff = currentDate.getTime() - taskCreatedDate.getTime();
    return diff / 60000;
  }

  dummyData = [
    // {
    //     id: 1,
    //     queue: 'Data Entry',
    //     task: 'Intake document',
    //     escalated: null,
    //     reason: 'Needs assigned',
    //     receivedDate: '2020-12-17T09:44:43.65224',
    //     age: {
    //         totalMinutes: 13693.465103023333,
    //     },
    // },
    {
      id: 2,
      queue: 'Identification',
      task: 'Identify doc. type and patient',
      escalated: 'Heather Rose',
      reason: 'Blank document',
      receivedDate: '2021-02-08T10:24:43.65224',
      age: {
        totalMinutes: this.getMinutesBetweenDates('2021-02-08T10:24:43.65224'),
      },
    },
    {
      id: 3,
      queue: 'Reimbursment',
      task: 'Follow-up with healthcare provider',
      escalated: 'David Galownia',
      reason: 'Follow-ups exhausted',
      receivedDate: '2021-02-09T10:44:43.65224',
      age: {
        totalMinutes: this.getMinutesBetweenDates('2021-02-09T10:44:43.65224'),
      },
    },
    {
      id: 4,
      queue: 'Identification',
      task: 'Identify doc. type and patient',
      escalated: 'Cheyenne Nolan',
      reason: 'Illegible document',
      receivedDate: '2021-02-08T09:31:43.65224',
      age: {
        totalMinutes: this.getMinutesBetweenDates('2021-02-08T09:31:43.65224'),
      },
    },
    {
      id: 5,
      queue: 'Welcome Call',
      task: 'Welcome Call',
      escalated: 'Heather Rose',
      reason: 'Follow-ups exhausted',
      receivedDate: '2021-02-10T12:42:43.65224',
      age: {
        totalMinutes: this.getMinutesBetweenDates('2021-02-10T12:42:43.65224'),
      },
    },
    {
      id: 6,
      queue: 'Welcome Call',
      task: 'Welcome Call',
      escalated: 'David Galownia',
      reason: 'Follow-ups exhausted',
      receivedDate: '2021-02-10T09:44:43.65224',
      age: {
        totalMinutes: this.getMinutesBetweenDates('2021-02-10T09:44:43.65224'),
      },
    },
  ];
}
