<div class="container">
    <div class="creation-wrapper">
        <div class="breadcrumbs">
            <ul>
                <li>
                    <a routerLink="/"
                        ><img src="../../../../assets/icons/icon-arrow-long-right-breadcrumbs.svg" alt="back" /> WORK QUEUES</a
                    >
                </li>
                <li>QUEUE CREATION</li>
            </ul>
        </div>
        <app-queue-setup
            *ngIf="settings.activeStep == 'setup'"
            [setup]="settings.setup"
            (setSetupValueHandler)="setSetupValue($event)"
            (focusNextBtnHandler)="focusNextBtn()"
        ></app-queue-setup>
        <app-queue-tasks
            *ngIf="settings.activeStep == 'tasks'"
            [selectedTasks]="settings.selectedTasks"
            (setSelectedTasksHandler)="setSelectedTasks($event)"
        ></app-queue-tasks>
        <app-queue-territories
            *ngIf="settings.activeStep == 'territories'"
            [selectedTerritories]="settings.selectedTerritories"
            (setSelectedTerritoriesHandler)="setSelectedTerritories($event)"
        ></app-queue-territories>
        <app-queue-users
            *ngIf="settings.activeStep == 'users'"
            [selectedUsers]="settings.selectedUsers"
            (setSelectedUsersHandler)="setSelectedUsers($event)"
        ></app-queue-users>
        <div class="creation-controls">
            <div class="queue-stepper">
                <button class="step-button prev" *ngIf="settings.activeStep != 'setup'" (click)="setStep('prev')">
                    <img src="../../../../assets/icons/icon-step-arrow.svg" alt="back" />
                    <span>Back</span>
                </button>
                <div class="queue-steps">
                    <div
                        class="queue-step"
                        *ngFor="let step of settings.steps; index as i"
                        [ngClass]="{
                            active: step == settings.activeStep,
                            done: isStepDone(i)
                        }"
                    >
                        <ng-container *ngIf="isStepDone(i)"><mat-icon>done</mat-icon></ng-container>
                        <ng-container *ngIf="!isStepDone(i)">{{ i + 1 }}</ng-container>
                    </div>
                </div>
                <button class="step-button next" *ngIf="settings.activeStep == 'tasks' || settings.activeStep == 'territories'" (click)="skipStep()">
                    <span>Skip</span>
                    <img src="../../../../assets/icons/icon-step-arrow.svg" alt="skip" />
                </button>
            </div>
            <button
                #nextBtn
                type="submit"
                class="next-button next-stack"
                (click)="setStep('next')"
                *ngIf="settings.activeStep != 'users'"
                tabindex="1"
                [disabled]="
                    settings.setup.name.trim().length &&
                    settings.setup.description.trim().length &&
                    settings.setup.configuration.length
                        ? false
                        : true
                "
            >
                <b>Next:</b> <span>{{ setNextButtonText() }}</span>
                <mat-icon>arrow_forward</mat-icon>
            </button>
            <button
                type="submit"
                (click)="submitQueue()"
                class="next-button complete"
                *ngIf="settings.activeStep == 'users'"
            >
                Complete
            </button>
        </div>
    </div>
</div>
