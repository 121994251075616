<div class="document-sidebar" [formGroup]="documentForm">
    <div class="aside-wrapper">
        <div class="document-type">
            <h2>Document Type</h2>
            <div class="input-item checkbox">
                <mat-button-toggle-group #duration="matButtonToggleGroup" name="documentType" aria-label="Type"
                    formControlName="documentType" (change)="setDocumentOptions(); focusOtherField()" #documentType
                    class="document-type-group">
                    <mat-button-toggle value="enrollmentForm">Enrollment Form</mat-button-toggle>
                    <mat-button-toggle value="papForm">PAP Form</mat-button-toggle>
                    <mat-button-toggle value="prescription">Prescription</mat-button-toggle>
                    <mat-button-toggle value="insuranceCard">Insurance Card</mat-button-toggle>
                    <mat-button-toggle value="proofOfIncome">Proof of Income</mat-button-toggle>
                    <mat-button-toggle value="other">Other</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <form [formGroup]="documentForm" novalidate (ngSubmit)="focusPatientField()">
                <div class="input-item small" *ngIf="documentForm.value.documentType == 'other'">
                    <label for="otherText">Other</label>
                    <input #other name="otherText" id="otherText" formControlName="otherText" maxlength="50"
                        (blur)="setDocumentOptions()" />
                </div>
            </form>
        </div>
        <div class="patient">
            <h2>Patient</h2>
            <div class="patients-search">
                <div class="input-item search">
                    <input matInput type="text" formControlName="search" placeholder="Search by Name"
                        (input)="searchPatients()" maxlength="50" (keydown)="patientNavigate($event)" autocomplete="off"
                        #searchPatientField />
                    <mat-icon>search</mat-icon>
                    <button mat-button *ngIf="documentForm.value.search" matSuffix mat-icon-button aria-label="Clear"
                        (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <ul class="patients-results" *ngIf="documentForm.value.search">
                    <ng-scrollbar #scrollbar *ngIf="!isPatientLoading && patients.length" class="custom-scrollbar">
                        <div class="patient-list" #patientList>
                            <li *ngFor="let patient of patients; index as i" (click)="selectPatient(patient)"
                                [class.focused]="focusedPatient == i">
                                <h5>
                                    {{ patient.firstName }} {{ patient.middleName ? patient.middleName : null }}
                                    {{ patient.lastName }}
                                </h5>
                                <p>
                                    <mat-icon>person</mat-icon>{{ patient.socialSecurityNumber | ssn }}
                                </p>
                                <p>
                                    <mat-icon>calendar_today</mat-icon>{{ patient.dateOfBirth | date: "MM / dd / yyyy"
                                    }}
                                </p>
                                <p>
                                    <mat-icon>location_on</mat-icon>{{ patient.currentMailingAddress?.address |
                                    fulladdress }}
                                </p>
                                <p>
                                    <mat-icon>phone</mat-icon>{{ patient.contactInfos | phone }}
                                </p>
                            </li>
                        </div>
                    </ng-scrollbar>
                    <li *ngIf="isPatientLoading">
                        <mat-spinner></mat-spinner>
                    </li>
                    <li *ngIf="!isPatientLoading && !patients.length" class="not-found">
                        <h5>{{ documentForm.value.search }}</h5>
                        <p>Patient Not Found</p>
                    </li>
                    <li>
                        <button class="primary-btn" (click)="addPatient()">
                            <mat-icon>add_circle</mat-icon>New Patient
                        </button>
                    </li>
                </ul>
            </div>
            <div class="suggest-wrapper" *ngIf="suggestedPatients">
                <button class="suggest-item" [ngClass]="{
                        active:
                            documentList[selectedDocument].patient &&
                            patient.name == documentList[selectedDocument].patient.name
                    }" *ngFor="let patient of suggestedPatients" (click)="selectPatient(patient)">
                    {{ patient.name }}
                </button>
            </div>
        </div>
    </div>
    <button *ngIf="
            (isFinished && documentForm.value.documentType != 'other') ||
                (isFinished &&
                    documentForm.value.documentType == 'other' &&
                    documentForm.value.otherText.trim().length);
            else nextBtn
        " class="next-button next-stack" (click)="getNextStack()" #nextStack>
        Next Stack <mat-icon>east</mat-icon>
    </button>
    <ng-template #nextBtn>
        <button #nextButton class="next-button" (click)="switchDocument()">Next Page <mat-icon>east</mat-icon></button>
    </ng-template>
</div>