<form [formGroup]="patientForm" novalidate (ngSubmit)="submitHandler()">
    <div class="aside-wrapper">
        <h2>Patient Demographics</h2>
        <div class="form-row">
            <div [class]="
                    patientForm.controls['firstName'].invalid &&
                    (patientForm.controls['firstName'].touched || settings.isValid == false)
                        ? 'input-item input-error'
                        : 'input-item'
                ">
                <label for="firstName">* {{ patientFormFields.firstName }}</label>
                <input name="firstName" id="firstName" formControlName="firstName" maxlength="50" #firstNameField />
            </div>
            <div class="input-item">
                <label for="middleName">{{ patientFormFields.middleName }}</label>
                <input name="middleName" id="middleName" formControlName="middleName" maxlength="50" />
            </div>
            <div [class]="
                    patientForm.controls['lastName'].invalid &&
                    (patientForm.controls['lastName'].touched || settings.isValid == false)
                        ? 'input-item input-error'
                        : 'input-item'
                ">
                <label for="lastName">* {{ patientFormFields.lastName }}</label>
                <input name="lastName" id="lastName" formControlName="lastName" maxlength="50" />
            </div>
        </div>
        <div class="form-row" style="margin-top: 17px">
            <div [class]="
                    patientForm.controls['dateOfBirth'].invalid &&
                    (patientForm.controls['dateOfBirth'].touched || settings.isValid == false)
                        ? 'input-item input-error'
                        : 'input-item'
                ">
                <label for="dateOfBirth">{{ patientFormFields.dateOfBirth }}</label>
                <div class="date-input">
                    <input matInput [matDatepicker]="dateOfBirth" id="dateOfBirth" formControlName="dateOfBirth"
                        placeholder="MM/DD/YY" [max]="disableDate" (focus)="dateOfBirth.open()"
                        (click)="dateOfBirth.open()" />
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth" tabindex="-1"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div [class]="
                    patientForm.controls['gender'].invalid &&
                    (patientForm.controls['gender'].touched || settings.isValid == false)
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>{{ patientFormFields.gender }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="gender" name="gender" placeholder="Select" id="gender"
                        disableOptionCentering panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Female</mat-option>
                        <mat-option [value]="1">Male</mat-option>
                        <mat-option [value]="2">Other</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row full-row" formGroupName="address">
            <div class="input-item">
                <label for="address">{{ patientFormFields.streetAddress }}</label>
                <input name="address" id="address" formControlName="streetAddress" maxlength="150" #addressField
                    (focus)="toggleAddressFocus(true)" (blur)="toggleAddressFocus(false)" />
            </div>
        </div>
        <div class="form-row full-row address-line-2" formGroupName="address">
            <div class="input-item">
                <input name="address2" id="address2" formControlName="addressExtension" maxlength="150" />
            </div>
        </div>
        <div class="form-row" formGroupName="address">
            <div [class]="
                    patientForm.controls.address['controls']['zipCode'].invalid &&
                    (patientForm.controls.address['controls']['zipCode'].touched || settings.isValid == false)
                        ? 'input-item input-error'
                        : 'input-item'
                ">
                <label for="zipCode">{{ patientFormFields.zipCode }}</label>
                <input name="zipCode" id="zipCode" formControlName="zipCode" (blur)="checkCityState()" />
            </div>
            <div class="state-wrapper">
                <label>City, State</label>
                <div class="state">{{ this.city }}{{this.city && this.state ? ', ':''}}{{ this.state }}</div>
            </div>
        </div>
        <div class="form-row">
            <div class="input-item" [class.input-error]="
                    patientForm.controls['socialSecurityNumber'].invalid &&
                    (patientForm.controls['socialSecurityNumber'].touched || settings.isValid == false)
                ">
                <label for="socialSecurityNumber">{{ patientFormFields.socialSecurityNumber }}</label>
                <input name="socialSecurityNumber" id="socialSecurityNumber" formControlName="socialSecurityNumber"
                    mask="000-00-0000" placeholder="AAA-GG-SSSS" />
            </div>
        </div>
        <div class="form-row">
            <div [class]="
                    patientForm.controls['bestTimeToContact'].invalid && submitted
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>{{ patientFormFields.bestTimeToContact }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="bestTimeToContact" name="bestTimeToContact" placeholder="Select"
                        id="bestTimeToContact" disableOptionCentering panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Morning</mat-option>
                        <mat-option [value]="1">Afternoon</mat-option>
                        <mat-option [value]="2">Evening</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div [class]="
                    patientForm.controls['bestMethodToContact'].invalid && submitted
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>{{ patientFormFields.bestMethodToContact }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="bestMethodToContact" name="bestMethodToContact" placeholder="Select"
                        id="bestMethodToContact" disableOptionCentering panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Phone</mat-option>
                        <mat-option [value]="3">Text</mat-option>
                        <mat-option [value]="2">Email</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row" formGroupName="phoneInfo">
            <div [class]="
                    patientForm.controls.phoneInfo['controls']['contactString'].invalid &&
                    (patientForm.controls.phoneInfo['controls']['contactString'].touched || settings.isValid == false)
                        ? 'input-item input-error'
                        : 'input-item'
                ">
                <label for="phone">{{ patientFormFields.contactString }}</label>
                <input name="contactString" id="contactString" formControlName="contactString" mask="(000) 000-0000"
                    placeholder="(_ _ _) _ _ _ - _ _ _ _" />
            </div>
            <div [class]="
                    patientForm.controls.phoneInfo['controls']['contactType'].invalid && submitted
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>{{ patientFormFields.phoneType }}?</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="contactType" name="contactType" placeholder="Select" id="contactType"
                        disableOptionCentering panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Home</mat-option>
                        <mat-option [value]="1">Mobile</mat-option>
                        <mat-option [value]="2">Work</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row full-row">
            <div class="input-item"
                [class]="patientForm.controls['email'].invalid && submitted ? 'input-item input-error' : 'input-item'">
                <label for="email">{{ patientFormFields.email }}</label>
                <input name="email" id="email" formControlName="email" maxlength="150" />
            </div>
        </div>
        <div class="form-row">
            <div class="input-item select" [class.input-error]="patientForm.controls['hipaaConsent'].invalid &&
            (patientForm.controls['hipaaConsent'].touched || settings.isValid == false)">
                <label for="hipaaConsent">* {{ patientFormFields.hipaaConsent }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="hipaaConsent" name="hipaaConsent" placeholder="Select"
                        (selectionChange)="selectHipaaConsent()" id="hipaaConsent" disableOptionCentering
                        panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Yes</mat-option>
                        <mat-option [value]="1">No</mat-option>
                        <mat-option [value]="2">Unknown</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row" style="margin-top: 17px" *ngIf="patientForm.value.hipaaConsent == 0">
            <div class="input-item" [class.input-error]="patientForm.controls['hipaaConsentSignatureDate'].invalid &&
            (patientForm.controls['hipaaConsentSignatureDate'].touched || settings.isValid == false || submitted)">
                <label for="hipaaConsentSignatureDate">* {{ patientFormFields.hipaaConsentSignatureDate }}</label>
                <div class="date-input">
                    <input matInput [matDatepicker]="hipaaConsentSignatureDate" id="hipaaConsentSignatureDate"
                        formControlName="hipaaConsentSignatureDate" placeholder="MM/DD/YY" [max]="disableDate"
                        (focus)="hipaaConsentSignatureDate.open()" (click)="hipaaConsentSignatureDate.open()" />
                    <mat-datepicker-toggle matSuffix [for]="hipaaConsentSignatureDate" tabindex="-1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #hipaaConsentSignatureDate></mat-datepicker>
                </div>
            </div>
        </div>
    </div>
    <button type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()">
        <b>Next:</b> Insurance <mat-icon>arrow_forward</mat-icon>
    </button>
</form>