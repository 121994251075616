<div class="task-wrapper">
    <div class="task-title">
        <h2>Fulfillment</h2>
    </div>
    <div class="task-description-wrapper">
        <div class="task-description">
            <div class="task-description-item">
                <h5>Current SP</h5>
                <p>{{ currentSp | empty }}</p>
            </div>
            <div class="task-description-item">
                <h5>SP Phone</h5>
                <p>{{ spPhone | phoneNumber | empty }}</p>
            </div>
            <div class="task-description-item">
                <h5>SP Status</h5>
                <p>{{ spStatus | empty }}</p>
            </div>
            <div class="task-description-item">
                <h5>SP SubStatus</h5>
                <p>{{ spSubStatus | empty }}</p>
            </div>
            <div class="task-description-item">
                <h5>Last Ship Date</h5>
                <p>{{ lastShipDate | date: "MM/dd/yyyy" | empty }}</p>
            </div>
            <div class="task-description-item">
                <h5>Next Shipment Due</h5>
                <p>{{ nextShipDate | date: "MM/dd/yyyy" | empty }}</p>
            </div>
        </div>
    </div>
</div>
<div class="fulfillment-wrapper" *ngIf="data && data.length">
    <h2>Shipment History</h2>
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="pharmacy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy</th>
            <td mat-cell *matCellDef="let element">
                {{ element.pharmacy }}
            </td>
        </ng-container>
        <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.productName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="ndc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NDC</th>
            <td mat-cell *matCellDef="let element">
                {{ element.ndc }}
            </td>
        </ng-container>
        <ng-container matColumnDef="daysSupply">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Days Supply</th>
            <td mat-cell *matCellDef="let element">
                {{ element.daysSupply }}
            </td>
        </ng-container>
        <ng-container matColumnDef="shipDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element.shipDate | date: "MM/dd/yyyy" }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>
</div>
<div *ngIf="!data || !data.length" class="triage-successful">
    <h2>Triage Successful</h2>
    <p>
        Your prescription has successfully been triaged to <b>{{ currentSp }}</b> as of
        <b>{{ triagedDate | date: "M/d/yyyy" }}</b> at <b>{{ triagedDate | date: "h:mmaaaaa'm'" }}</b> Check here later to
        see shipment history from the SP.
    </p>
    <!-- replace image -->
    <img src="../../../../../../../assets/images/empty.svg" alt="empty" />
</div>
