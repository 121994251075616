import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../../../core/services/abstract-rest.service';
import { IResponse, IPatient, IPrescription, IFacility, IPhysician } from '../../../../../shared/interfaces/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataEntryFormService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getPatient(id: number): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`Patients/${id}`);
  }

  public updatePatient(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Patients`;
    return this.updateItem<any, IResponse>(data);
  }

  public createPatient(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Patients`;
    return this.putItem<any, IResponse>(data);
  }

  public createPatientAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.putItem<any, IResponse>(data);
  }

  public updatePatientAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.updateItem<any, IResponse>(data);
  }

  public createConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.putItem<any, IResponse>(data);
  }

  public updateConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.updateItem<any, IResponse>(data);
  }

  public getInsurancePlans(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientInsurance`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public updatePlan(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientInsurance`;
    return this.updateItem<any, IResponse>(data);
  }

  public getPlan(id: number): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`PatientInsurance/${id}`);
  }

  public searchCases(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.postItem<any, IResponse>(data);
  }

  public createCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.putItem<any, IResponse>(data);
  }

  public updateCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.updateItem<any, IResponse>(data);
  }

  public updatePrescription(data: IPrescription): Observable<IResponse> {
    this.url = this.apiUrl + `/Prescriptions`;
    return this.updateItem<IPrescription, IResponse>(data);
  }

  public getProducts(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Product`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public searchDiagnosis(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Diagnosis`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSuggestedPhysicians(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Physicians/Suggested`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSuggestedFacilities(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Facilities/Suggested`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public searchPhysicians(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Physicians`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public searchFacilities(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Facilities`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public updateFacility(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Facilities`;
    return this.updateItem<any, IResponse>(data);
  }

  public createFacility(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Facilities`;
    return this.putItem<any, IResponse>(data);
  }

  public updatePhysician(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Physicians`;
    return this.updateItem<any, IResponse>(data);
  }

  public createPhysician(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Physicians`;
    return this.putItem<any, IResponse>(data);
  }

  public completeCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/CompleteTask`;
    return this.postItem<any, IResponse>(data);
  }

  public setDocumentBatchCompleted(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/DataEntryQueue/CompleteDocumentBatch/${id}`;
    return this.postItem<any, IResponse>();
  }

}
