import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as DocumentAction from '../../..//store/document/document.actions';
import { DocumentOptions, IDocument, IDocumentOptions } from '../../../features/intake/document/document.model';
import { DeleteDocumentModalComponent } from './../../../features/intake/document/type/modals/delete/delete.component';
import { UncertainDocumentModalComponent } from './../../../features/intake/document/type/modals/uncertain/uncertain.component';
import { DocumentTypes, DocumentTypeNames } from '../../enums/enums';
import { QueueIDs } from '../../../shared/enums/enums';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
    selector: 'app-document-viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
    documentList;
    documentsOptions: IDocumentOptions = new DocumentOptions();
    dragPosition = { x: 0, y: 0 };
    isGrid: boolean;
    documentTypes = DocumentTypes;
    documentTypeNames = DocumentTypeNames;
    queueID: number;

    modals = {
        delete: DeleteDocumentModalComponent,
        information: UncertainDocumentModalComponent,
    };

    @ViewChild(NgScrollbar) scrollbar: NgScrollbar;

    constructor(public dialog: MatDialog, private store: Store<any>) {
        this.store.select('document').subscribe((state) => {
            this.documentsOptions.selectedDocument = state.selectedDocument;
            this.isGrid = state.grid;
            this.documentList = state.documentList;
            this.documentsOptions.currentDocumentOrder = state.selectedDocument + 1;            
            if(this.scrollbar){
                this.scrollbar.scrollTo({ top: state.selectedDocument * 195 });
            }
        });

        const queueType = window.location.pathname.replace('/document-', '');
        this.queueID = QueueIDs[queueType];
    }

    selectDocument(selectedDocument: IDocument) {
        let index = this.documentList.findIndex((document) => document == selectedDocument);
        this.store.dispatch(new DocumentAction.SetSelectedDocument(index));
        this.clearDocument();
    }

    switchDocument(direction: string) {
        let documentOrder = this.documentsOptions.selectedDocument;
        if (direction === 'prev') {
            documentOrder == 0 ? null : documentOrder--;
        }
        if (direction === 'next') {
            documentOrder + 1 == this.documentList.length ? null : documentOrder++;
        }
        this.selectDocument(this.documentList[documentOrder]);
    }

    setSelectedDocument() {
        this.clearDocument();
        if (this.documentsOptions.currentDocumentOrder) {
            if (this.documentsOptions.currentDocumentOrder > this.documentList.length) {
                this.store.dispatch(new DocumentAction.SetSelectedDocument(this.documentList.length - 1));
            }
        }
    }

    zoomDocument(scale: string): number {
        let scaleStep = 0.25;
        if (scale === 'in' && this.documentsOptions.zoomPercent != 2) {
            return (this.documentsOptions.zoomPercent += scaleStep);
        }
        if (scale === 'out' && this.documentsOptions.zoomPercent != 0.5) {
            return (this.documentsOptions.zoomPercent -= scaleStep);
        }
    }

    rotateDocument(): number {
        return (this.documentsOptions.rotate -= 90);
    }
    setGrid(value: boolean) {
        this.store.dispatch(new DocumentAction.SetGrid(value));
    }

    openDialog(modal) {
        this.dialog.open(this.modals[modal]);
    }

    setDocumentOptions(value) {
        this.documentsOptions.documentList[this.documentsOptions.currentDocumentOrder - 1].documentType =
            value.documentType;
        this.documentsOptions.documentList[this.documentsOptions.currentDocumentOrder - 1].patient = value.patient;
        this.switchDocument('next');
    }

    clearDocument() {
        this.documentsOptions.zoomPercent = 1;
        this.dragPosition = { x: 0, y: 0 };
        this.setGrid(false);
    }

    ngOnInit(): void {}
}
