<div class="queue-title-wrap">
    <div class="breadcrumbs">
        <ul>
            <li>
                <a routerLink="/"><img src="../../../../assets/icons/icon-arrow-long-right-breadcrumbs.svg"
                        alt="back" /> WORK QUEUES</a>
            </li>
            <li>Supervisor Tasks</li>
        </ul>
    </div>
    <div class="queue-title">
        <h2>Supervisor</h2>
    </div>
</div>

<table [hidden]="!data.length" mat-table [dataSource]="dataSource" matSort matSortActive="age" matSortDirection="desc"
    class="supervisor-table">
    <ng-container matColumnDef="queue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prev. Queue</th>
        <td mat-cell *matCellDef="let queue">{{ queue.queue }}</td>
    </ng-container>
    <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
        <td mat-cell *matCellDef="let queue">{{ queue.task }}</td>
    </ng-container>
    <ng-container matColumnDef="escalated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Escalated</th>
        <td mat-cell *matCellDef="let queue">
            <i *ngIf="!queue.escalated">Auto</i><span *ngIf="queue.escalated">{{ queue.escalated }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
        <td mat-cell *matCellDef="let queue">{{ queue.reason }}</td>
    </ng-container>
    <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>
        <td mat-cell *matCellDef="let queue">
            <div class="lock-wrapper"
                [style]="queue.queueStatus == 1 ? 'display: flex;align-items: center;' : 'padding-left: 44px'">
                <div *ngIf="queue.queueStatus == 1" class="lock-container">
                    <mat-icon class="lock">lock</mat-icon>
                    <img *ngIf="queue.userImageS3Url" [src]="queue.userImageS3Url" />
                    <mat-icon *ngIf="!queue.userImageS3Url" class="default-user">account_circle</mat-icon>
                </div>
                <div class="lock-text">
                    <i *ngIf="queue.queueStatus == 1">In Progress</i>
                    {{ queue.receivedDate | date: "E, M/d - hh:mmaaaaa" }}
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Aging</div>
                <div class="filter-buttons" [class.active]="
                        settings.filter.value.age[0] != settings.filter.initialValue.age[0] ||
                        settings.filter.value.age[1] != settings.filter.initialValue.age[1]
                    ">
                    <button class="filter-button" (click)="openFilter('age')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button" *ngIf="
                            settings.filter.value.age[0] != settings.filter.initialValue.age[0] ||
                            settings.filter.value.age[1] != settings.filter.initialValue.age[1]
                        " (click)="clearFilter('age')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.age">
                    <div class="filter-title">
                        <h5>Aging</h5>
                        <button (click)="closeFilter('age')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <nouislider #ageSlider [connect]="true" [min]="0" [max]="maxHoursCount" [step]="1"
                            [(ngModel)]="settings.filter.value.age"></nouislider>
                        <div class="range-inputs">
                            <div class="input-item">
                                <input type="number" name="start" id="start" min="0"
                                    [max]="settings.filter.value.age[1]" [(ngModel)]="settings.filter.value.age[0]"
                                    (input)="onChangeRange('ageSlider')" />
                            </div>
                            <div class="input-item">
                                <input type="number" name="end" id="end" [min]="settings.filter.value.age[0]"
                                    [max]="maxHoursCount" [(ngModel)]="settings.filter.value.age[1]"
                                    (input)="onChangeRange('ageSlider')" />
                            </div>
                        </div>
                        <div class="age-type">
                            <div class="input-item toggle">
                                <mat-button-toggle-group name="ageType" [(ngModel)]="settings.ageType"
                                    (change)="countAgeDuration()">
                                    <mat-button-toggle value="hours"> Hours </mat-button-toggle>
                                    <mat-button-toggle value="days"> Days </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('age')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('age')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            <img [src]="'../../../../../assets/icons/icon-age-' + setAgeColor(queue.age) + '.svg'" alt="age" />
            {{ setAge(queue.age) }}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let queue; columns: columnsToDisplay" (click)="openItem(queue)"
        [class.locked]="queue.queueStatus == 1"></tr>
    <tr class="mat-row no-data" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter</td>
    </tr>
</table>
<mat-paginator style="display: none" [pageSize]="settings.itemPerPage"></mat-paginator>
<app-pagination mat-paginator *ngIf="data.length && isLoaded"
    [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'" [settings]="settings"
    (setActivePageHandler)="setActivePage($event)"></app-pagination>
<app-empty-queue *ngIf="!data.length && isLoaded" type="tasks"></app-empty-queue>