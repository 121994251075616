<form (ngSubmit)="save()" [formGroup]="newAddressForm" class="enrollment create-form form-row address-form">

  <h3 *ngIf="!address">New Address</h3>

  <div class="form-row">
    <div class="input-item address" [class]="
          newAddressForm.controls['streetAddress'].invalid &&
          (newAddressForm.controls['streetAddress'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="address" *ngIf="!address">Address</label>
      <label for="address" *ngIf="address">
        {{address.addressType === AddressType.mailing ? 'Mailing' : 'Shipping'}} Address
      </label>
      <input name="address" id="address" formControlName="streetAddress" maxlength="150" #addressField/>
    </div>
  </div>

  <div class="form-row zip">
    <div class="input-item" [class]="
          newAddressForm.controls['zipCode'].invalid &&
          (newAddressForm.controls['zipCode'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="zip">ZIP</label>
      <input name="zip" id="zip" formControlName="zipCode" maxlength="20"/>
    </div>

    <div class="input-item city-state">
      <label>City, State</label>
      <div class="value">{{newAddressForm.controls['city'].value | empty}}, {{newAddressForm.controls['state'].value | empty}}</div>
    </div>
  </div>

  <div class="form-row" *ngIf="!address">
    <div class="input-item toggle address-type">
      <label for="addressType">Address Type</label>
      <mat-button-toggle-group id="addressType" formControlName="addressType">
        <mat-button-toggle [value]="AddressType.mailing">
          Mailing
        </mat-button-toggle>
        <mat-button-toggle [value]="AddressType.shipping">
          Shipping
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="form-row current">
    <div class="input-item toggle">
      <mat-button-toggle-group formControlName="current">
        <mat-button-toggle [value]="true"> Yes </mat-button-toggle>
        <mat-button-toggle [value]="false"> No </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <span>Is this the current address?</span>
  </div>

  <div class="controls default-form fx-row">
    <button class="cancel-btn" type="button" mat-button (click)="cancelled.emit()">
      exit
    </button>

    <button [disabled]="deepEqual(initData, newAddressForm.getRawValue()) || newAddressForm.invalid" class="save-btn" mat-button type="submit">
      save
    </button>
  </div>

</form>
