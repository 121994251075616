import { Component, OnInit } from '@angular/core';
import { cases } from '../../../shared/data/data';
import { isThisWeek } from '../../../shared/helpers/utils';
import { Store } from '@ngrx/store';
import * as CaseAction from '../../../store/case/case.actions';
import { SearchOptions, AdvancedSearchOptions } from 'src/app/shared/models/models';
import { QueueService } from './dashboard.service';
import { IUserQueue } from './dashboard.models';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-manager-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class ManagerDashboardComponent implements OnInit {
    searchOptions = new SearchOptions();
    intakeQueueList: IUserQueue[];
    queueList: IUserQueue[];

    constructor(private store: Store<any>, public queueService: QueueService) { }

    ngOnInit() {
        this.queueService.getIntakeQueues().subscribe((response) => {
            this.intakeQueueList = response;
        }, error => throwError(error));

        this.queueService.getCaseManagementQueues().subscribe((response) => {
            this.queueList = response;
        }, error => throwError(error));
        this.showCasesHandler();
        this.store.dispatch(new CaseAction.RemoveCase());
    }

    cases = cases;
    allCases = this.cases;

    clearSearchHandler() {
        this.searchOptions = new SearchOptions();
        this.showCasesHandler();
    }

    addSearchHandler() {
        this.searchOptions.advancedOptions.push(new AdvancedSearchOptions());
    }

    showDuration() {
        if (this.searchOptions.durationValue == 'today') {
            return (this.cases = this.cases.filter(
                (caseItem) =>
                    new Date(caseItem.due).getDate() == new Date().getDate() &&
                    new Date(caseItem.due).getMonth() == new Date().getMonth() &&
                    new Date(caseItem.due).getFullYear() == new Date().getFullYear()
            ));
        } else if (this.searchOptions.durationValue == 'week') {
            return (this.cases = this.cases.filter((caseItem) => isThisWeek(caseItem.due)));
        } else if (this.searchOptions.durationValue == 'month') {
            return (this.cases = this.cases.filter(
                (caseItem) =>
                    new Date(caseItem.due).getMonth() == new Date().getMonth() &&
                    new Date(caseItem.due).getFullYear() == new Date().getFullYear()
            ));
        } else {
            return this.cases;
        }
    }

    showStatus() {
        if (this.searchOptions.statusValue == 'all') {
            return this.cases;
        } else if (this.searchOptions.statusValue == 'open') {
            return (this.cases = this.cases.filter((caseItem) => caseItem.status == 'Open'));
        } else if (this.searchOptions.statusValue == 'closed') {
            return (this.cases = this.cases.filter((caseItem) => caseItem.status == 'Closed'));
        } else {
            return this.cases;
        }
    }

    filterCasesHandler(data: { action: string; group: string }) { }

    searchCase() {
        let searchValue = this.searchOptions.searchValue.toLowerCase().replace(/[^A-Z0-9]+/gi, '');
        return (this.cases = this.cases.filter(
            (caseItem) =>
                Object.values(caseItem).filter((value) =>
                    value
                        .toString()
                        .toLowerCase()
                        .replace(/[^A-Z0-9]+/gi, '')
                        .includes(searchValue)
                ).length
        ));
    }

    findByField() {
        this.searchOptions.advancedOptions.forEach((field) => {
            if (field.field && field.search) {
                this.cases = this.cases.filter((caseItem) => {
                    if (field.exact) {
                        var reg = new RegExp('\\b' + field.search + '\\b');
                        return reg.test(caseItem[field.field]);
                    } else {
                        return caseItem[field.field].toLowerCase().includes(field.search.toLowerCase());
                    }
                });
            }
        });
        return this.cases;
    }

    findBetweenDates() {
        const startDate = new Date(this.searchOptions.date.startDate).getTime();
        const endDate = new Date(this.searchOptions.date.endDate).getTime();
        if (startDate && endDate) {
            return (this.cases = this.cases.filter((caseItem) => caseItem.due >= startDate && caseItem.due <= endDate));
        } else if (startDate && !endDate) {
            return (this.cases = this.cases.filter((caseItem) => caseItem.due >= startDate));
        } else if (!startDate && endDate) {
            return (this.cases = this.cases.filter((caseItem) => caseItem.due <= endDate));
        } else {
            return this.cases;
        }
    }

    setAdvancedFieldSearchValue() {
        this.searchOptions.searchValue = this.searchOptions.advancedOptions.map((item) => item.search).join();
        if (this.searchOptions.date.startDate || this.searchOptions.date.endDate) {
            this.searchOptions.searchValue += this.searchOptions.searchValue.length ? ', ' : '';
            this.searchOptions.searchValue += this.searchOptions.date.startDate
                ? `${new Date(this.searchOptions.date.startDate).toDateString()}`
                : ', ...';
            this.searchOptions.searchValue += ' - ';
            this.searchOptions.searchValue += this.searchOptions.date.endDate
                ? `${new Date(this.searchOptions.date.endDate).toDateString()}`
                : '...';
        }
    }

    showCasesHandler() {
        this.cases = this.allCases;
        this.showDuration();
        this.showStatus();
        if (this.searchOptions.isAdvanced) {
            this.findByField();
            this.findBetweenDates();
            this.setAdvancedFieldSearchValue();
        } else {
            this.searchCase();
        }
    }
}
