import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactMethod } from 'src/app/shared/enums/enums';
import { interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-text-task',
    templateUrl: './text.component.html',
    styleUrls: ['../reschedule/reschedule.component.scss', './text.component.scss'],
})
export class TextTaskComponent implements OnInit {
    @Output() setSelectedTask = new EventEmitter();
    patientFirstName;
    patientLastName;
    patientGender;
    patientPhone;
    case;
    body;
    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        console.log(data, this.data)
        this.case = data.case;
        this.patientFirstName = data.case.patient.firstName;
        this.patientLastName = data.case.patient.lastName;
        let phone = data.case.patient.contactInfos && data.case.patient.contactInfos.length
            ? data.case.patient.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
            : null;
        this.patientPhone = phone.contactString;
        this.patientGender = data.case.patient.gender;
        this.body = data.taskData ? interpolateTemplate(data.taskData.text, this.getBodyTemplateObject()) : '';
    }

    setSelectedTaskHandler() {
        this.setSelectedTask.emit(null);
    }

    getBodyTemplateObject() {
        return {
            patientFirstName: this.case.patient.firstName || '',
            pharmacyName: this.case.specialtyPharmacy?.name || '',
            productName: this.case.prescription?.product?.name || ''
        };
    }

    ngOnInit(): void { }
}
