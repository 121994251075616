import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, TaskOutcome } from 'src/app/shared/enums/enums';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-task-adherence-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['../../task.component.scss', '../../welcome/welcome.component.scss', './compliance.component.scss'],
})
export class AdherenceComplianceTaskComponent implements OnInit {
    @Input() selectedTask;
    @Input() case;
    @Output() minimizeTaskHandler = new EventEmitter();
    @Output() setSelectedTaskHandler = new EventEmitter();
    @Output() rescheduleTaskHandler = new EventEmitter();
    @Output() submitTaskHandler = new EventEmitter();
    complianceForm: FormGroup;
    interpolatedDescription;
    interpolatedScript;
    patientEmail;

    constructor() {
        this.complianceForm = new FormGroup({
            contact: new FormControl(null, [Validators.required]),
            success: new FormControl(null, [Validators.required]),
            concerns: new FormControl(null, [Validators.required]),
            mailType: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            note: new FormControl(null, [Validators.required]),
        });
    }

    minimizeTask(boolean) {
        this.selectedTask.formData = this.complianceForm.getRawValue();
        this.minimizeTaskHandler.emit(boolean);
    }

    setSelectedTask(task) {
        this.setSelectedTaskHandler.emit(task);
    }

    submitTask() {
        const data = {
            queueItemId: this.case.id,
            queueTaskId: this.selectedTask.id,
            taskOutcome: this.selectedTask.attemptNumber < this.selectedTask.maxAttempts - 1 ? TaskOutcome.success : TaskOutcome.failure,
            outcomeNote: this.complianceForm.value.note,
        };
        this.submitAdditionalTasks();
        this.submitTaskHandler.emit(data);
    }

    submitAdditionalTasks() {
        for (var i = 0; i < this.complianceForm.value?.concerns?.length; i++) {

            var autoTask = {
                taskOutcome: TaskOutcome.success,
                queueItemId: this.case.id,
                workflowTaskId: 0,
                contactMethod: 0
            };

            switch (this.complianceForm.value.concerns[i]) {
                case "0":
                    autoTask.workflowTaskId = 16;
                    autoTask.contactMethod = 2;
                    break;
                case "1":
                    autoTask.workflowTaskId = 23;
                    autoTask.contactMethod = 2;
                    break;
                case "2":
                    autoTask.workflowTaskId = 17;
                    autoTask.contactMethod = 2;
                    break;
            }
            this.submitTaskHandler.emit(autoTask);
        }
    }

    rescheduleTask() {
        const data = {
            attemptNumber: this.selectedTask.attemptNumber,
            task: this.selectedTask.task,
            outcomeNote: this.complianceForm.value.note,
            case: this.case,
        };
        this.rescheduleTaskHandler.emit(data);
    }

    ngOnInit(): void {
        this.interpolatedDescription =
            this.case.facility && this.case.physician
                ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject())
                : this.selectedTask.description;
        this.interpolatedScript = interpolateTemplate(this.selectedTask.script, this.getScriptTemplateObject());
        if (this.selectedTask.formData) {
            this.complianceForm.patchValue(this.selectedTask.formData);
        }
    }

    getDescriptionTemplateObject() {
        let patientPhone =
            this.case.patient && this.case.patient.contactInfos && this.case.patient.contactInfos.length
                ? this.case.patient.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
                : null;
        let patientName = this.case.patient ? `${this.case.patient.firstName} ${this.case.patient.lastName}` : '';
        this.patientEmail =
            this.case.patient && this.case.patient.contactInfos && this.case.patient.contactInfos.length
                ? this.case.patient.contactInfos.find((contact) => contact.contactMethod == ContactMethod.email)
                : null;
        let genderPronoun = this.case.patient.gender == 0 ? 'her' : (this.case.patient.gender == 1 ? 'him' : 'them');
        return {
            patientPhone: `<a href="#" class="highlighted">${formatPhone(patientPhone?.contactString)}</a>`,
            patientName: `<span class="highlighted">${patientName}</span>`,
            genderPronoun: genderPronoun
        };
    }

    getScriptTemplateObject() {
        return {
            patientName: `${this.case.patient.firstName || ''}`,
            caseManagerName: 'Heather',
            newLine: '<br><br>',
        };
    }
}
