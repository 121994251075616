import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../../features/manager/case/interfaces/address.interface';
import { getFullAddress } from '../helpers/utils';

@Pipe({ name: 'fulladdress' })
export class FullAddressPipe implements PipeTransform {
    transform(address: IAddress): string {
        return getFullAddress(address);
    }
}
