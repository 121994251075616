<div class="container">
    <app-search-field></app-search-field>
</div>
<div class="container">
    <app-manager-dashboard-queues [queueList]="queueList" [intakeQueueList]="intakeQueueList">
    </app-manager-dashboard-queues>
</div>
<!-- <app-manager-dashboard-filter
    [durationValue]="searchOptions.durationValue"
    (showCases)="searchOptions.durationValue = $event; showCasesHandler()"
></app-manager-dashboard-filter>
<app-manager-dashboard-cases [cases]="cases"></app-manager-dashboard-cases> -->