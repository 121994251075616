<div class="search-wrapper">
    <div class="search-form">
        <h2>Search Cases</h2>
        <div class="input-item search">
            <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
            <input matInput type="text" [(ngModel)]="searchOptions.searchValue"
                placeholder="Search by Patient, Case ID, Payer, etc." (input)="searchCases()" />
            <button mat-button *ngIf="searchOptions.searchValue" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchOptions.searchValue = ''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <button class="primary-btn" (click)="searchCases()">Search</button>
        <!-- <button (click)="searchOptions.isAdvanced = true" class="primary-btn invert">Advanced Search</button> -->
        <div class="suggest-wrapper">
            <div class="suggest-item" *ngFor="let case of suggestedCases" (click)="openCase(case.id)">
                <b>{{case.patient}}</b><span>Case #{{case.id}}</span>
            </div>
        </div>
    </div>
    <h3>Search for</h3>
    <div class="input-item checkbox">
        <mat-button-toggle-group #duration="matButtonToggleGroup" name="searchType" aria-label="Type"
            [(ngModel)]="searchType" (change)="searchCases();">
            <mat-button-toggle [value]="0">Patient</mat-button-toggle>
            <mat-button-toggle [value]="1">Physician</mat-button-toggle>
            <mat-button-toggle [value]="2">Territory</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="title-wrapper">
        <h3>Results</h3>
        <button class="primary-btn invert clear-all-filters" *ngIf="!isFiltersNotUsed" (click)="clearAllFilters()">
            Clear All Filters <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="!isLoaded" class="dashboard-loader-container">
        <app-loader></app-loader>
    </div>
    <table [hidden]="!data.length" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Case ID</th>
            <td mat-cell *matCellDef="let case">{{ case.id }}</td>
        </ng-container>
        <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Patient</div>
                    <div class="filter-buttons"
                        [class.active]="!!settings.filter.value.patient != !!settings.filter.initialValue.patient">
                        <button class="filter-button" (click)="openFilter('patient')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button"
                            *ngIf="!!settings.filter.value.patient != !!settings.filter.initialValue.patient"
                            (click)="clearFilter('patient')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.patient">
                        <div class="filter-title">
                            <h5>Patient</h5>
                            <button (click)="closeFilter('patient')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="input-item">
                                <input name="patient" id="patient" [(ngModel)]="settings.filter.value.patient" />
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('patient')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('patient')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.patient }}</td>
        </ng-container>
        <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Payer</div>
                    <div class="filter-buttons"
                        [class.active]="!!settings.filter.value.payer != !!settings.filter.initialValue.payer">
                        <button class="filter-button" (click)="openFilter('payer')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button"
                            *ngIf="!!settings.filter.value.payer != !!settings.filter.initialValue.payer"
                            (click)="clearFilter('payer')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.payer">
                        <div class="filter-title">
                            <h5>Payer</h5>
                            <button (click)="closeFilter('payer')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="input-item">
                                <input name="payer" id="payer" [(ngModel)]="settings.filter.value.payer" />
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('payer')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('payer')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.payer }}</td>
        </ng-container>
        <ng-container matColumnDef="physician">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Physician</div>
                    <div class="filter-buttons"
                        [class.active]="!!settings.filter.value.physician != !!settings.filter.initialValue.physician">
                        <button class="filter-button" (click)="openFilter('physician')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button"
                            *ngIf="!!settings.filter.value.physician != !!settings.filter.initialValue.physician"
                            (click)="clearFilter('physician')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.physician">
                        <div class="filter-title">
                            <h5>Physician</h5>
                            <button (click)="closeFilter('physician')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="input-item">
                                <input name="physician" id="physician" [(ngModel)]="settings.filter.value.physician" />
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('physician')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('physician')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.physician }}</td>
        </ng-container>
        <ng-container matColumnDef="territory">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Territory</div>
                    <div class="filter-buttons"
                        [class.active]="!!settings.filter.value.territory != !!settings.filter.initialValue.territory">
                        <button class="filter-button" (click)="openFilter('territory')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button"
                            *ngIf="!!settings.filter.value.territory != !!settings.filter.initialValue.territory"
                            (click)="clearFilter('territory')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.territory">
                        <div class="filter-title">
                            <h5>Territory</h5>
                            <button (click)="closeFilter('territory')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="input-item">
                                <input name="territory" id="territory" [(ngModel)]="settings.filter.value.territory" />
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('territory')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('territory')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.territory }}</td>
        </ng-container>
        <ng-container matColumnDef="phase">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Phase</div>
                    <div class="filter-buttons" [class.active]="isCheckboxSelected('phase')">
                        <button class="filter-button" (click)="openFilter('phase')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button" *ngIf="isCheckboxSelected('phase')" (click)="clearFilter('phase')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.phase">
                        <div class="filter-title">
                            <h5>Phase</h5>
                            <button (click)="closeFilter('phase')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.phase.enrollment">Enrollment
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.phase.benefitsVerification">
                                    Benefits Verification
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.phase.priorAuthorization">
                                    Prior Authorization
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.phase.fulfillment">
                                    Fulfillment
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('phase')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('phase')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.phase }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Status</div>
                    <div class="filter-buttons" [class.active]="isCheckboxSelected('status')">
                        <button class="filter-button" (click)="openFilter('status')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button" *ngIf="isCheckboxSelected('status')"
                            (click)="clearFilter('status')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.status">
                        <div class="filter-title">
                            <h5>Status</h5>
                            <button (click)="closeFilter('status')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.status.pending">Pending</mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.status.inProcess">
                                    In Process
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.status.closed"> Closed </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.status.completed">
                                    Completed
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('status')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('status')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.status }}</td>
        </ng-container>
        <ng-container matColumnDef="subStatus">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Substatus</div>
                    <div class="filter-buttons" [class.active]="isCheckboxSelected('subStatus')">
                        <button class="filter-button" (click)="openFilter('subStatus')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button" *ngIf="isCheckboxSelected('subStatus')"
                            (click)="clearFilter('subStatus')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.subStatus">
                        <div class="filter-title">
                            <h5>Substatus</h5>
                            <button (click)="closeFilter('subStatus')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.subStatus.missingInformation">Missing
                                    Information</mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.subStatus.benefitsVerification">
                                    Benefits Verification
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.subStatus.followUpWithHCP">
                                    Follow-up with HCP
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.subStatus.priorAuthorization">
                                    Prior Authorization
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-item filter-checkbox">
                                <mat-checkbox [(ngModel)]="settings.filter.value.subStatus.awaitingShipment">
                                    Awaiting Shipment
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('subStatus')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('subStatus')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.subStatus }}</td>
        </ng-container>
        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef>
                <div class="filter-header">
                    <div mat-sort-header>Updated</div>
                    <div class="filter-buttons"
                        [class.active]="settings.filter.value.updated.start || settings.filter.value.updated.end">
                        <button class="filter-button" (click)="openFilter('updated')">
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <button class="clear-button"
                            *ngIf="settings.filter.value.updated.start || settings.filter.value.updated.end"
                            (click)="clearFilter('updated')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-wrapper" *ngIf="settings.filter.show.updated">
                        <div class="filter-title">
                            <h5>Updated</h5>
                            <button (click)="closeFilter('updated')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="filter-body">
                            <div class="date-range">
                                <mat-form-field appearance="fill">
                                    <mat-label>Enter a date range</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate placeholder="Start date"
                                            [(ngModel)]="settings.filter.value.updated.start" />
                                        <input matEndDate placeholder="End date"
                                            [(ngModel)]="settings.filter.value.updated.end" />
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="filter-controls">
                            <button class="clear" (click)="clearFilter('updated')">Clear</button>
                            <div class="primary-btn" (click)="applyFilter('updated')">Filter</div>
                        </div>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let case">{{ case.updated | date: "MM / dd / yyyy" }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let case; columns: columnsToDisplay" routerLink="/case" [queryParams]="{ id: case.id }">
        </tr>
        <tr class="mat-row no-data" *matNoDataRow>
            <td class="mat-cell" colspan="9">No data found</td>
        </tr>
    </table>
    <mat-paginator style="display: none" [pageSize]="settings.itemPerPage"></mat-paginator>
    <app-pagination mat-paginator *ngIf="data.length"
        [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'" [settings]="settings"
        (setActivePageHandler)="setActivePage($event)"></app-pagination>
</div>