import { Component, OnInit } from '@angular/core';
import { IUserQueue } from './dashboard.models';
import { throwError } from 'rxjs';
import { QueueService } from './dashboard.service';
import { isIntakeQueue } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-supervisor-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class SupervisorDashboardComponent implements OnInit {
  queueList: IUserQueue[];
  intakeQueueList: IUserQueue[];
  supervisorQueueList: IUserQueue[];

  constructor(public queueService: QueueService) {
  }

  ngOnInit() {
    this.queueService.getSupervisorQueues().subscribe((response) => {
      this.supervisorQueueList = response;
      this.supervisorQueueList.find(queue => queue.id == 1)['taskCount'] = 5  // hardcoded before demo
    }, error => throwError(error));

    this.queueService.getIntakeQueues().subscribe((response) => {
      this.intakeQueueList = response;
    }, error => throwError(error));

    this.queueService.getCaseManagementQueues().subscribe((response) => {
      this.queueList = response;
    }, error => throwError(error));
  }

  isIntakeQueue(queue) {
    return isIntakeQueue(queue.id);
  }
}
