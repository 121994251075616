import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as DocumentAction from '../../../../../store/document/document.actions';
import { DocumentTypes } from '../../../../../shared/enums/enums';
import { DocumentService } from './../../document.service';
import { throwError } from 'rxjs';
import { IDocument, IDocumentUpdateRequest } from '../../document.model';
import { IPatient } from '../../../../../shared/interfaces/interfaces';
import { NgScrollbar } from 'ngx-scrollbar';
import { Router } from '@angular/router';

@Component({
    selector: 'app-document-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss'],
})
export class DocumentAsideComponent implements OnInit {
    documentList: IDocument[];
    documentForm: FormGroup;
    patients: IPatient[] = [];
    isPatientLoading: boolean = false;
    isFinished = false;
    selectedPatient = null;
    selectedDocument: number;
    documentBatchId: number;
    suggestedPatients = [];
    focusedPatient: number = 0;

    @ViewChild('documentType') documentType: ElementRef;
    @ViewChild('other') otherField: ElementRef;
    @ViewChild('searchPatientField') searchPatientField: ElementRef;
    @ViewChild('patientList') patientList: ElementRef;
    @ViewChild('nextStack') nextStack: ElementRef;
    @ViewChild('nextButton') nextButton: ElementRef;
    @ViewChild(NgScrollbar) scrollbar: NgScrollbar;

    constructor(private store: Store<any>, public documentService: DocumentService, private router: Router) {
        this.documentForm = new FormGroup({
            documentType: new FormControl('', Validators.required),
            otherText: new FormControl(''),
            search: new FormControl(''),
        });
    }

    clearSearch(): void {
        this.documentForm.patchValue({
            search: '',
        });
    }
    selectPatient(patient) {
        this.selectedPatient = {
            id: patient.id,
            name: patient.name ? patient.name : `${patient.firstName} ${patient.lastName}`,
        };
        this.setDocumentOptions();
        this.documentForm.patchValue({
            search: '',
        });

        this.suggestedPatients = [];
        this.documentList.forEach((document) => {
            if (document.patient) {
                this.setSuggestedPatients(document.patient);
            }
        });
    }
    addPatient() {
        if (this.documentForm.value.search.trim().length) {
            let patient = { patientId: null, name: `NP: ${this.documentForm.value.search}` };
            this.selectPatient(patient);
        }
    }

    patientNavigate(event) {
        if (this.patientList) {
            if (event.key === 'Tab' || event.key === 'ArrowDown') {
                event.preventDefault();
                this.focusedPatient == this.patientList.nativeElement.children.length - 1
                    ? (this.focusedPatient = 0)
                    : (this.focusedPatient = this.focusedPatient + 1);
                this.scrollbar.scrollTo({ top: this.focusedPatient * 151 });
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.focusedPatient == 0
                    ? (this.focusedPatient = this.patientList.nativeElement.children.length - 1)
                    : (this.focusedPatient = this.focusedPatient - 1);
                this.scrollbar.scrollTo({ top: this.focusedPatient * 151 });
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                this.selectPatient(this.patients[this.focusedPatient]);
            }
        }
    }

    switchDocument() {
        let untypedDocument;
        untypedDocument = this.documentList.findIndex(
            (document, index) => !document.documentType && !document.patient && index > this.selectedDocument
        );
        if (untypedDocument == -1) {
            untypedDocument = this.documentList.findIndex((document) => !document.documentType && !document.patient);
            untypedDocument = this.selectedDocument + 1 == this.documentList.length ? 0 : this.selectedDocument + 1;
        }

        this.store.dispatch(new DocumentAction.SetSelectedDocument(untypedDocument));
        if (this.documentList[untypedDocument].documentType == null) {
            this.clearForm();
        }
        this.focusDocumentType();
    }

    setSuggestedPatients(newPatient) {
        let isAlreadySuggested = this.suggestedPatients.find(
            (patient) => patient.id == newPatient.id && patient.name.toLowerCase() == newPatient.name.toLowerCase()
        );
        if (!Boolean(isAlreadySuggested)) {
            this.suggestedPatients.push(newPatient);
        }
    }

    setDocumentOptions() {
        let options = {
            documentType: DocumentTypes[this.documentForm.value.documentType],
            otherText:
                this.documentForm.value.documentType == 'other'
                    ? this.documentForm.value.otherText
                    : this.documentForm.patchValue({
                        otherText: '',
                    }),
            patient: this.selectedPatient,
        };

        let newList = this.documentList.map((item, index) => {
            if (index == this.selectedDocument) {
                return {
                    ...item,
                    documentType: options.documentType,
                    otherText: options.otherText,
                    patient: options.patient,
                };
            } else {
                return item;
            }
        });

        if (this.selectedPatient) {
            this.setSuggestedPatients(this.selectedPatient);
        }

        this.store.dispatch(new DocumentAction.SetDocumentsList(newList));

        if (
            ((this.documentForm.value.documentType &&
                DocumentTypes[this.documentList[this.selectedDocument].documentType] != 'other') ||
                this.documentForm.value.otherText.trim().length) &&
            this.selectedPatient &&
            this.selectedPatient.name
        ) {
            this.checkIsAllDocumentFinished();
            this.isFinished ? null : this.switchDocument();
        }
    }

    focusPatientField() {
        this.searchPatientField.nativeElement.focus();
    }

    focusDocumentType() {
        this.documentType.nativeElement.firstChild.focus();
    }

    focusNextStack() {
        this.nextStack?.nativeElement.focus();
    }

    focusNextButton() {
        this.nextButton?.nativeElement.focus();
    }

    focusOtherField() {
        if (DocumentTypes[this.documentList[this.selectedDocument].documentType] == 'other') {
            setTimeout(() => {
                this.otherField.nativeElement.focus();
            }, 0);
        } else {
            this.focusPatientField();
        }
    }

    focusNotFilledData() {
        if (this.documentList[this.selectedDocument].documentType != null && this.selectedPatient) {
            if (this.nextButton) {
                this.focusNextButton();
            }
            else if (this.nextStack) {
                this.focusNextStack();
            }
        } else if (this.documentList[this.selectedDocument].documentType != null) {
            this.focusPatientField();
        }
    }

    clearForm() {
        this.documentForm.patchValue({
            otherText: '',
        });
    }

    checkIsAllDocumentFinished() {
        if (!this.isFinished) {
            let untypedDocument = this.documentList.find((document) => {
                if (DocumentTypes[document.documentType] == 'other') {
                    return !document.otherText || document.otherText.trim().length == 0 || !document.patient;
                } else {
                    return !DocumentTypes[document.documentType] || !document.patient;
                }
            });
            this.isFinished = !untypedDocument;
            if (!untypedDocument) {
                setTimeout(() => {
                    this.focusNextStack();
                }, 0);
            }
        } else {
            this.focusNextStack();
        }
    }

    searchPatients() {
        if (this.documentForm.value.search.trim().length) {
            this.isPatientLoading = true;
            const data = {
                search: this.documentForm.value.search,
            };
            this.documentService.getPatients(data).subscribe(
                (response) => {
                    this.patients = response;
                },
                (error) => throwError(error),
                () => (this.isPatientLoading = false)
            );
        }
    }

    updateDocuments() {
        const myPromise = this.getUpdatePromise();

        const promises = this.documentList.map((document) => {
            return myPromise(document);
        });

        return Promise.all(promises);
    }

    updateDocument(index) {
        if (this.documentList) {
            let document = { ...this.documentList[index] };
            if (document.documentType || document.documentType == 0 || document.patient) {
                if (DocumentTypes[document.documentType] == 'other' && document.otherText.trim().length == 0) {
                    document.documentType = null;
                }
                const myPromise = this.getUpdatePromise();
                const promise = myPromise({ ...document });
                return promise;
            }
        }
        return Promise.resolve();
    }

    getUpdatePromise() {
        return (document) => {
            const updatedDocumentData: IDocumentUpdateRequest = {
                documentType:
                    document.documentType || document.documentType == 0 ? Number(document.documentType) : null,
                patientId: document.patient && document.patient.id ? document.patient.id : null,
            };
            if (DocumentTypes[document.documentType] == 'other') {
                updatedDocumentData.otherDocumentTypeName = document.otherText;
            }
            return new Promise((resolve) =>
                this.documentService.updateDocument(document.id, updatedDocumentData).subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => throwError(error)
                )
            );
        };
    }

    getNextStack() {
        this.router.navigate(['/document-identify']);
        this.updateDocument(this.selectedDocument).then((response) => {
            this.documentService.setDocumentBatchCompleted(this.documentBatchId).subscribe(
                (response) => {
                    this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
                    this.suggestedPatients = [];
                    this.isFinished = false;
                    this.focusDocumentType();
                },
                (error) => throwError(error)
            );
        });
    }

    ngOnInit(): void {
        this.store.select('document').subscribe((state) => {
            let prevDocumentList = this.documentList;
            this.documentList = state.documentList;
            if (this.documentList &&
                this.documentList.length > 0 &&
                (!prevDocumentList || prevDocumentList.length == 0 || prevDocumentList.length != this.documentList.length)
            ) {
                this.checkIsAllDocumentFinished();
            }

            if (
                (this.selectedDocument && this.selectedDocument != state.selectedDocument) ||
                (this.selectedDocument == 0 && state.selectedDocument > 0)
            ) {
                this.updateDocument(this.selectedDocument);
            }
            this.selectedDocument = state.selectedDocument;

            this.selectedPatient =
                this.documentList && this.documentList.length ? this.documentList[this.selectedDocument].patient : null;
            this.documentBatchId = state.documentsBatchId;

            if (this.documentList && this.documentList.length) {
                if (DocumentTypes[this.documentList[this.selectedDocument].documentType] != 'other') {
                    this.documentForm.patchValue({
                        documentType: DocumentTypes[this.documentList[this.selectedDocument].documentType],
                    });
                } else {
                    if (this.documentList[this.selectedDocument].documentType) {
                        this.documentForm.patchValue({
                            documentType: DocumentTypes[this.documentList[this.selectedDocument].documentType],
                            otherText: this.documentList[this.selectedDocument].otherText,
                        });
                    }
                }
                this.documentList.forEach((document) => {
                    if (document.patient) {
                        this.setSuggestedPatients(document.patient);
                    }
                });

                this.focusNotFilledData();
            }

            if (this.documentList && !this.documentList.length) {
                this.getNextStack();
            }
        });
    }

    ngAfterViewInit() {
        this.focusDocumentType();
    }
}
