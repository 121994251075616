// TODO: remove this file and split each enum to a file

export enum Roles {
  Manager = 'Manager',
  Intake = 'Intake',
  Supervisor = 'Supervisor',
  Admin = 'Admin',
}

export enum CaseColumns {
  id,
  task,
  physician,
  pharmacy,
  payer,
  status,
  due,
}

export enum DocumentTypes {
  enrollmentForm = 0,
  papForm = 1,
  prescription = 2,
  insuranceCard = 3,
  proofOfIncome = 4,
  other = 5,
}

export enum DocumentTypeNames {
  enrollmentForm = 'Enrollment Form',
  papForm = 'PAP Form',
  prescription = 'Prescription',
  insuranceCard = 'Insurance Card',
  proofOfIncome = 'Proof of Income',
  other = 'Other',
}

export enum SupervisorUserColumns {
  userName,
  avgTasks,
  tasksOnTime,
  rank,
  remove,
}

export enum SupervisorAddUserColumns {
  name,
  role,
  assignments,
  isAdded,
}

export enum SupervisorTerritoryColumns {
  name,
  remove,
}

export enum SupervisorAddTerritoryColumns {
  name,
  isAdded,
}

export enum SupervisorTaskColumns {
  phase,
  name,
  description,
  remove,
}

export enum SupervisorAddTaskColumns {
  phase,
  task,
  description,
  isAdded,
}

export enum ManagerQueueIdentifyColumns {
  source,
  contact,
  pageCount,
  receivedDate,
  age,
}

export enum ManagerQueueDataColumns {
  patient,
  documentType,
  receivedDate,
  age,
}

export enum ManagerQueueTaskColumns {
  phase,
  task,
  patient,
  physician,
  payer,
  pharmacy,
  dueDate,
}

export enum SupervisorQueueTaskColumns {
  queue,
  task,
  escalated,
  reason,
  receivedDate,
  age,
}

export enum DeleteReason {
  blankPage = 0,
  other = 1,
}

export enum UncertainReason {
  blurryPage = 0,
  illegibleWriting = 1,
  other = 2,
}

export enum QueueIDs {
  'supervisor' = 1,
  'identify' = 2,
  'data-entry' = 3,
}

export enum QueueTypeNames {
  supervisor = 'SupervisorQueue',
  identification = 'IdentificationQueue',
  dataEntry = 'DataEntryQueue',
  caseManagement = 'CaseManagementQueue',
}

export enum CreationSteps {
  setup = 1,
  tasks = 2,
  territories = 3,
  users = 4,
}

export enum ContactMethod {
  phone = 0,
  fax = 1,
  email = 2,
  text = 3,
  mail = 4
}

export enum TaskContactMethodNames {
  'Call' = 0,
  'Fax' = 1,
  'Email' = 2,
  'Text' = 3,
  'Mail' = 4,
}

export enum ContactType {
  home = 0,
  mobile = 1,
  work = 2,
  emergency = 3,
  caregiver = 4,
}

export enum Gender {
  female = 0,
  male = 1,
  other = 2,
}

export enum TaskColumns {
  phase,
  task,
  due,
  button,
}

export enum Phases {
  enrollment = 0,
  fulfillment = 1,
}

export enum PhasesNames {
  enrollment = 'Enrollment',
  benefitsVerification = 'Benefits Verification',
  priorAuthorization = 'Prior Authorization',
  fulfillment = 'Fulfillment',
  'Enrollment' = 'enrollment',
  'Benefits Verification' = 'benefitsVerification',
  'Prior Authorization' = 'priorAuthorization',
  'Fulfillment' = 'fulfillment',
}

export enum CaseStatus {
  pending = 'Pending',
  inProcess = 'In Process',
  closed = 'Closed',
  completed = 'Completed',
  'Pending' = 'pending',
  'In Process' = 'inProcess',
  'Closed' = 'closed',
  'Completed' = 'completed',
}

export enum CaseSubStatus {
  missingInformation = 'Missing Information',
  benefitsVerification = 'Benefits Verification',
  followUpWithHCP = 'Follow-up with HCP',
  priorAuthorization = 'Prior Authorization',
  awaitingShipment = 'Awaiting Shipment',
  'Missing Information' = 'missingInformation',
  'Benefits Verification' = 'benefitsVerification',
  'Follow-up with HCP' = 'followUpWithHCP',
  'Prior Authorization' = 'priorAuthorization',
  'Awaiting Shipment' = 'awaitingShipment',
}

export enum PhaseEstimatedDurations {
  enrollment = 4,
  benefitsVerification = 24,
  priorAuthorization = 96,
  fulfillment = 96,
}

export enum Tasks {
  welcomeCall = 0,
  missingInfo = 1,
  pharmacyTriage = 2,
}

export enum TasksNames {
  welcomeCall = 'Welcome call',
  missingInfo = 'Missing info follow-up',
  pharmacyTriage = 'Pharmacy triage follow-up',
  'Welcome call to Patient' = 'welcomeCall',
  'Follow-up with HCP' = 'missingInfo',
  'Triage to SP' = 'pharmacyTriage',
}

export enum TaskType {
  'Follow-up with HCP, E' = 1,
  'Welcome call to Patient' = 2,
  'Call Payer' = 3,
  'Call PBM' = 4,
  'Send SOB to Physician' = 5,
  'Follow-up with HCP, BV' = 6,
  'Follow-up with HCP, PA' = 7,
  'Call payer' = 8,
  'Triage to SP' = 9,
  'Complete' = 997,
  'Pending' = 998,
  'Canceled' = 999,
}

export enum TaskOutcome {
  success = 0,
  failure = 1,
  repeat = 2,
}

export enum PatientGender {
  'Female' = 0,
  'Male' = 1,
  'Other' = 2,
}

export enum BestTimeToContact {
  'Morning' = 0,
  'Afternoon' = 1,
  'Evening' = 2,
}

export enum BestMethodToContact {
  'Phone' = 0,
  'Text' = 3,
  'Email' = 2,
}

export enum MethodReceived {
  Verbal = 0,
  Written = 1,
  Unselected = 2,
}

export enum PatientContactType {
  'Home' = 0,
  'Mobile' = 1,
  'Work' = 2,
}

export enum HIPAAConsent {
  'Yes' = 0,
  'No' = 1,
  'Unknown' = 2,
}

export enum PlanType {
  'Private/Commercial' = 0,
  'Medicare Part D' = 1,
  'Medicare Advantage' = 2,
  'Medicaid' = 3,
  'VA or Military' = 4,
}

export enum ConsentMethod {
  written = 0,
  verbal = 1,
}

export enum ConsentTypes {
  hipaa = 1,
  program = 2,
  marketing = 3,
  texting = 4,
  voicemail = 5,
}

export enum AddressType {
  shipping = 0,
  mailing = 1,
}

export enum EnrollmentColumns {
  isOptedIn,
  method,
  effectiveFrom,
  effectiveTo,
}

export enum SearchModalColumns {
  id,
  patient,
  payer,
  physician,
  territory,
  phase,
  status,
  updated,
}

export enum SearchColumns {
  id,
  patient,
  payer,
  physician,
  territory,
  phase,
  status,
  subStatus,
  updated,
}

export enum HistoryTaskType {
  task = 0,
  statusChange = 1,
}

export enum TargetNames {
  'Patient' = 0,
  'Payer' = 1,
  'Physician' = 2,
  'Pharmacy' = 3,
}

export enum BenefitsVerificationTasks {
  callPayer = 3,
  callPBM = 4,
  sendSummary = 5,
}

export enum TaskDirectionNames {
  'In' = 0,
  'Out' = 1,
}

export enum FulfillmentColumns {
  pharmacy,
  productName,
  ndc,
  daysSupply,
  shipDate
}

export enum PatientSegmentation {
  naive = 0,
  experienced = 1
}

export enum ConsentTypeId {
  hipaa = 1,
  program = 2,
  marketing = 3,
  texting = 4,
  voicemail = 5
}
