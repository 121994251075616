import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../../../../core/classes/UnsubscribeOnDestroy';
import { AddressType, ConsentTypes, ContactMethod, EnrollmentColumns } from '../../../../../../shared/enums/enums';
import {
  deepCopy,
  enumToArray, expiredFile,
  getContactInfoString,
  getDateObject,
  getISOString
} from '../../../../../../shared/helpers/utils';
import { IAddress } from '../../../interfaces/address.interface';
import { ICaregiver } from '../../../interfaces/caregiver.interface';
import { IConsent } from '../../../interfaces/consent.interface';
import { CaseService } from '../../../services/case.service';
import { AddressHistoryDialogComponent } from '../../dialogs/address-history-dialog/address-history-dialog.component';
import { ConfirmAddressDialogComponent } from '../../dialogs/confirm-address-dialog/confirm-address-dialog.component';
import { ConfirmCaregiverDialogComponent } from '../../dialogs/confirm-caregiver-dialog/confirm-caregiver-dialog.component';

@Component({
  selector: 'app-case-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: [
    '../../timeline/timeline.component.scss',
    '../benefit/benefit.component.scss',
    '../../task/benefit/benefit.component.scss',
    '../../task/task.component.scss',
    './enrollment.component.scss',
  ],
})
export class EnrollmentComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() case;
  @Input() panel;

  @Output() updateCase: EventEmitter<void> = new EventEmitter<void>();

  columnsToDisplay = enumToArray(EnrollmentColumns);

  hipaaDataSource;
  programDataSource;
  marketingDataSource;
  textingDataSource;

  AddressType = AddressType;
  ConsentTypes = ConsentTypes;
  consentMethods = [
    'Verbal',
    'Written',
  ];

  isConsentCreate: boolean;
  isHipaaOptOut: boolean;

  isProgramOptCreate: boolean;
  isProgramOptOut: boolean;

  isMarketingOptCreate: boolean;
  isMarketingOptOut: boolean;

  isTextingOptCreate: boolean;
  isTextingOptOut: boolean;

  acceptedConsents = {
    hipaaDataSource: false,
    programDataSource: false,
    marketingDataSource: false,
    textingDataSource: false,
  };

  showAddressForm: boolean;
  showCaregiverForm: boolean;

  addresses: IAddress[] = [];
  currentAddresses: IAddress[] = [];
  otherAddresses: IAddress[] = [];

  caregivers: ICaregiver[] = [];
  activeCaregivers: ICaregiver[] = [];
  inactiveCaregivers: ICaregiver[] = [];

  editAddressMode: boolean[] = [];
  editCaregiverMode: boolean[] = [];

  getContactInfoString = getContactInfoString;
  ContactMethod = ContactMethod;

  today = new Date();
  getDate = getDateObject;
  expiredFile = expiredFile;

  constructor(
    private caseService: CaseService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.case?.patient) {
      this.getConsents('hipaaDataSource', ConsentTypes.hipaa);
      this.getConsents('programDataSource', ConsentTypes.program);
      this.getConsents('marketingDataSource', ConsentTypes.marketing);
      this.getConsents('textingDataSource', ConsentTypes.texting);
      this.getAddresses(false);
      this.getCaregivers(false);
    }
  }

  toggleConsentForm(type: ConsentTypes, create: boolean, show: boolean): void {
    switch (type) {
      case ConsentTypes.hipaa:
        create ? this.isConsentCreate = show : this.isHipaaOptOut = show;
        break;
      case ConsentTypes.program:
        create ? this.isProgramOptCreate = show : this.isProgramOptOut = show;
        break;
      case ConsentTypes.marketing:
        create ? this.isMarketingOptCreate = show : this.isMarketingOptOut = show;
        break;
      case ConsentTypes.texting:
        create ? this.isTextingOptCreate = show : this.isTextingOptOut = show;
        break;
    }
  }

  toggleAddressForm(): void {
    this.showAddressForm = !this.showAddressForm;
  }

  toggleCaregiverForm(): void {
    this.showCaregiverForm = !this.showCaregiverForm;
  }

  createConsent(data, consentTypeId: ConsentTypes, dataSourceType: string, optOut = false): void {
    const consent = {
      id: 0,
      consentTypeId,
      consentIsOnFile: !optOut,
      consentMethod: +data.consentMethod,
      dateReceived: getISOString(data.effectiveFrom),
      effectiveEndDate: getISOString(data.effectiveTo),
      patientId: this.case?.patient?.id,
    };

    this.caseService.createEnrollmentConsent({ consent })
      .subscribe((res) => {
        this.getConsents(dataSourceType, consentTypeId);
        this.toggleConsentForm(consentTypeId, !optOut, false);

        this.updateCase.emit();
      });
  }

  cancelEditAddress(address: IAddress, i: number, isCurrent: boolean): void {
    const absIndex = this.addresses.findIndex(el => el.id === address.id);

    if (absIndex >= 0) {
      this[isCurrent ? 'currentAddresses' : 'otherAddresses'][i] = this.addresses[absIndex];
      this.editAddressMode[i + (isCurrent ? 0 : this.currentAddresses.length)] = false;
    }
  }

  cancelEditCaregiver(address: ICaregiver, i: number, isCurrent: boolean): void {
    const absIndex = this.caregivers.findIndex(el => el.id === address.id);

    if (absIndex >= 0) {
      this[isCurrent ? 'activeCaregivers' : 'inactiveCaregivers'][i] = this.caregivers[absIndex];
      this.editCaregiverMode[i + (isCurrent ? 0 : this.activeCaregivers.length)] = false;
    }
  }

  saveAddress(data, isInlineForm = true, isEdit = false, editIndex = null): void {
    const address = {
      patientAddress: {
        id: isEdit ? data.id : 0,
        address: {
          streetAddress: isEdit && !isInlineForm ? data.address.streetAddress : data.address,
          addressExtension: '',
          zipCode: isEdit && !isInlineForm ? data.address.zipCode : data.zipCode,
          city: isEdit && !isInlineForm ? data.address.city : data.city,
          state: isEdit && !isInlineForm ? data.address.state : data.state,
        },
        addressType: +data.addressType,
        current: data.current,
        patientId: this.case?.patient?.id,
      }
    };

    forkJoin([
      isEdit ?
        this.caseService.updateEnrollmentAddress(address) :
        this.caseService.createEnrollmentAddress(address)
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getAddresses(isEdit);

        if (!isEdit && isInlineForm) {
          this.toggleAddressForm();
        }

        if (isEdit) {
          this.editAddressMode[editIndex] = false;
        }

        if (+data.addressType === AddressType.mailing) {
          this.updateCase.emit();
        }
      });
  }

  setAddressCurrent(address: IAddress): void {
    this.dialog.open(ConfirmAddressDialogComponent, {
      data: {
        address,
        patient: this.case.patient,
      },
      panelClass: ['createEntityDialog', 'confirm'],
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          address.current = true;
          this.saveAddress(address, false, true);
        }
      });
  }

  saveCaregiver(data, isInlineForm = true, isEdit = false, editIndex = null): void {
    const splitName = data.name ? data.name.split(' ') : [];

    const caregiver = {
      id: isEdit ? data.id : 0,
      relationship: data.relationship,
      patientId: this.case?.patient?.id,
      current: data.current,
      firstName: data.name ? splitName[0] : data.firstName,
      middleName: data.name ? (splitName[2] ? splitName[1] : '') : data.middleName,
      lastName: data.name ? (splitName[2] ? splitName[2] : splitName[1]) : data.lastName,
      contactInfos: data.contactInfos,
    };

    forkJoin([
      isEdit ?
        this.caseService.updateEnrollmentCaregiver({ caregiver }) :
        this.caseService.createEnrollmentCaregiver({ caregiver })
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getCaregivers(isEdit);

        if (!isEdit && isInlineForm) {
          this.toggleCaregiverForm();
        }

        if (isEdit) {
          this.editCaregiverMode[editIndex] = false;
        }
      });
  }

  setCaregiverCurrent(caregiver: ICaregiver): void {
    this.dialog.open(ConfirmCaregiverDialogComponent, {
      data: {
        caregiver,
        patient: this.case.patient,
      },
      panelClass: ['createEntityDialog', 'confirm', 'caregiver'],
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          caregiver.current = !caregiver.current;
          this.saveCaregiver(caregiver, false, true);
        }
      });
  }

  viewAddressHistory(address: IAddress): void {
    this.dialog.open(AddressHistoryDialogComponent, {
      data: address,
      panelClass: ['createEntityDialog', 'address-history'],
    });
  }

  isConsentAvailable(consent: IConsent): boolean {
    return consent ? getDateObject(consent.dateReceived).getTime() <= this.today.getTime() &&
      getDateObject(consent.effectiveEndDate).getTime() >= this.today.getTime() : false;
  }

  private getConsents(dataSourceType: string, consentTypeId: ConsentTypes): void {
    this.caseService.getEnrollmentConsents({ take: -1, patientId: this.case.patient.id, consentTypeId })
      .subscribe((consents) => {
        this[dataSourceType] = new MatTableDataSource(consents);
        this.acceptedConsents[dataSourceType] = consents[0]?.consentIsOnFile || false;
      });
  }

  private getAddresses(isAfterEdit: boolean) {
    this.caseService.getEnrollmentAddresses({ take: -1, patientId: this.case.patient.id })
      .subscribe((addresses) => {
        this.addresses = deepCopy(addresses);
        this.currentAddresses = addresses.filter(el => el.current);
        this.otherAddresses = addresses.filter(el => !el.current);

        if (!isAfterEdit) {
          this.editAddressMode = Array(addresses.length).fill(false);
        }
      });
  }

  private getCaregivers(isAfterEdit: boolean): void {
    this.caseService.getEnrollmentCaregivers({ take: -1, patientId: this.case.patient.id })
      .subscribe((caregivers) => {
        this.caregivers = deepCopy(caregivers);
        this.activeCaregivers = caregivers.filter(el => el.current);
        this.inactiveCaregivers = caregivers.filter(el => !el.current);

        if (!isAfterEdit) {
          this.editCaregiverMode = Array(caregivers.length).fill(false);
        }
      });
  }
}
