import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manager-dashboard-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class ManagerDashboardFilterComponent implements OnInit {
  @Output() showCases = new EventEmitter();
  @Input() durationValue;

  action: string = '';
  group: string = '';
  constructor() {}

  ngOnInit(): void {}

  showCasesHandler() {
    this.showCases.emit(this.durationValue);
  }
}
