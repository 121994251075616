import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from '../search.service';
import { throwError } from 'rxjs';
import { SearchModalColumns } from 'src/app/shared/enums/enums';
import { enumToArray, getSuggestedCases } from 'src/app/shared/helpers/utils';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-field-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  columnsToDisplay = enumToArray(SearchModalColumns);
  showItems = 5;
  cases = [];
  suggestedCases = [];
  dataSource;
  searchValue;
  searchType = 0;
  isLoaded = false;

  constructor(
    public searchService: SearchService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.searchValue = this.data.searchValue;
    this.suggestedCases = getSuggestedCases();
    this.searchCases();
  }

  searchCases() {
    const data = { take: 0, search: this.searchValue, type: this.searchType };
    this.isLoaded = false;
    this.searchService.getSearchedCases(data).subscribe(
      (response) => {
        this.cases = response;
        this.dataSource = new MatTableDataSource(this.cases.slice(0, this.showItems));
        this.isLoaded = true;
      },
      (error) => {
        throwError(error);
      }
    );
  }

  goToSearchPage() {
    this.router.navigate(['search'], { state: { searchValue: this.searchValue, cases: this.cases } });
  }

  openCase(caseId) {
    this.router.navigate(['/case'], { queryParams: { id: caseId } });
  }
}
