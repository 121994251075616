<div class="task-wrapper">
  <div class="task-title">
    <h2>Current Tasks</h2>
    <button class="primary-btn invert" #createActivityEl (click)="createActivityEl.blur();createActivity.emit();">
      <mat-icon>add</mat-icon>
      Create Activity
    </button>
  </div>
  <div class="task-table">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef>Phase</th>
        <td mat-cell *matCellDef="let task">
          <div class="">
            <!-- <mat-icon *ngIf="task.locked">lock</mat-icon> -->
            {{ task.phase }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef>Task</th>
        <td mat-cell *matCellDef="let task">
          <div class="task-name" (click)="!task.locked && setSelectedTask(task)">
            {{ task.task }}<ng-container *ngIf="task.attemptNumber">, attempt {{ task.attemptNumber + 1 }}
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef>Due</th>
        <td mat-cell *matCellDef="let task">{{ task.due | date: "EEE, M/d" }}</td>
      </ng-container>
      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let task">
          <button *ngIf="!task.outcome" (click)="setSelectedTask(task)">
            Start
            <img src="../../../../../../assets/icons/icon-arrow-back.svg" alt="start" />
          </button>
          <button *ngIf="task.locked" class="in-progress">In Progress...</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let task; columns: columnsToDisplay"></tr>
    </table>
    <div class="empty-current-tasks" *ngIf="!data?.length">No current tasks; try checking here later.</div>
  </div>
</div>