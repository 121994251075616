import { Pipe, PipeTransform } from '@angular/core';
import { ContactMethod, ContactType } from '../enums/enums';
import { IContactInfo } from '../interfaces/interfaces';

@Pipe({
  name: 'contactString'
})
export class ContactStringPipe implements PipeTransform {

  transform(value: IContactInfo[], contactMethod: ContactMethod, contactType: ContactType = ContactType.work): string {
    if (value && value.length) {
      for (const el of value) {
        if (el.contactType === contactType && el.contactMethod === contactMethod) {
          return el.contactString;
        }
      }
    }

    return '';
  }

}
