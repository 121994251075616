<table [hidden]="!_tasks.length" mat-table [dataSource]="dataSource" matSort matSortActive="phase" matSortDirection="asc">
    <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
        <td mat-cell *matCellDef="let task">{{ task.phase }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
        <td mat-cell *matCellDef="let task">{{ task.name }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let task">{{ task.description }}</td>
    </ng-container>
    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
            <button class="primary-btn" (click)="addTask()">
                <mat-icon>add_circle</mat-icon>Add Task
            </button>
        </th>
        <td mat-cell *matCellDef="let task">
            <button class="remove-button" (click)="removeTask(task)">
                <mat-icon>close</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let task; columns: columnsToDisplay"></tr>
</table>
<app-manage-empty-queue *ngIf="!_tasks.length" (addFunction)="addTask()" type="tasks"></app-manage-empty-queue>