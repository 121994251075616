<div mat-dialog-title>
  <div class="header">
    <img src="../../../../../../../assets/icons/stethoscope-physician.svg" alt="edit physician">
    <h1>Update {{data.step}}</h1>
  </div>

  <button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <p>You’re updating the {{data.step}} for <span>Case ID #{{data.case?.id}}</span>.</p>
</div>

<div mat-dialog-content>
  <div class="dialog-body"
       [class.height-auto]="(physicianOptions.selectedPhysician && data.step === 'physician') || (physicianOptions.selectedFacility && data.step === 'office')">
    <div class="physician-dialog" *ngIf="data.step === 'physician'">
      <div class="input-item search" *ngIf="!(physicianOptions.selectedPhysician || physicianOptions.newPhysician)">
        <label for="physicianSearch">Select Physician</label>
        <img *ngIf="!isPhysicianSearchActive" src="../../../../../../../assets/icons/icon-search.svg" alt="search"/>
        <img *ngIf="isPhysicianSearchActive" src="../../../../../../../assets/icons/icon-search-active.svg" alt="search"/>
        <input matInput type="text" id="physicianSearch"
               [formControl]="physicianSearchControl"
               (focus)="isPhysicianSearchActive = true"
               (blur)="isPhysicianSearchActive = false"
               placeholder="Search by Physician Name or NPI"
               (keydown)="searchNavigate($event, 'physician')"/>
      </div>

      <ul class="physician-results physician-search"
          *ngIf="physicianSearchControl.value.length >= 3 && !(physicianOptions.newPhysician || physicianOptions.selectedPhysician)"
          #physicianSearchList>
        <div class="items-container">
          <li *ngFor="let physician of physicians$ | async; index as i" (click)="selectPhysician(physician)"
              [class.focused]="focusedSearch == i">
            <div class="physician-title">
              <h5>{{ physician.firstName | capitalize }} {{ physician.lastName | capitalize }}</h5>
            </div>
            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>NPI</h6>
                <p>{{ physician.npi | empty}}</p>
              </div>
              <div class="physician-item licenses">
                <h6>Licenses</h6>
                <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
                <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.license | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Specialty</h6>
                <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
                <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.desc | empty}}</p>
              </div>
            </div>
          </li>
        </div>
        <li *ngIf="!physicians$.getValue().length" class="not-found">
          <h5>{{ physicianSearchControl.value }}</h5>
          <p>Physician Not Found</p>
        </li>
        <li class="add">
          <button class="primary-btn" type="button" (click)="addNewPhysician()">
            <mat-icon>add_circle</mat-icon>
            New Physician
          </button>
        </li>
      </ul>

      <app-new-physician-form
        [initName]="physicianSearchControl.value"
        [getFocus]="physicianOptions.newPhysician"
        (cancel)="cancelNewPhysician()"
        (create)="createNewPhysician($event)"
        *ngIf="physicianOptions.newPhysician"
      ></app-new-physician-form>

<!--      TODO: move to shared-->
      <!--      TODO: add missing fields??-->
      <div class="selected-physician" *ngIf="physicianOptions.selectedPhysician">
        <div class="physician-title">
          <img src="../../../../../../../assets/icons/icon-check.svg" alt="check"/>
          <h5>
            {{ physicianOptions.selectedPhysician.firstName | capitalize }} {{ physicianOptions.selectedPhysician.lastName | capitalize }}
          </h5>
          <button (click)="selectPhysician(null)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="physician-wrapper">
          <div class="physician-item">
            <h6>NPI</h6>
            <p>{{ physicianOptions.selectedPhysician.npi | empty}}</p>
          </div>
          <div class="physician-item licenses">
            <h6>License(s)</h6>
            <p *ngFor="let taxonomy of physicianOptions.selectedPhysician.taxonomies">
              {{ taxonomy.license | empty}}
            </p>
          </div>
          <div class="physician-item">
            <h6>Specialty</h6>
            <p *ngFor="let taxonomy of physicianOptions.selectedPhysician.taxonomies">
              {{ taxonomy.desc | empty}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="office-dialog" *ngIf="data.step === 'office'">
      <div class="input-item search" *ngIf="!physicianOptions.newFacility && !physicianOptions.selectedFacility">
        <label for="facilitySearch">Select Office</label>

        <img *ngIf="!isFacilitySearchActive" src="../../../../../../../assets/icons/icon-search.svg" alt="search"/>
        <img *ngIf="isFacilitySearchActive" src="../../../../../../../assets/icons/icon-search-active.svg" alt="search"/>

        <input matInput type="text" id="facilitySearch"
               [formControl]="facilitySearchControl"
               placeholder="Search Existing Offices"
               #searchFacility (keydown)="searchNavigate($event, 'facility')"/>
      </div>

      <ul #facilitySearchList class="physician-results facility-results"
          *ngIf="facilitySearchControl.value.length >= 3 && !physicianOptions.newFacility && !physicianOptions.selectedFacility">
        <div class="items-container" #physicianFacility>
          <li *ngFor="let facility of facilities$ | async; index as i"
              (click)="selectFacility(facility, false)"
              [class.focused]="focusedSearch == i">
            <div class="physician-title">
              <h5>
                {{ facility.name }}
              </h5>
              <span *ngIf="facility.mostLikely">
                <mat-icon>grade</mat-icon>Most Likely
              </span>
            </div>
            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>Address</h6>
                <p>{{ facility.address | fulladdress | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Group Tax</h6>
                <p>{{ facility.groupTaxId | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Phone</h6>
                <p>{{ facility.contactInfos | phone | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Fax</h6>
                <p>{{ facility.contactInfos | fax | empty}}</p>
              </div>
            </div>
          </li>
        </div>
        <li *ngIf="!facilities$.getValue().length" class="not-found">
          <h5>{{ facilitySearchControl.value }}</h5>
          <p>Facility Not Found</p>
        </li>
        <li class="add">
          <button class="primary-btn" type="button" (click)="addNewFacility()">
            <mat-icon>add_circle</mat-icon>
            New Facility
          </button>
        </li>
      </ul>

      <!--      TODO: move to shared-->
      <!--      TODO: add missing fields, separate address to pieces??-->
      <form *ngIf="physicianOptions.selectedFacility"
            class="standard-form selected-physician selected-facility" [formGroup]="facilityForm">
        <div class="physician-title">
          <div>
            <img src="../../../../../../../assets/icons/icon-check.svg" alt="check"/>
            <h5>{{ physicianOptions.selectedFacility.name }}</h5>
          </div>
          <div>
            <button type="button" *ngIf="!physicianOptions.isEditedFacility" (click)="editFacility()"
                    class="edit">
              <mat-icon>edit</mat-icon>
            </button>
            <button type="button" (click)="selectFacility(null, false)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="physician-wrapper" *ngIf="!physicianOptions.isEditedFacility">
          <div class="physician-item">
            <h6>Address</h6>
            <p>{{ physicianOptions.selectedFacility.address | fulladdress | empty}}</p>
          </div>
          <div class="physician-item">
            <h6>Group Tax</h6>
            <p>{{ physicianOptions.selectedFacility.groupTaxId | empty}}</p>
          </div>
          <div class="physician-item">
            <h6>Phone</h6>
            <p>{{ physicianOptions.selectedFacility.contactInfos | phone | empty}}</p>
          </div>
          <div class="physician-item">
            <h6>Fax</h6>
            <p>{{ physicianOptions.selectedFacility.contactInfos | fax | empty}}</p>
          </div>
        </div>
        <div class="physician-wrapper edited" *ngIf="physicianOptions.isEditedFacility">
          <div class="form-row address-row half-width first">
            <div class="input-item" [ngClass]="{
              'input-success': facilityForm.value.address && facilityForm.controls['address'].touched
              }">
              <label for="editAddress">Address</label>
              <input name="address" id="editAddress" formControlName="address"/>
            </div>
          </div>
          <div class="form-row half-width">
            <div class="input-item" [ngClass]="{
              'input-error':
                  facilityForm.controls['tax'].invalid &&
                  (facilityForm.controls['tax'].touched),
              'input-success': facilityForm.controls['tax'].valid && facilityForm.controls['tax'].touched
            }">
              <label for="editTax">Group Tax</label>
              <input name="tax" id="editTax" formControlName="tax"/>
            </div>
          </div>
          <div class="form-row half-width">
            <div class="input-item" [ngClass]="{
              'input-error':
                  facilityForm.controls['officePhone'].invalid &&
                  (facilityForm.controls['officePhone'].touched),
              'input-success':
                  facilityForm.controls['officePhone'].valid &&
                  facilityForm.controls['officePhone'].touched
            }">
              <label for="editOfficePhone">Phone</label>
              <input name="officePhone" id="editOfficePhone" formControlName="officePhone" mask="(000) 000-0000"
                     placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
            </div>
          </div>
          <div class="form-row half-width">
            <div class="input-item" [ngClass]="{
              'input-error':
                  facilityForm.controls['officeFax'].invalid &&
                  (facilityForm.controls['officeFax'].touched),
              'input-success':
                  facilityForm.controls['officeFax'].valid && facilityForm.controls['officeFax'].touched
            }">
              <label for="editOfficeFax">Fax</label>
              <input name="officeFax" id="editOfficeFax" formControlName="officeFax" mask="(000) 000-0000"
                     placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
            </div>
          </div>
          <div class="form-row half-width">
            <div class="input-item" [ngClass]="{
              'input-error':
                  facilityForm.controls['zipCode'].invalid &&
                  (facilityForm.controls['zipCode'].touched),
              'input-success':
                  facilityForm.controls['zipCode'].valid && facilityForm.controls['zipCode'].touched
            }">
              <label for="editZipCode">Zip</label>
              <input name="zipCode" id="editZipCode" formControlName="zipCode"/>
            </div>
          </div>
          <div *ngIf="physicianOptions.selectedFacility.city || physicianOptions.selectedFacility.state" class="form-row half-width">
            <div class="input-item">
              <label for="editZipCode">City, State</label>
              <input disabled [value]="physicianOptions.selectedFacility.city + ' ' + physicianOptions.selectedFacility.state"/>
            </div>
          </div>
          <div class="action-buttons">
            <button class="cancel" (click)="exitEditFacility()">Exit</button>
            <div class="primary-btn" (click)="saveFacility(); editFacility()">Save</div>
          </div>
        </div>
      </form>

      <app-new-facility-form
        *ngIf="physicianOptions.newFacility"
        [initName]="facilitySearchControl.value"
        [getFocus]="physicianOptions.newFacility"
        (cancel)="cancelNewFacility()"
        (create)="createNewFacility($event)"
      ></app-new-facility-form>
    </div>
  </div>
</div>

<div mat-dialog-actions [class.office]="data.step === 'office'">
  <button mat-button class="next"
          *ngIf="data.step === 'physician'"
          [disabled]="physicianOptions.newPhysician || !physicianOptions.selectedPhysician"
          (click)="setStep('office')">
    Next: <span class="type">Select Office</span>
  </button>

  <button mat-button class="prev"
          *ngIf="data.step === 'office'"
          [disabled]="physicianOptions.newFacility"
          (click)="setStep('physician')">
    Back: <span class="type">Select Physician</span>
  </button>

  <button mat-button class="save"
          [disabled]="physicianOptions.newFacility || !physicianOptions.selectedFacility"
          *ngIf="data.step === 'office'"
          (click)="save()">
    Save
  </button>
</div>
