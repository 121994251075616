import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, TaskOutcome } from 'src/app/shared/enums/enums';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-task-followup-enrollment',
    templateUrl: './followup-enrollment.component.html',
    styleUrls: ['../task.component.scss', './followup-enrollment.component.scss'],
})
export class FollowupEnrollmentTaskComponent implements OnInit {
    @Input() selectedTask;
    @Input() case;
    @Output() minimizeTaskHandler = new EventEmitter();
    @Output() setSelectedTaskHandler = new EventEmitter();
    @Output() rescheduleTaskHandler = new EventEmitter();
    @Output() submitTaskHandler = new EventEmitter();
    followUpForm: FormGroup;
    interpolatedDescription;

    constructor() {
        this.followUpForm = new FormGroup({
            contact: new FormControl(null, [Validators.required]),
            missingInfo: new FormControl(null, [Validators.required]),
            note: new FormControl(null, [Validators.required]),
        });
    }

    minimizeTask(boolean) {
        this.selectedTask.formData = this.followUpForm.getRawValue();
        this.minimizeTaskHandler.emit(boolean);
    }

    setSelectedTask(task) {
        this.setSelectedTaskHandler.emit(task);
    }

    submitTask() {
        let contact = this.followUpForm.value.contact == '0';
        let missingInfo = this.followUpForm.value.missingInfo == '0';
        const data = {
            queueItemId: this.case.id,
            queueTaskId: this.selectedTask.id,
            taskOutcome: contact && missingInfo ? TaskOutcome.success : TaskOutcome.failure,
            outcomeNote: this.followUpForm.value.note
        };
        this.submitTaskHandler.emit(data);
    }

    rescheduleTask() {
        const data = {
            attemptNumber: this.selectedTask.attemptNumber,
            task: this.selectedTask.task,
            outcomeNote: this.followUpForm.value.note,
            case: this.case
        };
        this.rescheduleTaskHandler.emit(data);
    }

    ngOnInit(): void {
        this.interpolatedDescription = this.case.facility && this.case.physician
            ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject())
            : this.selectedTask.description;
        if (this.selectedTask.formData) {
            this.followUpForm.patchValue(this.selectedTask.formData);
        }
    }

    getDescriptionTemplateObject() {
        let facilityPhone = this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
            ? this.case.facility.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
            : null;
        let facilityName = this.case.facility?.name || '';
        let physicianName = `${this.case.physician?.firstName || ''} ${this.case.physician?.lastName || ''}`;
        return {
            facilityPhone: `<a href="#" class="highlighted">${formatPhone(facilityPhone?.contactString)}</a>`,
            facilityName: `<span class="highlighted">${facilityName}</span>`,
            physicianName: `<span class="highlighted">Dr. ${physicianName}</span>`,
        };
    }
}
