<form [formGroup]="insuranceForm" novalidate (ngSubmit)="submitHandler()" (keydown.enter)="$event.preventDefault()">
    <div class="aside-wrapper">
        <h2>Insurance</h2>
        <div class="form-row">
            <div [class]="
                    insuranceForm.controls['medicalInsurance'].invalid && submitted
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>* {{ insuranceFormFields.medicalInsurance }}</label>
                <mat-form-field appearance="fill" class="input-medical">
                    <mat-select formControlName="medicalInsurance" name="medicalInsurance" placeholder="Select"
                        id="medicalInsurance" disableOptionCentering panelClass="dropdown-item"
                        (selectionChange)="selectMedicalInsurance()" #medicalInsurance>
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Yes</mat-option>
                        <mat-option [value]="1">No</mat-option>
                        <mat-option [value]="2">Unknown</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="tip" *ngIf="insuranceForm.value.medicalInsurance == undefined">
            <b>Next:</b><i>Select Medical Insurance Plan</i>
        </div>
        <div class="search-form" *ngIf="insuranceForm.value.medicalInsurance === 0">
            <div class="input-item">
                <label>{{ insuranceFormFields.searchMedicalInsurancePlan }}</label>
            </div>
            <div class="input-item search">
                <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
                <input matInput type="text" formControlName="searchMedicalInsurancePlan"
                    placeholder="Search by Plan Name, Payer Name, or Phone Number" (input)="searchPlans()"
                    (keydown)="searchNavigate($event)" />
                <button mat-button *ngIf="insuranceForm.value.searchMedicalInsurancePlan" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <ul class="physician-results facility-results"
                *ngIf="insuranceForm.value.searchMedicalInsurancePlan && insuranceForm.value.searchMedicalInsurancePlan.length >= 3">
                <ng-scrollbar #scrollbar *ngIf="!isPlansLoading && medicalPlans.length" class="custom-scrollbar">
                    <div class="patient-list" #searchList>
                        <li *ngFor="let plan of medicalPlans; index as i" [class.focused]="focusedSearch == i">
                            <div *ngIf="!plan.planId" (click)="selectPlan(plan)" class="add-plan">
                                <div class="add-plan-title">
                                    <div class="physician-title">
                                        <h5>
                                            {{ plan.payerName }}
                                        </h5>
                                    </div>
                                    <i>Plan Unknown</i>
                                </div>
                                <button class="primary-btn" type="button">
                                    <mat-icon>add_circle</mat-icon>Add Plan
                                </button>
                            </div>
                            <div *ngIf="plan.planId" (click)="selectPlan(plan)">
                                <div class="physician-title">
                                    <h5>
                                        {{ plan.planName }}
                                    </h5>
                                </div>
                                <div class="physician-wrapper">
                                    <div class="physician-item">
                                        <h6>Payer</h6>
                                        <p>{{ plan.payerName | empty}}</p>
                                    </div>
                                    <div class="physician-item phone-item">
                                        <h6>Payer Phone</h6>
                                        <p>{{ plan.payerPhone | phoneNumber | empty}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </div>
                </ng-scrollbar>
                <li *ngIf="isPlansLoading">
                    <mat-spinner></mat-spinner>
                </li>
                <li *ngIf="!medicalPlans.length && !isPlansLoading" class="not-found">
                    <h5>{{ insuranceForm.value.searchMedicalInsurancePlan }}</h5>
                    <p>Insurance Not Found</p>
                </li>
            </ul>
        </div>
        <div class="selected-physician selected-plan" *ngIf="selectedPlan != null">
            <div *ngIf="selectedPlan.planId">
                <div class="physician-title">
                    <img src="../../../../../../../assets/icons/icon-check.svg" alt="check" />
                    <h5>{{ selectedPlan.planName }}</h5>
                    <button (click)="removePlan()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="physician-wrapper">
                    <div class="physician-item">
                        <h6>Payer</h6>
                        <p>{{ selectedPlan.payerName | empty}}</p>
                    </div>
                    <div class="physician-item">
                        <h6>Payer Phone</h6>
                        <p>{{ selectedPlan.payerPhone | phoneNumber | empty}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!selectedPlan.planId">
                <div class="physician-title">
                    <img src="../../../../../../../assets/icons/icon-check.svg" alt="check" />
                    <h5>
                        {{ selectedPlan.payerName }}
                    </h5>
                    <button (click)="removePlan()" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <i>New Plan</i>
            </div>
        </div>
        <div class="new-plan" [formGroup]="newPlanForm" *ngIf="selectedPlan != null">
            <div class="form-row" *ngIf="!selectedPlan.planId || !selectedPlan.planName">
                <div [class]="
                        newPlanForm.controls['name'].invalid &&
                        (newPlanForm.controls['name'].touched || settings.isValid == false) ? 'input-item input-error' : 'input-item'
                    " style="width: 267px">
                    <label for="name">{{ insuranceFormFields.name }}</label>
                    <input name="name" id="name" formControlName="name" />
                </div>
            </div>
            <div class="form-row">
                <div [class]="
                        newPlanForm.controls['planPhone'].invalid &&
                        (newPlanForm.controls['planPhone'].touched || settings.isValid == false)
                            ? 'input-item input-error'
                            : 'input-item'
                    " style="width: 142px">
                    <label for="planPhone">{{ insuranceFormFields.planPhone }}</label>
                    <input name="planPhone" id="planPhone" formControlName="planPhone" mask="(000) 000-0000"
                        placeholder="(_ _ _) _ _ _ - _ _ _ _" />
                </div>
            </div>
            <div class="form-row">
                <div [class]="
                        newPlanForm.controls['planId'].invalid && submitted ? 'input-item input-error' : 'input-item'
                    " style="width: 142px">
                    <label for="planId">{{ insuranceFormFields.planId }}</label>
                    <input name="planId" id="planId" formControlName="planId" maxlength="50" />
                </div>
                <div [class]="
                        newPlanForm.controls['group'].invalid &&
                        (newPlanForm.controls['group'].touched || settings.isValid == false)
                         ? 'input-item input-error' : 'input-item'
                    " style="width: 142px">
                    <label for="group">{{ insuranceFormFields.group }} #</label>
                    <input name="group" id="group" formControlName="group" maxlength="50" />
                </div>
            </div>
            <div class="form-row">
                <div [class]="
                        newPlanForm.controls['policyHolderName'].invalid &&
                        (newPlanForm.controls['policyHolderName'].touched || settings.isValid == false)
                            ? 'input-item input-error'
                            : 'input-item'
                    " style="width: 267px; flex-shrink: 0">
                    <label for="policyHolderName">{{ insuranceFormFields.policyHolderName }} #</label>
                    <input name="policyHolderName" id="policyHolderName" formControlName="policyHolderName"
                        maxlength="50" />
                </div>
                <div [class]="
                        newPlanForm.controls['policyHolderDOB'].invalid &&
                        (newPlanForm.controls['policyHolderDOB'].touched || settings.isValid == false)
                            ? 'input-item input-error'
                            : 'input-item'
                    ">
                    <label for="policyHolderDOB">{{ insuranceFormFields.policyHolderDOB }}</label>
                    <div class="date-input">
                        <input matInput [matDatepicker]="policyHolderDOB" id="policyHolderDOB"
                            formControlName="policyHolderDOB" placeholder="MM/DD/YY" [max]="disableDate"
                            (focus)="policyHolderDOB.open()" (click)="policyHolderDOB.open()" />
                        <mat-datepicker-toggle matSuffix [for]="policyHolderDOB" tabindex="-1"></mat-datepicker-toggle>
                        <mat-datepicker #policyHolderDOB></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div [class]="
                        newPlanForm.controls['planType'].invalid &&
                        (newPlanForm.controls['planType'].touched || settings.isValid == false)
                            ? 'input-item input-error select'
                            : 'input-item select'
                    " style="width: 142px">
                    <label>{{ insuranceFormFields.planType }}</label>
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="planType" name="planType" placeholder="Select" id="planType"
                            disableOptionCentering panelClass="dropdown-item">
                            <mat-option>--</mat-option>
                            <mat-option [value]="0">Private/Commercial</mat-option>
                            <mat-option [value]="1">Medicare Part D</mat-option>
                            <mat-option [value]="2">Medicare Advantage</mat-option>
                            <mat-option [value]="3">Medicaid</mat-option>
                            <mat-option [value]="4">VA or Military</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row">
                <div [class]="
                        newPlanForm.controls['planOrder'].invalid &&
                        (newPlanForm.controls['planOrder'].touched || settings.isValid == false)
                            ? 'input-item input-error select'
                            : 'input-item select'
                    " style="width: 142px">
                    <label>{{ insuranceFormFields.planOrder }}</label>
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="planOrder" name="planOrder" placeholder="Select" id="planOrder"
                            disableOptionCentering panelClass="dropdown-item">
                            <mat-option>--</mat-option>
                            <mat-option [value]="0">Yes</mat-option>
                            <mat-option [value]="1">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <button type="submit" class="next-button next-stack" #nextBtn (keydown.enter)="submitHandler()">
        <b>Next:</b> Physician <mat-icon>arrow_forward</mat-icon>
    </button>
</form>