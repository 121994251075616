<div *ngIf="!documentList" class="document-loader-container">
    <app-loader></app-loader>
</div>
<div *ngIf="documentList && documentList.length" [class]="isGrid ? 'document-gallery grid' : 'document-gallery'">
    <ng-scrollbar #scrollbar class="custom-scrollbar document-list" visibility="hover">
        <div [class]="!isGrid && documentsOptions.selectedDocument == i ? 'document-item active' : 'document-item'"
            *ngFor="let document of documentList; index as i">
            <div class="img-wrapper" (click)="selectDocument(document)">
                <img [src]="document.thumbnail" *ngIf="document.thumbnail" />
                <mat-spinner *ngIf="!document.thumbnail"></mat-spinner>
                <div class="document-tags"
                    *ngIf="queueID == 2 && (document.patient || documentTypes[document.documentType])">
                    <span *ngIf="documentTypes[document.documentType]">{{
                        documentTypes[document.documentType] != "other"
                        ? documentTypeNames[documentTypes[document.documentType]]
                        : document.otherText
                        }}</span>
                    <span *ngIf="document.patient">{{ document.patient.name }}</span>
                </div>
            </div>
            <h4>{{ i + 1 }}</h4>
        </div>
    </ng-scrollbar>
    <div class="document-view" *ngIf="!isGrid">
        <div class="full-image" mouseWheel (mouseWheelUp)="zoomDocument('in')" (mouseWheelDown)="zoomDocument('out')">
            <div class="img-drag" cdkDrag [cdkDragFreeDragPosition]="dragPosition">
                <img *ngIf="documentList && documentList[documentsOptions.selectedDocument].raw" [style.transform]="
                        'scale(' + documentsOptions.zoomPercent + ') rotate(' + documentsOptions.rotate + 'deg)'
                    " [src]="documentList[documentsOptions.selectedDocument].raw" />
                <mat-spinner *ngIf="!(documentList && documentList[documentsOptions.selectedDocument].raw)">
                </mat-spinner>
            </div>
        </div>
        <div class="document-controls-wrapper" *ngIf="documentList">
            <div class="document-controls">
                <div class="document-count">
                    <mat-icon (click)="switchDocument('prev')">expand_less</mat-icon>
                    <b>
                        <input type="text" mask="0000" [(ngModel)]="documentsOptions.currentDocumentOrder"
                            (input)="setSelectedDocument()" />
                        <i>of</i> {{ documentList.length }}</b>
                    <mat-icon (click)="switchDocument('next')">expand_more</mat-icon>
                </div>
                <div class="zoom">
                    <mat-icon (click)="zoomDocument('out')">zoom_out</mat-icon>
                    <span>{{ documentsOptions.zoomPercent * 100 }}%</span>
                    <mat-icon (click)="zoomDocument('in')">zoom_in</mat-icon>
                </div>
                <div class="rotate">
                    <mat-icon (click)="rotateDocument()">rotate_90_degrees_ccw</mat-icon>
                </div>
                <div class="back-to-documents">
                    <img (click)="setGrid(true)" src="../../../../assets/icons/icon-thumbnail.svg" alt="grid" />
                </div>
            </div>
            <div *ngIf="queueID == 2" class="document-controls">
                <div class="info" (click)="openDialog('information')">
                    <mat-icon>help</mat-icon>
                </div>
                <div class="delete">
                    <mat-icon (click)="openDialog('delete')">delete</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>