import { IAddress } from '../../features/manager/case/interfaces/address.interface';
import { ICaregiver } from '../../features/manager/case/interfaces/caregiver.interface';
import { ICase, IContactInfo, IManagerUser, ISupervisorTask } from '../interfaces/interfaces';

// TODO: remove a folder and put these mocks into testing
export const cases: ICase[] = [
  {
    id: 1,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1604254062780,
  },
  {
    id: 2,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'Open',
    reason: 'Pending Payer',
    due: 1604454062780,
  },
  {
    id: 3,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Closed',
    reason: 'Benefits Investigation',
    due: 1604654062780,
  },
  {
    id: 4,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1604854062780,
  },
  {
    id: 5,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'CVS',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1605054062780,
  },
  {
    id: 6,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1605254062780,
  },
  {
    id: 7,
    task: 'PA: Follow-up with Payer',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1605454062780,
  },
  {
    id: 9,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1605654062780,
  },
  {
    id: 10,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1605854062780,
  },
  {
    id: 11,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Humana',
    status: 'Open',
    reason: 'Pending Payer',
    due: 1606054062780,
  },
  {
    id: 12,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'MetLife',
    status: 'Closed',
    reason: 'Pending Patient',
    due: 1606254062780,
  },
  {
    id: 13,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1606454062780,
  },
  {
    id: 14,
    task: 'PA: Follow-up with Payer',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1606654062780,
  },
  {
    id: 15,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1606854062780,
  },
  {
    id: 16,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Closed',
    reason: 'Benefits Investigation',
    due: 1607054062780,
  },
  {
    id: 17,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1607254062780,
  },
  {
    id: 18,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'Closed',
    reason: 'Pending Payer',
    due: 1607454062780,
  },
  {
    id: 19,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'In Process',
    reason: 'Benefits Investigation',
    due: 1607654062780,
  },
  {
    id: 20,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1607854062780,
  },
];

// TODO: left side component
export const caseDetails = [
  {
    title: 'Key Details',
    id: 'keyDetails',
    icon: 'flag',
    data: [
      {
        title: 'Case Status',
        value: 'In Process',
      },
      {
        title: 'Substatus',
        value: 'Prior Authorization',
      },
      {
        title: 'Reason',
        value: 'Pending Payer',
      },
      {
        title: 'Case Manager',
        value: 'Logan ONeal',
      },
      {
        title: 'FRM',
        value: 'Clive Onangwagto',
      },
      {
        title: 'Region & Territory',
        value: 'Midwest * Indianapolis IN',
      },
      {
        title: 'HCP',
        value: 'Cheri Fedelm',
      },
      {
        title: 'HCP Staff',
        value: 'Agni Kailash',
      },
      {
        title: 'Sales Specialist',
        value: 'Mihn Tran',
      },
      {
        title: 'Speciality Pharmacy',
        value: 'Walgreens',
      },
      {
        title: 'Patient ID',
        value: '01234567',
      },
      {
        title: 'Patient DoB',
        value: '1/1/1981 * 39 years',
      },
      {
        title: 'Primary Contact',
        value: 'Patient',
      },
      {
        title: 'Enrolment Date',
        value: '6/3/2020',
      },
      {
        title: 'Treatment Start',
        value: '6/24/2020',
      },
    ],
  },
  {
    title: 'User Details',
    id: 'userDetails',
    icon: 'person',
    data: [
      {
        title: 'test',
        value: 'test',
      },
    ],
  },
  {
    title: 'Guard Details',
    id: 'guardDetails',
    icon: 'shield',
    data: [
      {
        title: 'test',
        value: 'test',
      },
    ],
  },
  {
    title: 'Physician',
    id: 'connectDetails',
    icon: 'stethoscope',
    data: [
      {
        title: 'Physician Name',
        value: 'Dr. Cheri Fedelm',
      },
      {
        title: 'NPI',
        value: '0123456789',
      },
      {
        title: 'Physician Phone',
        value: '555-239-9742',
      },
      {
        title: 'Physician Email',
        value: 'cfedelm@riversideclinic.com',
      },
      {
        title: 'Office Address',
        value: '456 Archwood Avenue STE 105 Pawnee, IN 12345',
      },
      {
        title: 'Office Phone',
        value: '555-239-3984',
      },
      {
        title: 'Office Fax',
        value: '555-239-2346',
      },
    ],
  },
  {
    title: 'Pharmacy Details',
    id: 'pharmacyDetails',
    icon: 'pill',
    data: [
      {
        title: 'test',
        value: 'test',
      },
    ],
  },
  {
    title: 'Payment Details',
    id: 'paymentDetails',
    icon: 'shipping',
    data: [
      {
        title: 'test',
        value: 'test',
      },
    ],
  },
  {
    title: 'File Details',
    id: 'fileDetails',
    icon: 'attachment',
    data: [
      {
        title: 'test',
        value: 'test',
      },
    ],
  },
];

// TODO: 1) top navigation 2) page cases http://localhost:4200/case?id=12
export const caseTimeline = [
  {
    phase: 'Enrollment',
    id: 'enrollment',
    estimate: '2 Days',
    spent: '2 Days',
    status: 'Complete',
    disabled: false
  },
  {
    phase: 'Benefits Verification',
    id: 'benefit',
    estimate: '3 Days',
    spent: '4 Days',
    status: 'Complete',
    disabled: false
  },
  {
    phase: 'Prior Authorization',
    id: 'prior',
    estimate: '2 Days',
    spent: '2 Days',
    status: 'Current',
    disabled: true
  },
  {
    phase: 'Fulfillment',
    id: 'fulfillment',
    estimate: '2 Days',
    status: 'Upcoming',
    disabled: true
  },
];

// TODO: 1) history, page case 2) tasks, page case
export const caseTasks = [
  {
    id: 1,
    activity: 'Follow Up',
    due: 1595064681285,
    completed: null,
    success: false,
    statusChange: 1595064681285,
    phase: 'Enrollment',
    caseStatus: 'Completed',
    substatus: 'Eligibility Determination',
    reason: 'Approved',
    type: 0,
  },
  {
    id: 2,
    activity: 'Welcome Call',
    due: 1596064681285,
    completed: 1595064681285,
    success: true,
    statusChange: 1595064681285,
    phase: 'Enrollment',
    caseStatus: 'Completed',
    substatus: 'Eligibility Determination',
    reason: 'Approved',
    type: 1,
    script: `Hello, Sally. This is Erika calling on behalf of Manufacturing Co. In most countries, the
        dispensary is subject to pharmacy legislation; with requirements for storage conditions,
        compulsory texts, equipment, etc., specified in legislation. It was once the case that
        pharmacists stayed within the dispensary compounding/dispensing medications, but there has
        been an increasing trend towards the use of trained pharmacy technicians, with the
        pharmacist spending more time communicating with patients. Pharmacies are typically required
        to have a pharmacist on-duty at all times when they are open. It is also often a requirement
        for the owner of a pharmacy to be a registered pharmacist, but that is not the case in all
        jurisdictions, such that many retailers (including supermarkets and mass merchandisers) now
        include a pharmacy as a department of their store. Likewise, many pharmacies are now rather
        grocery store-like in their design. In addition to medicines and prescriptions, many now
        sell a diverse arrangements of additional items such as cosmetics, shampoo, office supplies,
        confections, snack foods,`,
  },
  {
    id: 3,
    activity: 'Missing information',
    due: 1597064681285,
    completed: 1595064681285,
    success: true,
    statusChange: null,
    phase: 'Benefit Verification',
    caseStatus: 'Completed',
    substatus: 'Eligibility Determination',
    reason: 'Approved',
    locked: false,
    type: 2,
  },
];

// TODO dashboard: charts, chartJS lib
export const queueData = {
  new: {
    tasks: 118,
    assigned: 13,
    data: [-10, -2, 20, 25, 5, -15, -2, 15, 7],
  },
  intake: {
    tasks: 44,
    assigned: 10,
    data: [10, -2, 5, 10, 10, 2, -3, -7, -2],
  },
  stable: {
    tasks: 21,
    assigned: 5,
    data: [10, 5, -5, -2, 3, -1, -3, 2, 4],
  },
};

// TODO: http://localhost:4200/manage-queue?id=5
export const supervisorChartData = [
  {
    tasks: 8,
    cms: 10,
    baseline: '20%',
  },
  {
    tasks: 1,
    cms: 7,
    baseline: '10%',
  },
  {
    tasks: 5,
    cms: 10,
    baseline: '30%',
  },
  {
    tasks: 3,
    cms: 5,
    baseline: '14%',
  },
  {
    tasks: 6,
    cms: 14,
    baseline: '4%',
  },
  {
    tasks: 2,
    cms: 5,
    baseline: '25%',
  },
  {
    tasks: 1,
    cms: 3,
    baseline: '15%',
  },
  {
    tasks: 5,
    cms: 15,
    baseline: '13%',
  },
];

// TODO: http://localhost:4200/manage-queue?id=5 only for supervisor
export const users: IManagerUser[] = [
  {
    id: 1,
    userName: 'Etta Garrett',
    userImg: 'https://image.flaticon.com/icons/png/512/194/194938.png',
    avgTasks: 10,
    tasksOnTime: '89%',
    rank: 1,
    prevRank: 2,
    role: 'Case Manager',
    assignments: 'Midwest Territory, Southeast Territory',
  },
  {
    id: 2,
    userName: 'Herbert Flores',
    avgTasks: 9,
    tasksOnTime: '83%',
    rank: 2,
    prevRank: 1,
    role: 'Case Manager',
    assignments: 'Northeast Territory, Southeast Territory',
  },
  {
    id: 3,
    userName: 'Ida Gibson',
    userImg: 'https://www.pngkit.com/png/detail/115-1150342_user-avatar-icon-iconos-de-mujeres-a-color.png',
    avgTasks: 8,
    tasksOnTime: '55%',
    rank: 5,
    prevRank: 4,
    role: 'Case Manager',
    assignments: 'Southwest Territory, Midwest Territory',
  },
  {
    id: 4,
    userName: 'Jeffrey Goodman',
    userImg:
      'https://png.pngtree.com/png-vector/20190704/ourmid/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg',
    avgTasks: 7,
    tasksOnTime: '62%',
    rank: 4,
    prevRank: 5,
    role: 'Case Manager',
    assignments: 'Reimbursement, Southwest Territory',
  },
  {
    id: 5,
    userName: 'Jerome Garza',
    userImg: 'https://png.pngtree.com/element_our/20190604/ourlarge/pngtree-user-avatar-boy-image_1482937.jpg',
    avgTasks: 7,
    tasksOnTime: '79%',
    rank: 3,
    prevRank: 3,
    role: 'Case Manager',
    assignments: 'Midwest Territory, Northeast Territory',
  },
];

// TODO: http://localhost:4200/queue-supervisor-task?id=1
export const supervisorTasks: ISupervisorTask[] = [
  {
    id: 1,
    phase: 'Enrollment',
    name: 'Missing info follow-up',
    description: 'Follow-up with healthcare provider to obtain missing information',
  },
  {
    id: 2,
    phase: 'Enrollment',
    name: 'Missing info follow-up',
    description: 'Follow-up with patient to obtain missing information',
  },
  {
    id: 3,
    phase: 'Enrollment',
    name: 'Welcome call',
    description: 'Call patient to welcome them to program and obtain information',
  },
  {
    id: 4,
    phase: 'Enrollment',
    name: 'Order welcome kit',
    description: 'Order welcome kit or educational materials from vendor to ship to patient',
  },
  {
    id: 5,
    phase: 'Benefit Verification',
    name: 'Call to payer',
    description: 'Call payer to verify patient policy',
  },
  {
    id: 6,
    phase: 'Benefit Verification',
    name: 'Call to payer',
    description: 'Call payer to determine patient eligibility dates',
  },
  {
    id: 7,
    phase: 'Benefit Verification',
    name: 'Communicate benefits to HCP',
    description: 'Communicate patient’s benefits to HCP via fax or call',
  },
  {
    id: 8,
    phase: 'Prior Authorization',
    name: 'Follow-up with HCP',
    description: 'Confirm healthcare provider completed and submitted paperwork to payer',
  },
  {
    id: 9,
    phase: 'Prior Authorization',
    name: 'Follow-up with Payer',
    description: 'Confirm payer received PA request',
  },
];

// TODO: http://localhost:4200/manage-queue?id=5 tab territory
export const region = {
  region: 'Northwest Region',
  states: ['Idaho', 'Montana', 'Oregon', 'Washington', 'Wyoming'],
};

// TODO: http://localhost:4200/document-data-entry?id=300 tab physician
export const physicians = [
  {
    id: 783789,
    name: 'Jeffrey Goldberg',
    state: 'KY',
    specialty: 'Allergy and Immunology',
    license: ['KY (0183483940)', 'IN (2483399128)'],
  },
  {
    id: 284401,
    name: 'Jeffrey Goldberg',
    state: 'IN',
    specialty: 'Obstetrics and Gynecology',
    license: ['KY (0183483940)', 'IN (2483399128)'],
  },
];

// TODO: http://localhost:4200/document-data-entry?id=300 tab Physician
export const facilities = [
  {
    id: 1,
    name: 'Beechmont Hospice',
    address: '56 Beechmont Blvd Louisville, KY 40241',
    tax: 1715993,
    phone: '(502) 555–2158',
    fax: '(502) 555–2162',
  },
  {
    id: 2,
    name: 'Bybee Care Clinic',
    address: '7 Bybee Ct Ste #3 Louisville, KY 40220',
    tax: 490237,
    phone: '(502) 555–2158',
    fax: '(502) 555–2162',
  },
];

// TODO: http://localhost:4200/queue-manager-task?id=5 table columns filters
export const caseTaskFilterData = {
  phase: {
    enrollment: false,
    fulfillment: false,
  },
  task: {
    welcomeCall: false,
    missingInfo: false,
    pharmacyTriage: false,
  },
  patient: null,
  physician: null,
  payer: null,
  pharmacy: null,
  dueDate: {start: '', end: ''},
};

export const enrollmentDataMock = [
  {
    isOptedIn: true,
    valid: {
      start: '1/1/2021',
      end: '12/31/2022',
    },
    method: 'Verbal',
  },
  {
    isOptedIn: false,
    valid: {
      start: '1/25/2021',
      end: '1/31/2021',
    },
    method: 'Written',
  },
  {
    isOptedIn: true,
    valid: {
      start: '1/25/2021',
      end: '1/31/2021',
    },
    method: 'Verbal',
  },
];

export const addressesDataMock: IAddress[] = [
  {
    address: {
      streetAddress: 'gj',
      addressExtension: '',
      zipCode: '81506',
      city: 'Grand Junction',
      state: 'CO',
    },
    addressType: 1,
    createDate: '2021-03-23T22:00:20.429328',
    current: false,
    id: 5,
    patientId: 18,
    updateDate: null,
  },
];

export const caregiversDataMock: ICaregiver[] = [
  {
    id: 1,
    relationship: 'father',
    patientId: 23,
    current: true,
    firstName: 'April',
    middleName: '',
    lastName: 'Ludgate-Dwyer',
    contactInfos: [],
  },
];
