<div class="task-wrapper">
    <div class="top-block">
        <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
        <h2>{{ selectedTask.task }}</h2>
        <div class="description">
            <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
            <p [innerHtml]="interpolatedDescription"></p>
        </div>
        <div class="buttons-block">
            <button class="collapse" (click)="minimizeTask(true)">
                <mat-icon>remove</mat-icon>
            </button>
            <button class="close" (click)="setSelectedTask(null)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="welcome-task-wrapper">
        <div class="task-block" [formGroup]="complianceForm">
            <!-- <div class="task-item contact-method">
            <h3>Contact Method</h3>
            <div class="call-info">
                <button><mat-icon>call</mat-icon>Call</button>
                <span>555-239-3984</span>
            </div>
        </div> -->
            <div class="task-item contact-outcome">
                <h3>Contact Outcome</h3>
                <div class="outcome-item">
                    <div class="input-item toggle">
                        <mat-button-toggle-group formControlName="contact">
                            <mat-button-toggle value="0"> Yes </mat-button-toggle>
                            <mat-button-toggle value="1"> No </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <span>Were you able to contact patient?</span>
                </div>
                <div class="outcome-item">
                    <div class="input-item toggle">
                        <mat-button-toggle-group
                            formControlName="success"
                            [disabled]="complianceForm.value.contact != '0'"
                        >
                            <mat-button-toggle value="0"> Yes </mat-button-toggle>
                            <mat-button-toggle value="1"> No </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <span>Was contact successful? </span>
                </div>
                <h3>Additional Concerns <i>(Optional)</i></h3>
                <p>
                    If the patient has additional concerns, you can send the following educational materials in addition
                    to this call:
                </p>
                <div class="input-item toggle concerns">
                    <mat-button-toggle-group multiple
                        formControlName="concerns"
                        [disabled]="!(complianceForm.value.success == '0' && complianceForm.value.contact == '0')"
                    >
                        <mat-button-toggle value="0"> Dosing Questions </mat-button-toggle>
                        <mat-button-toggle value="1"> Product Storage </mat-button-toggle>
                        <mat-button-toggle value="2"> Side Effects </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <ng-container *ngIf="!patientEmail && complianceForm.value.concerns">
                    <h4>
                        <img src="../../../../../../../../assets/icons/icon-warning.svg" alt="warning" /> No Patient Email
                        on File
                    </h4>
                    <div class="outcome-item">
                        <div class="input-item toggle email">
                            <mat-button-toggle-group formControlName="mailType">
                                <mat-button-toggle value="0"> Email </mat-button-toggle>
                                <mat-button-toggle value="1"> Mail </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <span>Was contact successful? </span>
                    </div>
                    <div class="input-item email-item" *ngIf="complianceForm.value.mailType == '0'">
                        <label for="email">Patient Email</label>
                        <input name="email" id="email" formControlName="email" />
                    </div>
                </ng-container>
            </div>
            <div class="task-item outcome-note">
                <h3><mat-icon>assignment</mat-icon>Outcome Note</h3>
                <textarea matInput formControlName="note"></textarea>
            </div>
        </div>
        <div class="welcome-script" *ngIf="interpolatedScript">
            <mat-expansion-panel #script>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{
                        selectedTask.taskId == 18 ? "Compliance is Key Script" : "Patient Check In Script"
                    }}</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!script.expanded">open_in_full</mat-icon>
                        <mat-icon *ngIf="script.expanded">close</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-scrollbar #scrollbar class="custom-scrollbar">
                    <p [innerHtml]="interpolatedScript"></p>
                </ng-scrollbar>
            </mat-expansion-panel>
        </div>
    </div>
    <div class="task-controls">
        <button
            mat-button
            class="primary-btn invert reschedule"
            (click)="rescheduleTask()"
            [disabled]="complianceForm.value.contact != '1' && complianceForm.value.success != '1'"
            *ngIf="selectedTask.attemptNumber < selectedTask.maxAttempts - 1"
        >
            <mat-icon>schedule</mat-icon>Reschedule
        </button>
        <button
            mat-button
            class="primary-btn invert done"
            (click)="submitTask()"
            [disabled]="
                (!(
                    complianceForm.value.contact == '0' &&
                    complianceForm.value.success == '0'
                ) && selectedTask.attemptNumber < selectedTask.maxAttempts - 1)|| (selectedTask.attemptNumber >= selectedTask.maxAttempts - 1 && !complianceForm.value.contact)
            "
        >
            <mat-icon>done</mat-icon>Submit
        </button>
    </div>
</div>
