import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ISupervisorTask } from '../../../../../shared/interfaces/interfaces';
import { SupervisorAddTaskColumns } from '../../../../../shared/enums/enums';
import { enumToArray } from '../../../../../shared/helpers/utils';
import { QueueCreateService } from '../../../creation/creation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-supervisor-task-add',
    templateUrl: './add.component.html',
    styleUrls: ['../../users/users.component.scss', '../../users/add/add.component.scss', './add.component.scss'],
})
export class SupervisorAddTaskComponent implements OnInit {
    @Output() tasksSaved = new EventEmitter<ISupervisorTask[]>();

    searchValue = '';
    columnsToDisplay = enumToArray(SupervisorAddTaskColumns);
    addedTasks: ISupervisorTask[] = [];
    data = [];
    dataSource;
    isLoading: boolean = false;
    isAllSelected = false;

    checkTask(task) {
        if (!this.isSelected(task.id)) {
            this.addedTasks.push(task);
        }
        else {
            this.addedTasks = this.addedTasks.filter(item => item.id !== task.id);
        }
    }

    isSelected(id) {
        return this.addedTasks.some((x) => x.id == id);
    }

    selectAll() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.addedTasks = this.data;
        } else {
            this.addedTasks = [];
        }
    }

    saveTasks() {
        this.tasksSaved.emit(this.addedTasks);
    }

    constructor(public queueCreateService: QueueCreateService, @Inject(MAT_DIALOG_DATA) public dialogData) {
        this.addedTasks = [...dialogData.addedTasks];
    }

    clearSearch() {
        this.searchValue = '';
        this.getTasks();
    }

    getTasks() {
        const data = { take: 0, search: this.searchValue };
        this.isLoading = true;
        this.queueCreateService.getTasks(data).subscribe(
            (response) => {
                this.data = response.value;
                this.dataSource = new MatTableDataSource(this.data);
                this.isAllSelected = this.data.length == this.addedTasks.length ? true : false;
            },
            (error) => throwError(error),
            () => (this.isLoading = false)
        );
    }

    ngOnInit() {
        this.getTasks();
    }
}
