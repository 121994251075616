<h2>Patient</h2>
<div class="details-wrapper">
  <ng-scrollbar class="custom-scrollbar case-scrollbar">
    <div class="sub-section">
      <div class="header">
        Demographics
      </div>
      <app-editable-aside-field [fieldId]="'firstName'" (saveData)="savePatientData($event, ['firstName', 'lastName'])">
        <div text class="detail-item">
          <h3>Name</h3>
          <p>{{ patient?.lastName | empty }}, {{ patient?.firstName | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row">
            <label for="firstName">First Name</label>
            <input [(ngModel)]="patientCopy.firstName" name="firstName" id="firstName" maxlength="50"/>
          </div>
          <div class="input-row">
            <label for="lastName">Last Name</label>
            <input [(ngModel)]="patientCopy.lastName" id="lastName" type="text" maxlength="50"/>
          </div>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'gender'" (saveData)="savePatientData($event, ['gender'])">
        <div text class="detail-item">
          <h3>Gender</h3>
          <p>{{ genderTypes[patient?.gender] | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row input-item select">
            <label for="genderType">Gender</label>
            <mat-form-field appearance="fill">
              <mat-select name="genderType" [(ngModel)]="patientCopy.gender" placeholder="Gender" id="genderType"
                          disableOptionCentering panelClass="dropdown-item">
                <mat-option [value]="0">Female</mat-option>
                <mat-option [value]="1">Male</mat-option>
                <mat-option [value]="2">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'dateOfBirth'" (saveData)="savePatientData($event, ['dateOfBirth'])">
        <div text class="detail-item">
          <h3>Date of birth</h3>
          <p>{{ getDateFromISO(patient?.dateOfBirth) | date: 'yyyy / MM / dd' | empty }} &#183;
            <span *ngIf="patient?.dateOfBirth">{{calculateAge(patient?.dateOfBirth)}} years old</span>
          </p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row date-input input-item">
            <label for="birthDate">Date of birth</label>
            <input matInput [(ngModel)]="patientCopy.dateOfBirth" [matDatepicker]="birthDatePicker" id="birthDate"
                   placeholder="MM/DD/YY" [max]="maxDate" (focus)="birthDatePicker.open()"
                   (click)="birthDatePicker.open()"/>
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker></mat-datepicker>
          </div>
        </div>
      </app-editable-aside-field>
      <div class="non-editable-field">
        <div class="detail-item">
          <h3>Patient Id</h3>
          <p>{{ patient?.id | empty }}</p>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="sub-section consent">
      <div class="header">
        Consent
      </div>
      <app-editable-aside-field [fieldId]="'consent'" (editOutside)="openConsentManagement()" [editMode]="false">
        <div text class="detail-item">
          <h3>HIPAA Consent</h3>
          <p>{{ currentConsents?.hipaaConsent ?
            (expiredFile(currentConsents?.hipaaConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.hipaaConsent?.consentIsOnFile ? 'Yes' : 'No')) : '--'}}
          </p>
        </div>
        <div text class="detail-item">
          <h3>HIPAA Consent Date</h3>
          <p>{{ getDateFromISO(currentConsents?.hipaaConsent?.dateReceived) | date: 'yyyy / MM / dd' | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>HIPAA Source</h3>
          <p>{{ consentTypes[+currentConsents?.hipaaConsent?.consentTypeId] | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Texting Opt-in</h3>
          <p>{{ currentConsents?.textingConsent ?
            (expiredFile(currentConsents?.textingConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.textingConsent?.consentIsOnFile ? 'Opted In' : 'Opted Out')) : '--'}}
          </p>
        </div>
        <div text class="detail-item">
          <h3>Program Opt-in</h3>
          <p>{{ currentConsents?.programConsent ?
            (expiredFile(currentConsents?.programConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.programConsent?.consentIsOnFile ? 'Opted In' : 'Opted Out')) : '--'}}
          </p>
        </div>
        <div text class="detail-item">
          <h3>Marketing Opt-in</h3>
          <p>{{ currentConsents?.marketingConsent ?
            (expiredFile(currentConsents?.marketingConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.marketingConsent?.consentIsOnFile ? 'Opted In' : 'Opted Out')) : '--'}}
          </p>
        </div>
        <div text class="detail-item">
          <h3>Voicemail Opt-in</h3>
          <p>{{ currentConsents?.voicemailConsent ?
            (expiredFile(currentConsents?.voicemailConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.voicemailConsent?.consentIsOnFile ? 'Opted In' : 'Opted Out')) : '--'}}
          </p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section">
      <div class="header">
        Contact
      </div>
      <app-editable-aside-field [fieldId]="'contact'" (editOutside)="openAddressManagement()" [editMode]="false">
        <div text class="detail-item">
          <h3>Address</h3>
          <p *ngIf="patient?.currentMailingAddress?.address?.streetAddress ||
          patient?.currentMailingAddress?.address?.city ||
          patient?.currentMailingAddress?.address?.zipCode; else noAddress">
            {{ patient?.currentMailingAddress?.address?.streetAddress }}<ng-container *ngIf="patient?.currentMailingAddress?.address?.city">,</ng-container>
            {{ patient?.currentMailingAddress?.address?.city }}<ng-container *ngIf="patient?.currentMailingAddress?.address?.zipCode">,</ng-container>
            {{ patient?.currentMailingAddress?.address?.zipCode }}
          </p>
          <ng-template #noAddress>
            <p>--</p>
          </ng-template>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'mobile'" [disableSave]="mobileForm.invalid"
                                (saveData)="savePatientData($event, ['contactInfos', 'mobile', ContactMethod.phone, ContactType.mobile])">
        <div text class="detail-item">
          <h3>Mobile Phone (1st)</h3>
          <p>{{ mobileForm.value.mobile | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="mobileForm">
            <div class="input-row input-item" [class]="
          mobileForm.controls['mobile'].invalid &&
            (mobileForm.controls['mobile'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="firstPhone">Mobile Phone (1st)</label>
              <input mask="(000) 000-0000" placeholder="(_ _ _) _ _ _ - _ _ _ _" formControlName="mobile" matInput
                id="firstPhone" maxlength="50" />
            </div>
          </form>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'home'" [disableSave]="homeForm.invalid"
                                (saveData)="savePatientData($event, ['contactInfos', 'home', ContactMethod.phone, ContactType.home])">
        <div text class="detail-item">
          <h3>Home Phone (2nd)</h3>
          <p>{{ homeForm.value.home | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="homeForm">
            <div class="input-row input-item" [class]="
            homeForm.controls['home'].invalid &&
            (homeForm.controls['home'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="secondPhone">Home Phone (2nd)</label>
              <input mask="(000) 000-0000" placeholder="(_ _ _) _ _ _ - _ _ _ _" formControlName="home" matInput
                id="secondPhone" maxlength="50" />
            </div>
          </form>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'email'" [disableSave]="emailForm.invalid"
                                (saveData)="savePatientData($event, ['contactInfos', 'email', ContactMethod.email, ContactType.work])">
        <div text class="detail-item">
          <h3>Email</h3>
          <p>{{ emailForm.value.email | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="emailForm">
            <div class="input-row input-item" [class]="
            emailForm.controls['email'].invalid &&
            (emailForm.controls['email'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="email">Email</label>
              <input id="email" maxlength="50" formControlName="email" matInput />
            </div>
          </form>
        </div>
      </app-editable-aside-field>
    </div>
  </ng-scrollbar>
</div>