<mat-expansion-panel id="MedicalAOBPanel" [expanded]="expanded" (afterExpand)="openPanel(2)" (afterCollapse)="closePanel(2)">
  <mat-expansion-panel-header>
    <mat-panel-title
    >
      <div class="task-indicator" *ngIf="selectedTask">
        <mat-progress-spinner
          [diameter]="32"
          [class]="setIndicatorColor(settings.value)"
          [value]="settings.value"
        >
        </mat-progress-spinner>
        <mat-icon *ngIf="settings.value == 100">check</mat-icon>
        <span *ngIf="settings.value != 100">{{ settings.value }}%</span>
      </div>
      Medical (Assignment of Benefits)
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="collapse-section" [formGroup]="medicalAOBForm">
    <div class="collapse-item" formGroupName="coverage">
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCoverage">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is there coverage?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSameAsBB">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is coverage the same as Medical (Buy & Bill)?</span>
        </div>
      </div>
      <div style="text-align: center">
        <div class="copied">
          <img src="../../../../../../assets/icons/icon-check.svg" alt="copied"/> Information
          copied from previous step, <b>Medical (Buy & Bill).</b>
        </div>
      </div>
    </div>
  </div>
  <div id="MedicalAOBInNetworkSPs"
       appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicalAOBInNetworkSPs')"
  ></div>
  <div id="MedicalAOBAuthorizationDeterminations"
       appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicalAOBAuthorizationDeterminations')"
  ></div>
</mat-expansion-panel>
