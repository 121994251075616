<div class="top-block">
    <h2>Assign Users</h2>
    <p>Finally, choose which users will be assigned to work this queue. You can edit this later.</p>
</div>

<div class="tasks-wrapper">
    <div class="search-form">
        <div class="input-item search">
            <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
            <input matInput type="text" [(ngModel)]="searchValue" placeholder="Search for Users" #search
                (keydown.enter)="getUsers()" (keydown.tab)="focusTable($event)" tabindex="1" />
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchValue = ''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <button class="primary-btn" (click)="getUsers()" [disabled]="isLoading">Search</button>
    </div>
    <div class="selected-wrapper">
        <div class="selected-item" *ngFor="let id of selectedUsers; index as i">
            <span *ngIf="showAll || maxBubblesCount > i">
                <mat-icon>account_circle</mat-icon>
                <b>{{ renderUser(id) }}</b>
                <button (click)="checkUser(id)">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </div>
        <span class="show-more" *ngIf="!showAll && selectedUsers.length > maxBubblesCount"
            (click)="showAll = !showAll">Show More</span>
        <span class="show-more" *ngIf="showAll && selectedUsers.length > maxBubblesCount"
            (click)="showAll = !showAll">Show Less</span>
    </div>
    <table [hidden]="isLoading" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let user">
                <div class="user-name" [class.inactive]="!user.active">
                    <div class="user-img">
                        <mat-icon>account_circle</mat-icon>
                    </div>
                    {{ user.userName }}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let user">{{ user.roles }}</td>
        </ng-container>
        <ng-container matColumnDef="assignments">
            <th mat-header-cell *matHeaderCellDef>Current Assignments</th>
            <td mat-cell *matCellDef="let user">{{ user.assignments }}</td>
        </ng-container>
        <ng-container matColumnDef="isAdded">
            <th mat-header-cell *matHeaderCellDef>
                <button class="primary-btn select-all" [class.deselect]="isAllSelected" (click)="selectAll()">
                    <ng-container *ngIf="!isAllSelected">
                        <mat-icon>add_circle</mat-icon> Select All
                    </ng-container>
                    <ng-container *ngIf="isAllSelected">
                        <mat-icon>remove_circle</mat-icon> Unselect All
                    </ng-container>
                </button>
            </th>
            <td mat-cell *matCellDef="let user">
                <button *ngIf="!selectedUsers.includes(user.id); else check" tabindex="1">
                    <img src="../../../../../../assets/icons/icon-add.svg" alt="add" />
                </button>
                <ng-template #check>
                    <button>
                        <img src="../../../../../../assets/icons/icon-check.svg" alt="check" tabindex="1" />
                    </button>
                </ng-template>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let user; columns: columnsToDisplay; index as i" (click)="checkUser(user.id)"
            [class.focused]="i == selectedItem"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching</td>
        </tr>
    </table>
    <mat-paginator style="display: none" [pageSize]="settings.itemPerPage"></mat-paginator>
    <app-pagination mat-paginator *ngIf="data.length"
        [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'" [settings]="settings"
        (setActivePageHandler)="setActivePage($event)"></app-pagination>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>