<app-task-followup-enrollment *ngIf="selectedTask.taskId == 1" [selectedTask]="selectedTask" [case]="case"
    (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
    (rescheduleTaskHandler)="rescheduleTask($event)" (submitTaskHandler)="submitTask($event)">
</app-task-followup-enrollment>

<app-task-welcome *ngIf="selectedTask.taskId == 2" [selectedTask]="selectedTask" [case]="case"
    (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
    (rescheduleTaskHandler)="rescheduleTask($event)" (submitTaskHandler)="submitTask($event)"></app-task-welcome>

<app-task-benefit *ngIf="selectedTask.taskId == 3 || selectedTask.taskId == 4 || selectedTask.taskId == 5" [case]="case"
    [selectedTask]="selectedTask" (minimizeTaskHandler)="minimizeTask($event)" (submitTaskHandler)="submitTask($event)"
    (setSelectedTaskHandler)="setSelectedTask($event)" (rescheduleTaskHandler)="rescheduleTask($event)">
</app-task-benefit>

<app-task-followup-benefit *ngIf="selectedTask.taskId == 6" [selectedTask]="selectedTask" [case]="case"
    (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
    (rescheduleTaskHandler)="rescheduleTask($event)" (submitTaskHandler)="submitTask($event)">
</app-task-followup-benefit>

<app-task-followup-prior *ngIf="selectedTask.taskId == 8" [selectedTask]="selectedTask" [case]="case"
    (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
    (rescheduleTaskHandler)="rescheduleTask($event)" (submitTaskHandler)="submitTask($event)"></app-task-followup-prior>

<app-task-triage *ngIf="selectedTask.taskId == 9" [selectedTask]="selectedTask" [case]="case"
    (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
    (rescheduleTaskHandler)="rescheduleTask($event)" (submitTaskHandler)="submitTask($event)"></app-task-triage>

<app-task-adherence-welcome *ngIf="selectedTask.taskId == 14 || selectedTask.taskId == 15 || selectedTask.taskId== 25"
    [selectedTask]="selectedTask" [case]="case" (minimizeTaskHandler)="minimizeTask($event)"
    (setSelectedTaskHandler)="setSelectedTask($event)" (rescheduleTaskHandler)="rescheduleTask($event)"
    (submitTaskHandler)="submitTask($event)"></app-task-adherence-welcome>

<app-task-adherence-compliance *ngIf="selectedTask.taskId == 18 || selectedTask.taskId == 19"
    [selectedTask]="selectedTask" [case]="case" (minimizeTaskHandler)="minimizeTask($event)"
    (setSelectedTaskHandler)="setSelectedTask($event)" (rescheduleTaskHandler)="rescheduleTask($event)"
    (submitTaskHandler)="submitTask($event)"></app-task-adherence-compliance>