import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupervisorAddTaskColumns } from '../../../../shared/enums/enums';
import { enumToArray } from '../../../../shared/helpers/utils';
import { MatPaginator } from '@angular/material/paginator';
import { throwError } from 'rxjs';
import { QueueCreateService } from '../creation.service';
import { ISupervisorTask } from 'src/app/shared/interfaces/interfaces';

@Component({
    selector: 'app-queue-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['../creation.component.scss', './tasks.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class QueueCreationTasksComponent implements OnInit {
    searchValue = '';
    @Input() selectedTasks;
    @Output() setSelectedTasksHandler = new EventEmitter();
    columnsToDisplay = enumToArray(SupervisorAddTaskColumns);
    data: ISupervisorTask[] = [];
    dataSource;
    isLoading: boolean = false;
    selectedItem = null;
    tableFocused = false;
    isAllSelected = false;

    handleKeyboardEvents(event: KeyboardEvent) {
        if (this.tableFocused) {
            this.tableNavigate(event);
        }
    }

    @ViewChild('search') search: ElementRef;

    settings = {
        itemPerPage: 20,
        paginationPageCount: null,
        activePage: 0,
    };

    setActivePage(index) {
        this.settings.activePage = index;
        this.paginator.pageIndex = index;
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    setSelectedTasks(ids) {
        this.setSelectedTasksHandler.emit(ids);
        if (ids.length == 0) {
            this.isAllSelected = false;
        }
        if (ids.length == this.data.length) {
            this.isAllSelected = true;
        }
    }

    selectAll() {
        this.isAllSelected = !this.isAllSelected;
        const IDs = this.data.map((state) => state.id);
        this.isAllSelected ? this.setSelectedTasks(IDs) : this.setSelectedTasks([]);
    }

    checkTask(id) {
        if (!this.selectedTasks.includes(id)) {
            this.setSelectedTasks([...this.selectedTasks, id]);
        }
        else {
            const tasks = this.selectedTasks.filter((checkedId) => checkedId !== id);
            this.setSelectedTasks(tasks);
        }
    }
    constructor(public queueCreateService: QueueCreateService) { }


    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    getTasks() {
        const data = { take: 0, search: this.searchValue };
        this.isLoading = true;
        this.queueCreateService.getTasks(data).subscribe(
            (response) => {
                this.data = response.value.map((task: ISupervisorTask) => {
                    return {
                        id: task.id,
                        phase: task.phase,
                        task: task.name,
                        description: task.description,
                    };
                });

                this.dataSource = new MatTableDataSource(this.data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.settings.paginationPageCount = Math.ceil(
                    this.dataSource.filteredData.length / this.settings.itemPerPage
                );
            },
            (error) => throwError(error),
            () => (this.isLoading = false)
        );
    }

    focusTable(event) {
        this.search.nativeElement.blur();
        this.selectedItem = -1;
        this.tableFocused = true;
    }

    tableNavigate(event) {
        event.preventDefault();
        if (this.dataSource.filteredData) {
            if (event.key === 'Tab' || event.key === 'ArrowDown') {
                this.selectedItem = this.selectedItem + 1;
                if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
                    if (this.selectedItem == this.dataSource.filteredData.length) {
                        this.selectedItem = 0;
                    }
                } else {
                    if (this.selectedItem == this.settings.itemPerPage) {
                        if (this.settings.paginationPageCount == this.settings.activePage + 1) {
                            this.setActivePage(0);
                        } else {
                            this.setActivePage(this.settings.activePage + 1);
                        }
                        this.selectedItem = 0;
                    }
                }
            }
            if (event.key === 'ArrowUp') {
                this.selectedItem = this.selectedItem - 1;

                if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
                    if (this.selectedItem == -1) {
                        this.selectedItem = this.dataSource.filteredData.length - 1;
                    }
                } else {
                    if (this.selectedItem == -1) {
                        if (this.settings.activePage == 0) {
                            this.setActivePage(this.settings.paginationPageCount - 1);
                        } else {
                            this.setActivePage(this.settings.activePage - 1);
                        }
                        this.selectedItem = this.settings.itemPerPage - 1;
                    }
                }
            }
            if (event.key === 'Enter') {
                const id = this.dataSource._renderData._value[this.selectedItem].id;
                this.checkTask(id);
            }
            if (event.key === 'Backspace') {
                this.selectedItem = null;
                this.tableFocused = false;
                this.search.nativeElement.focus();
            }
        }
    }

    ngOnInit() {
        this.getTasks();
    }

    ngAfterViewInit() {
        this.search.nativeElement.focus();
    }
}
