import { Component, OnChanges, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICase } from 'src/app/shared/interfaces/interfaces';
import { Store } from '@ngrx/store';
import * as CaseAction from '../../../../store/case/case.actions';
import { CaseColumns } from 'src/app/shared/enums/enums';
import { enumToArray } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-manager-dashboard-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
})
export class ManagerDashboardCasesComponent implements OnChanges {
  @Input() cases: ICase[];
  columnsToDisplay = enumToArray(CaseColumns);
  dataSource;

  selectCase(selectedCase) {
    this.store.dispatch(new CaseAction.SetCase(selectedCase));
  }

  constructor(private store: Store<any>) {}

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.cases);
    this.dataSource.sort = this.sort;
  }
}
