<div class="task-wrapper">
  <div class="task-title">
    <h2>Enrollment</h2>
  </div>

  <div class="task-description-wrapper">
    <div class="task-description">
      <div class="row">
        <div class="task-description-item">
          <h5>Case Type</h5>
          <p>Reimbursement</p>
        </div>
        <div class="task-description-item">
          <h5>Case status</h5>
          <p>{{case.status | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Case Substatus</h5>
          <p>{{case.subStatus | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Substatus Reason</h5>
          <p>{{case.subStatusReason | empty}}</p>
        </div>
      </div>
      <div class="row">
        <div class="task-description-item">
          <h5>Case Manager</h5>
          <p>
            <span class="user-img" *ngIf="case.caseManager">
              <img
                [src]="case.caseManager.userImageS3Url ? case.caseManager.userImageS3Url : 'http://reserverecruitment.co.za/images/usericon.png'"
                alt="user" />
            </span>
            {{(case.caseManager ? case.caseManager.firstName + ' ' + case.caseManager.lastName : '') | empty }}
          </p>
        </div>
        <div class="task-description-item">
          <h5>Sales Rep</h5>
          <p>{{(case.salesRep ? case.salesRep.firstName + ' ' + case.salesRep.lastName : '') | empty }}</p>
        </div>
        <div class="task-description-item">
          <h5>Territory</h5>
          <p>{{case.territory | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Enrollment Date</h5>
          <p>{{case.enrollmentStartDate | date: "M/d/yyyy" | empty}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="benefit-task-wrapper task-block">
  <mat-accordion>

    <!--CONSENTS AND OPTS-->

    <mat-expansion-panel [expanded]="panel === 'consent'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="header">
            Consent
          </div>
          <div class="status-label" [class.ready]="acceptedConsents.hipaaDataSource">
            HIPAA Consent
          </div>
          <div class="status-label" [class.ready]="acceptedConsents.programDataSource">
            Program Opt-In
          </div>
          <div class="status-label" [class.ready]="acceptedConsents.marketingDataSource">
            Marketing Opt-In
          </div>
          <div class="status-label" [class.ready]="acceptedConsents.textingDataSource">
            Texting Opt-In
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="collapse-section">
        <div class="collapse-item">
          <div class="section-header">
            <h3>
              HIPAA Consent
            </h3>
            <div class="status-label" [class.ready]="acceptedConsents.hipaaDataSource">
              {{acceptedConsents.hipaaDataSource ? 'Consent' : 'No Consent'}}
            </div>
          </div>

          <div class="empty-state" *ngIf="!hipaaDataSource?.data.length">
            No consent yet.
          </div>

          <table *ngIf="hipaaDataSource?.data.length" mat-table [dataSource]="hipaaDataSource">
            <ng-container matColumnDef="isOptedIn">
              <th mat-header-cell *matHeaderCellDef>Consent on file?</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ expiredFile(item.effectiveEndDate) ? 'Expired' : (item.consentIsOnFile ? 'Yes' : 'No') }}
                <span>(Current)</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="method">
              <th mat-header-cell *matHeaderCellDef>Method Received</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ consentMethods[item.consentMethod] | empty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveFrom">
              <th mat-header-cell *matHeaderCellDef>Effective From</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.dateReceived ?
                (expiredFile(item.effectiveEndDate) ?
                (getDate(item.effectiveEndDate) | date: 'M/d/yyyy') :
                getDate(item.dateReceived) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveTo">
              <th mat-header-cell *matHeaderCellDef>Effective To</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.effectiveEndDate ?
                (expiredFile(item.effectiveEndDate) ? 'N/A' :
                getDate(item.effectiveEndDate) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
          </table>

          <div class="controls" *ngIf="!(isConsentCreate || isHipaaOptOut)">
            <button (click)="toggleConsentForm(ConsentTypes.hipaa, true, true)" mat-button class="primary-btn add-btn"
              type="button">
              <div class="add-btn-wrapper">
                <mat-icon>add</mat-icon>
                {{hipaaDataSource?.data[0] &&
                hipaaDataSource?.data[0]?.consentIsOnFile && expiredFile(hipaaDataSource?.data[0].effectiveEndDate) ?
                'Renew' : 'Add'}} Consent
              </div>
            </button>
            <button
              [disabled]="!hipaaDataSource?.data[0]?.consentIsOnFile || !isConsentAvailable(hipaaDataSource?.data[0])"
              (click)="toggleConsentForm(ConsentTypes.hipaa, false, true)" class="opt-out-btn" mat-button>
              Opt Out
            </button>
          </div>

          <app-new-opt-in-form *ngIf="isConsentCreate" [type]="'Consent'"
            [isRenew]="hipaaDataSource?.data[0] && hipaaDataSource?.data[0]?.consentIsOnFile && expiredFile(hipaaDataSource?.data[0].effectiveEndDate)"
            [currentConsent]="hipaaDataSource?.data[0]" (cancelled)="toggleConsentForm(ConsentTypes.hipaa, true, false)"
            (submitted)="createConsent($event, ConsentTypes.hipaa, 'hipaaDataSource')"></app-new-opt-in-form>
          <app-opt-out-form *ngIf="isHipaaOptOut" [type]="'Consent'"
            (cancelled)="toggleConsentForm(ConsentTypes.hipaa, false, false)"
            (submitted)="createConsent($event, ConsentTypes.hipaa, 'hipaaDataSource', true)"></app-opt-out-form>
        </div>

        <!--PROGRAM OPT IN-->

        <div class="collapse-item">
          <div class="section-header">
            <h3>
              Program Opt-In
            </h3>
            <div class="status-label" [class.ready]="acceptedConsents.programDataSource">
              {{acceptedConsents.programDataSource ? 'Opted In' : 'Opted Out'}}
            </div>
          </div>

          <div class="empty-state" *ngIf="!programDataSource?.data.length">
            No opt-in status yet.
          </div>

          <table *ngIf="programDataSource?.data.length" mat-table [dataSource]="programDataSource">
            <ng-container matColumnDef="isOptedIn">
              <th mat-header-cell *matHeaderCellDef>Patient Preference</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ expiredFile(item.effectiveEndDate) ? 'Expired' : (item.consentIsOnFile ? 'Opted In' : 'Opted Out') }}
                <span>(Current)</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="method">
              <th mat-header-cell *matHeaderCellDef>Method Received</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ consentMethods[item.consentMethod] | empty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveFrom">
              <th mat-header-cell *matHeaderCellDef>Effective From</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.dateReceived ?
                (expiredFile(item.effectiveEndDate) ?
                (getDate(item.effectiveEndDate) | date: 'M/d/yyyy') :
                getDate(item.dateReceived) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveTo">
              <th mat-header-cell *matHeaderCellDef>Effective To</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.effectiveEndDate ?
                (expiredFile(item.effectiveEndDate) ? 'N/A' :
                getDate(item.effectiveEndDate) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
          </table>

          <div class="controls" *ngIf="!(isProgramOptCreate || isProgramOptOut)">
            <button (click)="toggleConsentForm(ConsentTypes.program, true, true)" mat-button class="primary-btn add-btn"
              type="button">
              <div class="add-btn-wrapper">
                <mat-icon>add</mat-icon>
                {{programDataSource?.data[0] &&
                programDataSource?.data[0]?.consentIsOnFile && expiredFile(programDataSource?.data[0].effectiveEndDate)
                ?
                'Renew' : 'Add'}} Opt-In
              </div>
            </button>
            <button
              [disabled]="!programDataSource?.data[0]?.consentIsOnFile || !isConsentAvailable(programDataSource?.data[0])"
              (click)="toggleConsentForm(ConsentTypes.program, false, true)" class="opt-out-btn" mat-button>
              Opt Out
            </button>
          </div>

          <app-new-opt-in-form *ngIf="isProgramOptCreate" [type]="'Program'"
            [isRenew]="programDataSource?.data[0] && programDataSource?.data[0]?.consentIsOnFile && expiredFile(programDataSource?.data[0].effectiveEndDate)"
            [currentConsent]="programDataSource?.data[0]"
            (cancelled)="toggleConsentForm(ConsentTypes.program, true, false)"
            (submitted)="createConsent($event, ConsentTypes.program, 'programDataSource')"></app-new-opt-in-form>
          <app-opt-out-form *ngIf="isProgramOptOut" [type]="'Program'"
            (cancelled)="toggleConsentForm(ConsentTypes.program, false, false)"
            (submitted)="createConsent($event, ConsentTypes.program, 'programDataSource', true)"></app-opt-out-form>
        </div>

        <!--MARKETING OPT IN-->

        <div class="collapse-item">
          <div class="section-header">
            <h3>
              Marketing Opt-In
            </h3>
            <div class="status-label" [class.ready]="acceptedConsents.marketingDataSource">
              {{acceptedConsents.marketingDataSource ? 'Opted In' : 'Opted Out'}}
            </div>
          </div>

          <div class="empty-state" *ngIf="!marketingDataSource?.data.length">
            No opt-in status yet.
          </div>

          <table *ngIf="marketingDataSource?.data.length" mat-table [dataSource]="marketingDataSource">
            <ng-container matColumnDef="isOptedIn">
              <th mat-header-cell *matHeaderCellDef>Patient Preference</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ expiredFile(item.effectiveEndDate) ? 'Expired' : (item.consentIsOnFile ? 'Opted In' : 'Opted Out') }}
                <span>(Current)</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="method">
              <th mat-header-cell *matHeaderCellDef>Method Received</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ consentMethods[item.consentMethod] | empty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveFrom">
              <th mat-header-cell *matHeaderCellDef>Effective From</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.dateReceived ?
                (expiredFile(item.effectiveEndDate) ?
                (getDate(item.effectiveEndDate) | date: 'M/d/yyyy') :
                getDate(item.dateReceived) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveTo">
              <th mat-header-cell *matHeaderCellDef>Effective To</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.effectiveEndDate ?
                (expiredFile(item.effectiveEndDate) ? 'N/A' :
                getDate(item.effectiveEndDate) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
          </table>

          <div class="controls" *ngIf="!(isMarketingOptCreate || isMarketingOptOut)">
            <button (click)="toggleConsentForm(ConsentTypes.marketing, true, true)" mat-button
              class="primary-btn add-btn" type="button">
              <div class="add-btn-wrapper">
                <mat-icon>add</mat-icon>
                {{marketingDataSource?.data[0] &&
                marketingDataSource?.data[0]?.consentIsOnFile &&
                expiredFile(marketingDataSource?.data[0].effectiveEndDate) ?
                'Renew' : 'Add'}} Opt-In
              </div>
            </button>
            <button
              [disabled]="!marketingDataSource?.data[0]?.consentIsOnFile || !isConsentAvailable(marketingDataSource?.data[0])"
              (click)="toggleConsentForm(ConsentTypes.marketing, false, true)" class="opt-out-btn" mat-button>
              Opt Out
            </button>
          </div>

          <app-new-opt-in-form *ngIf="isMarketingOptCreate" [type]="'Marketing'"
            [isRenew]="marketingDataSource?.data[0] && marketingDataSource?.data[0]?.consentIsOnFile && expiredFile(marketingDataSource?.data[0].effectiveEndDate)"
            [currentConsent]="marketingDataSource?.data[0]"
            (cancelled)="toggleConsentForm(ConsentTypes.marketing, true, false)"
            (submitted)="createConsent($event, ConsentTypes.marketing, 'marketingDataSource')"></app-new-opt-in-form>
          <app-opt-out-form *ngIf="isMarketingOptOut" [type]="'Marketing'"
            (cancelled)="toggleConsentForm(ConsentTypes.marketing, false, false)"
            (submitted)="createConsent($event, ConsentTypes.marketing, 'marketingDataSource', true)"></app-opt-out-form>
        </div>

        <!--TEXTING OPT IN-->

        <div class="collapse-item">
          <div class="section-header">
            <h3>
              Texting Opt-In
            </h3>
            <div class="status-label" [class.ready]="acceptedConsents.textingDataSource">
              {{acceptedConsents.textingDataSource ? 'Opted In' : 'Opted Out'}}
            </div>
          </div>

          <div class="empty-state" *ngIf="!textingDataSource?.data.length">
            No opt-in status yet.
          </div>

          <table *ngIf="textingDataSource?.data.length" mat-table [dataSource]="textingDataSource">
            <ng-container matColumnDef="isOptedIn">
              <th mat-header-cell *matHeaderCellDef>Patient Preference</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ expiredFile(item.effectiveEndDate) ? 'Expired' : (item.consentIsOnFile ? 'Opted In' : 'Opted Out') }}
                <span>(Current)</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="method">
              <th mat-header-cell *matHeaderCellDef>Method Received</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ consentMethods[item.consentMethod] | empty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveFrom">
              <th mat-header-cell *matHeaderCellDef>Effective From</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.dateReceived ?
                (expiredFile(item.effectiveEndDate) ?
                (getDate(item.effectiveEndDate) | date: 'M/d/yyyy') :
                getDate(item.dateReceived) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="effectiveTo">
              <th mat-header-cell *matHeaderCellDef>Effective To</th>
              <td mat-cell *matCellDef="let item; let i = index">
                {{ item.effectiveEndDate ?
                (expiredFile(item.effectiveEndDate) ? 'N/A' :
                getDate(item.effectiveEndDate) | date: 'M/d/yyyy')
                : 'N/A' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
          </table>

          <div class="controls" *ngIf="!(isTextingOptCreate || isTextingOptOut)">
            <button (click)="toggleConsentForm(ConsentTypes.texting, true, true)" mat-button class="primary-btn add-btn"
              type="button">
              <div class="add-btn-wrapper">
                <mat-icon>add</mat-icon>
                {{textingDataSource?.data[0] &&
                textingDataSource?.data[0]?.consentIsOnFile && expiredFile(textingDataSource?.data[0].effectiveEndDate)
                ?
                'Renew' : 'Add'}} Opt-In
              </div>
            </button>
            <button
              [disabled]="!textingDataSource?.data[0]?.consentIsOnFile || !isConsentAvailable(textingDataSource?.data[0])"
              (click)="toggleConsentForm(ConsentTypes.texting, false, true)" class="opt-out-btn" mat-button>
              Opt Out
            </button>
          </div>

          <app-new-opt-in-form *ngIf="isTextingOptCreate" [type]="'Texting'"
            [isRenew]="textingDataSource?.data[0] && textingDataSource?.data[0]?.consentIsOnFile && expiredFile(textingDataSource?.data[0].effectiveEndDate)"
            [currentConsent]="textingDataSource?.data[0]"
            (cancelled)="toggleConsentForm(ConsentTypes.texting, true, false)"
            (submitted)="createConsent($event, ConsentTypes.texting, 'textingDataSource')"></app-new-opt-in-form>
          <app-opt-out-form *ngIf="isTextingOptOut" [type]="'Texting'"
            (cancelled)="toggleConsentForm(ConsentTypes.texting, false, false)"
            (submitted)="createConsent($event, ConsentTypes.texting, 'textingDataSource', true)"></app-opt-out-form>
        </div>
      </div>
    </mat-expansion-panel>

    <!--ADDRESSES-->

    <mat-expansion-panel [expanded]="panel === 'address'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="header">
            Patient Addresses
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="collapse-section">

        <div class="collapse-item">
          <h3>Current Addresses</h3>

          <div class="empty-state" *ngIf="!currentAddresses.length">
            No current addresses
          </div>

          <div *ngFor="let address of currentAddresses; let i = index" class="address-block active-block"
            [class.edit-mode]="editAddressMode[i]">
            <ng-container *ngIf="!editAddressMode[i]">
              <div class="info">
                <h5>
                  {{address.addressType === AddressType.shipping ? 'Shipping' : 'Mailing'}} Address
                  <div class="controls items">
                    <button class="favorite selected">
                      <mat-icon *ngIf="address.current">star</mat-icon>
                      <mat-icon *ngIf="!address.current">star_border</mat-icon>
                    </button>
                    <button (click)="editAddressMode[i] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </h5>
                <p>{{address.address?.streetAddress | empty}}</p>
                <p class="last">{{address.address?.city | empty}}
                  , {{address.address?.zipCode | empty}}</p>
                <span>Added {{getDate(address.createDate) | date: 'MM/dd/yyyy' | empty}}</span>∙<a class="history-link"
                  (click)="viewAddressHistory(address)">View History
                  <img src="assets/icons/icon-arrow-long-right-blue.svg" alt="history" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="editAddressMode[i]">
              <app-new-address-form [address]="address" (cancelled)="cancelEditAddress(address, i, true)"
                (submitted)="saveAddress($event, true, true, i)"></app-new-address-form>
            </ng-container>
          </div>
        </div>
        <div class="collapse-item">
          <h3>Other Addresses</h3>

          <div class="empty-state" *ngIf="!otherAddresses.length">
            No other addresses
          </div>

          <div *ngFor="let address of otherAddresses; let i = index"
            [class.edit-mode]="editAddressMode[i + currentAddresses.length]" class="address-block">
            <ng-container *ngIf="!editAddressMode[i + currentAddresses.length]">
              <div class="info">
                <h5>
                  {{address.addressType === AddressType.shipping ? 'Shipping' : 'Mailing'}} Address
                  <div class="controls items">
                    <button class="favorite" (click)="setAddressCurrent(address)">
                      <mat-icon *ngIf="address?.current">star</mat-icon>
                      <mat-icon *ngIf="!address?.current">star_border</mat-icon>
                    </button>
                    <button (click)="editAddressMode[i + currentAddresses.length] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </h5>
                <p>{{address?.address?.streetAddress | empty}}</p>
                <p class="last">{{address?.address?.city | empty}}, {{address?.address?.zipCode | empty}}</p>
                <span>Added {{getDate(address?.createDate) | date: 'MM/dd/yyyy' | empty}}
                </span>∙<a class="history-link" (click)="viewAddressHistory(address)">View History
                  <img src="assets/icons/icon-arrow-long-right-blue.svg" alt="history" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="editAddressMode[i + currentAddresses.length]">
              <app-new-address-form [address]="address" (cancelled)="cancelEditAddress(address, i, false)"
                (submitted)="saveAddress($event, true, true, i + currentAddresses.length)"></app-new-address-form>
            </ng-container>
          </div>

          <button *ngIf="!showAddressForm" (click)="toggleAddressForm()" class="primary-btn add-btn" type="button">
            <mat-icon>add</mat-icon>
            Add Address
          </button>

          <app-new-address-form *ngIf="showAddressForm" (submitted)="saveAddress($event, true, false)"
            (cancelled)="toggleAddressForm()"></app-new-address-form>
        </div>
      </div>
    </mat-expansion-panel>

    <!--CAREGIVERS-->

    <mat-expansion-panel [expanded]="panel === 'caregivers'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="header">
            Caregivers
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="collapse-section">
        <div class="collapse-item">
          <h3>Active Caregivers</h3>

          <div class="empty-state" *ngIf="!activeCaregivers.length">
            No active caregivers
          </div>
          <div class="caregiver-container">
            <div class="caregiver-block active-block" [class.edit-mode]="editCaregiverMode[i]"
              *ngFor="let caregiver of activeCaregivers; let i = index">
              <ng-container *ngIf="!editCaregiverMode[i]">
                <div class="title">
                  <h3>{{caregiver.firstName | empty}}<span> {{caregiver.middleName}}</span> {{caregiver.lastName}}</h3>
                  <div class="controls items">
                    <button class="favorite selected" (click)="setCaregiverCurrent(caregiver)">
                      <mat-icon>star</mat-icon>
                    </button>
                    <button (click)="editCaregiverMode[i] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="description">
                  <div class="item">
                    <h5>Relation</h5>
                    <p>{{caregiver.relationship | empty}}</p>
                  </div>
                  <div class="item">
                    <h5>Phone</h5>
                    <p>{{(caregiver.contactInfos | contactString: ContactMethod.phone) | mask: '(000) 000-0000' |
                      empty}}
                    </p>
                  </div>
                  <div class="item">
                    <h5>Email</h5>
                    <p>{{(caregiver.contactInfos | contactString: ContactMethod.email) | empty}}</p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="editCaregiverMode[i]">
                <app-new-caregiver-form [caregiver]="caregiver" (submitted)="saveCaregiver($event, true, true, i)"
                  (cancelled)="cancelEditCaregiver(caregiver, i, true)"></app-new-caregiver-form>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="collapse-item">
          <h3>Inactive Caregivers</h3>

          <div class="empty-state" *ngIf="!inactiveCaregivers.length">
            No inactive caregivers
          </div>
          <div class="caregiver-container">
            <div class="caregiver-block" [class.edit-mode]="editCaregiverMode[i + activeCaregivers.length]"
              *ngFor="let caregiver of inactiveCaregivers; let i = index">
              <ng-container *ngIf="!editCaregiverMode[i + activeCaregivers.length]">
                <div class="title">
                  <h3>{{caregiver.firstName | empty}}<span> {{caregiver.middleName}}</span> {{caregiver.lastName}}</h3>
                  <div class="controls items">
                    <button class="favorite" (click)="setCaregiverCurrent(caregiver)">
                      <mat-icon>star_border</mat-icon>
                    </button>
                    <button (click)="editCaregiverMode[i + activeCaregivers.length] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="description">
                  <div class="item">
                    <h5>Relation</h5>
                    <p>{{caregiver.relationship | empty}}</p>
                  </div>
                  <div class="item">
                    <h5>Phone</h5>
                    <p>{{(caregiver.contactInfos | contactString: ContactMethod.phone) | mask: '(000) 000-0000' |
                      empty}}
                    </p>
                  </div>
                  <div class="item">
                    <h5>Email</h5>
                    <p>{{(caregiver.contactInfos | contactString: ContactMethod.email) | empty}}</p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="editCaregiverMode[i + activeCaregivers.length]">
                <app-new-caregiver-form [caregiver]="caregiver"
                  (submitted)="saveCaregiver($event, true, true, i + activeCaregivers.length)"
                  (cancelled)="cancelEditCaregiver(caregiver, i, false)"></app-new-caregiver-form>
              </ng-container>
            </div>
          </div>

          <button *ngIf="!showCaregiverForm" (click)="toggleCaregiverForm()" class="primary-btn add-btn" type="button">
            <mat-icon>add</mat-icon>
            Add Caregiver
          </button>
          <app-new-caregiver-form *ngIf="showCaregiverForm" (submitted)="saveCaregiver($event, true, false)"
            (cancelled)="toggleCaregiverForm()"></app-new-caregiver-form>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>