import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod } from '../../../../../../shared/enums/enums';
import { deepEqual, getContactInfoObject, noWhitespaceValidator } from '../../../../../../shared/helpers/utils';
import { IContactInfo } from '../../../../../../shared/interfaces/interfaces';
import { ICaregiver } from '../../../interfaces/caregiver.interface';

@Component({
  selector: 'app-new-caregiver-form',
  templateUrl: './new-caregiver-form.component.html',
  styleUrls: ['./new-caregiver-form.component.scss', '../enrollment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCaregiverFormComponent implements OnInit, AfterViewInit {
  @Input() caregiver: ICaregiver;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('caregiverNameField') caregiverNameField;
  @ViewChild('newCaregiverFormElement') newCaregiverFormElement;

  newCaregiverForm: FormGroup;

  contactInfos: IContactInfo[] = [];

  deepEqual = deepEqual;

  initialData;

  constructor() {
  }

  ngOnInit(): void {
    const name = this.caregiver
      ? `${this.caregiver.firstName ? this.caregiver.firstName : ''}${this.caregiver.middleName ? ' ' + this.caregiver.middleName : ''}${this.caregiver.lastName ? ' ' + this.caregiver.lastName : ''}`
      : '';

    this.initialData = {
      name,
      id: this.caregiver ? this.caregiver.id : 0,
      relationship: this.caregiver ? this.caregiver.relationship : '',
      phone: this.caregiver ? this.getContactValue(this.caregiver.contactInfos, ContactMethod.phone) : '',
      email: this.caregiver ? this.getContactValue(this.caregiver.contactInfos, ContactMethod.email) : '',
      current: this.caregiver ? this.caregiver.current : '',
    };

    this.newCaregiverForm = new FormGroup({
      id: new FormControl(this.initialData.id),
      name: new FormControl(this.initialData.name.slice(), [Validators.required, noWhitespaceValidator]),
      relationship: new FormControl(this.initialData.relationship.slice(), [Validators.required, noWhitespaceValidator]),
      phone: new FormControl(this.initialData.phone.slice(), [Validators.required]),
      email: new FormControl(this.initialData.email.slice(), [Validators.required, Validators.email]),
      current: new FormControl(this.initialData.current, [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    this.caregiverNameField.nativeElement.focus();
    this.newCaregiverFormElement?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  save(): void {
    if (this.contactInfos.length) {
      this.contactInfos.forEach((info) => {
        if (info.contactMethod === ContactMethod.phone) {
          info.contactString = this.newCaregiverForm.get('phone').value;
        }
        if (info.contactMethod === ContactMethod.email) {
          info.contactString = this.newCaregiverForm.get('email').value;
        }
      });
    } else {
      this.contactInfos = [
        getContactInfoObject(ContactMethod.email, this.newCaregiverForm.get('email').value),
        getContactInfoObject(ContactMethod.phone, this.newCaregiverForm.get('phone').value),
      ];
    }

    this.submitted.emit({ ...this.newCaregiverForm.getRawValue(), contactInfos: this.contactInfos });
  }

  private getContactValue(contactInfos: IContactInfo[], contactMethod: ContactMethod): string {
    for (const info of contactInfos) {
      if (info.contactMethod === contactMethod) {
        this.contactInfos.push(info);
        return info.contactString;
      }
    }

    this.contactInfos.push(getContactInfoObject(contactMethod, ''));
    return '';
  }
}
