import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../../../store/document/document.actions';

@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
})
export class EmptyDocumentModalComponent implements OnInit {
    constructor(private store: Store<any>) {}

    updateBatch() {
        this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
    }

    ngOnInit(): void {}
}
