import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IQueueParameters, IQueueResponse } from './queue.model';
import { IResponse } from 'src/app/shared/interfaces/interfaces';

@Injectable({
    providedIn: 'root',
})
export class QueueService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public getAllItemsInQueue(data: IQueueParameters): Observable<IQueueResponse[]> {
        this.url = this.apiUrl + '/Queue/GetAllItemsInQueueFromQuery';
        return this.postItem<IQueueParameters, IQueueResponse[]>(data);
    }

    public getUserImage(s3Key: string): Observable<Blob> {
        this.url = this.apiUrl;
        return this.getImage(`Document/${s3Key.replace(/\//g, '%2F')}`);
    }

    public getQueue(id: number): Observable<IResponse> {
        this.url = this.apiUrl + `/QueueConfiguration`;
        return this.getItems<IResponse>(id);
  }

  public getConfigurationFromQueueId(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration/getConfigurationFromQueueId`;
    return this.getItems<IResponse>(id);
  }
}
