import { Component, Input, OnInit } from '@angular/core';
import { PhaseEstimatedDurations, PhasesNames } from 'src/app/shared/enums/enums';
import { getDateDiff, getDurationString, getPhaseCompleted, getPhaseSpent, getPhaseStart } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-case-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['../../timeline/timeline.component.scss', './benefit.component.scss'],
})
export class BenefitCaseComponent implements OnInit {
  isDisabled = true;
  started = '';
  completed = '';
  timeInPhase = '';
  leftHours = '';
  showEdit = false;


  @Input() case;

  constructor() {
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }

  ngOnInit(): void {
    const phaseTasks = this.case?.tasks?.filter(x => x.phase === PhasesNames.benefitsVerification);
    this.started = getPhaseStart(phaseTasks);
    this.completed = getPhaseCompleted(phaseTasks);
    if (this.completed) {
      const spent = getPhaseSpent(phaseTasks);
      this.timeInPhase = getDurationString(spent > 0 ? spent : 0, 'hours', 'd [days] h [hours]', {trim: 'both'});
      const leftToEdit = PhaseEstimatedDurations.benefitsVerification - getDateDiff(new Date(), this.completed, 'hours', true);
      this.showEdit = leftToEdit > 0;
      this.leftHours = getDurationString(leftToEdit > 0 ? leftToEdit : 0, 'hours', 'h [hrs] m [min]', {trim: 'both', usePlural: false});
    }
  }

  getSpent(tasks) {
    const reducer = (accumulator, currentValue) => {
      const first = currentValue.createDate;
      const second = currentValue.completed || new Date();
      const diff = getDateDiff(second, first, 'hours', true);
      return accumulator + diff;
    };
    return tasks.reduce(reducer, 0);
  }
}
