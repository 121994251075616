import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../store/document/document.actions';
import { DocumentService } from './document.service';
import { Document, IUnlockDocument } from './document.model';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError, Subscription } from 'rxjs';
import { QueueIDs } from '../../../shared/enums/enums';
import { MatDialog } from '@angular/material/dialog';
import { EmptyDocumentModalComponent } from './../../../features/intake/document/type/modals/empty/empty.component';

@Component({
    selector: 'app-intake-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
    documents = [];
    queueID: number;
    queueItemId: string;
    unlockAfterHour;
    private subscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private store: Store<any>,
        public documentService: DocumentService,
        public router: Router,
        private route: ActivatedRoute
    ) {
        const queueType = window.location.pathname.replace('/document-', '');
        this.queueID = QueueIDs[queueType];
    }

    createImageFromBlob(image: Blob, id: number, type: string) {
        let reader = new FileReader();
        let setImageUrl = () => {
            let newDocuments = this.documents.map((document) => {
                let obj = {
                    ...document,
                };
                if (document.id == id) {
                    obj[type] = reader.result;
                }
                return obj;
            });
            this.documents = newDocuments;
            if (type == 'raw') {
                this.store.dispatch(new DocumentAction.SetDocumentsList(this.documents));
            }
        };
        reader.addEventListener('load', setImageUrl, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    getImages(image) {
        this.documentService.getDocument(image.thumbnailPNGKey).subscribe(
            (thumbnail) => {
                this.createImageFromBlob(thumbnail, image.id, 'thumbnail');
                this.documentService.getDocument(image.rawPNGKey).subscribe(
                    (raw) => {
                        this.createImageFromBlob(raw, image.id, 'raw');
                    },
                    (error) => throwError(error)
                );
            },
            (error) => throwError(error)
        );
    }

    unlockBatch() {
        let batchID;
        this.store.select('document').subscribe((state) => {
            batchID = state.documentsBatchId;
        });
        const data: IUnlockDocument = {
            queueId: this.queueID,
            itemIds: [batchID],
        };
        if (batchID) {
            this.documentService.unlockBatch(data).subscribe(
                (response) => {
                    this.store.dispatch(new DocumentAction.RestoreDocuments());
                },
                (error) => throwError(error)
            );
        }
    }

    unlockAfterHourHandler() {
        clearInterval(this.unlockAfterHour);
        this.unlockAfterHour = setInterval(() => {
            this.unlockBatch();
            return this.router.navigate(['/']);
        }, 60 * 60 * 1000);
    }

    ngOnInit(): void {
        this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));

        this.unlockAfterHourHandler();
        this.route.queryParams.subscribe((params) => {
            this.queueItemId = params.id;
            if (!this.subscription) {
                this.subscription = this.store.select('document').subscribe((state) => {
                    this.unlockAfterHourHandler();
                    if (state.updateDocumentBatch) {
                        this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(false));
                        this.documents = [];
                        if (this.queueItemId) {
                            this.getQueueItemObservable().subscribe(
                                (response) => this.postLoadItem(response.value),
                                this.errorHandler.bind(this)
                            );
                        } else {
                            this.documentService
                                .getQueue(this.queueID)
                                .subscribe((response) => this.postLoadItem(response), this.errorHandler.bind(this));
                        }
                    }
                });
            }
        });
    }

    getQueueItemObservable() {
        if (window.location.pathname == '/document-identify') {
            return this.documentService.getIdentificationQueueItem(this.queueItemId);
        }
        if (window.location.pathname == '/document-data-entry') {
            return this.documentService.getDataEntryQueueItem(this.queueItemId);
        }
    }

    postLoadItem(batch) {
        if (!batch || !batch.documentImages.length) {
            this.queueItemId
                ? this.router.navigate(['/'])
                : this.dialog.open(EmptyDocumentModalComponent, { disableClose: true });
        } else {
            this.documentService.getQueueCount(this.queueID).subscribe(
                (response) => {
                    this.store.dispatch(new DocumentAction.SetDocumentsCount(response));
                },
                (error) => throwError(error)
            );

            this.store.dispatch(new DocumentAction.SetDocumentsBatchID(batch.id));

            batch.documentImages.forEach((image) => {
                let document = new Document();
                document.id = image.id;
                document.documentBatchId = image.documentBatchId;
                document.patient = image.patient ? {
                    ...image.patient,
                    name: `${image.patient.firstName} ${image.patient.lastName}`,
                } : null;
                document.documentType = image.documentType;
                document.otherText = image.otherDocumentTypeName;
                document.source = batch.source;
                this.documents.push(document);
                this.getImages(image);
            });
        }
        this.queueItemId = null;
    }

    errorHandler(error) {
        throwError(error);
        return this.router.navigate(['/']);
    }

    @HostListener('window:beforeunload')
    ngOnDestroy() {
        this.unlockBatch();
        this.subscription.unsubscribe();
        this.store.dispatch(new DocumentAction.SetDocumentsCount(null));
        clearInterval(this.unlockAfterHour);
    }
}
