<div>
    <div class="setting-item">
        <b>Configuration</b>
        <div class="input-item toggle">
            <mat-button-toggle-group [(ngModel)]="userCanPull" (change)="changePull()">
                <mat-button-toggle value="pull"> Pull </mat-button-toggle>
                <mat-button-toggle value="push"> Push </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="saved" *ngIf="saved.pull" [@fadeInOut]>
            <mat-icon>check_circle</mat-icon>
            Saved
        </div>
    </div>
    <p><b>Pull</b> allows users to complete tasks in any order.</p>
    <p><b>Push</b> means the users must complete items in a specified order.</p>
    <div class="setting-item status">
        <b>Status</b>
        <div class="input-item toggle">
            <mat-button-toggle-group [(ngModel)]="active" (change)="changeStatus()">
                <mat-button-toggle value="enable"> Enable </mat-button-toggle>
                <mat-button-toggle value="disable"> Disable </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="saved" *ngIf="saved.status" [@fadeInOut]>
            <mat-icon>check_circle</mat-icon>
            Saved
        </div>
    </div>
</div>