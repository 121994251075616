<div class="top-block">
    <h2>Set-Up</h2>
    <p>
        First, set up this new queue by giving it a name, description, and selecting its configuration. You can edit
        this later.
    </p>
</div>

<div class="setup-wrapper" [formGroup]="queueForm">
    <div class="setup-item">
        <h3>Name</h3>
        <div class="input-item">
            <input name="name" id="name" formControlName="name"
                placeholder="Ex., Northwest Region, Louisville Metro, Reimbursement…" (input)="setSetup()"
                (keydown)="focusDescription($event)" #name />
        </div>
    </div>
    <div class="setup-item">
        <h3>Description</h3>
        <div class="input-item">
            <textarea name="description" id="description" formControlName="description"
                placeholder="Ex., Case Management Tasks, Reimbursement, Welcome Calls, Follow-Ups…" matInput
                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                (input)="setSetup()" #description (keydown)="focusConfiguration($event)"></textarea>
        </div>
    </div>
    <div class="setup-item configuration-item">
        <h3>Configuration</h3>
        <div class="input-item toggle">
            <mat-button-toggle-group formControlName="configuration" (change)="setSetup()" #configuration
                (keydown)="switchConfiguration($event)">
                <mat-button-toggle value="pull"> Pull </mat-button-toggle>
                <mat-button-toggle value="push"> Push </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <p><b>Pull</b> allows users to complete tasks in any order.</p>
    <p><b>Push</b> means the users must complete items in a specified order.</p>
</div>