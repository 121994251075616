import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { enumToArray, toSearchString, deepCopy, interpolateTemplate } from '../../../../shared/helpers/utils';
import { ManagerQueueTaskColumns, Phases, PhasesNames, Tasks, TasksNames, Roles, TaskContactMethodNames, TargetNames } from '../../../../shared/enums/enums';
import { QueueService } from './../queue.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { caseTaskFilterData } from 'src/app/shared/data/data';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['../queue.component.scss', './task.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)',
  },
})
export class ManagerQueueTaskComponent implements OnInit {
  columnsToDisplay = enumToArray(ManagerQueueTaskColumns);
  phases = enumToArray(Phases);
  phasesNames = PhasesNames;
  tasks = enumToArray(Tasks);
  tasksNames = TasksNames;
  data = [];
  taskType;
  usersAssigned = [];
  dataSource;
  isLoaded = false;
  isFiltersNotUsed = true;
  userRole: string;
  previousValue = caseTaskFilterData;
  queueConfig = {
    id: '',
    name: '',
    userCanPull: false,
  };

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
    filter: {
      show: {
        phase: false,
        task: false,
        patient: false,
        physician: false,
        payer: false,
        pharmacy: false,
        dueDate: false,
      },
      value: caseTaskFilterData,
      initialValue: caseTaskFilterData,
    },
  };

  constructor(
    public queueService: QueueService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>
  ) {
    this.store.select('user').subscribe((state) => {
      this.userRole = state.role;
    });
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  isSupervisor() {
    return this.userRole == Roles.Supervisor;
  }

  isOverdue(date) {
    const taskDate = new Date(date);
    taskDate.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return taskDate < currentDate ? true : false;
  }
  isToday(date) {
    const taskDate = new Date(date);
    const today = new Date();
    return (
      taskDate.getDate() == today.getDate() &&
      taskDate.getMonth() == today.getMonth() &&
      taskDate.getFullYear() == today.getFullYear()
    );
  }

  setActivePage(index) {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  getQueue(id) {
    this.queueService.getConfigurationFromQueueId(id).subscribe(
      (response) => {
        this.queueConfig = {
          id: response.value.id,
          name: response.value.name,
          userCanPull: response.value.userCanPull,
        };
      },
      (error) => throwError(error)
    );
  }

  getAllItemsInQueue(id) {
    const data = { queueId: id, take: 10000 };
    this.queueService.getAllItemsInQueue(data).subscribe(
      (response) => {
        this.data = response.map((queue) => {
          return {
            id: queue.id,
            phase: queue.phase,
            task: interpolateTemplate(queue.task, {
              reason: queue.followUpReason,
              contactMethod: TaskContactMethodNames[queue.contactMethod] || '',
              entity: TargetNames[queue.target] || ''
            }),
            patient: queue.patient,
            physician: queue.physician,
            payer: queue.payer,
            pharmacy: queue.pharmacy,
            dueDate: queue.due,
            queueStatus: queue.queueStatus,
            userImageS3Url: queue.userImageS3Url,
            caseId: queue.caseId,
            target: queue.target,
          };
        });
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.setPagination();
        this.settings.filter.initialValue = { ...this.settings.filter.value };
        this.isLoaded = true;
      },
      (error) => throwError(error)
    );
  }

  selectType() {
    this.applyFilter(null);
  }

  openFilter(filter) {
    this.previousValue = deepCopy(this.settings.filter.value)
    this.closeAllFilters();
    this.settings.filter.show[filter] = true;
  }

  closeFilter(filter) {
    if (filter) {
      this.settings.filter.show[filter] = false;
      this.settings.filter.value = this.previousValue
    }
  }

  closeAllFilters() {
    const shownFilters = this.settings.filter.show;
    Object.keys(shownFilters).forEach((k) => {
      shownFilters[k] = false;
    });
    this.settings.filter.value = deepCopy(this.previousValue)
  }

  clearFilter(filter) {
    switch (filter) {
      case 'phase':
        this.settings.filter.value[filter] = {
          enrollment: false,
          fulfillment: false,
        };
        break;
      case 'task':
        this.settings.filter.value[filter] = {
          welcomeCall: false,
          missingInfo: false,
          pharmacyTriage: false,
        };
        break;
      case 'dueDate':
        this.settings.filter.value[filter] = { start: '', end: '' };
        break;
      default:
        this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
    }
    this.applyFilter(filter);
  }

  clearAllFilters() {
    this.settings.filter.value = {
      ...this.settings.filter.initialValue,
      phase: {
        enrollment: false,
        fulfillment: false,
      },
      task: {
        welcomeCall: false,
        missingInfo: false,
        pharmacyTriage: false,
      },
      dueDate: { start: '', end: '' },
    };
    this.applyFilter('');
  }

  checkIsFilterUsed() {
    this.isFiltersNotUsed =
      !this.isCheckBoxFilterSelected('phase') &&
      !this.isCheckBoxFilterSelected('task') &&
      !!this.settings.filter.value.patient == !!this.settings.filter.initialValue.patient &&
      !!this.settings.filter.value.physician == !!this.settings.filter.initialValue.physician &&
      !!this.settings.filter.value.payer == !!this.settings.filter.initialValue.payer &&
      !!this.settings.filter.value.pharmacy == !!this.settings.filter.initialValue.pharmacy &&
      !this.settings.filter.value.dueDate.start &&
      !this.settings.filter.value.dueDate.end;
  }

  isCheckBoxFilterSelected(filter) {
    return Object.values(this.settings.filter.value[filter]).includes(true);
  }

  setPagination() {
    this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
  }

  applyFilter(filter) {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = this.settings.filter.value;
    this.settings.filter.show[filter] = false;
    this.checkIsFilterUsed();
    this.setPagination();
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      let shownFilter;
      for (const [key, value] of Object.entries(this.settings.filter.show)) {
        if (this.settings.filter.show[key]) {
          shownFilter = key;
        }
      }
      shownFilter && this.applyFilter(shownFilter);
    }
  }

  openItem(task) {
    if (this.queueConfig.userCanPull || this.userRole == Roles.Supervisor) {
      this.router.navigate(['/case'], { queryParams: { id: task.caseId }, state: { taskId: task.id } });
    }
  }

  getPhaseName(phase) {
    const phaseId: any = this.phases[phase];
    return this.phasesNames[phaseId];
  }

  getTaskName(task) {
    const taskId: any = this.tasks[task];
    return this.tasksNames[taskId];
  }

  customFilterPredicate() {
    const myFilterPredicate = (data, filter): boolean => {
      let typeFilter = false;
      if (this.taskType !== undefined) {
        typeFilter = typeFilter || this.taskType == data.target;
      } else {
        typeFilter = true;
      }

      let phaseFilter = false;
      if (Object.values(filter.phase).includes(true)) {
        const phase: any = PhasesNames[data.phase];
        phaseFilter = phaseFilter || filter.phase[phase];
      } else {
        phaseFilter = true;
      }

      let taskFilter = false;
      if (Object.values(filter.task).includes(true)) {
        const task: any = TasksNames[data.task];
        taskFilter = taskFilter || filter.task[task];
      } else {
        taskFilter = true;
      }

      const patientFilter = filter.patient
        ? toSearchString(data.patient).includes(toSearchString(filter.patient))
        : true;

      const physicianFilter = filter.physician
        ? toSearchString(data.physician).includes(toSearchString(filter.physician))
        : true;

      const payerFilter = filter.payer ? toSearchString(data.payer).includes(toSearchString(filter.payer)) : true;

      const pharmacyFilter = filter.pharmacy
        ? toSearchString(data.pharmacy).includes(toSearchString(filter.pharmacy))
        : true;

      var fullEndDate = new Date(filter.dueDate.end);
      let dueDateFilter;
      if (filter.dueDate.start && filter.dueDate.end) {
        dueDateFilter =
          new Date(data.dueDate) >= new Date(filter.dueDate.start) &&
          new Date(data.dueDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else if (filter.dueDate.start && !filter.dueDate.end) {
        dueDateFilter = new Date(data.dueDate) >= new Date(filter.dueDate.start);
      } else if (!filter.dueDate.start && filter.dueDate.end) {
        dueDateFilter = new Date(data.dueDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else {
        dueDateFilter = true;
      }

      return (
        typeFilter &&
        phaseFilter &&
        taskFilter &&
        patientFilter &&
        physicianFilter &&
        payerFilter &&
        pharmacyFilter &&
        dueDateFilter
      );
    };
    return myFilterPredicate;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      let id: number = Number(queryParams.get('id'));
      if (!id) {
        return this.router.navigate(['/']);
      }
      this.getQueue(id);
      this.getAllItemsInQueue(id);
    });
  }

  dummyData = [
    {
      id: 1,
      phase: 0,
      task: 1,
      patient: {
        firstName: 'Mae',
        lastName: 'Fünke',
      },
      physician: 'Dale Gribble',
      payer: 'Aetna',
      pharmacy: 'CVS',
      date: '2021-01-22T14:49:28.463606',
      queueStatus: 0,
      userImageS3Url: null,
    },
    {
      id: 2,
      phase: 1,
      task: 2,
      patient: {
        firstName: 'Hudson',
        lastName: 'Stanley',
      },
      physician: 'Nellie Bertram',
      payer: 'Humana',
      pharmacy: 'Kroger',
      date: '2021-01-26T18:49:28.463606',
      queueStatus: 0,
      userImageS3Url: null,
    },
    {
      id: 3,
      phase: 0,
      task: 0,
      patient: {
        firstName: 'Sketchit',
        lastName: 'Tracey',
      },
      physician: 'Scott Summers',
      payer: 'MetLife',
      pharmacy: 'Walgreens',
      date: '2021-02-25T14:49:28.463606',
      queueStatus: 1,
      userImageS3Url: null,
    },
    {
      id: 4,
      phase: 0,
      task: 1,
      patient: {
        firstName: 'Hill',
        lastName: 'Robert',
      },
      physician: 'Ann Veal',
      payer: 'Anthem',
      pharmacy: 'RiteAid',
      date: '2021-02-02T14:49:28.463606',
      queueStatus: 0,
      userImageS3Url: null,
    },
    {
      id: 5,
      phase: 1,
      task: 1,
      patient: {
        firstName: 'Pickles',
        lastName: 'Louis',
      },
      physician: 'Stanley Sitwell',
      payer: 'Aetna',
      pharmacy: 'Walmart',
      date: '2021-01-29T14:49:28.463606',
      queueStatus: 0,
      userImageS3Url: null,
    },
  ];
  dummyUsers = [
    {
      id: 1,
      userImageS3Url: 'https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png',
      firstName: 'firstName',
      lastName: 'lastName',
    },
    {
      id: 2,
      userImageS3Url: null,
      firstName: 'firstName',
      lastName: 'lastName',
    },
    {
      id: 3,
      userImageS3Url: null,
      firstName: 'firstName',
      lastName: 'lastName',
    },
    {
      id: 4,
      userImageS3Url: null,
      firstName: 'firstName',
      lastName: 'lastName',
    },
    {
      id: 5,
      userImageS3Url: null,
      firstName: 'firstName',
      lastName: 'lastName',
    },
  ];
}
