import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { PrescriptionFormFields } from '../form.model';
import { MatDialog } from '@angular/material/dialog';
import { MissedCaseModalComponent } from './modals/missed/missed.component'
import { throwError } from 'rxjs';
import { DataEntryFormService } from '../form.service';
import * as moment from 'moment';
import { TaskOutcome } from 'src/app/shared/enums/enums';
import { isFilled, isPositive, numberValidator } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-intake-document-prescription-form',
    templateUrl: './prescription.component.html',
    styleUrls: ['../form.component.scss', './prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit {
    @Input() settings;
    @Input() fullSettings;
    @Input() isValid;
    @Output() validateForm = new EventEmitter();
    @Output() updateCase = new EventEmitter();
    @Output() updateMissedInfo = new EventEmitter();
    @Output() getNextStack = new EventEmitter();
    prescriptionForm: FormGroup;
    submitted: boolean = false;
    disableDate = new Date();
    prescriptionFormFields = PrescriptionFormFields;

    _case;
    products = [];

    @Input()
    get case() { return this._case; }
    set case(newCase) {
        if (!this._case && newCase) {
            this.prescriptionForm.patchValue(this.getPrescriptionFormObject(newCase.prescription));
            this.selectSignature();
            this._case = newCase;
        }
    }

    @Output() submitForm = new EventEmitter();

    @ViewChild('medication') medication: MatSelect;

    constructor(public dialog: MatDialog, private dataEntryService: DataEntryFormService) {
        this.prescriptionForm = new FormGroup({
            medication: new FormControl(null, [Validators.required]),
            dosage: new FormControl(null, [Validators.required]),
            quantity: new FormControl(null, [Validators.required, numberValidator, isPositive]),
            signature: new FormControl(null, [Validators.required, isFilled]),
            physicianSignatureDate: new FormControl(null),
            refillsNumber: new FormControl(null, [numberValidator]),
        });
    }

    selectSignature() {
        setTimeout(() => {
            if (this.prescriptionForm.value.signature == null || this.prescriptionForm.value.signature == 1) {
                this.prescriptionForm.get('physicianSignatureDate').setValidators(null);
                this.prescriptionForm.patchValue({
                    physicianSignatureDate: null
                });
            }
            else {
                this.prescriptionForm.get('physicianSignatureDate').setValidators([Validators.required, isFilled]);
            }
            this.prescriptionForm.get('physicianSignatureDate').updateValueAndValidity();
        });
    }

    submitHandler(activeTab = 'missed') {
        this.submitted = true;
        this.savePrescription();
        this.validateForm.emit({ formControls: this.prescriptionForm.controls, tab: 'prescription' });

        if (activeTab == 'missed') {
            let missedInfo = this.getFullMissedFields();
            this.updateMissedInfo.emit(missedInfo);
            if (this.hasAllRequiredBlank(missedInfo)) {
                return this.openModal(MissedCaseModalComponent, missedInfo);
            } else if (this.hasAllDesiredBlank(missedInfo) || !missedInfo.invalid.length) {
                return this.completeCase();
            }
        }
        else {
            if (this.hasPrescriptionRequiredBlank()) {
                return this.switchTab(false, activeTab);
            } else if (this.hasPrescriptionDesiredBlank()) {
                return this.switchTab(true, activeTab);
            } else if (!this.settings.validate.invalid.length) {
                return this.switchTab(true, activeTab);
            }
        }
    }

    hasPrescriptionRequiredBlank() {
        return !this.settings.validate.invalid.length && this.settings.validate.requiredBlank.length;
    }
    hasPrescriptionDesiredBlank() {
        return !this.settings.validate.invalid.length && this.settings.validate.desiredBlank.length;
    }

    hasAllRequiredBlank(missingInfo) {
        return !missingInfo.invalid.length && missingInfo.requiredBlank.length;
    }

    hasAllDesiredBlank(missingInfo) {
        return !missingInfo.invalid.length && missingInfo.desiredBlank.length;
    }

    getFullMissedFields() {
        const missedInfo = {
            requiredBlank: [],
            desiredBlank: [],
            invalid: []
        }
        for (const [key, value] of Object.entries(this.fullSettings)) {
            if (value.hasOwnProperty('validate')) {
                missedInfo.requiredBlank = [...missedInfo.requiredBlank, ...value['validate'].requiredBlank]
                missedInfo.desiredBlank = [...missedInfo.desiredBlank, ...value['validate'].desiredBlank]
                missedInfo.invalid = [...missedInfo.invalid, ...value['validate'].invalid]
            }
        }
        return missedInfo;
    }

    completeCase() {
        const data = {
            queueItemId: this._case.id,
            queueTaskId: 0,
            workflowTaskId: 0,
            taskOutcome: TaskOutcome.success
        }
        this.dataEntryService.completeCase(data).subscribe(
            (response) => {
                this.getNextStack.emit();
            },
            (error) => throwError(error)
        );
    }

    openModal(modal, missedInfo) {
        let dialogRef = this.dialog.open(modal, {
            data: {
                required: missedInfo.requiredBlank,
                missed: missedInfo.desiredBlank,
            },
        });
        const dialog: any = dialogRef.componentInstance;
        const sub = dialog.switchTab.subscribe(() => {
            this.switchTab(false)
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
            this.submitted = false;
        });
    }

    switchTab(boolean, activeTab = 'missed') {
        const data = {
            activeTab: activeTab,
            prescription: {
                isValid: boolean,
            },
        };
        this.submitForm.emit(data);
    }

    savePrescription() {
        this._case.prescription = this.getPrescriptionCaseObject();
        const data = {
            prescription: this._case.prescription
        };
        this.updateCase.emit(this._case);
        this.updatePrescription(data);
    }

    updatePrescription(data) {
        this.dataEntryService.updatePrescription(data).subscribe(
            (response) => {
                //save case
            },
            (error) => throwError(error)
        );
    }

    ngOnInit(): void {
        const data = { take: 0 };
        this.dataEntryService.getProducts(data).subscribe((response) => {
            this.products = response;
        },
            (error) => throwError(error))
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.medication.focus();
        }, 0);
    }

    getPrescriptionFormObject(prescription) {
        return {
            medication: prescription && prescription.product ? prescription.product.id : '',
            dosage: prescription ? prescription.dosage : null,
            quantity: prescription ? prescription.quantity : null,
            signature: prescription
                ? (prescription.physicianSignature == false
                    ? 1
                    : (prescription.physicianSignature == true ? 0 : null))
                : null,
            physicianSignatureDate: prescription ? prescription.physicianSignatureDate : null,
            refillsNumber: prescription ? prescription.refillsNumber : null,
        }
    }

    getPrescriptionCaseObject() {
        let medication = this.prescriptionForm.value.medication ? this.products.find(x => x.id == this.prescriptionForm.value.medication) : null;
        return {
            id: this._case.prescriptionId,
            dosage: this.prescriptionForm.value.dosage,
            product: medication,
            quantity: this.prescriptionForm.value.quantity ? Number(this.prescriptionForm.value.quantity) : null,
            physicianSignature: this.prescriptionForm.value.signature == 0
                ? true
                : (this.prescriptionForm.value.signature == 1 ? false : null),
            physicianSignatureDate: this.prescriptionForm.value.physicianSignatureDate ? moment(this.prescriptionForm.value.physicianSignatureDate).format() : null,
            refillsNumber: this.prescriptionForm.value.refillsNumber ? Number(this.prescriptionForm.value.refillsNumber) : null
        }
    }
}
