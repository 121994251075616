import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { phoneValidator } from 'src/app/shared/helpers/utils';
import { ContactMethod, ContactType } from '../../../../../../shared/enums/enums';
import { IFacility, IPhysician } from '../../../../../../shared/interfaces/interfaces';

@Component({
  selector: 'app-section-physician',
  templateUrl: './section-physician.component.html',
  styleUrls: ['./section-physician.component.scss', '../aside-sections.scss']
})
export class SectionPhysicianComponent implements OnInit, OnChanges {
  @Input() physician: IPhysician;
  @Input() facility: IFacility;

  @Output() update: EventEmitter<IFacility> = new EventEmitter<IFacility>();
  @Output() openManagement: EventEmitter<string> = new EventEmitter<string>();

  ContactMethod = ContactMethod;
  ContactType = ContactType;

  officePhone: string;
  officeFax: string;

  emailForm: FormGroup;
  mobileForm: FormGroup;

  constructor() {
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.email]),
    });
    this.mobileForm = new FormGroup({
      mobile: new FormControl(null, [phoneValidator]),
    });
  }

  ngOnInit(): void {
    this.updateContactInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facility.currentValue !== changes.facility.previousValue) {
      this.updateContactInfo();
    }
  }

  savePhysicianData(save: boolean, inputs: any[]): void {
    if (save) {
      this.setContactInfo(inputs, true);
      this.update.emit(this.facility);
    } else {
      this.setContactInfo(inputs, false);
    }
  }

  openPhysicianEditDialog(type: string): void {
    this.openManagement.emit(type);
  }

  private setContactInfo(inputs, save): void {
    let exists = false;

    this.facility.contactInfos.forEach((info) => {
      if (info.contactMethod === inputs[2] && info.contactType === inputs[3]) {
        if (inputs[1] == 'email' || inputs[1] == 'mobile') {
          let obj = {};
          obj[inputs[1]] = info.contactString?.slice();
          save ? info.contactString = this[inputs[1] + 'Form'].value[inputs[1]]?.slice() : this[inputs[1] + 'Form'].patchValue(obj);
        }
        else {
          save ? info.contactString = this[inputs[1]].slice() : this[inputs[1]] = info.contactString.slice();
        }
        exists = true;
      }
    });

    if (!exists) {
      if (save) {
        this.facility.contactInfos.push({
          name: null,
          primary: true,
          contactString: inputs[1] == 'email' || inputs[1] == 'mobile' ? this[inputs[1] + 'Form'].value[inputs[1]] : this[inputs[1]],
          contactType: inputs[3],
          contactMethod: inputs[2],
        });

        this.updateContactInfo();
      }
      else {
        if (inputs[1] == 'email' || inputs[1] == 'mobile') {
          let obj = {};
          obj[inputs[1]] = '';
          this[inputs[1] + 'Form'].patchValue(obj);
        }
        else {
          this[inputs[1]] = '';
        }
      }
    }
  }

  private updateContactInfo(): void {
    this.facility?.contactInfos.forEach((info) => {
      if (info.contactMethod === ContactMethod.phone && info.contactType === ContactType.work) {
        this.officePhone = info.contactString.slice();
      }
      if (info.contactMethod === ContactMethod.phone && info.contactType === ContactType.mobile) {
        this.mobileForm.patchValue({ mobile: info.contactString?.slice() });
      }
      if (info.contactMethod === ContactMethod.email && info.contactType === ContactType.work) {
        this.emailForm.patchValue({ email: info.contactString?.slice() });
      }
      if (info.contactMethod === ContactMethod.fax && info.contactType === ContactType.work) {
        this.officeFax = info.contactString.slice();
      }
    });
  }

}
