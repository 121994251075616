import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable, throwError } from 'rxjs';
import {
    IBatch,
    IPatientsRequestData,
    IDeletedImage,
    IUncertainImage,
    IDocumentUpdateRequest,
    IUnlockDocument,
} from './document.model';
import { IResponse, IPatient } from '../../../shared/interfaces/interfaces';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DocumentService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public getQueue(id: number): Observable<IBatch> {
        this.url = this.apiUrl;
        return this.getItems<IBatch>(`Queue/GetNextItemInQueue/${id}`);
    }

    public getIdentificationQueueItem(id: string): Observable<IResponse> {
        this.url = this.apiUrl;
        return this.getItems<IResponse>(`IdentificationQueue/GetDocumentBatch/${id}`);
    }

    public getDataEntryQueueItem(id: string): Observable<IResponse> {
        this.url = this.apiUrl;
        return this.getItems<IResponse>(`DataEntryQueue/GetDocumentBatch/${id}`);
    }

    public getDocument(s3Key: string): Observable<Blob> {
        this.url = this.apiUrl;
        return this.getImage(`Document/${s3Key.replace(/\//g, '%2F')}`);
    }

    public getPatients(data: IPatientsRequestData): Observable<IPatient[]> {
        this.url = this.apiUrl + `/Patients`;
        return this.postItem<IPatientsRequestData, IResponse>(data).pipe(
            map((response) => {
                return response.value;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    public deleteImage(id: number, data: IDeletedImage): Observable<IResponse> {
        this.url = this.apiUrl + `/IdentificationQueue/DeleteDocumentImage/${id}`;
        return this.deleteItem<IDeletedImage, IResponse>(data);
    }

    public uncertainImage(id: number, data: IUncertainImage): Observable<IResponse> {
        this.url = this.apiUrl + `/IdentificationQueue/UncertainDocumentImage/${id}`;
        return this.postItem<IUncertainImage, IResponse>(data);
    }

    public updateDocument(id: number, data: IDocumentUpdateRequest): Observable<IResponse> {
        this.url = this.apiUrl + `/IdentificationQueue/UpdateDocumentImage/${id}`;
        return this.updateItem<IDocumentUpdateRequest, IResponse>(data);
    }

    public setDocumentBatchCompleted(id: number): Observable<IResponse> {
        this.url = this.apiUrl + `/IdentificationQueue/CompleteDocumentBatch/${id}`;
        return this.postItem<any, IResponse>();
    }

    public unlockBatch(data: IUnlockDocument): Observable<IResponse> {
        this.url = this.apiUrl + `/Queue/UnlockQueueItems`;
        return this.postItem<IUnlockDocument, IResponse>(data);
    }

    public getQueueCount(id: number): Observable<number> {
        this.url = this.apiUrl;
        return this.getItems<number>(`Queue/GetItemCountInQueue/${id}`);
    }
}
