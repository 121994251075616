import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddressType } from '../../../../../../shared/enums/enums';

@Component({
  selector: 'app-confirm-address-dialog',
  templateUrl: './confirm-address-dialog.component.html',
  styleUrls: ['./confirm-address-dialog.component.scss', '../confirm-dialog.scss'],
})
export class ConfirmAddressDialogComponent {
  AddressType = AddressType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialogRef<ConfirmAddressDialogComponent>,
  ) {
  }

}
