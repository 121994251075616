<div class="queue-wrapper">
    <h2>
        Queue Dashboard
        <button class="primary-btn invert" [routerLink]="['/create-queue']">
            <mat-icon>add</mat-icon> Create Queue
        </button>
    </h2>
    <div *ngIf="!queueList && !intakeQueueList && !supervisorQueueList" class="dashboard-loader-container">
        <app-loader></app-loader>
    </div>
    <div *ngIf="queueList || intakeQueueList || supervisorQueueList">
        <div class="supervisor-queue">
            <app-queue-item *ngFor="let queue of supervisorQueueList" [data]="queue" viewRoute="queue-supervisor-task"
                [beginRoute]="'supervisor-tasks'">
            </app-queue-item>
        </div>
        <h2 *ngIf="intakeQueueList && intakeQueueList.length">Intake Queues</h2>
        <app-queue-item *ngFor="let queue of intakeQueueList" [data]="queue"
            [viewRoute]="'queue-' + queueIDs[queue.id]">
        </app-queue-item>
        <h2 *ngIf="queueList && queueList.length">Patient Management Queues</h2>
        <app-queue-item *ngFor="let queue of queueList" [data]="queue" [viewRoute]="'queue-manager-task'"
            [viewParams]="{ id:queue.id}">
        </app-queue-item>
    </div>
</div>