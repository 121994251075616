import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'app-data-entry-case',
    templateUrl: './case.component.html',
    styleUrls: ['../physician/physician.component.scss', './case.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class DataEntryCaseComponent implements OnInit {
    @Output() submitForm = new EventEmitter();
    @Output() caseSelected = new EventEmitter();
    @Output() caseCreated = new EventEmitter();
    focusedSearch: number = 0;
    title = '';
    @ViewChild('caseList') caseList: ElementRef;
    @ViewChild('addButton') addButton: ElementRef;

    constructor() { }

    _patient;
    @Input()
    get patient() {
        return this._patient;
    }
    set patient(patient) {
        if (!this._patient && patient) {
            this.title = `${patient.firstName}  ${patient.lastName}${patient.lastName || patient.lastName ? '’s' : ''}`;
        }
        this._patient = patient;
    }
    @Input() cases = [];

    handleKeyboardEvents(event: KeyboardEvent) {
        if (this.caseList && (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')) {
            event.preventDefault();
            if (event.key === 'Tab' || event.key === 'ArrowDown') {
                if (this.focusedSearch >= this.caseList.nativeElement.children.length - 1) {
                    this.addButton.nativeElement.focus();
                    if (this.focusedSearch == this.caseList.nativeElement.children.length) {
                        this.focusedSearch = 0;
                        this.addButton.nativeElement.blur();
                    } else {
                        this.focusedSearch = this.caseList.nativeElement.children.length;
                    }
                } else {
                    this.focusedSearch = this.focusedSearch + 1;
                    this.addButton.nativeElement.blur();
                }
            }
            if (event.key === 'ArrowUp') {
                if (this.focusedSearch <= 0) {
                    this.addButton.nativeElement.focus();
                    this.focusedSearch == -1
                        ? (this.focusedSearch = this.caseList.nativeElement.children.length - 1)
                        : (this.focusedSearch = this.caseList.nativeElement.children.length);
                } else {
                    this.focusedSearch = this.focusedSearch - 1;
                    this.addButton.nativeElement.blur();
                }
            }
            if (event.key === 'Enter') {
                if (this.focusedSearch == this.caseList.nativeElement.children.length) {
                    this.createCase();
                } else {
                    this.selectCase(this.cases[this.focusedSearch]);
                }
            }
        }
    }

    selectCase(selectedCase) {
        this.caseSelected.emit(selectedCase);
        this.switchTab();
    }

    createCase() {
        this.caseCreated.emit();
        this.switchTab();
    }

    switchTab() {
        const data = {
            init: true,
            activeTab: 'patient',
        };
        this.submitForm.emit(data);
    }

    submitHandler() {
        return this.switchTab();
    }

    ngOnInit(): void { }
}
