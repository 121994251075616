<hr />
<mat-button-toggle-group
  #duration="matButtonToggleGroup"
  name="duration"
  aria-label="Duration"
  [(ngModel)]="durationValue"
  (change)="showCasesHandler()"
>
  <mat-button-toggle value="today">Due Today</mat-button-toggle>
  <mat-button-toggle value="week">Due This Week</mat-button-toggle>
  <mat-button-toggle value="month">Due This Month</mat-button-toggle>
</mat-button-toggle-group>
<hr />
<mat-radio-group
  #action="matRadioGroup"
  aria-label="Select an option"
>
  <mat-radio-button value="group">Group by</mat-radio-button>
  <mat-radio-button value="filter">Filter by</mat-radio-button>
</mat-radio-group>
<div class="example-selected-value">Action: {{ action.value }}</div>

<mat-button-toggle-group
  #group="matButtonToggleGroup"
  name="group"
  aria-label="Group"
>
  <mat-button-toggle value="physician">Physician</mat-button-toggle>
  <mat-button-toggle value="pharmacy">Pharmacy</mat-button-toggle>
  <mat-button-toggle value="payer">Payer</mat-button-toggle>
</mat-button-toggle-group>
<div class="example-selected-value">By: {{ group.value }}</div>