<form [formGroup]="clinicalForm" class="clinical-form" novalidate (ngSubmit)="submitHandler()"
    (keydown.enter)="$event.preventDefault()">
    <div class="aside-wrapper">
        <h2>Clinical</h2>
        <div [class]="
                clinicalForm.controls['primaryDiagnosticCode'].invalid &&
                (clinicalForm.controls['primaryDiagnosticCode'].touched || settings.isValid == false)
                    ? 'input-item input-error'
                    : 'input-item'
            ">
            <label>{{ clinicalFormFields.primaryDiagnosticCode }}</label>
            <div class="search-form" (clickOutside)="showResult = false">
                <div class="input-item search">
                    <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
                    <input matInput type="text" formControlName="primaryDiagnosticCode" placeholder="Search by ICD-10"
                        #primaryDiagnosticCode (input)="searchCodeHandler()"
                        (focus)="diagnosticCodes.length ? (showResult = true) : (showResult = false)"
                        (keydown)="searchNavigate($event)" />
                    <button mat-button *ngIf="clinicalForm.value.primaryDiagnosticCode" matSuffix mat-icon-button
                        aria-label="Clear" (click)="selectCode(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <ul class="physician-results" *ngIf="showResult">
                    <ng-scrollbar #scrollbar *ngIf="!isLoading && diagnosticCodes.length" class="custom-scrollbar">
                        <div class="code-list" #clinicalSearchList>
                            <li *ngFor="let code of diagnosticCodes; index as i" (click)="selectCode(code)"
                                [class.focused]="focusedSearch == i">
                                <b>{{ code.code }}</b> {{ code.description }}
                            </li>
                        </div>
                    </ng-scrollbar>
                    <li *ngIf="!diagnosticCodes.length && !isLoading" class="not-found">
                        <h5>{{ clinicalForm.value.primaryDiagnosticCode }}</h5>
                        <p>Diagnosis Code Not Found</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="selected-physician" *ngIf="selectedDiagnosis && selectedDiagnosis.id">
            <div class="physician-title">
                <img src="../../../../../../../assets/icons/icon-check.svg" alt="check" />
                <div class="code-selected">
                    <b>{{ this.selectedDiagnosis.code }}</b> {{ this.selectedDiagnosis.description }}
                </div>
                <button (click)="selectCode(null)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <button type="submit" class="next-button next-stack" #nextBtn (keydown.enter)="submitHandler()">
        <b>Next:</b> Prescription <mat-icon>arrow_forward</mat-icon>
    </button>
</form>