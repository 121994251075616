<div mat-dialog-title>
    <h1><img src="../../../../../../../../../assets/icons/icon-warning.svg" alt="warning" />Missing Info</h1>
    <button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
    <p>This document is missing the following:</p>
    <div class="dialog-body">
        <ul>
            <li *ngFor="let item of data.required"><mat-icon>error</mat-icon>{{ item }}</li>
            <li *ngFor="let item of data.missed"><mat-icon>error</mat-icon>{{ item }}</li>
        </ul>
    </div>
    <p>Review the Missing Info to confirm it is correct, and then send a request for the information to the physician.</p>
</div>
<div mat-dialog-actions>
    <button mat-button class="back" mat-dialog-close (click)="switchTabHandler()">
        Review Missing Info <img style="transform: rotate(0deg); margin-right: 0; margin-left: 12px;" src="../../../../../../../../../assets/icons/icon-arrow-back.svg" alt="Back" />
    </button>
</div>
