import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { caseTimeline } from '../../../shared/data/data';
import { IFacility, IResponse } from '../../../shared/interfaces/interfaces';
import * as CaseAction from '../../../store/case/case.actions';
import { Store } from '@ngrx/store';
import { User } from '../../../shared/models/models';
import { LoginService } from '../../login/login.service';
import { OktaAuthService } from '@okta/okta-angular';
import { Subject, throwError } from 'rxjs';
import { DataEntryFormService } from '../../intake/document/data/form/form.service';
import { CreateActivityDialogComponent } from './components/dialogs/create-activity-dialog/create-activity-dialog.component';
import { PhysicianEditModalComponent } from './components/dialogs/physician-edit-modal/physician-edit-modal.component';
import { IConsent } from './interfaces/consent.interface';
import { CaseService } from './services/case.service';
import {
  PatientGender,
  BestTimeToContact,
  BestMethodToContact,
  ContactMethod,
  PatientContactType,
  HIPAAConsent,
  PlanType,
  PhasesNames,
  TargetNames,
  TaskDirectionNames,
  TaskContactMethodNames,
  PhaseEstimatedDurations,
  ConsentTypeId, ConsentTypes,
} from 'src/app/shared/enums/enums';
import {
  getDateString,
  getFullAddress,
  getAgeString,
  formatPhone,
  getDateDiff,
  getDurationString,
  addSuggestedCase,
  interpolateTemplate,
  getConsentValue
} from 'src/app/shared/helpers/utils';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../../shared/components/search/modal/modal.component';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
})
export class ManagerDashboardCaseComponent implements OnInit, OnDestroy {
  user = new User();
  currentPhase = '';

  panel: string;

  case;
  caseDetails = [];
  details;
  caseTimeline = caseTimeline;
  shownPhase;
  selectedTask = null;
  phaseList = caseTimeline.map((item) => {
    return {phase: item.phase, id: item.id, disabled: item.disabled};
  });
  activeTasks = [];
  completedTasks = [];
  historyTimeline = [];
  taskId = null;
  nextTaskId = null;
  destroyed = false;

  currentConsents = {
    hipaaConsent: null,
    textingConsent: null,
    programConsent: null,
    marketingConsent: null,
    voicemailConsent: null,
  };

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<any>,
    public loginService: LoginService,
    public oktaAuth: OktaAuthService,
    private router: Router,
    public caseService: CaseService,
    public dialog: MatDialog,
    private dataEntryService: DataEntryFormService,
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.taskId = this.router.getCurrentNavigation().extras.state.taskId;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!params?.id) {
        this.router.navigate(['/']);
      } else {
        this.getCase(params?.id, 'keyDetails');
      }
    });

    this.store.select('user').subscribe((state) => (this.user = state));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new CaseAction.RemoveCase());
    this.destroyed = true;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showPhaseHandler(phase) {
    this.shownPhase = phase;
    this.currentPhase = phase;
  }

  sectionUpdate(data: any, prop: string): void {
    this.case[prop] = data;
    this.caseService.updatePatient(this.case)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: IResponse) => {
      }, throwError);
  }

  facilityUpdate(data: IFacility): void {
    this.dataEntryService.updateFacility({facility: data})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: IResponse) => {
      }, throwError);
  }

  minimizeTask(data) {
    this.selectedTask.minimized = data;
    this.shownPhase = data ? this.currentPhase : '';
  }

  openEnrollmentItem(item: string): void {
    this.shownPhase = 'enrollment';
    this.panel = item;
  }

  openPhysicianEditDialog(step: string): void {
    this.dialog.open(PhysicianEditModalComponent, {
      data: {
        step,
        case: this.case,
      },
      panelClass: 'createEntityDialog',
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((caseId: number) => {
        if (caseId) {
          this.getCase(caseId, 'physician');
        }
      });
  }

  createActivityDialog(): void {
    this.dialog.open(CreateActivityDialogComponent, {
      data: this.case,
      panelClass: 'createEntityDialog',
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data) {
          this.getCase(this.case.id);
        }
      });
  }

  setDetails(detail) {
    this.details = detail ? this.caseDetails.find((item) => item.id === detail) : null;
  }

  setSelectedTask(task) {
    this.selectedTask = task;

    if (!task) {
      this.taskId = null;
    }
  }

  logout(): void {
    this.loginService.logout().subscribe(
      (response) => {
        this.oktaAuth.logout('/');
      },
      (error) => throwError(error)
    );
  }

  getCase(id: number, activeTab?: string) {
    this.caseService.getCaseItem(id).subscribe(
      (response) => {
        this.case = response;
        addSuggestedCase(this.case);
        this.setCurrentConsents();

        this.caseDetails = this.getCaseDetailsObject();
        this.activeTasks = this.case.tasks.filter((x) => x.taskId && x.outcome === null && !x.automated);
        this.completedTasks = this.case.tasks;
        this.completedTasks.sort((a, b) => {
          return (new Date(a.createDate) as any) < (new Date(b.createDate) as any) ? -1 : 1;
        });

        this.historyTimeline = this.getPhaseTimeline();

        if (activeTab) {
          this.setDetails(activeTab);
        } else if (this.details?.id) {
          this.setDetails(this.details.id);
        }

        this.checkPhaseEnabled(PhasesNames.priorAuthorization);
        this.checkPhaseEnabled(PhasesNames.fulfillment);

        if (this.taskId && !this.selectedTask?.minimized) {
          const selectedTask = this.activeTasks.find((task) => task.id === this.taskId);
          this.setSelectedTask(selectedTask);
        } else if (this.nextTaskId) {
          const selectedTask = this.activeTasks.find((task) => task.taskId === this.nextTaskId);
          this.setSelectedTask(selectedTask);
        }

        const headerInfo = {
          id: this.case.id,
          patient: `${this.case.patient.firstName} ${this.case.patient.lastName}`,
          queue: null,
          tasks: null,
        };

        if (!this.destroyed) {
          this.store.dispatch(new CaseAction.SetCase(headerInfo));
        }
      },
      (error) => throwError(error)
    );
  }

  checkPhaseEnabled(phaseName) {
    if (this.case.tasks.some(x => x.phase === phaseName && x.outcome != null)) {
      const found = this.phaseList.find(x => x.phase === phaseName);
      if (found) {
        found.disabled = false;
      }
    }
  }

  getPhaseTimeline() {
    const grouppedTasks = this.groupCaseTasks();
    const enrollmentSpent = this.getSpent(PhasesNames.enrollment, grouppedTasks);
    const benefitsVerificationSpent = this.getSpent(PhasesNames.benefitsVerification, grouppedTasks);
    const priorAuthorizationSpent = this.getSpent(PhasesNames.priorAuthorization, grouppedTasks);
    const fulfillmentSpent = this.getSpent(PhasesNames.fulfillment, grouppedTasks);

    return [
      {
        phase: PhasesNames.enrollment,
        estimate: getDurationString(
          PhaseEstimatedDurations.enrollment,
          'hours',
          'd [days] h [hours]',
          {trim: 'both'},
        ), // this.getEstimate(PhasesNames.enrollment, grouppedTasks),
        spent: getDurationString(enrollmentSpent > 0 ? enrollmentSpent : 0, 'hours', 'd [days] h [hours]', {trim: 'both'}),
        status: this.getStatus(PhasesNames.enrollment, grouppedTasks),
      },
      {
        phase: PhasesNames.benefitsVerification,
        estimate: getDurationString(
          PhaseEstimatedDurations.benefitsVerification,
          'hours',
          'd [days] h [hours]',
          {trim: 'both'},
        ), // this.getEstimate(PhasesNames.benefitsVerification, grouppedTasks),
        spent: getDurationString(
          benefitsVerificationSpent > 0 ? benefitsVerificationSpent : 0,
          'hours',
          'd [days] h [hours]',
          {trim: 'both'},
        ),
        status: this.getStatus(PhasesNames.benefitsVerification, grouppedTasks),
      },
      {
        phase: PhasesNames.priorAuthorization,
        estimate: getDurationString(
          PhaseEstimatedDurations.priorAuthorization,
          'hours', 'd [days] h [hours]',
          {trim: 'both'},
        ), // this.getEstimate(PhasesNames.priorAuthorization, grouppedTasks),
        spent: getDurationString(priorAuthorizationSpent > 0 ? priorAuthorizationSpent : 0, 'hours', 'd [days] h [hours]', {trim: 'both'}),
        status: this.getStatus(PhasesNames.priorAuthorization, grouppedTasks),
      },
      {
        phase: PhasesNames.fulfillment,
        estimate: getDurationString(
          PhaseEstimatedDurations.fulfillment,
          'hours',
          'd [days] h [hours]',
          {trim: 'both'},
        ), // this.getEstimate(PhasesNames.fulfillment, grouppedTasks),
        spent: getDurationString(fulfillmentSpent > 0 ? fulfillmentSpent : 0, 'hours', 'd [days] h [hours]', {trim: 'both'}),
        status: this.getStatus(PhasesNames.fulfillment, grouppedTasks),
      },
    ];
  }

  groupCaseTasks(filterCallback = null, sortCallback = null) {
    let tasks = [...this.case.tasks];

    if (filterCallback) {
      tasks = tasks.filter(filterCallback);
    }

    if (sortCallback) {
      tasks = tasks.sort(sortCallback);
    }

    const obj = {};

    obj[PhasesNames.enrollment] = [];
    obj[PhasesNames.benefitsVerification] = [];
    obj[PhasesNames.priorAuthorization] = [];
    obj[PhasesNames.fulfillment] = [];

    for (const item of tasks) {
      if (obj[item.phase]) {
        obj[item.phase].push(item);
      }
    }

    return obj;
  }

  getEstimate(phase, groppedTasks) {
    const reducer = (accumulator, currentValue) => {
      const first = currentValue.createDate;
      const second = currentValue.due;
      const diff = getDateDiff(second, first, 'hours', true);
      return accumulator + diff;
    };

    return groppedTasks[phase].reduce(reducer, 0);
  }

  getSpent(phase, groppedTasks) {
    const reducer = (accumulator, currentValue) => {
      const first = currentValue.createDate;
      const second = currentValue.completed || new Date();
      const diff = getDateDiff(second, first, 'hours', true);
      return accumulator + diff;
    };
    return groppedTasks[phase].reduce(reducer, 0);
  }

  getStatus(phase, groppedTasks) {
    if (!groppedTasks[phase] || !groppedTasks[phase].length) {
      return 'Upcoming';
    }
    if (groppedTasks[phase].find((x) => x.outcome === null)) {
      return 'Current';
    }
    return 'Complete';
  }

  submitTask(data) {
    this.caseService.completeCase(data)
      .subscribe((response) => {
          this.taskId = null;
          if (!data.nextTaskId) {
            this.setSelectedTask(null);
          } else {
            this.nextTaskId = data.nextTaskId;
          }
          this.getCase(this.case.id);
        },
        error => throwError(error));
  }

  getCaseDetailsObject() {
    return [
      this.getKeyDetailsObject(),
      this.getPatientDetailsObject(),
      this.getPayerDetailsObject(),
      this.getPhysicianDetailsObject(),
      this.getPrescriptionsObject(),
      // this.getShippingObject(),
      this.getAttachmentsObject(),
      this.getNotesObject(),
    ];
  }

  openModal() {
    this.dialog.open(SearchModalComponent, {
      data: {searchValue: ''},
      panelClass: 'searchDialog',
    });
  }

  getKeyDetailsObject() {
    const age = getAgeString(this.case.patient.dateOfBirth);
    const dob = this.case.patient.dateOfBirth
      ? `${getDateString(this.case.patient.dateOfBirth, 'MM / DD / YYYY')} · ${age}`
      : '';
    const hipaaConsent = this.case.patient.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);
    return {
      title: 'Key Details',
      id: 'keyDetails',
      icon: 'flag',
      data: [
        {
          title: 'Case Type',
          value: 'Reimbursement',
        },
        {
          title: 'Case Status',
          value: this.case.status,
        },
        {
          title: 'Substatus',
          value: this.case.subStatus,
        },
        {
          title: 'Reason',
          value: this.case.subStatusReason,
        },
        {
          title: 'Patient Id',
          value: this.case.patientId,
        },
        {
          title: 'Case ID',
          value: this.case.id,
        },
        {
          title: 'Patient DOB',
          value: dob,
        },
        {
          title: 'HIPAA Consent',
          value: HIPAAConsent[getConsentValue(hipaaConsent)],
        },
        {
          title: 'Territory',
          value: this.case.territory,
        },
        {
          title: 'HCP',
          value: `${this.case.physician ? (this.case.physician.firstName || '') : ''} ${this.case.physician ? (this.case.physician.lastName || '') : ''}`,
        },
        {
          title: 'Specialty Pharmacy',
          value: `${this.case.specialtyPharmacy?.name ?? ''}`,
        },
        {
          title: 'Enrollment Date',
          value: getDateString(this.case.enrollmentStartDate, 'MM / DD / YYYY'),
        },
        {
          title: 'Treatment Start',
          value: this.case.threatmentStart,
        },
      ],
    };
  }

  getPatientDetailsObject() {
    const patient = this.case.patient;

    const ssn =
      patient.socialSecurityNumber && patient.socialSecurityNumber.length > 4
        ? `${patient.socialSecurityNumber.substring(patient.socialSecurityNumber.length - 4)}`
        : '';

    const email =
      patient.contactInfos && patient.contactInfos.length
        ? patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.email)?.contactString ??
        null
        : null;

    const phone =
      patient.contactInfos && patient.contactInfos.length
        ? patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
        : null;

    const age = getAgeString(patient.dateOfBirth);

    const dob = patient.dateOfBirth
      ? `${getDateString(patient.dateOfBirth, 'MM / DD / YYYY')} · ${age}`
      : '';
    const hipaaConsent = this.case.patient.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);

    return {
      title: 'Patient',
      id: 'patient',
      icon: 'person',
      data: [
        {
          title: 'Patient Id',
          value: patient.id,
        },
        {
          title: 'Name',
          value: `${patient.lastName || ''}, ${patient.firstName || ''}`,
        },
        {
          title: 'Date of Birth',
          value: dob,
        },
        {
          title: 'HIPAA Consent',
          value: HIPAAConsent[getConsentValue(hipaaConsent)]
        },
        {
          title: 'Consent Date',
          value: getDateString(hipaaConsent?.dateReceived, 'MM / DD / YYYY'),
        },
        {
          title: 'SSN Last 4 Digits',
          value: ssn,
        },
        {
          title: 'Gender',
          value: PatientGender[patient.gender],
        },
        {
          title: 'Address',
          value: getFullAddress(patient.currentMailingAddress?.address),
        },
        {
          title: `Patient Phone ${phone?.contactMethod ? `(${PatientContactType[phone.contactMethod]})` : ''}`,
          value: formatPhone(phone?.contactString),
        },
        {
          title: 'Best Time to Contact',
          value: BestTimeToContact[patient.bestTimeToContact],
        },
        {
          title: 'Best Method to Contact',
          value: BestMethodToContact[patient.bestMethodToContact],
        },
        {
          title: 'Email',
          value: email,
        },
        {
          title: 'Primary Diagnosis',
          value: this.case.diagnosis?.code,
        },
        {
          title: 'Previous Medical History',
          value: '',
        },
      ],
    };
  }

  getPayerDetailsObject() {
    const patientInsurance = this.case.patientInsurance;

    const data = [
      {
        title: 'Payer Name',
        value: patientInsurance.payerName,
      },
      {
        title: 'Payer Phone',
        value: formatPhone(patientInsurance.payerPhone),
      },
      {
        title: 'Payer Fax',
        value: formatPhone(patientInsurance.payerFax),
      },
      {
        title: 'Plan Name',
        value: patientInsurance.planName,
      },
      {
        title: 'Plan Type',
        value: PlanType[patientInsurance.planType],
      },
      {
        title: 'Plan Phone Number',
        value: formatPhone(patientInsurance.planPhone),
      },
      {
        title: 'Policy Number',
        value: patientInsurance.planNumber,
      },
      {
        title: 'Group Number',
        value: patientInsurance.groupNumber,
      },
      {
        title: 'Policy Holder Name',
        value: patientInsurance.policyHolderName,
      },
      {
        title: 'Pharmacy Benefit Manager (PBM)',
        value: patientInsurance.pbm,
      },
      {
        title: 'PBM Group Number',
        value: patientInsurance.pbmGroupNumber,
      },
      {
        title: 'PBM Phone',
        value: patientInsurance.pbmPhone,
      },
      {
        title: 'PBM Fax',
        value: patientInsurance.pbmFax,
      },
      {
        title: 'Bin',
        value: patientInsurance.rxBIN,
      },
      {
        title: 'PCN',
        value: patientInsurance.rxPCN,
      },
    ];

    const emptyData = [
      {
        title: '',
        value: 'Patient does not have insurance.',
      },
    ];

    return {
      title: 'Payer',
      id: 'payer',
      icon: 'shield',
      data: this.case.patientInsurance.planId ? data : emptyData,
    };
  }

  getPhysicianDetailsObject() {
    const physician = this.case.physician;
    const specialty = physician && physician.taxonomies ? physician.taxonomies.map((x) => x.desc).join(', ') : '';
    const facility = this.case.facility;

    const contact =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.email) ?? null
        : null;

    const phone =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.phone) ?? null
        : null;

    const fax =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.fax) ?? null
        : null;

    return {
      title: 'Physician',
      id: 'physician',
      icon: 'stethoscope',
      data: [
        {
          title: 'HCP Name',
          value: `${physician?.firstName || ''} ${physician?.lastName || ''}`,
        },
        {
          title: 'NPI',
          value: physician?.npi,
        },
        {
          title: 'HCP Specialty',
          value: specialty,
        },
        {
          title: 'DEA',
          value: physician?.dea,
        },
        {
          title: 'SLN',
          value: physician?.sln,
        },
        {
          title: 'Office Name',
          value: facility?.name,
        },
        {
          title: 'Office Contact',
          value: contact?.name,
        },
        {
          title: 'Office Contact Emai',
          value: contact?.contactString,
        },
        {
          title: 'Office Address',
          value: getFullAddress(facility?.address),
        },
        {
          title: 'Office Contact Phone',
          value: formatPhone(phone?.contactString),
        },
        {
          title: 'Office Contact Fax',
          value: formatPhone(fax?.contactString),
        },
      ],
    };
  }

  getPrescriptionsObject() {
    const prescription = this.case.prescription;

    return {
      title: 'Prescription',
      id: 'prescription',
      icon: 'pill',
      data: [
        {
          title: 'Product Name',
          value: prescription.product?.name,
        },
        {
          title: 'Product NDC',
          value: prescription.product?.code,
        },
        {
          title: 'Dosage',
          value: prescription.dosage,
        },
        {
          title: 'Quantity',
          value: prescription.quantity,
        },
        {
          title: 'Refills Written',
          value: prescription.refillsNumber,
        },
        {
          title: 'Prescription Signed',
          value:
            prescription.physicianSignature === true
              ? 'Yes'
              : prescription.physicianSignature === false
              ? 'No'
              : '',
        },
        {
          title: 'Physician Signature Date',
          value: getDateString(prescription.physicianSignatureDate, 'MM / DD / YYYY'),
        },
      ],
    };
  }

  getShippingObject() {
    return {
      title: 'Payment Details',
      id: 'shipping',
      icon: 'shipping',
      data: [],
    };
  }

  getAttachmentsObject() {
    return {
      title: 'Attachments',
      id: 'attachments',
      icon: 'attachment',
      data: [],
    };
  }

  getNotesObject() {
    const groupped = this.groupCaseTasks((item) =>
      item.outcomeNote && item.outcomeNote.trim() && item.completed, (a, b) => a.completed < b.completed
    );

    const notesObject = {
      title: 'Notes',
      id: 'notes',
      icon: 'notes',
      data: {
        count: 0,
        items: []
      },
    };

    for (const key in groupped) {
      if (key && groupped[key]?.length) {
        notesObject.data.items.push({
          phase: key,
          notes: groupped[key].map(item => {
            return {
              ...item,
              task: interpolateTemplate(item.task, {
                reason: item.followUpReason,
                contactMethod: TaskContactMethodNames[item.contactMethod] || '',
                entity: TargetNames[item.target] || ''
              }),
              icon: this.getNoteIcon(item.contactMethod, item.direction)
            };
          })
        });
        notesObject.data.count += groupped[key].length;
      }
    }

    return notesObject;
  }

  getNoteIcon(method, direction) {
    const result = {
      value: '',
      isImage: false,
    };

    if (method === ContactMethod.phone) {
      // tslint:disable-next-line
      result.value = direction === TaskDirectionNames['In']
        ? '../../../../assets/icons/icon-phone-incoming.svg'
        : '../../../../assets/icons/icon-phone-outgoing.svg';
      result.isImage = true;
    } else if (method === ContactMethod.fax) {
      result.value = 'print';
    } else if (method === ContactMethod.email) {
      result.value = 'email';
    } else if (method === ContactMethod.text) {
      result.value = 'textsms';
    }

    return result;
  }

  private setCurrentConsents(): void {
    this.case.patient.currentConsents.forEach((el: IConsent) => {
      switch (el.consentTypeId) {
        case ConsentTypes.hipaa:
          this.currentConsents.hipaaConsent = el;
          break;
        case ConsentTypes.program:
          this.currentConsents.programConsent = el;
          break;
        case ConsentTypes.marketing:
          this.currentConsents.marketingConsent = el;
          break;
        case ConsentTypes.texting:
          this.currentConsents.textingConsent = el;
          break;
        case ConsentTypes.voicemail:
          this.currentConsents.voicemailConsent = el;
          break;
      }
    });
  }
}
