<div mat-dialog-title>
    <h1><img alt="age" src="../../../../../../assets/icons/icon-age-red.svg" />Attempts Exhausted</h1>
    <button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <p>You’ve reached the <b>maximum</b> amount of <b>attempts</b> for this task. What would you like to do?</p>
</div>
<div mat-dialog-actions>
    <button mat-button class="back" mat-dialog-close (click)="rescheduleTask(data)">
        <img alt="back" src="../../../../../../assets/icons/icon-age-blue.svg" />Manual Follow-Up
    </button>
    <button mat-button mat-dialog-close (click)="escalateToSupervisorHandler()">Escalate to Supervisor <mat-icon>east
        </mat-icon></button>
</div>