import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { QueueService } from './dashboard.service';
import { IUserQueue } from './dashboard.models';
import * as DocumentAction from '../../../store/document/document.actions';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-intake-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class IntakeDashboardComponent implements OnInit {
    queueList: IUserQueue[];
    intakeQueueList: IUserQueue[];

    constructor(private store: Store<any>, public queueService: QueueService) {
        this.store.dispatch(new DocumentAction.SetDocumentsCount(null));
    }

    ngOnInit() {
        this.queueService.getIntakeQueues().subscribe((response) => {
            this.intakeQueueList = response;
        }, error => throwError(error));

        this.queueService.getCaseManagementQueues().subscribe((response) => {
            this.queueList = response;
        }, error => throwError(error));
    }
}
