import { Component, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SupervisorTaskColumns } from '../../../../shared/enums/enums';
import { enumToArray } from '../../../../shared/helpers/utils';
import { SupervisorAddTaskComponent } from './add/add.component';
import { SupervisorDeleteTaskComponent } from './delete-dialog/delete-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { ISupervisorTask } from 'src/app/shared/interfaces/interfaces';

@Component({
    selector: 'app-supervisor-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['../users/users.component.scss', './tasks.component.scss'],
})
export class SupervisorTasksComponent implements AfterViewInit {
    @Output() taskRemoved = new EventEmitter<number>();
    @Output() tasksSaved = new EventEmitter<ISupervisorTask[]>();

    _tasks: ISupervisorTask[] = [];
    columnsToDisplay = enumToArray(SupervisorTaskColumns);
    dataSource;

    @Input()
    get tasks() { return this._tasks; }
    set tasks(tasks) {
        if (this._tasks.length != 0 || tasks.length != 0) {
            this._tasks = tasks;
            this.dataSource = new MatTableDataSource(this._tasks);
            if (this.sort) {
                this.dataSource.sort = this.sort;
            }
            if (this.paginator) {
                this.dataSource.paginator = this.paginator;
            }
        }
    }

    constructor(public dialog: MatDialog) { }

    removeTask(task) {
        let dialogRef = this.dialog.open(SupervisorDeleteTaskComponent, {
            data: {
                task
            },
            panelClass: 'removeDialog'
        });
        const sub = dialogRef.componentInstance.taskDeleted.subscribe((id) => {
            this.taskRemoved.emit(id);
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }

    confirmRemoveTask() {

    }

    addTask() {
        let dialogRef = this.dialog.open(SupervisorAddTaskComponent, {
            data: {
                addedTasks: this.tasks
            }, panelClass: 'addDialog'
        });
        const sub = dialogRef.componentInstance.tasksSaved.subscribe((users) => {
            this.tasksSaved.emit(users);
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        if (this.dataSource) {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }
}
