<form [formGroup]="newOptInForm" class="enrollment create-form form-row date-row">

  <h3>New {{type}} Opt-In</h3>

  <div class="form-row half-width">
    <div class="input-item select" [class]="
          newOptInForm.controls['consentMethod'].invalid &&
          (newOptInForm.controls['consentMethod'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="consentMethod">Method Received</label>
      <mat-form-field appearance="fill">
        <mat-select formControlName="consentMethod" name="consentMethod" placeholder="Select" id="consentMethod"
          disableOptionCentering #consentMethod panelClass="dropdown-item">
          <mat-option [value]="MethodReceived.Verbal">Verbal</mat-option>
          <mat-option [value]="MethodReceived.Written">Written</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="input-item date-input" [class]="
          newOptInForm.controls['effectiveFrom'].invalid &&
          (newOptInForm.controls['effectiveFrom'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="effectiveFrom">{{type}} Opt-In Effective Through</label>
      <input
        matInput
        [matDatepicker]="effectiveFromDatepicker"
        id="effectiveFrom"
        formControlName="effectiveFrom"
        placeholder="MM/DD/YY"
        [min]="effectiveFromMin"
        [max]="newOptInForm.get('effectiveTo').value"
        (focus)="effectiveFromDatepicker.open()"
        (click)="effectiveFromDatepicker.open()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="effectiveFromDatepicker"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #effectiveFromDatepicker></mat-datepicker>
    </div>
    <div class="long-dash">&mdash;</div>
    <div class="input-item date-input" [class]="
          newOptInForm.controls['effectiveTo'].invalid &&
          (newOptInForm.controls['effectiveTo'].touched)
              ? 'input-error'
              : ''
        ">
      <input
        matInput
        [matDatepicker]="effectiveToDatepicker"
        id="effectiveTo"
        formControlName="effectiveTo"
        placeholder="MM/DD/YY"
        [min]="newOptInForm.get('effectiveFrom').value"
        (focus)="effectiveToDatepicker.open()"
        (click)="effectiveToDatepicker.open()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="effectiveToDatepicker"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #effectiveToDatepicker></mat-datepicker>
    </div>
  </div>

  <div class="controls default-form fx-row">
    <button class="cancel-btn" type="button" mat-button (click)="cancelled.emit()">
      exit
    </button>

    <button [disabled]="newOptInForm.invalid" class="save-btn" mat-button type="button"
      (click)="submitted.emit(newOptInForm.getRawValue())">
      save
    </button>
  </div>

</form>