import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TextTaskComponent } from '../text/text.component';
import * as moment from 'moment';
import { ContactMethod } from 'src/app/shared/enums/enums';

@Component({
    selector: 'app-reschedule-task',
    templateUrl: './reschedule.component.html',
    styleUrls: ['./reschedule.component.scss'],
})
export class RescheduleTaskComponent implements OnInit {
    @Output() scheduleFollowUp = new EventEmitter();
    rescheduleForm: FormGroup;
    tomorrow;
    currentDate = new Date();

    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog) {
        this.tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        this.rescheduleForm = new FormGroup({
            creation: new FormControl(data.type, [Validators.required]),
            attemptDate: new FormControl(this.tomorrow, [Validators.required]),
            attemptTime: new FormControl('1:00 PM', [Validators.required]),
            attemptType: new FormControl(data.defaultType ? data.defaultType : ContactMethod.phone.toString(), [Validators.required]),
        });
    }

    scheduleFollowUpHandler(additionalData) {
        let date = moment(this.rescheduleForm.value.attemptDate);
        let time = moment(this.rescheduleForm.value.attemptTime, 'h:mm A');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: 0
        });
        let data = {
            rescheduleDate: date.format(),
            outcomeNote: additionalData?.outcomeNote,
            contactMethod: this.rescheduleForm.value.attemptType
        };

        this.scheduleFollowUp.emit(data);
    }

    rescheduleTask(data) {
        if (this.rescheduleForm.value.attemptType == ContactMethod.text) {
            let dialogRef;
            dialogRef = this.dialog.open(TextTaskComponent, { data });
            const dialog: any = dialogRef.componentInstance;
            dialog.setSelectedTask.subscribe((task) => {
                this.scheduleFollowUpHandler(data);
            });
        } else {
            this.scheduleFollowUpHandler(data);
        }
    }

    ngOnInit(): void { }
}
