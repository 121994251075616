import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CreationSteps } from '../../../shared/enums/enums';
import { enumToArray } from '../../../shared/helpers/utils';
import { QueueCreateService } from './creation.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-creation',
    templateUrl: './creation.component.html',
    styleUrls: ['./creation.component.scss'],
})
export class QueueCreationComponent implements OnInit {
    settings = {
        steps: enumToArray(CreationSteps),
        activeStep: 'setup',
        setup: {
            name: '',
            description: '',
            configuration: 'pull',
        },
        selectedTasks: [],
        selectedTerritories: [],
        selectedUsers: [],
    };
    
    @ViewChild('nextBtn') nextBtn: ElementRef;    

    setSetupValue(setup) {
        this.settings.setup.name = setup.name.trim();
        this.settings.setup.description = setup.description.trim();
        this.settings.setup.configuration = setup.configuration;
    }

    setSelectedTasks(tasks) {
        this.settings.selectedTasks = tasks;
    }

    setSelectedTerritories(territories) {
        this.settings.selectedTerritories = territories;
    }

    setSelectedUsers(users) {
        this.settings.selectedUsers = users;
    }

    constructor(public queueCreateService: QueueCreateService, private router: Router) {}

    setStep(direction) {
        const currentIndex = this.settings.steps.findIndex((step) => step == this.settings.activeStep);
        if (direction == 'next') {
            if (this.settings.activeStep != 'users') {
                this.settings.activeStep = String(this.settings.steps[currentIndex + 1]);
            } else {
                this.submitQueue();
            }
        } else {
            this.settings.activeStep = String(this.settings.steps[currentIndex - 1]);
        }
    }

    submitQueue() {
        const data = {
            queueConfiguration: {
                name: this.settings.setup.name,
                description: this.settings.setup.description,
                userCanPull: this.settings.setup.configuration == 'pull' ? true : false,
                userIDs: [...this.settings.selectedUsers],
                territoryIDs: [...this.settings.selectedTerritories],
                queueItemTaskIDs: [...this.settings.selectedTasks],
            },
        };
        this.queueCreateService.saveQueue(data).subscribe(
            (response) => {
                this.router.navigate(['/manage-queue'], { queryParams: { id: response.value }});
            },
            (error) => throwError(error)
        );
    }

    skipStep() {
        switch (this.settings.activeStep) {
            case 'tasks':
                this.settings.selectedTasks = [];
                break;
            case 'territories':
                this.settings.selectedTerritories = [];
                break;
            case 'users':
                this.settings.selectedUsers = [];
                break;
            default:
                break;
        }
        this.setStep('next');
    }

    setNextButtonText() {
        switch (this.settings.activeStep) {
            case 'setup':
                return 'Add Tasks';
            case 'tasks':
                return 'Select Territories';
            case 'territories':
                return 'Assign Users';
            default:
                return 'Complete';
        }
    }

    isStepDone(index) {
        const activeStepIndex = this.settings.steps.findIndex((currentStep) => currentStep == this.settings.activeStep);
        if (index < activeStepIndex) {
            return true;
        } else {
            return false;
        }
    }

    focusNextBtn(){
        this.nextBtn.nativeElement.focus();
    }

    ngOnInit(): void {}
}
