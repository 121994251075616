export const environment = {
    oidc: {
        clientId: `0oaken1mbeJIwCFcU4x6`,
        issuer: `https://dev-253390.okta.com/oauth2/default`,
        redirectUri: `${window.location.origin}/implicit/callback`,
        scopes: ['openid', 'profile', 'email'],
        testing: {
            disableHttpsCheck: `true`,
        },
    },
    resourceServer: {
        apiUrl: 'https://staging-api.p3casemanagement.com/api/v1',
    },
    production: false,
    googlePlaceAPIKey: 'AIzaSyBO9kKeAZLvfnmKnFDl374XAaj0Q3m1zjw'
};
