import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './core/guards/role.guard';
import { HomeComponent } from './features/home/home.component';
import { DocumentDataComponent } from './features/intake/document/data/data.component';
import { DocumentComponent } from './features/intake/document/document.component';
import { DocumentTypeComponent } from './features/intake/document/type/type.component';
import { OktaCallbackComponent } from './features/login/callback.component';
import { LoginComponent } from './features/login/login.component';
import { ManagerDashboardCaseComponent } from './features/manager/case/case.component';
import { ManagerQueueDataComponent } from './features/manager/queue/data/data.component';
import { ManagerQueueIdentifyComponent } from './features/manager/queue/identify/identify.component';
import { ManagerQueueComponent } from './features/manager/queue/queue.component';
import { ManagerQueueTaskComponent } from './features/manager/queue/task/task.component';
import { ProfileComponent } from './features/profile/profile.component';
import { QueueCreationComponent } from './features/supervisor/creation/creation.component';
import { SupervisorManageQueueComponent } from './features/supervisor/manage/manage.component';
import { SupervisorQueueComponent } from './features/supervisor/queue/queue.component';
import { SupervisorQueueTaskComponent } from './features/supervisor/queue/task/task.component';
import { TestComponent } from './features/test/test.component';
import { SearchComponent } from './shared/components/search/search.component';
import { Roles } from './shared/enums/enums';

// TODO: rewrite for lazy-loading and split with modules
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor, Roles.Manager, Roles.Intake]},
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor, Roles.Manager, Roles.Intake]},
  },
  {
    path: 'case',
    component: ManagerDashboardCaseComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor, Roles.Manager]},
  },
  {
    path: 'manage-queue',
    component: SupervisorManageQueueComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor]},
  },
  {
    path: '',
    component: ManagerQueueComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Manager, Roles.Supervisor]},
    children: [
      {
        path: 'queue-identify',
        component: ManagerQueueIdentifyComponent,
      },
      {
        path: 'queue-data-entry',
        component: ManagerQueueDataComponent,
      },
      {
        path: 'queue-manager-task',
        component: ManagerQueueTaskComponent,
      },
    ],
  },
  {
    path: '',
    component: SupervisorQueueComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor]},
    children: [
      {
        path: 'queue-supervisor-task',
        component: SupervisorQueueTaskComponent,
      },
    ],
  },
  {
    path: 'create-queue',
    component: QueueCreationComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor]},
  },
  {
    path: '',
    component: DocumentComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Intake, Roles.Manager, Roles.Supervisor]},
    children: [
      {
        path: 'document-identify',
        component: DocumentTypeComponent,
      },
      {
        path: 'document-data-entry',
        component: DocumentDataComponent,
      },
    ],
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Manager, Roles.Supervisor]},
  },
  {
    path: 'reports',
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor, Roles.Manager]},
    loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.Admin, Roles.Supervisor, Roles.Manager, Roles.Intake]},
  },
  {path: '**', redirectTo: '/error/404'},
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
