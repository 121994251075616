import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ITerritory } from '../../../../../shared/interfaces/interfaces';
import { SupervisorAddTerritoryColumns } from '../../../../../shared/enums/enums';
import { enumToArray } from '../../../../../shared/helpers/utils';
import { QueueCreateService } from '../../../creation/creation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-supervisor-task-add',
    templateUrl: './add.component.html',
    styleUrls: ['../../users/users.component.scss', '../../users/add/add.component.scss', './add.component.scss'],
})
export class SupervisorAddTerritoryComponent implements OnInit {

    @Output() territoriesSaved = new EventEmitter<ITerritory[]>();

    searchValue = '';
    columnsToDisplay = enumToArray(SupervisorAddTerritoryColumns);
    addedTerritories: ITerritory[] = [];
    data = [];
    dataSource;
    isLoading: boolean = false;
    isAllSelected = false;

    checkTerritory(task) {
        if (!this.isSelected(task.id)) {
            this.addedTerritories.push(task);
        }
        else {
            this.addedTerritories = this.addedTerritories.filter(item => item.id !== task.id);
        }
    }

    isSelected(id) {
        return this.addedTerritories.some(x => x.id == id);
    }

    selectAll() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.addedTerritories = this.data;
        } else {
            this.addedTerritories = [];
        }
    }

    saveTerritories() {
        this.territoriesSaved.emit(this.addedTerritories);
    }

    constructor(public queueCreateService: QueueCreateService, @Inject(MAT_DIALOG_DATA) public dialogData) {
        this.addedTerritories = [...dialogData.addedTerritories];
    }

    clearSearch() {
        this.searchValue = '';
        this.getTerritories();
    }

    getTerritories() {
        const data = { take: 0, search: this.searchValue };
        this.isLoading = true;
        this.queueCreateService.getTerritories(data).subscribe(
            (response) => {
                this.data = response.value;
                this.dataSource = new MatTableDataSource(this.data);
            },
            (error) => throwError(error),
            () => (this.isLoading = false)
        );
    }

    ngOnInit() {
        this.getTerritories();
    }
}
