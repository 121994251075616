import { Component, OnInit } from '@angular/core';
import { QueueService } from './queue.service';

@Component({
    selector: 'app-manager-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.scss'],
})
export class ManagerQueueComponent implements OnInit {
    queueId: number;

    constructor(public queueService: QueueService) {}

    ngOnInit() {}
}
