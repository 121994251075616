<!--TODO: move side nav to shared and concat with aside.component.html-->
<nav class="side-nav">
  <div class="side-menu">
    <div class="side-icon" *ngFor="let detail of caseDetails" [class.active]="details && details.id === detail.id"
      (click)="drawer.open(); setDetails(detail.id)">
      <img *ngIf="details && details.id === detail.id; else active"
        [src]="'assets/icons/' + detail.icon + '-active.svg'" />
      <ng-template #active>
        <img [src]="'assets/icons/' + detail.icon + '-default.svg'" />
      </ng-template>
    </div>
  </div>
  <div class="search" (click)="openModal()">
    <mat-icon class="search-icon">search</mat-icon>
  </div>
  <div class="home">
    <a routerLink="/">
      <mat-icon class="home-icon">home</mat-icon>
    </a>
  </div>
  <div *ngIf="user" class="user">
    <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-button">
      <div class="user-img" *ngIf="user && user.img.length; else initials">
        <img [src]="user.img" alt="user.name" />
      </div>
      <ng-template #initials>
        <div class="user-initials">{{ user ? user.name.slice(0, 1) : null }}</div>
      </ng-template>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="after" yPosition="above">
      <a mat-menu-item routerLink="/profile">Profile</a>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</nav>

<!--TODO: make a content as a router-outlet inner?-->
<!--TODO: split details to components-->
<mat-drawer-container class="drawer-container" [autosize]="true">
  <mat-drawer #drawer class="drawer-sidenav" [class]="details && details.id" mode="side" opened="true">
    <div *ngIf="details">
      <!-- <button type="button" mat-button>
          <mat-icon>edit</mat-icon>
      </button> -->

      <!--Section KEY DETAILS-->
      <ng-container *ngIf="details.id === 'keyDetails'">
        <h2>{{ details.title }}</h2>
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <div *ngFor="let detail of details.data" class="detail-item">
              <h3>{{ detail.title }}</h3>
              <p>{{ detail.value | empty }}</p>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section PATIENT-->
      <app-section-patient
        *ngIf="details.id === 'patient'"
        (update)="sectionUpdate($event, 'patient')"
        (openManagement)="openEnrollmentItem($event)"
        [currentConsents]="currentConsents"
        [patient]="case?.patient"
      ></app-section-patient>

      <!--Section PAYER-->
      <ng-container *ngIf="details.id === 'payer'">
        <h2>{{ details.title }}</h2>
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <div *ngFor="let detail of details.data" class="detail-item">
              <h3>{{ detail.title }}</h3>
              <p>{{ detail.value | empty }}</p>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section PHYSICIAN-->
      <app-section-physician *ngIf="details.id === 'physician'" (update)="facilityUpdate($event)"
        (openManagement)="openPhysicianEditDialog($event)" [physician]="case?.physician" [facility]="case?.facility">
      </app-section-physician>

      <!--Section PRESCRIPTION-->
      <ng-container *ngIf="details.id === 'prescription'">
        <h2>{{ details.title }}</h2>
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <div *ngFor="let detail of details.data" class="detail-item">
              <h3>{{ detail.title }}</h3>
              <p>{{ detail.value | empty }}</p>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section NOTES-->
      <app-section-notes *ngIf="details.id === 'notes'" [initialData]="details.data"></app-section-notes>

      <!--Section ATTACHMENTS-->
      <ng-container *ngIf="details.id === 'attachments'">
        <h2>{{ details.title }}</h2>
        <div class="attachment-item">
          <div class="attachment-img">
            <img src="../../../../assets/images/enrollment-form.jpg" alt="attachment">
          </div>
          <div class="attachment-description">
            <h5>Enrollment Form</h5>
            <p>EnrollmentForm_{{case && case.tasks && case.tasks[0].createDate | date: "M-d-yyyy"}}.pdf</p>
          </div>
        </div>
      </ng-container>

    </div>
  </mat-drawer>
  <div class="case-navigation">
    <app-case-navigation *ngIf="case && (!selectedTask || selectedTask.minimized)" [phaseList]="phaseList"
      [shownPhase]="shownPhase" (showPhase)="showPhaseHandler($event)"></app-case-navigation>
  </div>
  <div [ngSwitch]="shownPhase">
    <app-case-benefit *ngSwitchCase="'benefit'" [case]="case"></app-case-benefit>
    <app-case-enrollment (updateCase)="getCase(case.id)"
                         *ngSwitchCase="'enrollment'" [panel]="panel" [case]="case"></app-case-enrollment>
    <app-case-prior *ngSwitchCase="'prior'" [case]="case"></app-case-prior>
    <app-case-fulfillment *ngSwitchCase="'fulfillment'" [case]="case"></app-case-fulfillment>
    <div *ngSwitchDefault>
      <ng-container *ngIf="!selectedTask || selectedTask.minimized">
        <div class="current-tasks-container">
          <app-manager-case-timeline [activeTasks]="activeTasks" (createActivity)="createActivityDialog()"
            (setSelectedTaskHandler)="setSelectedTask($event)" *ngIf="case"></app-manager-case-timeline>
        </div>
        <app-case-history [historyTimeline]="historyTimeline" [completedTasks]="completedTasks" [case]="case"
          *ngIf="case">
        </app-case-history>
      </ng-container>
      <app-case-task *ngIf="selectedTask && !selectedTask.minimized" [selectedTask]="selectedTask" [case]="case"
        (minimizeTaskHandler)="minimizeTask($event)" (setSelectedTaskHandler)="setSelectedTask($event)"
        (submitTaskHandler)="submitTask($event)">
      </app-case-task>
    </div>
  </div>
  <div *ngIf="!case" class="case-loader-container">
    <app-loader></app-loader>
  </div>
</mat-drawer-container>

<!-- <app-manager-case-notes></app-manager-case-notes> -->

<div id="collapsedTask" *ngIf="selectedTask && selectedTask.minimized" (click)="minimizeTask(false)">
  <div class="task-name">
    <h5>Task</h5>
    <i>{{ selectedTask.task }}</i>
  </div>
  <mat-icon>open_in_full</mat-icon>
</div>
