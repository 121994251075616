<div class="queue-title-wrap">
    <div class="breadcrumbs">
        <ul>
            <li>
                <a routerLink="/"><img src="../../../../assets/icons/icon-arrow-long-right-breadcrumbs.svg"
                        alt="back" /> WORK
                    QUEUES</a>
            </li>
            <li>Case Mgmt. Tasks</li>
        </ul>
    </div>
    <div class="queue-title" *ngIf="queueConfig.id">
        <div class="title-wrapper">
            <h2>{{ queueConfig.name }}</h2>
            <!-- <div class="users-assigned">
                <ul>
                    <li
                        *ngFor="let user of usersAssigned; index as i"
                        [style]="'z-index:' + (usersAssigned.length - i)"
                        [title]="user.firstName + ' ' + user.lastName"
                    >
                        <img *ngIf="user.userImageS3Url" [src]="user.userImageS3Url" />
                        <mat-icon *ngIf="!user.userImageS3Url" class="default-user">account_circle</mat-icon>
                    </li>
                </ul>
                <p>
                    <b>{{ usersAssigned.length }}</b> Assigned
                </p>
            </div> -->
            <div class="filters">
                <div class="input-item select">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="taskType" name="taskType" placeholder="Task Type" id="taskType"
                            disableOptionCentering panelClass="dropdown-item type" (selectionChange)="selectType()"
                            (openedChange)="closeAllFilters()">
                            <mat-option>--</mat-option>
                            <mat-option [value]="0">Patient </mat-option>
                            <mat-option [value]="1">Payer</mat-option>
                            <mat-option [value]="2">Physician</mat-option>
                            <mat-option [value]="3">Pharmacy</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button class="primary-btn invert clear-all-filters" *ngIf="!isFiltersNotUsed"
                    (click)="clearAllFilters()">
                    Clear All Filters <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="title-buttons">
            <button routerLink="/case" [disabled]="!data.length">
                Begin
                <img *ngIf="data.length" src="../../../../../assets/icons/icon-arrow-long-right.svg" alt="begin" />
                <img *ngIf="!data.length" src="../../../../../assets/icons/icon-arrow-long-right-gray.svg"
                    alt="begin" />
            </button>
            <a routerLink="/manage-queue" *ngIf="isSupervisor()" [queryParams]="{ id: queueConfig.id }">Manage <img
                    src="../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="view" /></a>
        </div>
    </div>
</div>

<div *ngIf="!isLoaded || !queueConfig.id" class="dashboard-loader-container">
    <app-loader></app-loader>
</div>

<table [hidden]="!data.length" mat-table [dataSource]="dataSource" matSort matSortActive="dueDate"
    matSortDirection="asc">
    <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Phase</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.phase != !!settings.filter.initialValue.phase">
                    <button class="filter-button" (click)="openFilter('phase')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.phase != !!settings.filter.initialValue.phase"
                        (click)="clearFilter('phase')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.phase">
                    <div class="filter-title">
                        <h5>Phase</h5>
                        <button (click)="closeFilter('phase')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="checkbox-item filter-checkbox" *ngFor="let phase of phases">
                            <mat-checkbox [(ngModel)]="settings.filter.value.phase[phase]">{{
                                phasesNames[phase]
                                }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('phase')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('phase')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.phase }}
        </td>
    </ng-container>
    <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Task</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.task != !!settings.filter.initialValue.task">
                    <button class="filter-button" (click)="openFilter('task')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.task != !!settings.filter.initialValue.task"
                        (click)="clearFilter('task')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.task">
                    <div class="filter-title">
                        <h5>Task</h5>
                        <button (click)="closeFilter('task')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="checkbox-item filter-checkbox" *ngFor="let task of tasks">
                            <mat-checkbox [(ngModel)]="settings.filter.value.task[task]">{{
                                tasksNames[task]
                                }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('task')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('task')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.task }}
        </td>
    </ng-container>
    <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Patient</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.patient != !!settings.filter.initialValue.patient">
                    <button class="filter-button" (click)="openFilter('patient')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.patient != !!settings.filter.initialValue.patient"
                        (click)="clearFilter('patient')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.patient">
                    <div class="filter-title">
                        <h5>Patient</h5>
                        <button (click)="closeFilter('patient')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="input-item">
                            <input name="patient" id="patient" [(ngModel)]="settings.filter.value.patient" />
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('patient')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('patient')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.patient }}
        </td>
    </ng-container>
    <ng-container matColumnDef="physician">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Physician</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.physician != !!settings.filter.initialValue.physician">
                    <button class="filter-button" (click)="openFilter('physician')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.physician != !!settings.filter.initialValue.physician"
                        (click)="clearFilter('physician')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.physician">
                    <div class="filter-title">
                        <h5>Physician</h5>
                        <button (click)="closeFilter('physician')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="input-item">
                            <input name="physician" id="physician" [(ngModel)]="settings.filter.value.physician" />
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('physician')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('physician')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.physician }}
        </td>
    </ng-container>
    <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Payer</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.payer != !!settings.filter.initialValue.payer">
                    <button class="filter-button" (click)="openFilter('payer')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.payer != !!settings.filter.initialValue.payer"
                        (click)="clearFilter('payer')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.payer">
                    <div class="filter-title">
                        <h5>Payer</h5>
                        <button (click)="closeFilter('payer')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="input-item">
                            <input name="payer" id="payer" [(ngModel)]="settings.filter.value.payer" />
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('payer')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('payer')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.payer }}
        </td>
    </ng-container>
    <ng-container matColumnDef="pharmacy">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Pharmacy</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.pharmacy != !!settings.filter.initialValue.pharmacy">
                    <button class="filter-button" (click)="openFilter('pharmacy')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.pharmacy != !!settings.filter.initialValue.pharmacy"
                        (click)="clearFilter('pharmacy')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.pharmacy">
                    <div class="filter-title">
                        <h5>Pharmacy</h5>
                        <button (click)="closeFilter('pharmacy')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="input-item">
                            <input name="pharmacy" id="pharmacy" [(ngModel)]="settings.filter.value.pharmacy" />
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('pharmacy')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('pharmacy')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            {{ queue.pharmacy }}
        </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef>
            <div class="filter-header">
                <div mat-sort-header>Due</div>
                <div class="filter-buttons"
                    [class.active]="!!settings.filter.value.dueDate != !!settings.filter.initialValue.dueDate">
                    <button class="filter-button" (click)="openFilter('dueDate')">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                    <button class="clear-button"
                        *ngIf="!!settings.filter.value.dueDate != !!settings.filter.initialValue.dueDate"
                        (click)="clearFilter('dueDate')">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="filter-wrapper" *ngIf="settings.filter.show.dueDate">
                    <div class="filter-title">
                        <h5>Due</h5>
                        <button (click)="closeFilter('dueDate')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <div class="filter-body">
                        <div class="date-range">
                            <mat-form-field appearance="fill">
                                <mat-label>Enter a date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate placeholder="Start date"
                                        [(ngModel)]="settings.filter.value.dueDate.start" />
                                    <input matEndDate placeholder="End date"
                                        [(ngModel)]="settings.filter.value.dueDate.end" />
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="filter-controls">
                        <button class="clear" (click)="clearFilter('dueDate')">Clear</button>
                        <div class="primary-btn" (click)="applyFilter('dueDate')">Filter</div>
                    </div>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let queue">
            <div class="lock-wrapper" [style]="queue.queueStatus == 1 ? 'display: flex;align-items: center;' : ''">
                <div *ngIf="queue.queueStatus == 1" class="lock-container">
                    <mat-icon class="lock">lock</mat-icon>
                    <img *ngIf="queue.userImageS3Url" [src]="queue.userImageS3Url" />
                    <mat-icon *ngIf="!queue.userImageS3Url" class="default-user">account_circle</mat-icon>
                </div>
                <div class="lock-text">
                    <i *ngIf="queue.queueStatus == 1">In Progress</i><img
                        src="../../../../../assets/icons/icon-warning.svg" alt="warning"
                        *ngIf="isOverdue(queue.dueDate)" />
                    <mat-icon *ngIf="!isOverdue(queue.dueDate) && isToday(queue.dueDate)">error</mat-icon>{{
                    queue.dueDate | date: "E, M/d - hh:mmaaaaa" }}
                </div>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let queue; columns: columnsToDisplay" [class.locked]="queue.queueStatus == 1"
        [class.userpush]="!queueConfig.userCanPull && userRole != 'Supervisor'" (click)="openItem(queue)"></tr>
    <tr class="mat-row no-data" *matNoDataRow>
        <td class="mat-cell" colspan="7">No data matching the filter</td>
    </tr>
</table>
<mat-paginator style="display: none" [pageSize]="settings.itemPerPage"></mat-paginator>
<app-pagination mat-paginator *ngIf="data.length && isLoaded"
    [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'" [settings]="settings"
    (setActivePageHandler)="setActivePage($event)"></app-pagination>
<app-empty-queue *ngIf="!data.length && isLoaded" type="tasks"></app-empty-queue>