import { DocumentTypes, DocumentActions } from './document.actions';

export let initialState = {
    documentList: null,
    selectedDocument: 0,
    grid: false,
    documentsCount: null,
    updateDocumentBatch: false,
    documentsBatchId: null,
};

export function reducer(state = initialState, action: DocumentActions) {
    switch (action.type) {
        case DocumentTypes.SET_DOCUMENTS_LIST:
            return { ...state, documentList: action.payload };
        case DocumentTypes.SET_SELECTED_DOCUMENT:
            return { ...state, selectedDocument: action.payload };
        case DocumentTypes.SET_GRID:
            return { ...state, grid: action.payload };
        case DocumentTypes.SET_DOCUMENTS_COUNT:
            return { ...state, documentsCount: action.payload };
        case DocumentTypes.SET_DOCUMENTS_BATCH_ID:
            return { ...state, documentsBatchId: action.payload };
        case DocumentTypes.UPDATE_DOCUMENTS_BATCH:
            return { ...initialState, updateDocumentBatch: action.payload };
        case DocumentTypes.RESTORE_DOCUMENTS:
            return { ...initialState };
        default:
            return state;
    }
}
