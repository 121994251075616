import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { FaxPipe } from './pipes/fax.pipe';
import { FullAddressPipe } from './pipes/fulladdress.pipe';
import { PhonePipe } from './helpers/phone.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { NewPhysicianFormComponent } from './components/new-physician-form/new-physician-form.component';
import { NewFacilityFormComponent } from './components/new-facility-form/new-facility-form.component';
import { ContactStringPipe } from './pipes/contact-string.pipe';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PageStepperComponent } from './components/page-stepper/page-stepper.component';
import { OptionChipComponent } from './components/option-chip/option-chip.component';
import { LottieModule } from 'ngx-lottie';
import { LoaderComponent } from './components/loader/loader.component';

const components = [
  NewPhysicianFormComponent,
  NewFacilityFormComponent,
  BreadcrumbsComponent,
  PageStepperComponent,
  OptionChipComponent,
];

const pipes = [
  EmptyPipe,
  CapitalizePipe,
  FullAddressPipe,
  PhonePipe,
  FaxPipe,
  ContactStringPipe,
  LoaderComponent
];

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    ...components,
    ...pipes,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule,
    MatIconModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true, })
  ],
  exports: [
    ...components,
    ...pipes,
    CommonModule,
    RouterModule,
    MatIconModule,
  ],
  providers: []
})
export class SharedModule { }
