import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-missed-case-modal',
    templateUrl: './missed.component.html',
    styleUrls: ['./missed.component.scss'],
})
export class MissedCaseModalComponent implements OnInit {
    @Output() switchTab = new EventEmitter<boolean>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data) { }

    switchTabHandler() {
        this.switchTab.emit(true);
    }

    ngOnInit(): void {}
}
