import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsuranceFormFields } from '../form.model';
import { DataEntryFormService } from '../form.service';
import { throwError } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { NgScrollbar } from 'ngx-scrollbar';
import * as moment from 'moment';
import { isFilled, phoneValidator } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-intake-document-insurance-form',
    templateUrl: './insurance.component.html',
    styleUrls: ['../form.component.scss', '../physician/physician.component.scss', './insurance.component.scss'],
})
export class InsuranceComponent implements OnInit {
    @Input() settings;
    @Output() submitForm = new EventEmitter();
    @Output() setPatient = new EventEmitter();
    @Output() validateForm = new EventEmitter();
    @Output() updateCase = new EventEmitter();
    insuranceForm: FormGroup;
    newPlanForm: FormGroup;
    insuranceFormFields = InsuranceFormFields;
    submitted: boolean = false;
    medicalPlans = [];
    selectedPlan = null;
    disableDate = new Date();
    isPlansLoading: boolean = false;
    focusedSearch: number = 0;
    newPlan = false;

    @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
    @ViewChild('nextBtn') nextBtn: ElementRef;
    @ViewChild('searchList') searchList: ElementRef;
    @ViewChild('medicalInsurance') medicalInsurance: MatSelect;

    _case;
    @Input()
    get case() {
        return this._case;
    }

    set case(selectedCase) {
        if (!this._case && selectedCase) {
            this._case = selectedCase;

            this.insuranceForm.patchValue({
                medicalInsurance: selectedCase.patientInsurance && selectedCase.patientInsurance.planId != null ? 0
                    : (selectedCase.patientInsurance && selectedCase.patientInsurance.patientId != null ? 1 : 2)
            });
            this.selectedPlan = selectedCase.patientInsurance && selectedCase.patientInsurance.planId != null && selectedCase.patientInsurance.patientId ? selectedCase.patientInsurance : null;
            this.newPlanForm.patchValue(this.getPatientInsuranceFormObject(this.selectedPlan));
        }
    }

    constructor(public dataEntryService: DataEntryFormService) {
        this.insuranceForm = new FormGroup({
            medicalInsurance: new FormControl('', [Validators.required, isFilled]),
            searchMedicalInsurancePlan: new FormControl(''),
        });

        this.newPlanForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            planId: new FormControl(''),
            group: new FormControl(''),
            policyHolderName: new FormControl(''),
            policyHolderDOB: new FormControl(''),
            planType: new FormControl('', Validators.required),
            planOrder: new FormControl('', Validators.required),
            planPhone: new FormControl('', phoneValidator)
        });
    }

    submitHandler(activeTab = 'physician') {
        this.submitted = true;
        this.updatePlan();
        this.validateForm.emit({ formControls: this.insuranceForm.value.medicalInsurance != 1 ? this.newPlanForm.controls : [], tab: 'insurance' });
        if (this.insuranceForm.value.medicalInsurance == null || this.insuranceForm.value.medicalInsurance === '') {
            return;
        }
        if (!this.settings.validate.invalid.length && this.settings.validate.requiredBlank.length) {
            return this.switchTab(false, activeTab);
        } else if (!this.settings.validate.invalid.length && this.settings.validate.desiredBlank.length) {
            return this.switchTab(true, activeTab);
        } else if (!this.settings.validate.invalid.length) {
            return this.switchTab(true, activeTab);
        }
    }

    selectMedicalInsurance() {
        if (!this.insuranceForm.value.medicalInsurance || this.insuranceForm.value.medicalInsurance == 1 || this.insuranceForm.value.medicalInsurance == 2) {
            this.removePlan();
        }
    }

    switchTab(boolean, activeTab = 'physician') {
        const data = {
            activeTab: activeTab,
            insurance: {
                isValid: boolean,
            },
        };
        this.submitForm.emit(data);
    }

    clearSearch() {
        this.insuranceForm.patchValue({
            searchMedicalInsurancePlan: '',
        });
    }

    setPatientHandler(patient) {
        this.setPatient.emit(patient);
    }

    selectPlan(plan) {
        this.selectedPlan = plan;
        this.newPlan = true;
        this.newPlanForm.get('name').setValue(plan.planName);
        this.newPlanForm.get('planPhone').setValue(plan.planPhone);
        this.clearSearch();
    }
    removePlan() {
        this.selectedPlan = null;
        this.newPlanForm.patchValue(this.getPatientInsuranceFormObject(this.selectedPlan));
        this.newPlan = false;
    }

    searchPlans() {
        if (this.insuranceForm.value.searchMedicalInsurancePlan && this.insuranceForm.value.searchMedicalInsurancePlan.length >= 3) {
            this.isPlansLoading = true;
            const data = {
                search: this.insuranceForm.value.searchMedicalInsurancePlan,
            };
            this.dataEntryService.getInsurancePlans(data).subscribe(
                (response) => {
                    this.medicalPlans = response;
                },
                (error) => throwError(error),
                () => (this.isPlansLoading = false)
            );
        }
    }

    updatePlan() {
        this._case.patientInsurance = this.getPatientInsuranceCaseObject();
        this.updateCase.emit(this._case);
        const data = { patientInsurance: this._case.patientInsurance };
        this.dataEntryService.updatePlan(data).subscribe(
            (response) => {
            },
            (error) => throwError(error)
        );
    }

    searchNavigate(event) {
        if (this.medicalPlans.length) {
            if (event.key === 'Tab' || event.key === 'ArrowDown') {
                event.preventDefault();
                this.focusedSearch == this.searchList.nativeElement.children.length - 1
                    ? (this.focusedSearch = 0)
                    : (this.focusedSearch = this.focusedSearch + 1);
                this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.focusedSearch == 0
                    ? (this.focusedSearch = this.searchList.nativeElement.children.length - 1)
                    : (this.focusedSearch = this.focusedSearch - 1);
                this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                this.selectPlan(this.medicalPlans[this.focusedSearch]);
                this.nextBtn.nativeElement.focus();
            }
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.medicalInsurance.focus();
        }, 0);
    }

    getPatientInsuranceCaseObject() {
        return {
            id: this._case.patientInsuranceId,
            planName: this.newPlanForm.value.name,
            groupNumber: this.newPlanForm.value.group,
            policyHolderName: this.newPlanForm.value.policyHolderName,
            policyHolderDOB: this.newPlanForm.value.policyHolderDOB ? moment(this.newPlanForm.value.policyHolderDOB).format() : null,
            planType: (this.newPlanForm.value.planType == null || this.newPlanForm.value.planType === '') ? null : this.newPlanForm.value.planType,
            isPrimary: this.newPlanForm.value.planOrder == null ? null : this.newPlanForm.value.planOrder == '1',
            payerId: this.selectedPlan ? this.selectedPlan.payerId : null,
            planId: this.selectedPlan ? this.selectedPlan.planId : null,
            planNumber: this.newPlanForm.value.planId,
            payerName: this.selectedPlan ? this.selectedPlan.payerName : null,
            payerPhone: this.selectedPlan ? this.selectedPlan.payerPhone : null,
            planPhone: this.newPlanForm.value.planPhone,
            patientId: this.insuranceForm.value.medicalInsurance != 2 ? this._case.patientId : null
        }
    }

    getPatientInsuranceFormObject(selectedPlan) {
        return {
            name: selectedPlan ? selectedPlan.planName : '',
            planId: selectedPlan ? selectedPlan.planNumber : '',
            group: selectedPlan ? selectedPlan.groupNumber : '',
            policyHolderName: selectedPlan ? selectedPlan.policyHolderName : '',
            policyHolderDOB: selectedPlan ? selectedPlan.policyHolderDOB : '',
            planType: selectedPlan ? selectedPlan.planType : '',
            planOrder: selectedPlan ? (selectedPlan.isPrimary == null ? '' : (selectedPlan.isPrimary ? 1 : 0)) : '',
            planPhone: selectedPlan ? selectedPlan.planPhone : '',
        }
    }
}
