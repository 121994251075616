import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../core/services/abstract-rest.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public login(): Observable<any> {
        this.url = this.apiUrl + `/Account/Login`;
        return this.postItem<any, any>();
    }

    public logout(): Observable<any> {
        this.url = this.apiUrl + `/Account/Logout`;
        return this.postItem<any, any>();
    }
}
