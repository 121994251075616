<aside>
  <div class="search menu-item" (click)="openModal()">
    <a [class.active]="isSearchModalOpen$ | async">
      <mat-icon>search</mat-icon>
    </a>
  </div>

  <div class="reports menu-item">
    <a routerLink="reports" routerLinkActive="active">
      <img class="aside-icon" src="assets/icons/icon_chart.svg" alt="icon_chart" />
      <img class="active" src="assets/icons/icon_chart_active.svg" alt="icon_chart" />
    </a>
  </div>

  <div class="home menu-item">
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]= "{exact: true}">
      <mat-icon>home</mat-icon>
    </a>
  </div>

  <div *ngIf="(user$ | async) as user" class="user">
    <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-button">
      <div class="user-img" *ngIf="user?.img?.length; else initials">
        <img [src]="user.img" alt="user.name"/>
      </div>
      <ng-template #initials>
        <div class="user-initials">{{ user?.name | slice: 0: 1 }}</div>
      </ng-template>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="after" yPosition="above">
      <a mat-menu-item routerLink="/profile">Profile</a>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</aside>
