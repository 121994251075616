import { ContactMethod, ContactType, HIPAAConsent, QueueIDs, QueueTypeNames, Roles } from '../enums/enums';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import 'moment-duration-format';
import { IContactInfo } from '../interfaces/interfaces';

export const getUserRole = (user) => {
  if (user.is_admin) {
    return Roles.Admin;
  } else if (user.is_case_manager) {
    return Roles.Manager;
  } else if (user.is_intake) {
    return Roles.Intake;
  } else if (user.is_supervisor) {
    return Roles.Supervisor;
  } else {
    return null;
  }
};

export const isThisWeek = (date) => {
  const now = new Date();

  const weekDay = (now.getDay() + 6) % 7; // Make sure Sunday is 6, not 0
  const monthDay = now.getDate();
  const mondayThisWeek = monthDay - weekDay;

  const startOfThisWeek = new Date(+now);
  startOfThisWeek.setDate(mondayThisWeek);
  startOfThisWeek.setHours(0, 0, 0, 0);

  const startOfNextWeek = new Date(+startOfThisWeek);
  startOfNextWeek.setDate(mondayThisWeek + 7);

  return date >= startOfThisWeek && date < startOfNextWeek;
};

export const enumToArray = (enumValue) => {
  return Object.entries(enumValue)
    .filter((item) => !isNaN(item[0] as any))
    .map((item) => item[1]);
};

export const enumToObject = (enumValue) => {
  for (const [key, value] of enumValue) {
    console.log(key, value);
  }
};

export const setAgeColor = (age: number): string => {
  if (age < 120) {
    return 'green';
  } else if (age >= 120 && age < 240) {
    return 'yellow';
  } else {
    return 'red';
  }
};

export const setAge = (age: number): string => {
  if (age > 60 * 24) {
    return Math.floor(age / 60 / 24) + ' d';
  } else if (age > 60) {
    return Math.floor(age / 60) + ' hr ' + Math.round(age % 60) + ' min';
  } else {
    return Math.round(age) + ' min';
  }
};

export const noWhitespaceValidator = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {whitespace: true};
};

export const phoneValidator = (control: FormControl) => {
  const match =
    !control.value ||
    (control.value &&
      (control.value.match(/^(\d{1})(\d{3})(\d{3})(\d{4,})$/) ||
        control.value.match(/^(\d{3})(\d{3})(\d{4,})$/)));
  return match ? null : {phoneFormat: true};
};

export const numberValidator = (control: FormControl) => {
  const cleaned = control.value != null ? control.value + '' : '';
  const match = !cleaned || (cleaned && cleaned.match(/^([0-9]*)$/));
  return match ? null : {number: true};
};

export const ssnValidator = (control: FormControl) => {
  const match = !control.value || (control.value && control.value.match(/^(\d{3})(\d{2})(\d{4})$/));
  return match ? null : {ssnFormat: true};
};

export const greaterThanTodayValidator = (control: FormControl) => {
  let val = control.value;
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  if (!(val instanceof Date)) {
    val = new Date(val);
  }
  return val && val >= today ? null : {greaterThanToday: true};
};

export const isPositive = (control: FormControl) => {
  const cleaned = control.value != null ? control.value + '' : 0;
  const match = Number(cleaned) > 0;
  return match ? null : {positive: true};
};

export const isFilled = (control: FormControl) => {
  const cleaned = control.value != null ? control.value + '' : '';
  return cleaned != null && cleaned !== '' ? null : {empty: true};
};

export const getToken = () => {
  const token = localStorage.getItem('okta-token-storage');
  return JSON.parse(token).accessToken.value;
};

export const toSearchString = (value: string) => {
  return value.replace(/\ /g, '').toLowerCase();
};

export const createImageFromBlob = (image: Blob, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader), false);
  if (image) {
    reader.readAsDataURL(image);
  }
};

export const isIntakeQueue = (id) => {
  return id === QueueIDs.identify || id === QueueIDs['data-entry'];
};

export const getQueueTypeName = (id) => {
  if (id === QueueIDs.supervisor) {
    return QueueTypeNames.supervisor;
  } else if (id === QueueIDs.identify) {
    return QueueTypeNames.identification;
  } else if (id === QueueIDs['data-entry']) {
    return QueueTypeNames.dataEntry;
  }
  return QueueTypeNames.caseManagement;
};

export const setIndicatorColor = (value) => {
  if (value < 50) {
    return 'orange';
  } else if (value >= 50 && value < 75) {
    return 'yellow';
  } else if (value >= 75 && value < 100) {
    return 'blue';
  } else {
    return 'green';
  }
};

export const getFullAddress = (address) => {
  if (address) {
    const addressArray = [];
    if (address.streetAddress) {
      addressArray.push(address.streetAddress);
    }
    if (address.city) {
      addressArray.push(address.city);
    }
    if (address.state) {
      addressArray.push(address.state);
    }
    if (address.zipCode) {
      addressArray.push(address.zipCode);
    }
    return addressArray.slice(0, -1).join(', ') + ' ' + addressArray.slice(-1);
  }
  return '';
};

export const getDateString = (date, format) => {
  if (date) {
    return moment(date).format(format);
  }
  return '';
};

export const getDateObject = (ISO: string): Date => {
  return new Date(ISO || '');
};

export const expiredFile = (utc: string): boolean => {
  return getDateObject(utc).getTime() < new Date().getTime();
};

export const getAgeString = (date) => {
  if (date) {
    const diff = getDateDiff(new Date(), date, 'months', false);
    if (diff < 12) {
      return getDurationString(diff, 'months', 'M [month] [old]', {
        trunc: true
      });
    }
    return getDurationString(diff, 'months', 'y [year] [old]', {
      trunc: true
    });
  }
  return '';
};

export const formatPhone = (input) => {
  let intlCode = '';
  const cleaned = ('' + input).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4,})$/);
  if (match) {
    intlCode = '+' + match[1] + ' ';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  } else {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4,})$/);
    if (match) {
      return [intlCode, '(', match[1], ') ', match[2], '-', match[3]].join('');
    }
  }
  return cleaned;
};

export const getDateDiff = (date1, date2, param, decimal = false) => {
  const a = moment(date1);
  const b = moment(date2);
  return a.diff(b, param, decimal);
};

export const interpolateTemplate = (text, obj) => {
  if (text) {
    for (const property in obj) {
      if (property) {
        const re = new RegExp(`{{${property}}}`, 'g');
        text = text.replace(re, obj[property]);
      }
    }

    return text;
  }
  return '';
};

export const getDurationString = (val, param, template, options) => {
  return moment.duration(val, param).format(template, options);
};

export const getPhaseStart = (phaseTasks) => {
  if (phaseTasks && phaseTasks.length > 0) {
    const bvTasksByCreated = phaseTasks.sort((x, y) => x.createDate > y.createDate);
    return bvTasksByCreated[0].createDate;
  }
  return '';
};

export const getPhaseCompleted = (phaseTasks) => {
  if (phaseTasks && phaseTasks.length > 0) {
    if (phaseTasks.find(x => x.outcome === null)) {
      return '';
    }
    const bvTasksByCompleted = phaseTasks.sort((x, y) => x.completed < y.completed);
    return bvTasksByCompleted[0].completed;
  }
  return '';
};

export const getPhaseSpent = (tasks) => {
  if (tasks && tasks.length > 0) {
    const reducer = (accumulator, currentValue) => {
      const first = currentValue.createDate;
      const second = currentValue.completed || new Date();
      const diff = getDateDiff(second, first, 'hours', true);
      return accumulator + diff;
    };
    return tasks.reduce(reducer, 0);
  }
  return 0;
};

export const deepCopy = (oldObj: any) => {
  let newObj = oldObj;
  if (oldObj && typeof oldObj === 'object') {
    if (oldObj instanceof Date) {
      return new Date(oldObj.getTime());
    }
    newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
    for (const i in oldObj) {
      if (oldObj.hasOwnProperty(i)) {
        newObj[i] = deepCopy(oldObj[i]);
      }
    }
  }
  return newObj;
};

export const deepEqual = (obj1: object, obj2: object) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }

  const keysA = Object.keys(obj1);
  const keysB = Object.keys(obj2);

  if (keysA.length !== keysB.length) {
    return false;
  }

  let result = true;

  keysA.forEach((key) => {
    if (!keysB.includes(key)) {
      result = false;
    }

    if (
      typeof obj1[key] === 'function' ||
      typeof obj2[key] === 'function'
    ) {
      if (obj1[key].toString() !== obj2[key].toString()) {
        result = false;
      }
    }

    if (!deepEqual(obj1[key], obj2[key])) {
      result = false;
    }
  });

  return result;
};

export const addSuggestedCase = (selectedCase) => {
  if (selectedCase && selectedCase.id) {
    const key = 'suggested_cases';
    const numberOfItems = 4;
    const suggestedCasesItem = localStorage.getItem(key);
    const cases = suggestedCasesItem ? JSON.parse(suggestedCasesItem) : [];
    const index = cases.findIndex(x => x.id === selectedCase.id);
    if (index >= 0) {
      cases.splice(index, 1);
    }
    cases.unshift({
      id: selectedCase.id,
      patient: `${selectedCase.patient?.firstName || ''} ${selectedCase.patient?.lastName || ''}`
    });

    const result = cases.slice(0, numberOfItems);
    localStorage.setItem(key, JSON.stringify(result));
  }
};

export const getSuggestedCases = () => {
  const key = 'suggested_cases';
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return [];
};

export const addPeriod = (date, period, param) => {
  return moment(date).add(period, param).format();
};

export const getContactInfos = (form: FormGroup): IContactInfo[] => {
  const contactInfos = [];

  if (form.value.officePhone) {
    contactInfos.push({
      name: '',
      contactString: ('' + form.value.officePhone).replace(/\D/g, ''),
      primary: true,
      contactMethod: ContactMethod.phone,
      contactType: ContactType.work,
    });
  }

  if (form.value.officeFax) {
    contactInfos.push({
      name: '',
      contactString: ('' + form.value.officeFax).replace(/\D/g, ''),
      primary: true,
      contactMethod: ContactMethod.fax,
      contactType: ContactType.work,
    });
  }

  if (form.value.officeEmail) {
    contactInfos.push({
      name: form.value.officeName,
      contactString: form.value.officeEmail,
      primary: true,
      contactMethod: ContactMethod.email,
      contactType: ContactType.work,
    });
  }

  return contactInfos;
};

export const getTimeFormat = (date: Date): string => {
  const hours = date.getHours();
  const min = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  const hoursString = hours % 12 ? hours % 12 : '12';
  const minString = min < 10 ? '0' + min : min;

  return hoursString + ':' + minString + ' ' + ampm;
};

// convert string '7:30 PM' to '19:30'
export const get24FromAmpmTime = (time: string): string => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AMPM = time.match(/\s(.*)$/)[1];

  if (AMPM.toLowerCase() === 'pm' && hours < 12) {
    hours = hours + 12;
  }
  if (AMPM.toLowerCase() === 'am' && hours === 12) {
    hours = hours - 12;
  }

  let sHours = hours.toString();
  let sMinutes = minutes.toString();

  if (hours < 10) {
    sHours = '0' + sHours;
  }
  if (minutes < 10) {
    sMinutes = '0' + sMinutes;
  }

  return sHours + ':' + sMinutes;
};

export const getConsentValue = (consentObj) => {
  return consentObj?.consentIsOnFile === true
    ? HIPAAConsent.Yes
    : (consentObj?.consentIsOnFile === false ? HIPAAConsent.No : null);
};

export const getISOString = (date: Date): string => {
  return date ? moment(date).format('YYYY-MM-DDT00:00:00.000') + 'Z' : null;
};

export const getContactInfoObject = (contactMethod: ContactMethod, value: string): IContactInfo => {
  return {
    contactMethod,
    contactString: value,
    contactType: ContactType.work,
    name: '',
    primary: true,
  };
};

export const getContactInfoString = (
  contactInfos: IContactInfo[],
  method: ContactMethod,
  type: ContactType = ContactType.work,
): string => {
  contactInfos.forEach((el: IContactInfo) => {
    if (el.contactMethod === method && el.contactType === type) {
      return el.contactString;
    }
  });

  return '';
};
