import { UserTypes, UserActions } from './user.actions';

export let initialState = null;

export function reducer(state = initialState, action: UserActions) {
  switch (action.type) {
    case UserTypes.SET_USER:
      return { ...action.payload };
    default:
      return state;
  }
}
