import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeleteReason } from '../../../../../../shared/enums/enums';
import { DocumentService } from '../../../document.service';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../../../store/document/document.actions';
import { IDocument } from '../../../document.model';

@Component({
    selector: 'app-delete-document',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss'],
})
export class DeleteDocumentModalComponent implements OnInit {
    deleteForm: FormGroup;
    deleteReason = DeleteReason;
    imageID: number;
    documentList: IDocument[];
    selectedDocument: number;

    constructor(private store: Store<any>, public documentService: DocumentService) {
        this.deleteForm = new FormGroup({
            blankPage: new FormControl(false),
            other: new FormControl(false),
            otherText: new FormControl(''),
        });

        this.store.select('document').subscribe((state) => {
            if (state.documentList && state.documentList.length) {
                this.imageID = state.documentList[state.selectedDocument].id;
                this.documentList = state.documentList;
                this.selectedDocument = state.selectedDocument;
            }
        });
    }

    deleteImage() {
        let formValues = this.deleteForm.value;
        let reason = Object.keys(formValues).find((key) => formValues[key] === true);
        let deleteData = {
            deleteReason: this.deleteReason[reason],
            deleteReasonString: reason == 'other' ? formValues.otherText : null,
        };
        this.documentService.deleteImage(this.imageID, deleteData).subscribe(
            (response) => {
                let newDocumentList = this.documentList.filter((document) => document.id != this.imageID);
                let newSelectedDocument =
                    this.selectedDocument + 1 == this.documentList.length ? 0 : this.selectedDocument++;

                this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocument));
                this.store.dispatch(new DocumentAction.SetDocumentsList(newDocumentList));
            },
            (error) => throwError(error)
        );
    }

    ngOnInit(): void { }
}
