import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TargetNames, TaskColumns, TaskContactMethodNames } from '../../../../../shared/enums/enums';
import { enumToArray, interpolateTemplate } from '../../../../../shared/helpers/utils';

@Component({
  selector: 'app-manager-case-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  columnsToDisplay = enumToArray(TaskColumns);
  data = [];
  dataSource;
  activeTasksInner = [];

  @Input()
  get activeTasks() {
    return this.activeTasksInner;
  }

  set activeTasks(activeTasks) {
    if (this.activeTasksInner !== activeTasks) {
      this.activeTasksInner = activeTasks.map(item => {
        return {
          ...item,
          task: interpolateTemplate(item.task, {
            reason: item.followUpReason,
            contactMethod: TaskContactMethodNames[item.contactMethod] || '',
            entity: TargetNames[item.target] || ''
          })
        };
      });
      this.data = this.activeTasksInner;
      this.dataSource = new MatTableDataSource(this.data);
    }
  }

  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() createActivity: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  setBackground(status, time): string {
    if (status === 'Complete' && time === 'On Time') {
      return 'complete-on-time';
    }
    if (status === 'Complete' && time === 'Late') {
      return 'complete-late';
    }
    if (status === 'Current') {
      return 'current';
    }

    return '';
  }
}
