<div mat-dialog-title>
  <div class="header">
    <img src="assets/icons/icon-add-blue.svg" alt="add activity">
    <h1>Create Activity</h1>
  </div>

  <button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <p>You’re adding an activity for <span>Case ID #{{data.id}}</span>.</p>
</div>

<div mat-dialog-content>
  <div class="dialog-body">
    <div class="switcher-container">
      <div class="form-row">
        <div class="input-item toggle">
          <label
            for="switcherControl">{{switcherControl.value === 'followup' ? 'Which are you adding?' : 'Select Activity Type'}}</label>
          <mat-button-toggle-group id="switcherControl" #switcherType
                                   [class.both]="createBothControl.value"
                                   [formControl]="switcherControl">
            <mat-button-toggle value="contact">
              <span class="check-icon" *ngIf="createBothControl.value"></span>
              Log Contact
            </mat-button-toggle>
            <mat-button-toggle value="followup">
              Create Follow-up
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <ng-container *ngIf="switcherControl.value === 'contact'">
      <form class="activity-container" [formGroup]="contactForm">
        <div class="form-row first">
          <div class="input-item select" [ngClass]="{
          'input-error':
              contactForm.controls['target'].invalid && contactForm.controls['target'].touched,
          'input-success':
              contactForm.controls['target'].valid && contactForm.controls['target'].touched
        }">
            <label for="targetActivity">Select Entity</label>
            <mat-form-field appearance="fill">
              <mat-select panelClass="dropdown-item" #targetActivity
                          formControlName="target" name="target"
                          placeholder="Select" id="targetActivity">
                <mat-option [value]="TargetNames.Patient">Patient</mat-option>
                <mat-option [value]="TargetNames.Payer">Payer</mat-option>
                <mat-option [value]="TargetNames.Physician">Physician</mat-option>
                <mat-option [value]="TargetNames.Pharmacy">Pharmacy</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row direction">
          <div class="input-item toggle half-width" [ngClass]="{
          'input-error':
              contactForm.controls['direction'].invalid && contactForm.controls['direction'].touched,
          'input-success':
              contactForm.controls['direction'].valid && contactForm.controls['direction'].touched
        }">
            <label for="directionActivity">Direction</label>
            <mat-button-toggle-group #directionActivity="matButtonToggleGroup" id="directionActivity"
                                     formControlName="direction">
              <mat-button-toggle [value]="TaskDirectionNames.In">Inbound</mat-button-toggle>
              <mat-button-toggle [value]="TaskDirectionNames.Out">Outbound</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="input-item select half-width" [ngClass]="{
          'input-error':
              contactForm.controls['contactMethod'].invalid && contactForm.controls['contactMethod'].touched,
          'input-success':
              contactForm.controls['contactMethod'].valid && contactForm.controls['contactMethod'].touched
        }">
            <label for="contactMethodActivity">Contact Method</label>
            <mat-form-field appearance="fill">
              <mat-select panelClass="dropdown-item"
                          formControlName="contactMethod" name="target"
                          placeholder="Select" id="contactMethodActivity">
                <mat-option [value]="ContactMethod.phone">Call</mat-option>
                <mat-option [value]="ContactMethod.email">Email</mat-option>
                <mat-option [value]="ContactMethod.text">Text</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row date-row">
          <div class="input-item" [ngClass]="{
          'input-error':
              contactForm.controls['contactDate'].invalid && contactForm.controls['contactDate'].touched,
          'input-success':
              contactForm.controls['contactDate'].valid && contactForm.controls['contactDate'].touched
        }">
            <label for="contactDateActivity">Contact Date</label>
            <div class="date-input">
              <input matInput id="contactDateActivity"
                     [matDatepicker]="contactDateActivityDatepicker"
                     [max]="today"
                     formControlName="contactDate" placeholder="MM/DD/YY"
                     (focus)="contactDateActivityDatepicker.open()"
                     (click)="contactDateActivityDatepicker.open()"/>
              <mat-datepicker-toggle matSuffix [for]="contactDateActivityDatepicker" tabindex="-1">
              </mat-datepicker-toggle>
              <mat-datepicker #contactDateActivityDatepicker></mat-datepicker>
            </div>
          </div>
          <div class="input-item" [ngClass]="{
          'input-error':
              contactForm.controls['contactTime'].invalid && contactForm.controls['contactTime'].touched,
          'input-success':
              contactForm.controls['contactTime'].valid && contactForm.controls['contactTime'].touched
        }">
            <label for="contactTimeActivity">Contact Time</label>
            <div class="date-input">
              <input [ngxTimepicker]="contactTime"
                     id="contactTimeActivity"
                     formControlName="contactTime"/>
              <ngx-material-timepicker #contactTime></ngx-material-timepicker>
              <img class="icon-timer" src="assets/icons/icon-access-time.svg" alt="timer">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item notes">
            <label for="outcomeNoteActivity">Contact Notes</label>
            <textarea matInput id="outcomeNoteActivity" formControlName="outcomeNote"></textarea>
          </div>
        </div>
      </form>

      <div class="create-followup-container">
        <div class="form-row">
          <div class="input-item toggle">
            <label for="createBothControl">Would you like to create a follow-up based on this contact?</label>
            <mat-button-toggle-group id="createBothControl" [formControl]="createBothControl">
              <mat-button-toggle [value]="true">
                Yes
              </mat-button-toggle>
              <mat-button-toggle [value]="false">
                No
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </ng-container>

    <form class="followup-container" [formGroup]="followupForm" *ngIf="switcherControl.value === 'followup'">
      <div class="form-row first">
        <div class="input-item select" [ngClass]="{
          'input-error':
              followupForm.controls['target'].invalid && followupForm.controls['target'].touched,
          'input-success':
              followupForm.controls['target'].valid && followupForm.controls['target'].touched
        }">
          <label for="targetFollowup">Select Entity</label>
          <mat-form-field appearance="fill">
            <mat-select panelClass="dropdown-item" #targetFollowup
                        formControlName="target" name="target"
                        placeholder="Select" id="targetFollowup">
              <mat-option [value]="TargetNames.Patient">Patient</mat-option>
              <mat-option [value]="TargetNames.Payer">Payer</mat-option>
              <mat-option [value]="TargetNames.Physician">Physician</mat-option>
              <mat-option [value]="TargetNames.Pharmacy">Pharmacy</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select half-width" [ngClass]="{
          'input-error':
              followupForm.controls['associatedPhase'].invalid && followupForm.controls['associatedPhase'].touched,
          'input-success':
              followupForm.controls['associatedPhase'].valid && followupForm.controls['associatedPhase'].touched
        }">
          <label for="associatedPhase">Associated Phase</label>
          <mat-form-field appearance="fill">
            <mat-select panelClass="dropdown-item"
                        formControlName="associatedPhase"
                        name="associatedPhase"
                        placeholder="Select"
                        id="associatedPhase">
              <mat-option *ngFor="let phase of phases" [value]="phase.id">{{phase.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-item select half-width" [ngClass]="{
          'input-error':
              followupForm.controls['followUpReasonId'].invalid && followupForm.controls['followUpReasonId'].touched,
          'input-success':
              followupForm.controls['followUpReasonId'].valid && followupForm.controls['followUpReasonId'].touched
        }">
          <label for="associatedPhase">Reason for Follow-Up</label>
          <mat-form-field appearance="fill">
            <mat-select panelClass="dropdown-item"
                        formControlName="followUpReasonId"
                        name="followUpReasonId"
                        placeholder="Select"
                        id="followUpReasonId">
              <mat-option *ngFor="let reason of reasons" [value]="reason.id">{{reason.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select half-width" [ngClass]="{
          'input-error':
              followupForm.controls['contactMethod'].invalid && followupForm.controls['contactMethod'].touched,
          'input-success':
              followupForm.controls['contactMethod'].valid && followupForm.controls['contactMethod'].touched
        }">
          <label for="associatedPhase">Contact Method</label>
          <mat-form-field appearance="fill">
            <mat-select panelClass="dropdown-item"
                        formControlName="contactMethod"
                        name="contactMethodFollowup"
                        placeholder="Select"
                        id="contactMethodFollowup">
              <mat-option [value]="ContactMethod.phone">Call</mat-option>
              <mat-option [value]="ContactMethod.email">Email</mat-option>
              <mat-option [value]="ContactMethod.text">Text</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item notes">
          <label for="outcomeNoteFollowup">Follow-up Note</label>
          <textarea matInput id="outcomeNoteFollowup" formControlName="outcomeNote"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item half-width" [ngClass]="{
          'input-error':
              followupForm.controls['followUpDate'].invalid && followupForm.controls['followUpDate'].touched,
          'input-success':
              followupForm.controls['followUpDate'].valid && followupForm.controls['followUpDate'].touched
        }">
          <label for="followUpDate">Follow-up Date</label>
          <div class="date-input">
            <input matInput id="followUpDate"
                   [matDatepicker]="followUpDateDatepicker"
                   [min]="today"
                   formControlName="followUpDate" placeholder="MM/DD/YY"
                   (focus)="followUpDateDatepicker.open()"
                   (click)="followUpDateDatepicker.open()"/>
            <mat-datepicker-toggle matSuffix [for]="followUpDateDatepicker" tabindex="-1">
            </mat-datepicker-toggle>
            <mat-datepicker #followUpDateDatepicker></mat-datepicker>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions *ngIf="switcherControl.value">
  <button mat-button class="prev"
          (mouseenter)="arrowBackBlue.style.display = 'none'; arrowBackWhite.style.display = 'inline-block'"
          (mouseleave)="arrowBackBlue.style.display = 'inline-block'; arrowBackWhite.style.display = 'none'"
          *ngIf="createBothControl.value && switcherControl.value === 'followup'"
          (click)="switchToActivity()">
    <img #arrowBackBlue src="assets/icons/icon-arrow-long-right-blue.svg" alt="back">
    <img #arrowBackWhite style="display: none" src="assets/icons/icon-arrow-long-right-white.svg" alt="back">
    Back: <span class="type">Log Contact</span>
  </button>

  <button mat-button class="next"
          (mouseenter)="arrowNextBlue.style.display = 'none'; arrowNextWhite.style.display = 'inline-block'"
          (mouseleave)="arrowNextBlue.style.display = 'inline-block'; arrowNextWhite.style.display = 'none'"
          *ngIf="createBothControl.value && switcherControl.value === 'contact'"
          (click)="switchToFollowup()">
    Next: <span class="type">Create Follow-up</span>
    <img #arrowNextBlue src="assets/icons/icon-arrow-long-right-blue.svg" alt="next">
    <img #arrowNextWhite style="display: none" src="assets/icons/icon-arrow-long-right-white.svg" alt="back">
  </button>

  <button mat-button class="save"
          *ngIf="!(createBothControl.value && switcherControl.value === 'contact')"
          [disabled]="(createBothControl.value && (contactForm.invalid || followupForm.invalid)) || (switcherControl.value === 'contact' && contactForm.invalid) || (switcherControl.value === 'followup' && followupForm.invalid)"
          (click)="submit()">
    Submit
  </button>
</div>
