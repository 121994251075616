import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { queueData } from '../../../shared/data/data';
import { IUserQueue } from './dashboard.models';
import { IResponse } from 'src/app/shared/interfaces/interfaces';

@Injectable({
    providedIn: 'root',
})
export class QueueService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public getIntakeQueues(): Observable<IUserQueue[]> {
        this.url = this.apiUrl + '/IntakeQueues';
        return this.getItems<IResponse>('').pipe(
            map((response) => {
                response.value.map((queue) => {
                    return (queue.queueConfiguration = {
                        ...queue.queueConfiguration,
                        chartData: [{ data: queueData.new.data, label: 'Identify' }],
                        type: 'inc',
                        documentType: 'identify',
                    });
                });
                return response.value;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    public getCaseManagementQueues(): Observable<IUserQueue[]> {
        this.url = this.apiUrl + '/CaseManagementQueues';
        return this.getItems<IResponse>('').pipe(
            map((response) => {
                response.value.map((queue) => {
                    return (queue.queueConfiguration = {
                        ...queue.queueConfiguration,
                        chartData: [{ data: queueData.new.data, label: 'Identify' }],
                        type: 'inc',
                        documentType: 'identify',
                    });
                });
                return response.value;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }
}
