import { Component, OnInit, ViewChild } from '@angular/core';
import { createImageFromBlob, setAge, setAgeColor, toSearchString } from '../../../../shared/helpers/utils';
import { enumToArray, deepCopy } from '../../../../shared/helpers/utils';
import { ManagerQueueDataColumns, Roles } from '../../../../shared/enums/enums';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IManagerQueueData } from '../queue.model';
import { QueueService } from './../queue.service';
import { throwError } from 'rxjs';
import { DocumentTypes, DocumentTypeNames } from '../../../../shared/enums/enums';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-manager-queue-data',
    templateUrl: './data.component.html',
    styleUrls: ['../queue.component.scss', './data.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class ManagerQueueDataComponent implements OnInit {
    setAge = setAge;
    setAgeColor = setAgeColor;
    columnsToDisplay = enumToArray(ManagerQueueDataColumns);
    documentTypes = enumToArray(DocumentTypes);
    data: IManagerQueueData[] = [];
    DocumentTypeNames = DocumentTypeNames;
    dataSource;
    isLoaded = false;
    maxHoursCount;
    minutesInAgeType;
    isFiltersNotUsed = true;
    userRole: string;
    previousValue = null;

    settings = {
        itemPerPage: 20,
        paginationPageCount: null,
        activePage: 0,
        ageType: 'hours',
        filter: {
            show: {
                patient: false,
                documentType: false,
                receivedDate: false,
                age: false,
            },
            value: {
                patient: null,
                documentType: {
                    enrollmentForm: false,
                    papForm: false,
                    prescription: false,
                    insuranceCard: false,
                    proofOfIncome: false,
                    other: false,
                },
                receivedDate: { start: '', end: '' },
                age: [0, 1],
            },
            initialValue: {
                patient: null,
                documentType: {
                    enrollmentForm: false,
                    papForm: false,
                    prescription: false,
                    insuranceCard: false,
                    proofOfIncome: false,
                    other: false,
                },
                receivedDate: { start: '', end: '' },
                age: [0, 1],
            },
        },
    };

    constructor(public queueService: QueueService, private router: Router, private store: Store<any>) {
        this.store.select('user').subscribe((state) => {
            this.userRole = state.role;
        });
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('ageSlider') ageSlider;

    setActivePage(index) {
        this.settings.activePage = index;
        this.paginator.pageIndex = index;
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    onChangeRange(rangeSlider) {
        const rangeType = rangeSlider.replace('Slider', '');
        this[rangeSlider].slider.set(this.settings.filter.value[rangeType]);
    }

    openFilter(filter) {
        this.previousValue = deepCopy(this.settings.filter.value);
        this.closeAllFilters();
        this.settings.filter.show[filter] = true;
    }

    closeFilter(filter) {
        this.settings.filter.show[filter] = false;
        this.settings.filter.value = this.previousValue;
    }

    closeAllFilters() {
        const shownFilters = this.settings.filter.show;
        Object.keys(shownFilters).forEach((k) => {
            shownFilters[k] = false;
        });
        this.settings.filter.value = deepCopy(this.previousValue);
    }

    clearFilter(filter) {
        switch (filter) {
            case 'documentType':
                this.settings.filter.value[filter] = {
                    enrollmentForm: false,
                    papForm: false,
                    prescription: false,
                    insuranceCard: false,
                    proofOfIncome: false,
                    other: false,
                };
                break;
            case 'receivedDate':
                this.settings.filter.value[filter] = { start: '', end: '' };
                break;
            default:
                this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
        }
        this.applyFilter(filter);
    }

    clearAllFilters() {
        this.settings.filter.value = {
            ...this.settings.filter.initialValue,
            documentType: {
                enrollmentForm: false,
                papForm: false,
                prescription: false,
                insuranceCard: false,
                proofOfIncome: false,
                other: false,
            },
            receivedDate: { start: '', end: '' },
        };
        this.applyFilter('');
    }

    checkIsFilterUsed() {
        this.isFiltersNotUsed =
            !!this.settings.filter.value.patient == !!this.settings.filter.initialValue.patient &&
            !this.isDocumentTypeSelected() &&
            !this.settings.filter.value.receivedDate.start &&
            !this.settings.filter.value.receivedDate.end &&
            this.settings.filter.value.age[0] == this.settings.filter.initialValue.age[0] &&
            this.settings.filter.value.age[1] == this.settings.filter.initialValue.age[1];
    }

    setPagination() {
        this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
    }

    applyFilter(filter) {
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSource.filter = this.settings.filter.value;
        this.settings.filter.show[filter] = false;
        this.checkIsFilterUsed();
        this.setPagination();
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            let shownFilter;
            for (const [key, value] of Object.entries(this.settings.filter.show)) {
                if (this.settings.filter.show[key]) {
                    shownFilter = key;
                }
            }
            shownFilter && this.applyFilter(shownFilter);
        }
    }

    countAgeDuration() {
        this.minutesInAgeType = this.settings.ageType == 'days' ? 60 * 24 : 60;
        const maxAgeValue = Math.max(...this.data.map((a) => Math.floor(Number(a.age) / this.minutesInAgeType)));
        this.maxHoursCount = maxAgeValue;
        this.settings.filter.value.age = [0, maxAgeValue];
        this.settings.filter.initialValue.age = [0, maxAgeValue];
    }

    getAllItemsInQueue() {
        const data = { queueId: 3, take: 10000 };
        this.queueService.getAllItemsInQueue(data).subscribe(
            (response) => {
                this.data = response.map((queue) => {
                    return {
                        id: queue.id,
                        patient: queue.patient,
                        documentTypes: queue.documentTypes.map((item) => DocumentTypes[item]),
                        documentTypeNames: queue.documentTypes.map((item) => DocumentTypeNames[DocumentTypes[item]]),
                        receivedDate: queue.createDate,
                        age: queue.age.totalMinutes,
                        queueStatus: queue.queueStatus,
                        userImageS3Url: queue.userImageS3Url,
                    };
                });
                this.dataSource = new MatTableDataSource(this.data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.setPagination();
                this.countAgeDuration();
                this.settings.filter.initialValue = { ...this.settings.filter.value };
                this.isLoaded = true;
            },
            (error) => throwError(error)
        );
    }

    isDocumentTypeSelected() {
        return Object.values(this.settings.filter.value.documentType).includes(true);
    }
    getUserImage(queue) {
        this.queueService.getUserImage(queue.userImageS3Url).subscribe(
            (image) => {
                createImageFromBlob(image, (reader) => {
                    let item = this.data.find((x) => x.id == queue.id);
                    item.userImageS3Url = reader.result;
                });
            },
            (error) => throwError(error)
        );
    }

    openItem(queue) {
        if (this.isSupervisorOrManager() && queue.queueStatus != 1) {
            this.router.navigate(['/document-data-entry'], { queryParams: { id: queue.id } });
        }
    }

    isSupervisorOrManager() {
        return this.userRole == Roles.Supervisor || this.userRole == Roles.Manager;
    }

    customFilterPredicate() {
        const myFilterPredicate = (data, filter): boolean => {
            const patientFilter = filter.patient
                ? toSearchString(data.patient).includes(toSearchString(filter.patient))
                : true;

            let documentTypeFilter = false;
            if (Object.values(filter.documentType).includes(true)) {
                data.documentTypes.forEach((element) => {
                    documentTypeFilter = documentTypeFilter || filter.documentType[element];
                });
            } else {
                documentTypeFilter = true;
            }

            var fullEndDate = new Date(filter.receivedDate.end);
            let receivedDateFilter;
            if (filter.receivedDate.start && filter.receivedDate.end) {
                receivedDateFilter =
                    new Date(data.receivedDate) >= new Date(filter.receivedDate.start) &&
                    new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else if (filter.receivedDate.start && !filter.receivedDate.end) {
                receivedDateFilter = new Date(data.receivedDate) >= new Date(filter.receivedDate.start);
            } else if (!filter.receivedDate.start && filter.receivedDate.end) {
                receivedDateFilter =
                    new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
            } else {
                receivedDateFilter = true;
            }

            const ageFilter = filter.age
                ? data.age / this.minutesInAgeType >= filter.age[0] &&
                  data.age / this.minutesInAgeType <= filter.age[1] + 1
                : true;
            return patientFilter && documentTypeFilter && receivedDateFilter && ageFilter;
        };
        return myFilterPredicate;
    }

    ngOnInit() {
        this.getAllItemsInQueue();
    }
}
