import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(input: any): any {
    if (input != null) {
      const cleaned = ('' + input).replace(/[\s,·]+/g, '');
      if (cleaned === '') {
        return '--';
      }
      return input;
    }
    return '--';
  }
}
