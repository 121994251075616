import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '../../shared/shared.module';
import { SectionPatientComponent } from './case/components/aside-sections/section-patient/section-patient.component';
import { EditableAsideFieldComponent } from './case/components/aside-sections/editable-aside-field/editable-aside-field.component';
import { NewOptInFormComponent } from './case/components/phases/new-opt-in-form/new-opt-in-form.component';
import { OptOutFormComponent } from './case/components/phases/opt-out-form/opt-out-form.component';
import { SectionPhysicianComponent } from './case/components/aside-sections/section-physician/section-physician.component';
import { PhysicianEditModalComponent } from './case/components/dialogs/physician-edit-modal/physician-edit-modal.component';
import { CreateActivityDialogComponent } from './case/components/dialogs/create-activity-dialog/create-activity-dialog.component';
import { SectionNotesComponent } from './case/components/aside-sections/section-notes/section-notes.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NewAddressFormComponent } from './case/components/phases/new-address-form/new-address-form.component';
import { ConfirmAddressDialogComponent } from './case/components/dialogs/confirm-address-dialog/confirm-address-dialog.component';
import { AddressHistoryDialogComponent } from './case/components/dialogs/address-history-dialog/address-history-dialog.component';
import { NewCaregiverFormComponent } from './case/components/phases/new-caregiver-form/new-caregiver-form.component';
import { ConfirmCaregiverDialogComponent } from './case/components/dialogs/confirm-caregiver-dialog/confirm-caregiver-dialog.component';

const components = [
  SectionPatientComponent,
  EditableAsideFieldComponent,
  NewOptInFormComponent,
  OptOutFormComponent,
  SectionPhysicianComponent,
  PhysicianEditModalComponent,
  CreateActivityDialogComponent,
  NewAddressFormComponent,
  SectionNotesComponent,
  ConfirmAddressDialogComponent,
  AddressHistoryDialogComponent,
  NewCaregiverFormComponent,
  ConfirmCaregiverDialogComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgScrollbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatCheckboxModule,
    ClickOutsideModule,
    MatDialogModule,
    NgxMaskModule,
    MatButtonToggleModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
  ],
})
export class ManagerModule {
}
