<form [formGroup]="physicianForm" novalidate (ngSubmit)="submitHandler()" (keydown.enter)="$event.preventDefault()">
  <div class="form-wrapper">
    <h2>Physician</h2>
    <div class="search-form">
      <div class="input-item search">
        <img src="../../../../../assets/icons/icon-search.svg" alt="search"/>
        <input matInput type="text" formControlName="searchPhysician"
               placeholder="Search by Physician Name or NPI" #searchPhysician (input)="searchPhysicians()"
               (keydown)="searchNavigate($event, 'physician')"/>
        <button mat-button *ngIf="physicianForm.value.searchPhysician" matSuffix mat-icon-button
                aria-label="Clear" (click)="clearSearchPhysician()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ul class="physician-results physician-search"
          *ngIf="physicianForm.value.searchPhysician && physicianForm.value.searchPhysician.length >= 3"
          #physicianSearchList>
        <li *ngFor="let physician of physicians; index as i" (click)="selectPhysician(physician)"
            [class.focused]="focusedSearch == i">
          <div class="physician-title">
            <h5>{{ physician.firstName | capitalize }} {{ physician.lastName | capitalize }}</h5>
          </div>
          <div class="physician-wrapper">
            <div class="physician-item">
              <h6>NPI</h6>
              <p>{{ physician.npi | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Licenses</h6>
              <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
              <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.license | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Specialty</h6>
              <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
              <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.desc | empty}}</p>
            </div>
          </div>
        </li>
        <li *ngIf="!physicians.length" class="not-found">
          <h5>{{ physicianForm.value.searchPhysician }}</h5>
          <p>Physician Not Found</p>
        </li>
      </ul>
    </div>
    <ng-container *ngIf="!physicianOptions.newPhysician && physicianOptions.selectedPhysician == null">
      <div class="suggest-info">
        <b>Suggested from Source:</b> <i>{{ contactString }}</i>
      </div>
      <div class="suggest-wrapper">
        <button class="suggest-item" type="button" *ngFor="let physician of suggestedPhysicians"
                (click)="selectPhysician(physician)" (keydown.enter)="selectPhysician(physician)">
          {{ physician.firstName | capitalize }} {{ physician.lastName | capitalize }}
        </button>
      </div>
    </ng-container>
    <div class="new-physician" *ngIf="physicianOptions.newPhysician">
      <div class="form-row">
        <div class="input-item" [ngClass]="{
                        'input-error':
                            physicianForm.controls['physicianName'].invalid &&
                            physicianForm.controls['physicianName'].touched,
                        'input-success':
                            physicianForm.controls['physicianName'].valid &&
                            physicianForm.controls['physicianName'].touched
                    }">
          <label for="physicianName">Physician Name</label>
          <input name="physicianName" id="physicianName" formControlName="physicianName"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item half-width" [ngClass]="{
                        'input-error': physicianForm.controls['npi'].invalid && physicianForm.controls['npi'].touched,
                        'input-success': physicianForm.controls['npi'].valid && physicianForm.controls['npi'].touched
                    }">
          <label for="npi">Physician NPI</label>
          <input name="npi" id="npi" formControlName="npi" mask="000000"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item half-width select" [ngClass]="{
                        'input-error':
                            physicianForm.controls['specialty'].invalid && physicianForm.controls['specialty'].touched,
                        'input-success':
                            physicianForm.controls['specialty'].valid && physicianForm.controls['specialty'].touched
                    }">
          <label>Physician Specialty</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="specialty" name="specialty" placeholder="Select" id="specialty">
              <mat-option value="Allergy and Immunology">Allergy and Immunology</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="selected-physician" *ngIf="physicianOptions.selectedPhysician != null">
      <div class="physician-title">
        <img src="../../../../../../../assets/icons/icon-check.svg" alt="check"/>
        <h5>
          {{ physicianOptions.selectedPhysician.firstName | capitalize }} {{
          physicianOptions.selectedPhysician.lastName | capitalize }}
        </h5>
        <button (click)="selectPhysician(null)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="physician-wrapper">
        <div class="physician-item">
          <h6>NPI</h6>
          <p>{{ physicianOptions.selectedPhysician.npi | empty}}</p>
        </div>
        <div class="physician-item">
          <h6>Licenses</h6>
          <p *ngFor="let taxonomy of physicianOptions.selectedPhysician.taxonomies">{{ taxonomy.license |
            empty}}</p>
        </div>
        <div class="physician-item">
          <h6>Specialty</h6>
          <p *ngFor="let taxonomy of physicianOptions.selectedPhysician.taxonomies">{{ taxonomy.desc | empty}}
          </p>
        </div>
      </div>
    </div>
    <div class="physician-facilities search-form"
         *ngIf="physicianFacilities && physicianOptions.selectedPhysician != null">
      <h5>Select Facility</h5>
      <ul class="physician-results facility-results">
        <div #physicianFacility>
          <li *ngFor="let facility of physicianOptions.selectedPhysician.addresses; index as i"
              (click)="selectFacility(facility, true)" [class.focused]="focusedSearch == i">
            <div class="physician-title">
              <h5>
                {{ facility.name }}
              </h5>
              <span *ngIf="facility.mostLikely">
                                <mat-icon>grade</mat-icon>Most Likely
                            </span>
            </div>
            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>Address</h6>
                <p>{{ facility.address1 }}, {{ facility.state }} {{ facility.postalCode }}</p>
              </div>
              <div class="physician-item">
                <h6>Group Tax</h6>
                <p>{{ facility.groupTaxId | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Phone</h6>
                <p>{{ facility.telephoneNumber | phoneNumber | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Fax</h6>
                <p>{{ facility.faxNumber | phoneNumber | empty}}</p>
              </div>
            </div>
          </li>
        </div>
        <li class="add">
          <button class="primary-btn" type="button" (click)="showFacilitiesSearch()">
            Search Facilities
          </button>
        </li>
      </ul>
    </div>
    <div class="facility-block" *ngIf="
                !physicianFacilities && (physicianOptions.newPhysician || physicianOptions.selectedPhysician != null)
            ">
      <div class="search-form" *ngIf="facilitiesSearch">
        <div class="input-item search">
          <img src="../../../../../assets/icons/icon-search.svg" alt="search"/>
          <input matInput type="text" formControlName="searchFacility"
                 placeholder="Search Existing Facilities" #searchFacility (input)="searchFacilities()"
                 (keydown)="searchNavigate($event, 'facility')"/>
          <button mat-button *ngIf="physicianForm.value.searchFacility" matSuffix mat-icon-button
                  aria-label="Clear" (click)="clearSearchFacility()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <ul class="physician-results facility-results"
            *ngIf="physicianForm.value.searchFacility && physicianForm.value.searchFacility.length >= 3">
          <div #facilitySearchList>
            <li *ngFor="let facility of facilities; index as i" (click)="selectFacility(facility, false)"
                [class.focused]="focusedSearch == i">
              <div class="physician-title">
                <h5>
                  {{ facility.name }}
                </h5>
                <span *ngIf="facility.mostLikely">
                                    <mat-icon>grade</mat-icon>Most Likely
                                </span>
              </div>
              <div class="physician-wrapper">
                <div class="physician-item">
                  <h6>Address</h6>
                  <p>{{ facility.address | fulladdress | empty}}</p>
                </div>
                <div class="physician-item">
                  <h6>Group Tax</h6>
                  <p>{{ facility.groupTaxId }}</p>
                </div>
                <div class="physician-item">
                  <h6>Phone</h6>
                  <p>{{ facility.contactInfos | phone | empty}}</p>
                </div>
                <div class="physician-item">
                  <h6>Fax</h6>
                  <p>{{ facility.contactInfos | fax | empty}}</p>
                </div>
              </div>
            </li>
            <li *ngIf="!facilities.length" class="not-found">
              <h5>{{ physicianForm.value.searchFacility }}</h5>
              <p>Facility Not Found</p>
            </li>
          </div>
          <li class="add">
            <button class="primary-btn" type="button" (click)="selectNewFacility()">
              <mat-icon>add_circle</mat-icon>
              New Facility
            </button>
          </li>
        </ul>
      </div>
      <ng-container
        *ngIf="!physicianOptions.newFacility && physicianOptions.selectedFacility == null && suggestedFacilities.length">
        <div class="suggest-info"><b>Suggested</b></div>
        <div class="suggest-wrapper">
          <button class="suggest-item" type="button" *ngFor="let facility of suggestedFacilities"
                  (click)="selectFacility(facility, false)" (keydown.enter)="selectFacility(facility, false)">
            {{ facility.name }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="physicianOptions.selectedFacility != null">
        <div class="selected-physician selected-facility" [formGroup]="facilityForm">
          <div class="physician-title">
            <div>
              <img src="../../../../../../../assets/icons/icon-check.svg" alt="check"/>
              <h5>{{ physicianOptions.selectedFacility.name }}</h5>
            </div>
            <div>
              <button type="button" *ngIf="!physicianOptions.isEditedFacility" (click)="editFacility()"
                      class="edit">
                <mat-icon>edit</mat-icon>
              </button>
              <button type="button" (click)="selectFacility(null, false)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="physician-wrapper" *ngIf="!physicianOptions.isEditedFacility">
            <div class="physician-item">
              <h6>Address</h6>
              <p>{{ physicianOptions.selectedFacility.address | fulladdress | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Group Tax</h6>
              <p>{{ physicianOptions.selectedFacility.groupTaxId | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Phone</h6>
              <p>{{ physicianOptions.selectedFacility.contactInfos | phone | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Fax</h6>
              <p>{{ physicianOptions.selectedFacility.contactInfos | fax | empty}}</p>
            </div>
          </div>
          <div class="physician-wrapper edited" *ngIf="physicianOptions.isEditedFacility">
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input type="text" matInput formControlName="address"/>
              </mat-form-field>
            </div>
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Group Tax</mat-label>
                <input type="text" matInput formControlName="tax"/>
              </mat-form-field>
            </div>
            <div class="zip-wrapper">
              <div class="physician-item zip">
                <mat-form-field>
                  <mat-label>ZIP</mat-label>
                  <input type="text" matInput formControlName="zipCode"/>
                </mat-form-field>
              </div>
              <div class="physician-item">
                <h6 *ngIf="
                                        physicianOptions.selectedFacility.city ||
                                        physicianOptions.selectedFacility.state
                                    ">
                  City, State
                </h6>
                <p>
                                    <span *ngIf="physicianOptions.selectedFacility.city">{{
                                      physicianOptions.selectedFacility.city }},
                                    </span>
                  <span *ngIf="physicianOptions.selectedFacility.state">
                                        {{ physicianOptions.selectedFacility.state }}
                                    </span>
                </p>
              </div>
            </div>
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Phone</mat-label>
                <input type="text" matInput formControlName="officePhone" mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
              </mat-form-field>
            </div>
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Fax</mat-label>
                <input type="text" matInput formControlName="officeFax" mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
              </mat-form-field>
            </div>
            <div class="action-buttons">
              <button class="cancel" (click)="exitEditFacility()">Exit</button>
              <div class="primary-btn" (click)="saveFacility(); editFacility()">Save</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="new-facility" *ngIf="physicianOptions.newFacility" [formGroup]="facilityForm">
        <div class="form-row">
          <div class="input-item" [ngClass]="{
                            'input-error':
                                facilityForm.controls['facilityName'].invalid &&
                                (facilityForm.controls['facilityName'].touched || settings.isValid == false),
                            'input-success':
                                facilityForm.controls['facilityName'].valid &&
                                facilityForm.controls['facilityName'].touched
                        }">
            <label for="facilityName">{{ physicianFormFields.facilityName }}</label>
            <input name="facilityName" id="facilityName" formControlName="facilityName" #facilityName/>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item short" [ngClass]="{
                            'input-error':
                                facilityForm.controls['tax'].invalid &&
                                (facilityForm.controls['tax'].touched || settings.isValid == false),
                            'input-success': facilityForm.controls['tax'].valid && facilityForm.controls['tax'].touched
                        }">
            <label for="tax">{{ physicianFormFields.tax }}#</label>
            <input name="tax" id="tax" formControlName="tax"/>
          </div>
        </div>
        <div class="form-row address-row">
          <div class="input-item" [ngClass]="{
                            'input-success': facilityForm.value.address && facilityForm.controls['address'].touched
                        }">
            <label for="address">{{ physicianFormFields.address }}</label>
            <input name="address" id="address" formControlName="address"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item short" [ngClass]="{
                            'input-error':
                                facilityForm.controls['zipCode'].invalid &&
                                (facilityForm.controls['zipCode'].touched || settings.isValid == false),
                            'input-success':
                                facilityForm.controls['zipCode'].valid && facilityForm.controls['zipCode'].touched
                        }">
            <label for="zipCode">{{ physicianFormFields.zipCode }}</label>
            <input name="zipCode" id="zipCode" formControlName="zipCode"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item short" [ngClass]="{
                            'input-error':
                                facilityForm.controls['officePhone'].invalid &&
                                (facilityForm.controls['officePhone'].touched || settings.isValid == false),
                            'input-success':
                                facilityForm.controls['officePhone'].valid &&
                                facilityForm.controls['officePhone'].touched
                        }">
            <label for="officePhone">{{ physicianFormFields.officePhone }}</label>
            <input name="officePhone" id="officePhone" formControlName="officePhone" mask="(000) 000-0000"
                   placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item short" [ngClass]="{
                            'input-error':
                                facilityForm.controls['officeFax'].invalid &&
                                (facilityForm.controls['officeFax'].touched || settings.isValid == false),
                            'input-success':
                                facilityForm.controls['officeFax'].valid && facilityForm.controls['officeFax'].touched
                        }">
            <label for="officeFax">{{ physicianFormFields.officeFax }}</label>
            <input name="officeFax" id="officeFax" formControlName="officeFax" mask="(000) 000-0000"
                   placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
          </div>
        </div>
      </div>
      <ng-container *ngIf="physicianOptions.newFacility || physicianOptions.selectedFacility != null"
                    [formGroup]="facilityForm">
        <div class="form-row">
          <div class="input-item half-width" [ngClass]="{
                            'input-error':
                                facilityForm.controls['officeName'].invalid &&
                                (facilityForm.controls['officeName'].touched || settings.isValid == false),
                            'input-success':
                                facilityForm.value.officeName && facilityForm.controls['officeName'].touched
                        }">
            <label for="officeName">{{ physicianFormFields.officeName }}</label>
            <input name="officeName" id="officeName" formControlName="officeName" #officeName/>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item half-width" [ngClass]="{
                            'input-success':
                                facilityForm.controls.officeEmail.valid && facilityForm.controls['officeEmail'].touched,
                            'input-error':
                                facilityForm.controls.officeEmail.invalid &&
                                (facilityForm.controls['officeEmail'].touched || settings.isValid == false)
                        }">
            <label for="officeEmail">{{ physicianFormFields.officeEmail }}</label>
            <input name="officeEmail" id="officeEmail" formControlName="officeEmail"/>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <button type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()">
    <b>Next:</b> Clinical
    <mat-icon>east</mat-icon>
  </button>
</form>
