import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UncertainReason } from '../../../../../../shared/enums/enums';
import { DocumentService } from '../../../document.service';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../../../store/document/document.actions';
import { IDocument } from '../../../document.model';

@Component({
    selector: 'app-uncertain-document',
    templateUrl: './uncertain.component.html',
    styleUrls: ['./uncertain.component.scss'],
})
export class UncertainDocumentModalComponent implements OnInit {
    uncertainForm: FormGroup;
    imageID: number;
    uncertainReason = UncertainReason;
    documentList: IDocument[];
    selectedDocument: number;

    constructor(private store: Store<any>, public documentService: DocumentService) {
        this.uncertainForm = new FormGroup({
            blurryPage: new FormControl(false),
            illegibleWriting: new FormControl(false),
            other: new FormControl(false),
            otherText: new FormControl(''),
        });

        this.store.select('document').subscribe((state) => {
            this.imageID = state.documentList[state.selectedDocument].id;
            this.documentList = state.documentList;
            this.selectedDocument = state.selectedDocument;
        });
    }

    uncertainImage() {
        let formValues = this.uncertainForm.value;
        let reason = Object.keys(formValues).find((key) => formValues[key] === true);
        let uncertainData = {
            uncertainReason: this.uncertainReason[reason],
            uncertainReasonString: reason == 'other' ? formValues.otherText : null,
        };
        this.documentService.uncertainImage(this.imageID, uncertainData).subscribe(
            (response) => {
                let newDocumentList = this.documentList.filter((document) => document.id != this.imageID);
                let newSelectedDocument =
                    this.selectedDocument + 1 == this.documentList.length ? 0 : this.selectedDocument++;

                this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocument));
                this.store.dispatch(new DocumentAction.SetDocumentsList(newDocumentList));
            },
            (error) => throwError(error)
        );
    }

    ngOnInit(): void { }
}
