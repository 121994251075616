import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorModule } from './core/interceptors/auth.interceptor.module';
import { ManagerModule } from './features/manager/manager.module';

import { environment } from '../environments/environment';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ChartsModule } from 'ng2-charts';

import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickOutsideModule } from 'ng-click-outside';
import { NouisliderModule } from 'ng2-nouislider';
import { MouseWheelDirective } from './shared/directives/mousewheel.directive';
import { SharedModule } from './shared/shared.module';
import { reducers } from './store';
import { AppComponent } from './app.component';
import { HomeComponent } from './features/home/home.component';
import { LoginComponent } from './features/login/login.component';
import { ProfileComponent } from './features/profile/profile.component';
import { TestComponent } from './features/test/test.component';
import { ManagerDashboardComponent } from './features/manager/dashboard/dashboard.component';
import { IntakeDashboardComponent } from './features/intake/dashboard/dashboard.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SearchFieldComponent } from './shared/components/search/field/field.component';
import { SearchComponent } from './shared/components/search/search.component';
import { SearchModalComponent } from './shared/components/search/modal/modal.component';
import { ManagerDashboardFilterComponent } from './features/manager/dashboard/filter/filter.component';
import { ManagerDashboardCasesComponent } from './features/manager/dashboard/cases/cases.component';
import { ManagerDashboardCaseComponent } from './features/manager/case/case.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimelineComponent } from './features/manager/case/components/timeline/timeline.component';
import { HistoryComponent } from './features/manager/case/components/history/history.component';
import { NavigationComponent } from './features/manager/case/components/navigation/navigation.component';
import { EnrollmentComponent } from './features/manager/case/components/phases/enrollment/enrollment.component';
import { BenefitCaseComponent } from './features/manager/case/components/phases/benefit/benefit.component';
import { PriorComponent } from './features/manager/case/components/phases/prior/prior.component';
import { FulfillmentComponent } from './features/manager/case/components/phases/fulfillment/fulfillment.component';
import { NotesComponent } from './features/manager/case/components/notes/notes.component';
import { DocumentComponent } from './features/intake/document/document.component';
import { FormComponent } from './features/intake/document/data/form/form.component';
import { PatientComponent } from './features/intake/document/data/form/patient/patient.component';
import { DocumentAsideComponent } from './features/intake/document/type/aside/aside.component';
import { AsideComponent } from './shared/components/aside/aside.component';
import { IntakeDashboardQueuesComponent } from './features/intake/dashboard/queues/queues.component';
import { DeleteDocumentModalComponent } from './features/intake/document/type/modals/delete/delete.component';
import { UncertainDocumentModalComponent } from './features/intake/document/type/modals/uncertain/uncertain.component';
import { DocumentViewerComponent } from './shared/components/viewer/viewer.component';
import { DocumentTypeComponent } from './features/intake/document/type/type.component';
import { DocumentDataComponent } from './features/intake/document/data/data.component';
import { ManagerDashboardQueuesComponent } from './features/manager/dashboard/queues/queues.component';
import { QueueItemComponent } from './shared/components/queue/queue.component';
import { SupervisorDashboardComponent } from './features/supervisor/dashboard/dashboard.component';
import { SupervisorDashboardQueuesComponent } from './features/supervisor/dashboard/queues/queues.component';
import { SupervisorManageQueueComponent } from './features/supervisor/manage/manage.component';
import { SupervisorQueueTopComponent } from './features/supervisor/manage/top/top.component';
import { SupervisorUsersComponent } from './features/supervisor/manage/users/users.component';
import { SupervisorTasksComponent } from './features/supervisor/manage/tasks/tasks.component';
import { SupervisorTerritoriesComponent } from './features/supervisor/manage/territories/territories.component';
import { SupervisorSettingsComponent } from './features/supervisor/manage/settings/settings.component';
import { SupervisorAddUserComponent } from './features/supervisor/manage/users/add/add.component';
import { SupervisorAddTaskComponent } from './features/supervisor/manage/tasks/add/add.component';
import { SupervisorDeleteTaskComponent } from './features/supervisor/manage/tasks/delete-dialog/delete-dialog.component';
import { SupervisorDisableQueueComponent } from './features/supervisor/manage/settings/disable/disable.component';
import { ManagerQueueComponent } from './features/manager/queue/queue.component';
import { ManagerQueueDataComponent } from './features/manager/queue/data/data.component';
import { ManagerQueueIdentifyComponent } from './features/manager/queue/identify/identify.component';
import { PhysicianComponent } from './features/intake/document/data/form/physician/physician.component';
import { EmptyQueueComponent } from './features/manager/queue/empty/empty.component';
import { EmptyManageQueueComponent } from './features/supervisor/manage/empty/empty.component';
import { QueueCreationComponent } from './features/supervisor/creation/creation.component';
import { QueueCreationSetupComponent } from './features/supervisor/creation/setup/setup.component';
import { QueueCreationTasksComponent } from './features/supervisor/creation/tasks/tasks.component';
import { QueueCreationTerritoriesComponent } from './features/supervisor/creation/territories/territories.component';
import { QueueCreationUsersComponent } from './features/supervisor/creation/users/users.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { EmptyDocumentModalComponent } from './features/intake/document/type/modals/empty/empty.component';
import { PluckPipe } from './shared/pipes/pluck.pipe';
import { JoinPipe } from './shared/pipes/join.pipe';
import { PhoneNumberPipe } from './shared/pipes/phone.pipe';
import { TaskCaseComponent } from './features/manager/case/components/task/task.component';
import { RequiredPatientInfoModalComponent } from './features/intake/document/data/form/patient/modals/required/required.component';
import { MissedPatientInfoModalComponent } from './features/intake/document/data/form/patient/modals/missed/missed.component';
import { MissedCaseModalComponent } from './features/intake/document/data/form/prescription/modals/missed/missed.component';
import { DataEntryCaseComponent } from './features/intake/document/data/form/case/case.component';
import { SupervisorAddTerritoryComponent } from './features/supervisor/manage/territories/add/add.component';
import { InsuranceComponent } from './features/intake/document/data/form/insurance/insurance.component';
import { ClinicalComponent } from './features/intake/document/data/form/clinical/clinical.component';
import { PrescriptionComponent } from './features/intake/document/data/form/prescription/prescription.component';
import { MissedComponent } from './features/intake/document/data/form/missed/missed.component';
import { AgmCoreModule } from '@agm/core';
import { ManagerQueueTaskComponent } from './features/manager/queue/task/task.component';
import { SsnPipe } from './shared/pipes/ssn.pipe';
import { SupervisorQueueComponent } from './features/supervisor/queue/queue.component';
import { SupervisorQueueTaskComponent } from './features/supervisor/queue/task/task.component';
import { FollowupEnrollmentTaskComponent } from './features/manager/case/components/task/followup-enrollment/followup-enrollment.component';
import { WelcomeTaskComponent } from './features/manager/case/components/task/welcome/welcome.component';
import { BenefitTaskComponent } from './features/manager/case/components/task/benefit/benefit.component';
import { RescheduleTaskComponent } from './features/manager/case/components/task/modals/reschedule/reschedule.component';
import { ExhaustedTaskComponent } from './features/manager/case/components/task/modals/exhausted/exhausted.component';
import { TextTaskComponent } from './features/manager/case/components/task/modals/text/text.component';
import { EmailTaskComponent } from './features/manager/case/components/task/modals/email/email.component';
import { BenefitPlanComponent } from './features/manager/case/components/task/benefit/plan/plan.component';
import { BenefitMedicalBBComponent } from './features/manager/case/components/task/benefit/medical-bb/medical-bb.component';
import { BenefitMedicalAOBComponent } from './features/manager/case/components/task/benefit/medical-aob/medical-aob.component';
import { BenefitPharmacyComponent } from './features/manager/case/components/task/benefit/pharmacy/pharmacy.component';
import { BenefitCompletionComponent } from './features/manager/case/components/task/benefit/completion/completion.component';
import { FollowupBenefitTaskComponent } from './features/manager/case/components/task/followup-benefit/followup-benefit.component';
import { FollowupPriorTaskComponent } from './features/manager/case/components/task/followup-prior/followup-prior.component';
import { TriageTaskComponent } from './features/manager/case/components/task/triage/triage.component';
import { AdherenceWelcomeTaskComponent } from './features/manager/case/components/task/adherence/welcome/welcome.component';
import { AdherenceComplianceTaskComponent } from './features/manager/case/components/task/adherence/compliance/compliance.component';
import { FullNamePipe } from './shared/pipes/fullName.pipe';
import { DetectScrollTopDirective } from './shared/directives/detect-scroll-top.directive';

// TODO: clean up after app routing refactoring
const oktaConfig = Object.assign(
  {
    onAuthRequired: ({oktaAuth, injector}) => {
      const router = injector.get(Router);
      router.navigate(['/login']);
    },
  },
  environment.oidc,
);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    TestComponent,
    LoginComponent,
    ManagerDashboardComponent,
    IntakeDashboardComponent,
    HeaderComponent,
    SearchComponent,
    SearchFieldComponent,
    SearchModalComponent,
    ManagerDashboardFilterComponent,
    ManagerDashboardCasesComponent,
    ManagerDashboardCaseComponent,
    TaskCaseComponent,
    TimelineComponent,
    HistoryComponent,
    NavigationComponent,
    EnrollmentComponent,
    BenefitCaseComponent,
    PriorComponent,
    FulfillmentComponent,
    NotesComponent,
    DocumentComponent,
    FormComponent,
    PatientComponent,
    DocumentTypeComponent,
    DocumentDataComponent,
    DocumentAsideComponent,
    AsideComponent,
    IntakeDashboardQueuesComponent,
    DeleteDocumentModalComponent,
    UncertainDocumentModalComponent,
    DocumentViewerComponent,
    MouseWheelDirective,
    ManagerDashboardQueuesComponent,
    QueueItemComponent,
    SupervisorDashboardComponent,
    SupervisorDashboardQueuesComponent,
    SupervisorManageQueueComponent,
    SupervisorQueueTopComponent,
    SupervisorUsersComponent,
    SupervisorTasksComponent,
    SupervisorTerritoriesComponent,
    SupervisorSettingsComponent,
    SupervisorAddUserComponent,
    SupervisorAddTerritoryComponent,
    SupervisorAddTaskComponent,
    SupervisorDeleteTaskComponent,
    SupervisorDisableQueueComponent,
    ManagerQueueComponent,
    ManagerQueueDataComponent,
    ManagerQueueIdentifyComponent,
    PhysicianComponent,
    EmptyQueueComponent,
    EmptyManageQueueComponent,
    QueueCreationComponent,
    QueueCreationSetupComponent,
    QueueCreationTasksComponent,
    QueueCreationTerritoriesComponent,
    QueueCreationUsersComponent,
    PaginationComponent,
    EmptyDocumentModalComponent,
    PluckPipe,
    JoinPipe,
    PhoneNumberPipe,
    SsnPipe,
    FullNamePipe,
    RequiredPatientInfoModalComponent,
    MissedPatientInfoModalComponent,
    MissedCaseModalComponent,
    DataEntryCaseComponent,
    InsuranceComponent,
    ClinicalComponent,
    PrescriptionComponent,
    ManagerQueueTaskComponent,
    MissedComponent,
    SupervisorQueueComponent,
    SupervisorQueueTaskComponent,
    FollowupEnrollmentTaskComponent,
    WelcomeTaskComponent,
    BenefitTaskComponent,
    RescheduleTaskComponent,
    ExhaustedTaskComponent,
    TextTaskComponent,
    EmailTaskComponent,
    BenefitPlanComponent,
    BenefitMedicalBBComponent,
    BenefitMedicalAOBComponent,
    BenefitPharmacyComponent,
    BenefitCompletionComponent,
    FollowupBenefitTaskComponent,
    FollowupPriorTaskComponent,
    TriageTaskComponent,
    AdherenceWelcomeTaskComponent,
    AdherenceComplianceTaskComponent,
    DetectScrollTopDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    OktaAuthModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgxMaterialTimepickerModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSidenavModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTabsModule,
    DragDropModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ChartsModule,
    ClickOutsideModule,
    NouisliderModule,
    AuthInterceptorModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlaceAPIKey,
      libraries: ['places'],
    }),
    ManagerModule,
    SharedModule,
    MatButtonModule,
    AppRoutingModule,
  ],
  providers: [
    {provide: OKTA_CONFIG, useValue: oktaConfig},
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
