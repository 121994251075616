import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import { queueData, supervisorChartData } from '../../../../shared/data/data';
import { ChartDataSets } from 'chart.js';
import { QueueIDs } from '../../../../shared/enums/enums';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-supervisor-manage-queue-top',
    templateUrl: './top.component.html',
    styleUrls: ['./top.component.scss'],
})
export class SupervisorQueueTopComponent implements OnInit {
    isEdit = false;
    queueForm: FormGroup;
    @Input() activePage: string;
    @Input() name: string;
    @Input() description: string;
    @Input() queueId: string;
    @Input() queueConfigurationId: number;
    @Output() setActivePageHandler = new EventEmitter();
    @Output() infoSaved = new EventEmitter();
    public lineChartLabels: Label[] = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su', 'M'];

    queueData = queueData;
    public data: ChartDataSets[] = [
        {
            data: supervisorChartData.map((item) => item.tasks + item.cms),
            label: 'Reimbursement',
            pointBackgroundColor: '#fff',
            radius: 5,
            hoverRadius: 8,
            pointHoverBackgroundColor: '#5b2f26',
            pointHoverBorderColor: '#fff',
        },
    ];

    constructor(private router: Router) { }

    setActivePage(page: string) {
        this.setActivePageHandler.emit(page);
    }

    viewList() {
        if (QueueIDs[this.queueId]) {
            this.router.navigate(['queue-' + QueueIDs[this.queueId]]);
        }
        else {
            this.router.navigate(['queue-manager-task'], {
                queryParams: {
                    id: this.queueId
                }
            });
        }
    }

    toggleEdit() {
        this.isEdit = !this.isEdit;
        if (this.isEdit) {
            this.queueForm = new FormGroup({
                name: new FormControl(this.name, Validators.required),
                description: new FormControl(this.description, Validators.required)
            });
        }
    }
    saveData() {
        this.name = this.queueForm.value.name;
        this.description = this.queueForm.value.description;
        let model = {
            name: this.name,
            description: this.description
        };
        this.infoSaved.emit(model);
        this.toggleEdit();
    }

    isIntakeQueue() {
        return this.queueId == QueueIDs.identify.toString() || this.queueId == QueueIDs['data-entry'].toString();
    }

    customTooltips = function (tooltip) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            this._chart.canvas.parentNode.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0 as any;
            return;
        }
        function getBody(bodyItem) {
            return bodyItem.lines;
        }
        // Set Text
        if (tooltip.body) {
            const bodyLines = tooltip.body.map(getBody);
            let innerHtml = '';
            bodyLines.forEach(function (body, i) {
                innerHtml += '<div>' + '<b>' + supervisorChartData[i].tasks + '</b> Tasks' + '</div>';
                innerHtml += '<div>' + '<b>' + supervisorChartData[i].cms + '</b> CMs' + '</div>';
                innerHtml += '<div>' + '<i>' + supervisorChartData[i].baseline + ' < baseline </i>' + '</div>';
            });
            tooltipEl.innerHTML = innerHtml;
        }
        const positionY = this._chart.canvas.offsetTop;
        const positionX = this._chart.canvas.offsetLeft;
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1 as any;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    };

    public lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            },
        },
        scales: {
            yAxes: [
                {
                    display: false,
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        fontFamily: 'ProximaNova',
                        fontSize: 13,
                        fontColor: '#6c7689',
                    },
                },
            ],
        },
        tooltips: {
            enabled: false,
            custom: this.customTooltips,
        },
    };

    public chartColor: Color[] = [
        {
            borderColor: '#e3745e',
            backgroundColor: 'rgba(227,116,94,0.3)',
        },
    ];

    public lineChartType = 'line';

    ngOnInit(): void { }
}
