import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { PhasesNames } from 'src/app/shared/enums/enums';
import { getDurationString, getPhaseCompleted, getPhaseSpent, getPhaseStart } from 'src/app/shared/helpers/utils';
import { CaseService } from '../../../services/case.service';

@Component({
    selector: 'app-case-prior',
    templateUrl: './prior.component.html',
    styleUrls: [
        '../../timeline/timeline.component.scss',
        '../benefit/benefit.component.scss',
        './prior.component.scss',
    ],
})
export class PriorComponent implements OnInit {

    @Input()
    case;

    data = [];
    started;
    completed;
    timeInPhase;

    constructor(public dialog: MatDialog, public caseService: CaseService) { }

    ngOnInit(): void {
        let phaseTasks = this.case.tasks.filter(x => x.phase == PhasesNames.priorAuthorization);
        this.started = getPhaseStart(phaseTasks);
        this.completed = getPhaseCompleted(phaseTasks);
        if (this.completed) {
            let spent = getPhaseSpent(phaseTasks);
            this.timeInPhase = getDurationString(spent > 0 ? spent : 0, 'hours', 'd [days] h [hours]', { trim: 'both' });
        }
        this.getPriorAuthorizations(this.case?.id);
    }

    getPriorAuthorizations(id) {
        this.caseService.getPriorAuthorizations(id).subscribe((response) => {
            this.data = response;
        }, (error) => throwError(error));
    }

    isDateInFuture(date) {
        const currentTime = new Date().getTime();
        const receivedTime = new Date(date).getTime();
        return receivedTime > currentTime ? true : false;
    }
}
