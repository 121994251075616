<div mat-dialog-title class="mat-dialog-title">
    <h1>Add Territory</h1>
    <button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="search-form">
        <div class="input-item search">
            <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
            <input matInput type="text" [(ngModel)]="searchValue" (keyup.enter)="getTerritories()"
                placeholder="Search for Territory" />
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <button class="primary-btn" (click)="getTerritories()" [disabled]="isLoading">Search</button>
    </div>
    <ng-scrollbar class="custom-scrollbar dialog-scrollbar" visibility="hover">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let territory">{{ territory.name }}</td>
            </ng-container>
            <ng-container matColumnDef="isAdded">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="primary-btn select-all" *ngIf="data.length" [class.deselect]="isAllSelected"
                        (click)="selectAll()">
                        <ng-container *ngIf="!isAllSelected">
                            <mat-icon>add_circle</mat-icon> Select All
                        </ng-container>
                        <ng-container *ngIf="isAllSelected">
                            <mat-icon>remove_circle</mat-icon> Unselect All
                        </ng-container>
                    </button>
                </th>
                <td mat-cell *matCellDef="let territory">
                    <button *ngIf="!isSelected(territory.id); else check">
                        <img src="../../../../../../assets/icons/icon-add.svg" alt="add" />
                    </button>
                    <ng-template #check>
                        <button>
                            <img src="../../../../../../assets/icons/icon-check.svg" alt="check" /></button>
                    </ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let task; columns: columnsToDisplay" (click)="checkTerritory(task)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="2">No data matching</td>
            </tr>
        </table>
    </ng-scrollbar>
</div>
<div mat-dialog-actions>
    <button mat-button class="primary-btn green invert" mat-dialog-close (click)="saveTerritories()">Save</button>
</div>