import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, TaskOutcome, TargetNames, TaskContactMethodNames } from 'src/app/shared/enums/enums';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-task-adherence-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['../../task.component.scss', '../../welcome/welcome.component.scss', './welcome.component.scss'],
})
export class AdherenceWelcomeTaskComponent implements OnInit {
    @Input() selectedTask;
    @Input() case;
    @Output() minimizeTaskHandler = new EventEmitter();
    @Output() setSelectedTaskHandler = new EventEmitter();
    @Output() rescheduleTaskHandler = new EventEmitter();
    @Output() submitTaskHandler = new EventEmitter();
    welcomeForm: FormGroup;
    taskTarget;
    interpolatedName;
    interpolatedDescription;
    interpolatedScript;

    constructor() {
        this.welcomeForm = new FormGroup({
            contact: new FormControl(null, [Validators.required]),
            success: new FormControl(null, [Validators.required]),
            note: new FormControl(null, [Validators.required]),
        });
    }

    minimizeTask(boolean) {
        this.selectedTask.formData = this.welcomeForm.getRawValue();
        this.minimizeTaskHandler.emit(boolean);
    }

    setSelectedTask(task) {
        this.setSelectedTaskHandler.emit(task);
    }

    submitTask() {
        let contact = this.welcomeForm.value.contact == '0';
        let success = this.welcomeForm.value.success == '0';
        const data = {
            queueItemId: this.case.id,
            queueTaskId: this.selectedTask.id,
            taskOutcome: contact && success && this.selectedTask.attemptNumber < this.selectedTask.maxAttempts - 1 ? TaskOutcome.success : TaskOutcome.failure,
            outcomeNote: this.welcomeForm.value.note,
        };
        this.submitTaskHandler.emit(data);
    }

    rescheduleTask() {
        const data = {
            attemptNumber: this.selectedTask.attemptNumber,
            task: this.selectedTask.task,
            outcomeNote: this.welcomeForm.value.note,
            case: this.case,
            target: this.selectedTask.target,
            followUpReasonId: this.selectedTask.followUpReasonId
        };
        this.rescheduleTaskHandler.emit(data);
    }

    ngOnInit(): void {
        this.interpolatedDescription = interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject());
        this.interpolatedName = interpolateTemplate(this.selectedTask.task, this.getNameTemplateObject());
        this.taskTarget = (TargetNames[this.selectedTask.target] || 'patient').toLowerCase();

        this.interpolatedScript = interpolateTemplate(this.selectedTask.script, this.getScriptTemplateObject());

        this.selectedTask.formData = {
            ...this.selectedTask.formData,
            note: this.selectedTask.formData?.note || this.selectedTask.outcomeNote
        };
        this.welcomeForm.patchValue(this.selectedTask.formData);
    }

    getNameTemplateObject() {
        return {
            reason: this.selectedTask.followUpReason,
            contactMethod: TaskContactMethodNames[this.selectedTask.contactMethod] || '',
            entity: TargetNames[this.selectedTask.target] || ''
        };
    }

    getDescriptionTemplateObject() {
        let patientPhone =
            this.case.patient && this.case.patient.contactInfos && this.case.patient.contactInfos.length
                ? this.case.patient.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
                : null;
        let patientName = this.case.patient ? `${this.case.patient.firstName} ${this.case.patient.lastName}` : '';
        let genderPronoun = this.case.patient.gender == 0 ? 'her' : (this.case.patient.gender == 1 ? 'him' : 'them');
        let entity = this.getEntityProperties();
        return {
            patientPhone: `<a href="#" class="highlighted">${formatPhone(patientPhone?.contactString)}</a>`,
            patientName: `<span class="highlighted">${patientName}</span>`,
            genderPronoun: genderPronoun,
            reason: this.selectedTask.followUpReason?.toLowerCase() || '',
            entityName: `<span class="highlighted">${entity.name}</span>`,
            entityPhone: `<a href="#" class="highlighted">${formatPhone(entity.phone)}</a>`,
            contactMethod: TaskContactMethodNames[this.selectedTask.contactMethod] || ''
        };
    }

    getEntityProperties() {
        let entity = {
            name: '',
            phone: ''
        }
        if (this.selectedTask.target == TargetNames['Patient']) {
            entity.name = this.case.patient ? `${this.case.patient.firstName} ${this.case.patient.lastName}` : '';
            entity.phone = this.case.patient?.contactInfos?.find((contact) => contact.contactMethod == ContactMethod.phone)?.contactString || '';
        }
        else if (this.selectedTask.target == TargetNames['Payer']) {
            entity.name = this.case.patientInsurance?.payerName || '';
            entity.phone = this.case.patientInsurance?.payerPhone || '';
        }
        else if (this.selectedTask.target == TargetNames['Physician']) {
            entity.name = this.case.physician ? `${this.case.physician.firstName} ${this.case.physician.lastName}` : '';
            entity.phone = this.case.facility?.contactInfos?.find((contact) => contact.contactMethod == ContactMethod.phone)?.contactString || '';
        }
        else if (this.selectedTask.target == TargetNames['Pharmacy']) {
            entity.name = this.case.specialtyPharmacy?.name || '';
            entity.phone = this.case.specialtyPharmacy?.contactInfos?.find((contact) => contact.contactMethod == ContactMethod.phone)?.contactString || '';
        }
        return entity;
    }

    getScriptTemplateObject() {
        return {
            patientName: `${this.case.patient.firstName || ''}`,
            caseManagerName: 'Heather',
            newLine: '<br><br>'
        }
    }
}
