import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ssn',
})
export class SsnPipe implements PipeTransform {
    transform(input: string): any {
        if (input && input.length > 4) {
            return `***-**-${input.substring(input.length - 4)}`;
        }
        return null;
    }
}
