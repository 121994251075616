import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../../shared/interfaces/interfaces';
import { ISaveData, IGetItems } from './creation.models';

@Injectable({
    providedIn: 'root',
})
export class QueueCreateService extends AbstractRestService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public saveQueue(data: ISaveData): Observable<IResponse> {
        this.url = this.apiUrl + `/QueueConfiguration`;
        return this.putItem<ISaveData, IResponse>(data);
    }

    public getTasks(data: IGetItems): Observable<IResponse> {
        this.url = this.apiUrl + `/CaseTasks`;
        return this.postItem<IGetItems, IResponse>(data);
    }

    public getTerritories(data: IGetItems): Observable<IResponse> {
        this.url = this.apiUrl + `/Territories`;
        return this.postItem<IGetItems, IResponse>(data);
    }

    public getUsers(data: IGetItems): Observable<IResponse> {
        this.url = this.apiUrl + `/Users`;
        return this.postItem<IGetItems, IResponse>(data);
    }
}
