<div class="breadcrumbs-container">
  <div class="breadcrumbs">
    <div class="item" *ngFor="let item of items; let first = first; let last = last">
      <a routerLink="{{item.link}}" *ngIf="!last">
        <img *ngIf="first" src="../../../../assets/icons/icon-arrow-long-right-breadcrumbs.svg" alt="back" />
        {{item.text}}
      </a>
      <span *ngIf="!last">/</span>
    </div>
    <div class="item">{{items[items.length - 1].text}}</div>
  </div>
</div>
