import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-document-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss', '../document.component.scss'],
})
export class DocumentDataComponent implements OnInit {
    isGrid;

    constructor(private store: Store<any>) {
        this.store.select('document').subscribe((state) => {
            this.isGrid = state.grid;
        });
    }    

    ngOnInit(): void {}
}
