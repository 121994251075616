import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactMethod, FulfillmentColumns } from '../../../../../../shared/enums/enums';
import { addPeriod, enumToArray } from '../../../../../../shared/helpers/utils';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CaseService } from '../../../services/case.service';
import { throwError } from 'rxjs';
import { isNgTemplate } from '@angular/compiler';

@Component({
    selector: 'app-case-fulfillment',
    templateUrl: './fulfillment.component.html',
    styleUrls: [
        '../../timeline/timeline.component.scss',
        '../benefit/benefit.component.scss',
        './fulfillment.component.scss',
    ],
})
export class FulfillmentComponent implements OnInit {
    constructor(public caseService: CaseService) {}
    @Input()
    case;
    columnsToDisplay = enumToArray(FulfillmentColumns);
    dataSource;
    data = [];
    lastShipDate = '';
    nextShipDate = '';
    currentSp = '';
    spStatus = '';
    spSubStatus = '';
    spPhone;
    triagedDate;

    _sort;

    @ViewChild(MatSort) set sort(sort: MatSort) {
        if (sort) {
            if (this.dataSource && !this._sort && sort) {
                this.dataSource.sort = sort;
            }
            this._sort = sort;
        }
    }

    ngOnInit(): void {
        this.triagedDate = this.case.tasks.find((task) => task.taskId == 9).completed;
        this.caseService.getFulfillments(this.case.id).subscribe(
            (response) => {
                this.data = response
                    .filter((x) => x.shipDate)
                    .map((item) => {
                        return {
                            ...item,
                            pharmacy: item.specialtyPharmacy?.name,
                            daysSupply: Math.round(
                                this.case.prescription.quantity / this.case.prescription.refillsNumber
                            ),
                            productName: this.case.prescription.product?.name || '',
                            ndc: this.case.prescription.product?.code || '',
                        };
                    });
                if (this.data && this.data.length) {
                    this.lastShipDate = this.data[0].shipDate;
                    this.nextShipDate = addPeriod(this.lastShipDate, this.data[0].daysSupply, 'days');
                }
                this.currentSp = this.case.specialtyPharmacy?.name;
                this.spStatus = this.case.specialtyPharmacy?.status;
                this.spSubStatus = this.case.specialtyPharmacy?.subStatus;
                this.spPhone =
                    this.case.specialtyPharmacy?.contactInfos?.find((x) => x.contactMethod == ContactMethod.phone)
                        ?.contactString ?? '';
                this.dataSource = new MatTableDataSource(this.data);
                if (this._sort) {
                    this.dataSource.sort = this._sort;
                }
            },
            (error) => throwError(error)
        );
    }
}
