import { Component, AfterViewInit, ViewChild, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupervisorUserColumns } from '../../../../shared/enums/enums';
import { enumToArray } from '../../../../shared/helpers/utils';
import { IManagerUser } from '../../../../shared/interfaces/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { SupervisorAddUserComponent } from './add/add.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-supervisor-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class SupervisorUsersComponent implements AfterViewInit {
    @Input() queueId;
    @Input()
    get users() { return this._users; }
    set users(users) {
        if (this._users.length != 0 || users.length != 0) {
            this._users = users;
            this.dataSource = new MatTableDataSource(this._users);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }
    @Output() userRemoved = new EventEmitter<number>();
    @Output() usersSaved = new EventEmitter<IManagerUser[]>();

    _users: IManagerUser[] = [];
    columnsToDisplay = enumToArray(SupervisorUserColumns);
    dataSource;

    constructor(public dialog: MatDialog) { }

    removeUser(id) {
        this.userRemoved.emit(id);
    }

    addUser() {
        let dialogRef = this.dialog.open(SupervisorAddUserComponent, {
            data: {
                queueId: this.queueId,
                addedUsers: this.users
            }, panelClass: 'addDialog'
        });
        const sub = dialogRef.componentInstance.usersSaved.subscribe((users) => {
            this.usersSaved.emit(users);
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {

    }
}
