<mat-expansion-panel id="PharmacyPanel" [expanded]="expanded" (afterExpand)="openPanel(3)" (afterCollapse)="closePanel(3)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="task-indicator" *ngIf="selectedTask">
        <mat-progress-spinner [diameter]="32" [class]="setIndicatorColor(settings.value)"
                              [value]="settings.value">
        </mat-progress-spinner>
        <mat-icon *ngIf="settings.value == 100">check</mat-icon>
        <span *ngIf="settings.value != 100">{{ settings.value }}%</span>
      </div>
      Pharmacy
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="collapse-section" [formGroup]="pharmacyForm">
    <div id="PBMPlanInfo"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PBMPlanInfo')"
         class="collapse-item" formGroupName="planInfo">
      <h3>Plan Info</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCoverage">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is there coverage?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSame">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is the PBM plan info the same as in the Plan Information section?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="policyIdPh">Policy ID</label>
          <input name="policyId" id="policyIdPh" formControlName="policyId"/>
        </div>
        <div class="input-item">
          <label for="groupIdPh">Group ID</label>
          <input name="groupId" id="groupIdPh" formControlName="groupId"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="originalEffectiveDatePh">Original Effective Date</label>
          <div class="date-input">
            <input matInput [matDatepicker]="originalEffectiveDate" id="originalEffectiveDatePh"
                   formControlName="originalEffectiveDate" placeholder="MM/DD/YY"
                   (focus)="originalEffectiveDate.open()" (click)="originalEffectiveDate.open()"/>
            <mat-datepicker-toggle matSuffix [for]="originalEffectiveDate" tabindex="-1">
            </mat-datepicker-toggle>
            <mat-datepicker #originalEffectiveDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="planEffectiveDateStartPh">Plan Effective Date Range</label>
          <div class="date-input">
            <input matInput [matDatepicker]="planEffectiveDateStart" id="planEffectiveDateStartPh"
                   formControlName="planEffectiveDateStart" placeholder="MM/DD/YY"
                   [max]="pharmacyForm.controls.planInfo.value.planEffectiveDateEnd"
                   (focus)="planEffectiveDateStart.open()" (click)="planEffectiveDateStart.open()"/>
            <mat-datepicker-toggle matSuffix [for]="planEffectiveDateStart" tabindex="-1">
            </mat-datepicker-toggle>
            <mat-datepicker #planEffectiveDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input matInput [matDatepicker]="planEffectiveDateEnd" id="planEffectiveDateEndPh"
                   formControlName="planEffectiveDateEnd" placeholder="MM/DD/YY"
                   [min]="pharmacyForm.controls.planInfo.value.planEffectiveDateStart"
                   (focus)="planEffectiveDateEnd.open()" (click)="planEffectiveDateEnd.open()"/>
            <mat-datepicker-toggle matSuffix [for]="planEffectiveDateEnd" tabindex="-1">
            </mat-datepicker-toggle>
            <mat-datepicker #planEffectiveDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div id="PharmacyPolicyLimits"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PharmacyPolicyLimits')"
         class="collapse-item" formGroupName="policyLimits">
      <h3>Policy Limits</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSameAsPlanInfo">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Are limitations same as plan info?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select">
          <label>Individual or Family Plan, or Both?</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="type" name="type" placeholder="Select" id="typePh"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Individual</mat-option>
              <mat-option [value]="1">Family</mat-option>
              <mat-option [value]="2">Both</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <h5>Deductible — Individual</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualDeductibleAmountPh">Individual Deductible Amount</label>
          <input name="individualDeductibleAmount" id="individualDeductibleAmountPh"
                 formControlName="individualDeductibleAmount" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual deductible been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleOOP">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is deductible included in OOP?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCaryNextYear">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does deductible carry over to next plan year?</span>
        </div>
      </div>
      <h5>Deductible — Family</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="familyDeductibleAmount">Family Deductible Amount</label>
          <input name="familyDeductibleAmount" id="familyDeductibleAmount"
                 formControlName="familyDeductibleAmount" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyDeductibleMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has family deductible been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyDeductibleOOP">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is deductible included in OOP?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyCaryNextYear">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does deductible carry over to next plan year?</span>
        </div>
      </div>
      <h5>Out of Pocket — Individual</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOPPH">Individual Out of Pocket</label>
          <input name="individualOOP" id="individualOOPPH" formControlName="individualOOP" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualOOPMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual OOP been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOPAmountPh">Individual OOP Amount Met</label>
          <input name="individualOOPAmount" id="individualOOPAmountPh" formControlName="individualOOPAmount"
                 mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <h5>Out of Pocket — Family</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="familyOOP">Family Out of Pocket</label>
          <input name="familyOOP" id="familyOOP" formControlName="familyOOP" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyOOPMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has Family OOP been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="familyOOPAmount">Family OOP Amount Met</label>
          <input name="familyOOPAmount" id="familyOOPAmount" formControlName="familyOOPAmount" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <h5>Lifetime Max - Individual</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeUnlimited">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is the individual lifetime maximum unlimited?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualLifetimeMaximumPh">Individual Lifetime Maximum</label>
          <input name="individualLifetimeMaximum" id="individualLifetimeMaximumPh"
                 formControlName="individualLifetimeMaximum" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeMaximumMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has the individual lifetime maximum been met?</span>
        </div>
      </div>
      <h5>Lifetime Max - Family</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyLifetimeUnlimited">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is the family lifetime maximum unlimited?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="familyLifetimeMaximum">Individual Lifetime Maximum</label>
          <input name="familyLifetimeMaximum" id="familyLifetimeMaximum"
                 formControlName="familyLifetimeMaximum" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyLifetimeMaximumMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has the family lifetime maximum been met?</span>
        </div>
      </div>
      <h5>Annual Cap — Individual</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualAnnualCapPh">Individual Annual Cap</label>
          <input name="individualAnnualCap" id="individualAnnualCapPh" formControlName="individualAnnualCap"
                 mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualAnnualCapMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual annual cap been met?</span>
        </div>
      </div>
      <h5>Annual Cap — Family</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="familyAnnualCap">Family Annual Cap</label>
          <input name="familyAnnualCap" id="familyAnnualCap" formControlName="familyAnnualCap" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isFamilyAnnualCapMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has family annual cap been met?</span>
        </div>
      </div>
    </div>
    <div id="PharmacyInNetworkSPs"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PharmacyInNetworkSPs')"
         class="collapse-item" formGroupName="networkSpecialtyPharmacies">
      <h3>In-Network Specialty Pharmacies</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Specialty Pharmacy</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="specialty" name="specialty" placeholder="Select" id="specialtyPh"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Walgreen's Specialty</mat-option>
              <mat-option [value]="1">Specialty 2</mat-option>
              <mat-option [value]="2">Specialty 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="phonePh">SP Phone</label>
          <input name="phone" id="phonePh" formControlName="phone" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="faxPh">SP Fax</label>
          <input name="fax" id="faxPh" formControlName="fax" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreferred">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is this SP preferred by the MD?</span>
        </div>
      </div>
      <button class="primary-btn" type="button">
        <mat-icon>add_circle</mat-icon>
        Add In-Network SP
      </button>
    </div>
    <div id="DrugStatusPharmacy"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('DrugStatusPharmacy')"
         class="collapse-item" formGroupName="drugStatus">
      <h3>Drug Status</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Drug Status</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="drugStatus" name="drugStatus" placeholder="Select" id="drugStatus"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Tier 1</mat-option>
              <mat-option [value]="1">Tier 2</mat-option>
              <mat-option [value]="2">Tier 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="bin">BIN</label>
          <input name="bin" id="bin" formControlName="bin" mask="000000000" placeholder="_ _ _ _ _ _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="pcn">PCN</label>
          <input name="pcn" id="pcn" formControlName="pcn" placeholder="CVS Caremark"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="quantity">Quantity Limit</label>
          <input name="quantity" id="quantity" formControlName="quantity"/>
        </div>
        <div class="input-item select">
          <label>Per</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="per" name="per" placeholder="Select Timeframe" id="per"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Day</mat-option>
              <mat-option [value]="1">Week</mat-option>
              <mat-option [value]="2">Month</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select">
          <label>What is needed for a Quantity Limit Override?</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="override" name="override" placeholder="Select" id="override"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Phone Authorization</mat-option>
              <mat-option [value]="1">2</mat-option>
              <mat-option [value]="2">3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div id="PharmacyAuthorizationDetermination"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PharmacyAuthorizationDetermination')"
         class="collapse-item" formGroupName="authorization">
      <h3>Authorization & Determination</h3>
      <h5>Prior Authorization (PA)</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPARequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is PA required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paPhonePh">Entity Phone</label>
          <input name="paPhone" id="paPhonePh" formControlName="paPhone" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needInitiateCall">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate call?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="Ph">Entity Fax</label>
          <input name="paFax" id="paFaxPh" formControlName="paFax" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needInitiateFax">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate fax?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isStepEditRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is a step edit required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="stepDrugsPh">Step Drug(s)</label>
          <input name="stepDrugs" id="stepDrugsPh" formControlName="stepDrugs"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPAonFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is there a PA on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paReferencePh">PA Reference #</label>
          <input name="paReference" id="paReferencePh" formControlName="paReference"/>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="paValidDateStartPh">PA Valid Through</label>
          <div class="date-input">
            <input matInput [matDatepicker]="paValidDateStart" id="paValidDateStartPh"
                   formControlName="paValidDateStart" placeholder="MM/DD/YY"
                   [max]="pharmacyForm.controls.authorization.value.paValidDateEnd"
                   (focus)="paValidDateStart.open()" (click)="paValidDateStart.open()"/>
            <mat-datepicker-toggle matSuffix [for]="paValidDateStart" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #paValidDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input matInput [matDatepicker]="paValidDateEnd" id="paValidDateEndPh"
                   formControlName="paValidDateEnd" placeholder="MM/DD/YY"
                   [min]="pharmacyForm.controls.authorization.value.paValidDateStart"
                   (focus)="paValidDateEnd.open()" (click)="paValidDateEnd.open()"/>
            <mat-datepicker-toggle matSuffix [for]="paValidDateEnd" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #paValidDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
      <h5>Pre-Determination (Pre-D)</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreDRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is Pre-D required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paFaxPh">Entity Phone</label>
          <input name="preDPhone" id="preDPhonePh" formControlName="preDPhone" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDFaxPh">Entity Fax</label>
          <input name="preDFax" id="preDFaxPh" formControlName="preDFax" mask="(000) 000-0000"
                 placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needCMInitiateFax">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate fax?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isStepPreDEditRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is a step edit required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="stepPreDDrugsPh">Step Drug(s)</label>
          <input name="stepPreDDrugs" id="stepPreDDrugsPh" formControlName="stepPreDDrugs"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isLMNRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is LMN required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isLMNOnFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is LMN on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreDFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is Pre-D on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDReferencePh">Pre-D Reference #</label>
          <input name="preDReference" id="preDReferencePh" formControlName="preDReference"/>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="preDValidDateStartPh">Pre-D Valid Through</label>
          <div class="date-input">
            <input matInput [matDatepicker]="preDValidDateStart" id="preDValidDateStartPh"
                   formControlName="preDValidDateStart" placeholder="MM/DD/YY"
                   [max]="pharmacyForm.controls.authorization.value.preDValidDateEnd"
                   (focus)="preDValidDateStart.open()" (click)="preDValidDateStart.open()"/>
            <mat-datepicker-toggle matSuffix [for]="preDValidDateStart" tabindex="-1">
            </mat-datepicker-toggle>
            <mat-datepicker #preDValidDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input matInput [matDatepicker]="preDValidDateEnd" id="preDValidDateEndPh"
                   formControlName="preDValidDateEnd" placeholder="MM/DD/YY"
                   [min]="pharmacyForm.controls.authorization.value.preDValidDateStart"
                   (focus)="preDValidDateEnd.open()" (click)="preDValidDateEnd.open()"/>
            <mat-datepicker-toggle matSuffix [for]="preDValidDateEnd" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #preDValidDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDDosesPh">Doses</label>
          <input name="preDDoses" id="preDDosesPh" formControlName="preDDoses"/>
        </div>
        <div class="input-item select">
          <label>Per</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="preDPer" name="preDPer" placeholder="Select Timeframe" id="preDPer"
                        disableOptionCentering panelClass="dropdown-item">
              <mat-option>--</mat-option>
              <mat-option [value]="0">Day</mat-option>
              <mat-option [value]="1">Week</mat-option>
              <mat-option [value]="2">Month</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div id="PharmacyExclusions"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PharmacyExclusions')"
         class="collapse-item" formGroupName="exclusions">
      <h3>Exclusions</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isExclusions">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Are there exclusions?</span>
        </div>
      </div>
      <h3 style="margin-bottom: 16px">
        <mat-icon>assignment</mat-icon>
        Exclusion(s) Notes
      </h3>
      <div class="form-row">
        <div class="input-item">
          <textarea matInput formControlName="exclusionsNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
