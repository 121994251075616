import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { TaskOutcome } from 'src/app/shared/enums/enums';
import { DataEntryFormService } from '../form.service';

@Component({
  selector: 'app-case-missed',
  templateUrl: './missed.component.html',
  styleUrls: ['./missed.component.scss']
})
export class MissedComponent implements OnInit {
  _case;
  @Input() missedInfo;
  @Input()
  get case() { return this._case; }
  set case(newCase) {
    if (!this._case && newCase) {
      this._case = newCase;
    }
  }
  @Output() submitForm = new EventEmitter();
  @Output() getNextStack = new EventEmitter();

  constructor(private dataEntryService: DataEntryFormService) { }

  ngOnInit(): void {
    console.log(this.missedInfo)
  }

  cancelHandler() {
    this.switchTab();
  }

  switchTab() {
    const data = {
      activeTab: 'prescription',
      init: true
    };
    this.submitForm.emit(data);
  }

  submitHandler() {
    const data = {
      queueItemId: this._case.id,
      queueTaskId: 0,
      workflowTaskId: 0,
      taskOutcome: TaskOutcome.failure
    }
    this.dataEntryService.completeCase(data).subscribe(
      (response) => {
        this.getNextStack.emit();
      },
      (error) => throwError(error)
    );
  }

}
