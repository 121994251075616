import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepCopy, expiredFile, getConsentValue, phoneValidator } from '../../../../../../shared/helpers/utils';
import { IPatient } from '../../../../../../shared/interfaces/interfaces';
import { ConsentMethod, ConsentTypeId, ContactMethod, ContactType } from '../../../../../../shared/enums/enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-section-patient',
  templateUrl: './section-patient.component.html',
  styleUrls: ['./section-patient.component.scss', '../aside-sections.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionPatientComponent implements OnInit {
  @Input() patient: IPatient;
  @Input() currentConsents;

  @Output() update: EventEmitter<IPatient> = new EventEmitter<IPatient>();
  @Output() openManagement: EventEmitter<string> = new EventEmitter<string>();

  maxDate: Date;

  patientCopy: IPatient;

  genderTypes = ['Female', 'Male', 'Other'];
  hipaaConsent = ['Yes', 'No', 'Unknown'];
  hipaaConsentObj;
  hipaaConsentValue;

  consentTypes = {
    1: 'hipaa',
    2: 'program',
    3: 'marketing',
    4: 'texting',
    5: 'voicemail',
  };

  ContactMethod = ContactMethod;
  ContactType = ContactType;
  ConsentMethod = ConsentMethod;

  expiredFile = expiredFile;

  emailForm: FormGroup;
  homeForm: FormGroup;
  mobileForm: FormGroup;

  constructor() {
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.email]),
    });
    this.homeForm = new FormGroup({
      home: new FormControl(null, [phoneValidator]),
    });
    this.mobileForm = new FormGroup({
      mobile: new FormControl(null, [phoneValidator]),
    });
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.patientCopy = deepCopy(this.patient);
    this.hipaaConsentObj = this.patientCopy.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);
    this.hipaaConsentValue = getConsentValue(this.hipaaConsentObj);
    this.updateContactInfo();
  }

  getDateFromISO(val: string): Date {
    return new Date(val);
  }

  calculateAge(birthday: string): number {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  openAddressManagement(): void {
    this.openManagement.emit('address');
  }

  openConsentManagement(): void {
    this.openManagement.emit('consent');
  }

  savePatientData(save: boolean, inputs: any[]): void {
    if (save) {
      switch (inputs[0]) {
        case 'contactInfos':
          this.setContactInfo(inputs, true);
          break;
        case 'dateOfBirth':
          this.patient.dateOfBirth = this.patientCopy.dateOfBirth;
          break;
        case 'gender':
          this.patient.gender = +this.patientCopy.gender;
          break;
        default:
          inputs.forEach(item => this.patient[item] = this.patientCopy[item].toString().slice());
      }

      this.update.emit(this.patient);
    } else {
      if (inputs[0] === 'contactInfos') {
        this.setContactInfo(inputs, false);
      } else if (inputs[0] === 'gender') {
        this.patientCopy.gender = +this.patient.gender;
      } else {
        inputs.forEach(item => this.patientCopy[item] = this.patient[item].toString().slice());
      }
    }
  }

  private setContactInfo(inputs, save): void {
    let exists = false;

    this.patient.contactInfos.forEach((info) => {
      if (info.contactMethod === inputs[2] && info.contactType === inputs[3]) {
        let obj = {};
        obj[inputs[1]] = info.contactString?.slice();
        save ? info.contactString = this[inputs[1] + 'Form'].value[inputs[1]]?.slice() : this[inputs[1] + 'Form'].patchValue(obj);
        exists = true;
      }
    });

    if (!exists) {
      if (save) {
        this.patient.contactInfos.push({
          name: null,
          primary: true,
          contactString: this[inputs[1] + 'Form'].value[inputs[1]],
          contactType: inputs[3],
          contactMethod: inputs[2],
        });

        this.updateContactInfo();
      }
      else {
        let obj = {};
        obj[inputs[1]] = '';
        this[inputs[1] + 'Form'].patchValue(obj);
      }
    }
  }

  private updateContactInfo(): void {
    this.patient.contactInfos.forEach((info, index) => {
      if (info.contactMethod === ContactMethod.email && info.contactType === ContactType.work) {
        this.emailForm.patchValue({ email: info.contactString?.slice() });
      }
      if (info.contactMethod === ContactMethod.phone && info.contactType === ContactType.mobile) {
        this.mobileForm.patchValue({ mobile: info.contactString?.slice() });
      }
      if (info.contactMethod === ContactMethod.phone && info.contactType === ContactType.home) {
        this.homeForm.patchValue({ home: info.contactString?.slice() });
      }
    });
  }
}
