import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientFormFields } from '../../../form.model';

@Component({
    selector: 'app-required-patient-document',
    templateUrl: './required.component.html',
    styleUrls: ['./required.component.scss'],
})
export class RequiredPatientInfoModalComponent implements OnInit {
    @Output() switchTab = new EventEmitter<boolean>();
    patientFormFields = PatientFormFields;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {}

    switchTabHandler() {
        this.switchTab.emit(false);
    }

    ngOnInit(): void {}
}
