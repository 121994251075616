import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { throwError } from 'rxjs';
import { ClinicalFormFields } from '../form.model'
import { DataEntryFormService } from '../form.service';

@Component({
    selector: 'app-intake-document-clinical-form',
    templateUrl: './clinical.component.html',
    styleUrls: ['../form.component.scss', '../physician/physician.component.scss', './clinical.component.scss'],
})
export class ClinicalComponent implements OnInit {
    clinicalForm: FormGroup;
    clinicalFormFields = ClinicalFormFields;
    @Input() settings;
    @Output() validateForm = new EventEmitter();
    focusedSearch: number = 0;
    isLoading: boolean = false;
    showResult: boolean = false;
    @Output() submitForm = new EventEmitter();
    @Output() updateCase = new EventEmitter();
    @ViewChild('primaryDiagnosticCode') primaryDiagnosticCode: ElementRef;
    @ViewChild('nextBtn') nextBtn: ElementRef;
    @ViewChild('clinicalSearchList') clinicalSearchList: ElementRef;
    @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
    diagnosticCodes = [];
    selectedDiagnosis;
    _case;
    submitted: boolean = false;

    @Input()
    get case() { return this._case; }
    set case(newCase) {
        if (!this._case && newCase) {
            this.clinicalForm.patchValue({
                primaryDiagnosticCode: newCase.diagnosisId ? newCase.diagnosis.code : ''
            });
            this.selectedDiagnosis = newCase.diagnosis || { id: null, code: '', description: '' };
            this._case = newCase;
        }
    }

    constructor(private dataEntryService: DataEntryFormService) {
        this.clinicalForm = new FormGroup({
            primaryDiagnosticCode: new FormControl(null, [Validators.required]),
        });
    }

    submitHandler(activeTab = 'prescription') {
        this.submitted = true;
        this.saveCase();
        this.validateForm.emit({ formControls: this.clinicalForm.controls, tab: 'clinical' });
        if (!this.settings.validate.invalid.length && (this.settings.validate.requiredBlank.length || !this.selectedDiagnosis || !this.selectedDiagnosis.id)) {
            return this.switchTab(false, activeTab);
        } else if (!this.settings.validate.invalid.length && this.settings.validate.desiredBlank.length) {
            return this.switchTab(true, activeTab);
        } else if (!this.settings.validate.invalid.length) {
            return this.switchTab(true, activeTab);
        }
    }

    saveCase() {
        this._case.diagnosisId = this.selectedDiagnosis.id;
        this._case.diagnosis = this.selectedDiagnosis;
        this.updateCase.emit(this._case);
        const data = {
            caseManagementQueueItem: {
                id: this._case.id,
                patientId: this._case.patientId,
                patientInsuranceId: this._case.patientInsuranceId,
                physicianId: this._case.physicianId,
                facilityId: this._case.facilityId,
                diagnosisId: this._case.diagnosisId,
                prescriptionId: this._case.prescriptionId
            }
        }
        this.dataEntryService.updateCase(data).subscribe(
            (response) => {
                //save case
            },
            (error) => throwError(error)
        );
    }

    selectCode(diagnosis) {
        this.clinicalForm.patchValue({
            primaryDiagnosticCode: diagnosis ? diagnosis.code : '',
        });
        this.selectedDiagnosis = diagnosis || { id: null, code: '', description: '' };
        this.showResult = false;
        this.diagnosticCodes = [];
    }

    switchTab(boolean, activeTab = 'prescription') {
        const data = {
            activeTab: activeTab,
            clinical: {
                isValid: boolean,
            },
        };
        this.submitForm.emit(data);
    }

    searchCodeHandler() {
        this.showResult = true;
        const data = { search: this.clinicalForm.value.primaryDiagnosticCode };
        this.dataEntryService.searchDiagnosis(data).subscribe((response) => {
            this.diagnosticCodes = response;
        },
            (error) => throwError(error));
    }

    searchNavigate(event) {
        if (this.diagnosticCodes.length) {
            if (event.key === 'Tab' || event.key === 'ArrowDown') {
                event.preventDefault();
                this.focusedSearch == this.clinicalSearchList.nativeElement.children.length - 1
                    ? (this.focusedSearch = 0)
                    : (this.focusedSearch = this.focusedSearch + 1);
                this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.focusedSearch == 0
                    ? (this.focusedSearch = this.clinicalSearchList.nativeElement.children.length - 1)
                    : (this.focusedSearch = this.focusedSearch - 1);
                this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                this.selectCode(this.diagnosticCodes[this.focusedSearch]);
                this.nextBtn.nativeElement.focus();
            }
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.primaryDiagnosticCode.nativeElement.focus();
        }, 0);
    }
}
