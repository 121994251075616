import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getConsentValue, isFilled, noWhitespaceValidator, phoneValidator, ssnValidator } from '../../../../../../shared/helpers/utils';
import { PatientFormFields } from '../form.model';
import { MatDialog } from '@angular/material/dialog';
import { MissedPatientInfoModalComponent } from './modals/missed/missed.component';
import { RequiredPatientInfoModalComponent } from './modals/required/required.component';
import { DataEntryFormService } from '../form.service';
import { throwError } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import * as moment from 'moment';
import { AddressType, ConsentTypeId, HIPAAConsent } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-intake-document-patient-form',
  templateUrl: './patient.component.html',
  styleUrls: ['../form.component.scss', './patient.component.scss'],
})
export class PatientComponent implements OnInit, AfterViewInit {
  @Input() settings;
  @Output() submitForm = new EventEmitter();
  @Output() validateForm = new EventEmitter();
  @Output() setPatient = new EventEmitter();
  @Output() updateCase = new EventEmitter();

  patientFormFields = PatientFormFields;
  patientForm: FormGroup;
  city = '';
  state = '';
  disableDate = new Date();
  submitted = false;
  addressFocused = false;

  @ViewChild('firstNameField') firstNameField: ElementRef;
  @ViewChild('addressField') addressField: ElementRef;

  _case;
  @Input()
  get case() {
    return this._case;
  }

    set case(selectedCase) {
        if (!this._case && selectedCase) {
            this._case = selectedCase;
            this.patientForm.patchValue(this.getPatientFormObject(selectedCase.patient));
            this.city = selectedCase.patient.currentMailingAddress?.address?.city || '';
            this.state = selectedCase.patient.currentMailingAddress?.address?.state || '';
            this.selectHipaaConsent();
        }
    }

  constructor(
    public dialog: MatDialog,
    public dataEntryService: DataEntryFormService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.patientForm = new FormGroup({
      firstName: new FormControl(null, [Validators.required, noWhitespaceValidator, isFilled]),
      middleName: new FormControl(null),
      lastName: new FormControl(null, [Validators.required, noWhitespaceValidator, isFilled]),
      dateOfBirth: new FormControl(null, Validators.required),
      gender: new FormControl(null),
      address: new FormGroup({
        zipCode: new FormControl(null),
        streetAddress: new FormControl(null),
        addressExtension: new FormControl(null)
      }),
      bestTimeToContact: new FormControl(null),
      bestMethodToContact: new FormControl(null),
      phoneInfo: new FormGroup({
        contactType: new FormControl(null),
        contactString: new FormControl(null, phoneValidator),
      }),
      email: new FormControl(null, [Validators.email]),
      hipaaConsent: new FormControl(null, [Validators.required, isFilled]),
      hipaaConsentSignatureDate: new FormControl(null),
      socialSecurityNumber: new FormControl(null, ssnValidator),
    });
  }

    public submitHandler(activeTab = 'insurance') {
        if (!this.addressFocused) {
            this.submitted = true;

            Promise.all([this.saveAddress(), this.saveConsent()]).then(this.savePatient.bind(this));
            this.validateForm.emit({ formControls: this.patientForm.controls, tab: 'patient' });
            if (!this.settings.validate.invalid.length && this.settings.validate.requiredBlank.length) {
                return this.openModal(RequiredPatientInfoModalComponent, activeTab);
            } else if (!this.settings.validate.invalid.length && this.settings.validate.desiredBlank.length) {
                /*if (hasActiveTab) {
                    return this.openModal(MissedPatientInfoModalComponent);
                }*/
                return this.switchTab(true, activeTab);
            } else if (!this.settings.validate.invalid.length) {
                return this.switchTab(true, activeTab);
            }
        }
    }

  selectHipaaConsent() {
    setTimeout(() => {
      if (this.patientForm.value.hipaaConsent === null ||
        this.patientForm.value.hipaaConsent === 1 ||
        this.patientForm.value.hipaaConsent === 2) {
        this.patientForm.get('hipaaConsentSignatureDate').setValidators(null);
        this.patientForm.patchValue({
          hipaaConsentSignatureDate: null
        });
      } else {
        this.patientForm.get('hipaaConsentSignatureDate').setValidators([Validators.required, isFilled]);
      }
      this.patientForm.get('hipaaConsentSignatureDate').updateValueAndValidity();
    });
  }

  toggleAddressFocus(value) {
    this.addressFocused = value;
    if (!value) {
      this.checkCityState();
    }
  }

  openModal(modal, activeTab = 'insurance') {
    const dialogRef = this.dialog.open(modal, {
      data: {
        required: this.settings.validate.requiredBlank,
        missed: this.settings.validate.desiredBlank,
      },
    });
    const dialog: any = dialogRef.componentInstance;
    const sub = dialog.switchTab.subscribe((isValid) => {
      this.switchTab(isValid, activeTab);
    });
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
    return true;
  }

  updatePatient(data) {
    this.dataEntryService.updatePatient(data).subscribe(
      (response) => {
        console.log(data.patient);
      },
      (error) => throwError(error)
    );
  }

  createPatient(data) {
    this.dataEntryService.createPatient(data).subscribe(
      (response) => {
        console.log(data.patient);
      },
      (error) => throwError(error)
    );
  }

  checkCityState() {
    if (!this.patientForm.value.zipCode && this.patientForm.value.address && !this.patientForm.value.address.streetAddress) {
      this.city = '';
      this.state = '';
    }
  }

  setPatientHandler(patient) {
    this.setPatient.emit(patient);
  }

    saveAddress() {
        let address = this.getAddressObject();
        let data = { patientAddress: address };
        if (!address.id) {
            return new Promise((resolve) => this.dataEntryService.createPatientAddress(data).subscribe(
                (response) => {
                    address.id = response.value;
                    this._case.patient.currentMailingAddressId = address.id;
                    this._case.patient.currentMailingAddress = address;
                    resolve(response);
                },
                (error) => throwError(error)));
        }
        else {
            return new Promise((resolve) => this.dataEntryService.updatePatientAddress(data).subscribe(
                (response) => {
                    this._case.patient.currentMailingAddress = address;
                    resolve(response);
                },
                (error) => throwError(error)));
        }
    }

    saveConsent() {
        let consent = this.getConsentObject();
        let data = { consent };
        if (!consent.id) {
            return new Promise((resolve) => this.dataEntryService.createConsent(data).subscribe(
                (response) => {
                    consent.id = response.value;
                    this._case.patient.currentConsents.push(consent);
                    resolve(response);
                },
                (error) => throwError(error)));
        }
        else {
            return new Promise((resolve) => this.dataEntryService.updateConsent(data).subscribe(
                (response) => {
                    let index = this._case.patient.currentConsents.findIndex(x => x.id == consent.id);
                    this._case.patient.currentConsents[index] = consent;
                    resolve(response);
                },
                (error) => throwError(error)));
        }
    }

    savePatient() {
        this._case.patient = this.getPatientCaseObject();
        const data = {
            patient: this._case.patient
        };
        this.updateCase.emit(this._case);
        this.updatePatient(data);
        this.setPatientHandler(data.patient);
    }

  switchTab(isValid, activeTab = 'insurance') {
    const data = {
      activeTab,
      patient: {
        isValid,
      },
    };
    this.submitForm.emit(data);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.firstNameField.nativeElement.focus();
    this.getPlaceAutocomplete();
  }

  getPlaceAutocomplete() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressField.nativeElement,
        {
          componentRestrictions: {country: 'US'},
          fields: ['address_components', 'formatted_address']
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const city = place.address_components ? place.address_components.find(x => x.types.includes('locality')) : null;
        const state = place.address_components ? place.address_components.find(x => x.types.includes('administrative_area_level_1')) : null;
        const zipCode = place.address_components ? place.address_components.find(x => x.types.includes('postal_code')) : null;
        this.patientForm.get('address.zipCode').setValue(zipCode ? zipCode.long_name : '');
        const splittedAddress = place.formatted_address ? place.formatted_address.split(', ') : [];
        const address = splittedAddress.length > 0 ? splittedAddress[0] : '';
        this.patientForm.get('address.streetAddress').setValue(address);
        this.city = city ? city.long_name : '';
        this.state = state ? state.short_name : '';
      });
    });
  }

    getPatientFormObject(patient) {
        let hipaaConsent = patient.currentConsents?.find(x => x.consentTypeId == ConsentTypeId.hipaa);
        return {
            firstName: patient.firstName,
            middleName: patient.middleName,
            lastName: patient.lastName,
            dateOfBirth: patient.dateOfBirth,
            gender: patient.gender,
            address: {
                zipCode: patient.currentMailingAddress?.address?.zipCode,
                streetAddress: patient.currentMailingAddress?.address?.streetAddress,
                addressExtension: patient.currentMailingAddress?.address?.addressExtension
            },
            bestTimeToContact: patient.bestTimeToContact,
            bestMethodToContact: patient.bestMethodToContact,
            email: patient.contactInfos && patient.contactInfos.length
                ? patient.contactInfos.find((contact) => contact.contactMethod == 2)?.contactString ?? null
                : null,
            phoneInfo: {
                contactType: patient.contactInfos && patient.contactInfos.length
                    ? patient.contactInfos.find((contact) => contact.contactMethod == 0).contactType ?? null
                    : null,
                contactString: patient.contactInfos && patient.contactInfos.length
                    ? patient.contactInfos.find((contact) => contact.contactMethod == 0)?.contactString ?? null
                    : null,
            },
            hipaaConsent: getConsentValue(hipaaConsent),
            hipaaConsentSignatureDate: hipaaConsent?.dateReceived,
            socialSecurityNumber: patient.socialSecurityNumber,
        };
    }

    getAddressObject() {
        let address = {
            id: this._case.patient.currentMailingAddressId || 0,
            current: true,
            patientId: this._case.patient.id || null,
            addressType: AddressType.mailing,
            address: { ...this.patientForm.value.address }
        }
        address.address.city = this.city;
        address.address.state = this.state;
        return address;
    }

    getConsentObject() {
        let hippaConsentDate = this.patientForm.value.hipaaConsentSignatureDate
            ? moment(this.patientForm.value.hipaaConsentSignatureDate).format() : null;
        return {
            id: this._case.patient.currentConsents?.find(x => x.consentTypeId == ConsentTypeId.hipaa)?.id || 0,
            consentTypeId: ConsentTypeId.hipaa,
            consentIsOnFile: this.patientForm.value.hipaaConsent == HIPAAConsent.Yes,
            dateReceived: hippaConsentDate,
            patientId: this._case.patient.id
        };
    }

    getPatientCaseObject() {
        let patient = { ...this._case.patient, ...this.patientForm.value };
        patient.dateOfBirth = patient.dateOfBirth ? moment(patient.dateOfBirth).format() : null;
        patient.contactInfos = [{
            ...this.patientForm.value.phoneInfo,
            contactMethod: 0,
            primary: true,
        },
        {
            contactString: this.patientForm.value.email,
            contactMethod: 2,
            primary: true,
        }
        ];
        let hipaaConsent = patient.currentConsents?.find(x => x.consentTypeId == ConsentTypeId.hipaa);
        let hippaConsentDate = this.patientForm.value.hipaaConsentSignatureDate
            ? moment(this.patientForm.value.hipaaConsentSignatureDate).format() : null;
        if (hipaaConsent) {
            hipaaConsent.consentIsOnFile = this.patientForm.value.hipaaConsent == HIPAAConsent.Yes;
            hipaaConsent.dateReceived = hippaConsentDate;
        }
        else {
            patient.currentConsents = patient.currentConsents || [];
            patient.currentConsents.push({
                id: 0,
                consentTypeId: ConsentTypeId.hipaa,
                consentIsOnFile: this.patientForm.value.hipaaConsent == HIPAAConsent.Yes,
                dateReceived: hippaConsentDate,
                patientId: patient.id
            });
        }
        delete patient.phoneInfo, delete patient.email;
        return patient;
    }
}
