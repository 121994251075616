<h1 mat-dialog-title>Search Cases</h1>
<div mat-dialog-content>
    <div class="input-item search">
        <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
        <input matInput type="text" placeholder="Search by Patient, Case ID, Payer, etc." [(ngModel)]="searchValue"
            (input)="searchCases()" />
        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue = ''">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="suggest-wrapper">
        <div class="suggest-item" *ngFor="let case of suggestedCases" (click)="openCase(case.id)" mat-dialog-close>
            <b>{{case.patient}}</b><span>Case #{{case.id}}</span>
        </div>
    </div>
    <h2>Search for</h2>
    <div class="input-item checkbox">
        <mat-button-toggle-group #duration="matButtonToggleGroup" name="searchType" aria-label="Type"
            [(ngModel)]="searchType" (change)="searchCases();">
            <mat-button-toggle [value]="0">Patient</mat-button-toggle>
            <mat-button-toggle [value]="1">Physician</mat-button-toggle>
            <mat-button-toggle [value]="2">Territory</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <h2>Quick Results</h2>
    <div *ngIf="!isLoaded" class="modal-loader-container">
        <app-loader></app-loader>
    </div>
    <table mat-table [dataSource]="dataSource" [hidden]="!isLoaded">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Case ID</th>
            <td mat-cell *matCellDef="let case">{{ case.id }}</td>
        </ng-container>
        <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef>Patient</th>
            <td mat-cell *matCellDef="let case">{{ case.patient }}</td>
        </ng-container>
        <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef>Payer</th>
            <td mat-cell *matCellDef="let case">{{ case.payer }}</td>
        </ng-container>
        <ng-container matColumnDef="physician">
            <th mat-header-cell *matHeaderCellDef>Physician</th>
            <td mat-cell *matCellDef="let case">{{ case.physician }}</td>
        </ng-container>
        <ng-container matColumnDef="territory">
            <th mat-header-cell *matHeaderCellDef>Territory</th>
            <td mat-cell *matCellDef="let case">{{ case.territory }}</td>
        </ng-container>
        <ng-container matColumnDef="phase">
            <th mat-header-cell *matHeaderCellDef>Phase</th>
            <td mat-cell *matCellDef="let case">{{ case.phase }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let case">{{ case.status }}</td>
        </ng-container>
        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef>Last Updated</th>
            <td mat-cell *matCellDef="let case">{{ case.updated | date: "MM / dd / yyyy" }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row mat-dialog-close *matRowDef="let case; columns: columnsToDisplay" (click)="openCase(case.id)"></tr>
        <tr class="mat-row no-data" *matNoDataRow>
            <td class="mat-cell" colspan="8">No data found</td>
        </tr>
    </table>
</div>
<div mat-dialog-actions *ngIf="cases && cases.length > showItems">
    <div class="more">
        <b>{{ cases.length - showItems }}</b> More Results
    </div>
    <button mat-button mat-dialog-close (click)="goToSearchPage()">
        View All <img src="../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="view all" />
    </button>
</div>