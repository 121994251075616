import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientFormFields } from '../../../form.model';

@Component({
    selector: 'app-missed-patient-document',
    templateUrl: './missed.component.html',
    styleUrls: ['../required/required.component.scss', './missed.component.scss'],
})
export class MissedPatientInfoModalComponent implements OnInit {
    @Output() switchTab = new EventEmitter<boolean>();
    patientFormFields = PatientFormFields;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data) { }

    switchTabHandler() {
        this.switchTab.emit(true);
    }

    ngOnInit(): void {}
}
