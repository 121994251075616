import { Component, Input, OnInit } from '@angular/core';
import { QueueIDs } from 'src/app/shared/enums/enums';
import { IUserQueue } from '../dashboard.models';

@Component({
    selector: 'app-supervisor-dashboard-queues',
    templateUrl: './queues.component.html',
    styleUrls: ['./queues.component.scss'],
})
export class SupervisorDashboardQueuesComponent implements OnInit {
    queueIDs = QueueIDs;

    @Input() queueList: IUserQueue[];
    @Input() intakeQueueList: IUserQueue[];
    @Input() supervisorQueueList: IUserQueue[];

    constructor() { }

    ngOnInit(): void { }
}
