<div class="document-sidebar">
    <div class="sidebar-controls" *ngIf="settings.activeTab != 'missed'">
        <div [class]="settings.activeTab == 'patient' ? 'control-item active' : 'control-item'"
            [class.valid]="settings.patient.isValid == true" [class.invalid]="settings.patient.isValid == false"
            (click)="switchTab('patient')">
            <img [src]="
                    settings.activeTab == 'patient'
                        ? '../../../../../assets/icons/person-active.svg'
                        : '../../../../../assets/icons/person.svg'
                " />
        </div>
        <div [class]="settings.activeTab == 'insurance' ? 'control-item active' : 'control-item'"
            [class.valid]="settings.insurance.isValid == true" [class.invalid]="settings.insurance.isValid == false"
            (click)="switchTab('insurance')">
            <img [src]="
                    settings.activeTab == 'insurance'
                        ? '../../../../../assets/icons/shield-active.svg'
                        : '../../../../../assets/icons/shield.svg'
                " />
        </div>

        <div [class]="settings.activeTab == 'physician' ? 'control-item active' : 'control-item'"
            [class.valid]="settings.physician.isValid == true" [class.invalid]="settings.physician.isValid == false"
            (click)="switchTab('physician')">
            <img [src]="
                    settings.activeTab == 'physician'
                        ? '../../../../../assets/icons/stethoscope-active.svg'
                        : '../../../../../assets/icons/stethoscope.svg'
                " />
        </div>

        <div [class]="settings.activeTab == 'clinical' ? 'control-item active' : 'control-item'"
            [class.valid]="settings.clinical.isValid == true" [class.invalid]="settings.clinical.isValid == false"
            (click)="switchTab('clinical')">
            <img [src]="
                    settings.activeTab == 'clinical'
                        ? '../../../../../assets/icons/heartbeat-active.svg'
                        : '../../../../../assets/icons/heartbeat.svg'
                " />
        </div>

        <div [class]="settings.activeTab == 'prescription' ? 'control-item active' : 'control-item'"
            [class.valid]="settings.prescription.isValid == true"
            [class.invalid]="settings.prescription.isValid == false" (click)="switchTab('prescription')">
            <img [src]="
                    settings.activeTab == 'prescription'
                        ? '../../../../../assets/icons/pill-active.svg'
                        : '../../../../../assets/icons/pill.svg'
                " />
        </div>
    </div>
    <div class="sidebar-content">
        <ng-scrollbar #scrollbar class="custom-scrollbar">
            <ng-container [ngSwitch]="settings.activeTab">
                <app-intake-document-patient-form class="document-form" *ngSwitchCase="'patient'"
                    (submitForm)="submitHandler($event)" (setPatient)="setPatientHandler($event)" [case]="case"
                    (validateForm)="validateHandler($event)" (updateCase)="updateCaseHandler($event)"
                    [settings]="settings.patient" #patientForm>
                </app-intake-document-patient-form>
                <app-intake-document-insurance-form class="document-form" *ngSwitchCase="'insurance'"
                    (setPatient)="setPatientHandler($event)" (submitForm)="submitHandler($event)" [case]="case"
                    (validateForm)="validateHandler($event)" (updateCase)="updateCaseHandler($event)"
                    [settings]="settings.insurance" #insuranceForm>
                </app-intake-document-insurance-form>
                <app-intake-document-physician-form class="document-form" *ngSwitchCase="'physician'"
                    (submitForm)="submitHandler($event)" [case]="case" [contactString]="contactString"
                    (validateForm)="validateHandler($event)" (updateCase)="updateCaseHandler($event)"
                    [settings]="settings.physician" #physicianForm>
                </app-intake-document-physician-form>
                <app-intake-document-clinical-form class="document-form" *ngSwitchCase="'clinical'"
                    (submitForm)="submitHandler($event)" [case]="case" (validateForm)="validateHandler($event)"
                    (updateCase)="updateCaseHandler($event)" [settings]="settings.clinical" #clinicalForm>
                </app-intake-document-clinical-form>
                <app-intake-document-prescription-form class="document-form" *ngSwitchCase="'prescription'"
                    (submitForm)="submitHandler($event)" [case]="case" (validateForm)="validateHandler($event)"
                    (updateCase)="updateCaseHandler($event)" [settings]="settings.prescription"
                    [fullSettings]="settings" (updateMissedInfo)="updateMissedInfoHandler($event)"
                    (getNextStack)="getNextStackHandler()" #prescriptionForm>
                </app-intake-document-prescription-form>
                <app-case-missed *ngSwitchCase="'missed'" [missedInfo]="missedInfo" [case]="case"
                    (submitForm)="submitHandler($event)" (getNextStack)="getNextStackHandler()"></app-case-missed>

                <app-data-entry-case *ngSwitchDefault class="document-form" (submitForm)="submitHandler($event)"
                    [patient]="patient" [cases]="cases" (caseSelected)="onCaseSelected($event)"
                    (caseCreated)="onCaseCreated()"></app-data-entry-case>

            </ng-container>
        </ng-scrollbar>
    </div>
</div>