<div class="task-wrapper">
    <div class="top-block">
        <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
        <h2>{{ selectedTask.task }}</h2>
        <div class="description">
            <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
            <p [innerHtml]="interpolatedDescription">
            </p>
        </div>
        <div class="buttons-block">
            <button class="collapse" (click)="minimizeTask(true)">
                <mat-icon>remove</mat-icon>
            </button>
            <button class="close" (click)="setSelectedTask(null)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task-block" [formGroup]="followUpForm">
        <!-- <div class="task-item contact-method">
            <h3>Contact Method</h3>
            <div class="call-info">
                <button><mat-icon>call</mat-icon>Call</button>
                <span>555-239-3984</span>
            </div>
        </div> -->
        <div class="task-item contact-outcome">
            <h3>Contact Outcome</h3>
            <div class="outcome-item">
                <div class="input-item toggle">
                    <mat-button-toggle-group formControlName="contact">
                        <mat-button-toggle value="0"> Yes </mat-button-toggle>
                        <mat-button-toggle value="1"> No </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <span>Were you able to contact the healthcare provider?</span>
            </div>
            <div class="outcome-item">
                <div class="input-item toggle">
                    <mat-button-toggle-group formControlName="missingInfo"
                        [disabled]="followUpForm.value.contact != '0'">
                        <mat-button-toggle value="0"> Yes </mat-button-toggle>
                        <mat-button-toggle value="1"> No </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <span>Missing info completed
                    <!-- <i>This will auto-update as completed once document is returned and processed by intake.</i> -->
                </span>
            </div>
        </div>
        <div class="task-item outcome-note">
            <h3>
                <mat-icon>assignment</mat-icon>Outcome Note
            </h3>
            <textarea matInput formControlName="note"></textarea>
        </div>
    </div>
    <div class="task-controls">
        <button mat-button class="primary-btn invert reschedule" (click)="rescheduleTask()"
            [disabled]="followUpForm.value.contact != '1' && followUpForm.value.missingInfo != '1'">
            <mat-icon>schedule</mat-icon>Reschedule
        </button>
        <button mat-button class="primary-btn invert done" (click)="submitTask()"
            [disabled]="followUpForm.value.contact != '0'">
            <mat-icon>done</mat-icon>Submit
        </button>
    </div>
</div>