<div [class]="'queue-item ' + data.queueType | lowercase">
    <div class="text-block">
        <h4>{{ data.queueConfiguration.title || data.queueConfiguration.name }}</h4>
        <p>{{ data.queueConfiguration.description }}</p>
    </div>
    <div class="task-block" *ngIf="userRole == 'Supervisor' && data.id == 1; else chart">
        <b>{{ data.taskCount }}</b> Tasks
    </div>
    <ng-template #chart>
        <div class="chart-block">
            <canvas baseChart [datasets]="data.queueConfiguration.chartData"
                [colors]="chartType[data.queueConfiguration.type]" [labels]="lineChartLabels"
                [chartType]="lineChartType" [options]="lineChartOptions">
            </canvas>
            <div [class]="'chart-stats ' + data.queueConfiguration.type">
                <h6 *ngIf="data.queueConfiguration.type == 'desc'">
                    <mat-icon>arrow_downward</mat-icon>Declining
                </h6>
                <h6 *ngIf="data.queueConfiguration.type == 'inc'">
                    <mat-icon>arrow_upward</mat-icon>Increasing
                </h6>
                <h6 *ngIf="data.queueConfiguration.type == 'stable'">
                    <mat-icon>arrow_forward</mat-icon>Stabilizing
                </h6>
                <p>
                    <b>{{ data.taskCount }}</b> Tasks
                </p>
                <p>
                    <b>{{ data.assignedUserCount }}</b> Assigned
                </p>
            </div>
        </div>
    </ng-template>
    <div class="button-block">
        <button [routerLink]="beginRoute ? beginRoute : '/'" [disabled]="!data.taskCount"
            *ngIf="showBegin(); else viewListButton">
            Begin <img *ngIf="data.taskCount" src="../../../../../assets/icons/icon-arrow-long-right.svg" alt="begin" />
            <img *ngIf="!data.taskCount" src="../../../../../assets/icons/icon-arrow-long-right-gray.svg" alt="begin" />
        </button>

        <ng-template #viewListButton>
            <button [routerLink]="viewRoute ? viewRoute : '/'" [disabled]="!data.taskCount" class="view-list-btn"
                [queryParams]="viewParams">
                View List
                <img *ngIf="data.taskCount" src="../../../../../assets/icons/icon-arrow-long-right-white.svg"
                    alt="begin" />
                <img *ngIf="!data.taskCount" src="../../../../../assets/icons/icon-arrow-long-right-gray.svg"
                    alt="begin" />
            </button>
        </ng-template>

        <a routerLink="/manage-queue" [queryParams]="{ id: data.queueConfiguration.id }" *ngIf="showManage()">Manage
            <img src="../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="view" /></a>
        <a [routerLink]="viewRoute ? viewRoute : '/'" [queryParams]="{ id: data.id }" *ngIf="showViewList()">View List
            <img src="../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="view" /></a>
    </div>
</div>