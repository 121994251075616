import { Pipe, PipeTransform } from '@angular/core';
import { ContactMethod } from '../enums/enums';
import { IContactInfo } from '../interfaces/interfaces';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(contactInfos: IContactInfo[]): string {
        let contact = contactInfos.find(x => x.primary && x.contactMethod == ContactMethod.phone && x.contactString);
        if (contact) {
            let phone = contact.contactString;
            let firstPart = phone.substring(0, 3);
            let secondPart = phone.substring(3, 6);
            let thirdPart = phone.substring(6);
            return `(${firstPart}) ${secondPart}-${thirdPart}`;
        }

        return '';
    }
}