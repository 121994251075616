import { Component, OnInit, Input } from '@angular/core';
import { IUserQueue } from './../dashboard.models';
import { QueueIDs } from '../../../../shared/enums/enums';

@Component({
    selector: 'app-intake-dashboard-queues',
    templateUrl: './queues.component.html',
    styleUrls: ['./queues.component.scss'],
})
export class IntakeDashboardQueuesComponent implements OnInit {
    @Input() queueList: IUserQueue[];
    @Input() intakeQueueList: IUserQueue[];
    queueIDs = QueueIDs;

    constructor() { }

    ngOnInit(): void { }
}
