import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { DataEntryFormService } from './form.service';
import {
    PatientFormFields,
    InsuranceFormFields,
    PhysicianFormFields,
    ClinicalFormFields,
    PrescriptionFormFields,
} from './form.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { greaterThanTodayValidator, noWhitespaceValidator, phoneValidator, ssnValidator, numberValidator, isPositive, isFilled, getConsentValue } from 'src/app/shared/helpers/utils';
import { ConsentTypeId, ContactMethod, HIPAAConsent } from 'src/app/shared/enums/enums';
import { Router } from '@angular/router';
import * as DocumentAction from '../../../../../store/document/document.actions';

@Component({
    selector: 'app-intake-document-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
    patient;
    cases = [];
    contactString = '';
    case;
    loadingCases = false;
    settings = this.getSettingsObject();
    missedInfo;
    documentBatchId = '';

    @ViewChild('patientForm') patientForm;
    @ViewChild('insuranceForm') insuranceForm;
    @ViewChild('physicianForm') physicianForm;
    @ViewChild('clinicalForm') clinicalForm;
    @ViewChild('prescriptionForm') prescriptionForm;

    constructor(private store: Store<any>, public dataEntryService: DataEntryFormService, private router: Router) {
        this.store.select('document').subscribe((state) => {
            if (state.documentList) {
                if (state.documentList[0].patient) {
                    this.setPatientHandler(state.documentList[0].patient);
                }
                if (!this.case && !this.loadingCases) {
                    this.documentBatchId = state.documentsBatchId;
                    this.loadingCases = true;
                    this.contactString = state.documentList[0].source;
                    this.getCases(state.documentsBatchId);
                }
            }
        });
    }

    getCases(queueItemId) {
        let patientId = this.patient ? this.patient.id : null;
        const data = { take: 0, patientId: patientId, queueItemId: queueItemId };
        this.dataEntryService.searchCases(data).subscribe(
            (response) => {
                if (response.value.length == 1 && response.value[0].newCase) {
                    this.case = response.value[0];
                    this.submitHandler({
                        activeTab: 'patient',
                        init: true
                    });
                }
                else {
                    this.cases = response.value;
                }
            },
            (error) => throwError(error)
        );
    }

    onCaseSelected(selectedCase) {
        this.case = selectedCase;
        if (!this.case.newCase) {
            this.populateForms();
        }
    }

    onCaseCreated() {
        const data = {
            caseManagementQueueItem: {
                patientId: this.patient ? this.patient.id : null
            }
        };

        this.dataEntryService.createCase(data).subscribe(
            (response) => {
                this.onCaseSelected(response.value);
            },
            (error) => throwError(error)
        );
    }

    updateMissedInfoHandler(missedInfo) {
        this.missedInfo = missedInfo
    }

    setPatientHandler(patient) {
        this.patient = patient;
    }

    validateHandler(options) {
        const emptyState = {
            invalid: [],
            requiredBlank: [],
            desiredBlank: [],
        };
        this.settings[options.tab].validate = emptyState;
        const validateLoop = (object) => {
            for (const [key, value] of Object.entries(object)) {
                if (value['controls'] == undefined) {
                    if (value['invalid'] && (value['value'] || value['errors']?.empty)) {
                        this.settings[options.tab].validate.invalid.push(this.settings[options.tab].fields[key]);
                    } else if (value['invalid'] && !value['value']) {
                        this.settings[options.tab].validate.requiredBlank.push(this.settings[options.tab].fields[key]);
                    } else if (value['valid'] && !value['value'] && value['value'] !== 0) {
                        this.settings[options.tab].validate.desiredBlank.push(this.settings[options.tab].fields[key]);
                    }
                } else {
                    validateLoop(value['controls']);
                }
            }
        };
        validateLoop(options.formControls);
    }

    switchTab(tab) {
        if (this.case) {
            let form = this[this.settings[this.settings.activeTab].form];
            form.submitHandler(tab);
        }
    }

    updateTab() {

    }

    submitHandler(formSettings) {
        this.settings = {
            ...this.settings,
        };
        let obj = '';
        for (const [key, value] of Object.entries(formSettings)) {
            if (value.hasOwnProperty('isValid')) {
                obj = key;
                this.settings[key].isValid = value['isValid'];
            }
        }
        if (formSettings.activeTab
            && (formSettings.activeTab == 'missed' || formSettings.init || (obj && this.settings[obj] && !this.settings[obj].validate.invalid.length))) {
            this.settings.activeTab = formSettings.activeTab
        }
    }

    updateCaseHandler(updatedCase) {
        this.case = {
            ...updatedCase
        };
    }

    ngOnInit(): void { }

    validateForms() {
        let valid = true;
        for (const propertyName of Object.getOwnPropertyNames(this.settings)) {
            if (propertyName && this.settings[propertyName].validate) {
                valid = !this.settings[propertyName].validate.invalid.length && !this.settings[propertyName].validate.requiredBlank.length
                    && !this.settings[propertyName].validate.desiredBlank.length;
            }
        }
        return valid;
    }

    populateForms() {
        let options = [
            {
                tab: 'patient',
                formControls: this.getPatientFormControls()
            },
            {
                tab: 'insurance',
                formControls: this.getPatientInsuranceFormControls()
            },
            {
                tab: 'physician',
                formControls: this.getPhysicianFormControls()
            },
            {
                tab: 'clinical',
                formControls: this.getClinicalFormControls()
            },
            {
                tab: 'prescription',
                formControls: this.getPrescriptionFormControls()
            }
        ]
        options.forEach(item => {
            this.validateHandler(item);
            let obj = {};
            let invalid = !this.settings[item.tab].validate.invalid.length && this.settings[item.tab].validate.requiredBlank.length
                || this.settings[item.tab].validate.invalid.length > 0;
            obj[item.tab] = {};
            obj[item.tab].isValid = !invalid;
            this.submitHandler(obj);
        });
    }


    getPatientFormControls() {
        let patient = this.case.patient;
        let address = patient ? patient.currentMailingAddress?.address : null;
        let contactInfos = patient ? patient.contactInfos : null;
        let hipaaConsent = patient?.currentConsents?.find(x => x.consentTypeId == ConsentTypeId.hipaa);
        let formGroup = new FormGroup({
            firstName: new FormControl(patient ? patient.firstName : null, [Validators.required, noWhitespaceValidator, isFilled]),
            middleName: new FormControl(patient ? patient.middleName : null),
            lastName: new FormControl(patient ? patient.lastName : null, [Validators.required, noWhitespaceValidator, isFilled]),
            dateOfBirth: new FormControl(patient ? patient.dateOfBirth : null, Validators.required),
            gender: new FormControl(patient ? patient.gender : null),
            address: new FormGroup({
                zipCode: new FormControl(address ? address.zipCode : null),
                streetAddress: new FormControl(address ? address.streetAddress : null),
                addressExtension: new FormControl(address ? address.addressExtension : null)
            }),
            bestTimeToContact: new FormControl(patient ? patient.bestTimeToContact : null),
            bestMethodToContact: new FormControl(patient ? patient.bestMethodToContact : null),
            phoneInfo: new FormGroup({
                contactType: new FormControl(contactInfos && contactInfos.length
                    ? contactInfos.find((contact) => contact.contactMethod == 0).contactType
                    : null),
                contactString: new FormControl(contactInfos && contactInfos.length
                    ? contactInfos.find((contact) => contact.contactMethod == 0).contactString
                    : null, phoneValidator),
            }),
            email: new FormControl(contactInfos && contactInfos.length
                ? contactInfos.find((contact) => contact.contactMethod == 2).contactString : null, [Validators.email]),
            hipaaConsent: new FormControl(getConsentValue(hipaaConsent), [Validators.required, isFilled]),
            hipaaConsentSignatureDate: new FormControl(hipaaConsent?.dateReceived, hipaaConsent?.consentIsOnFile == true ? [Validators.required, isFilled] : null),
            socialSecurityNumber: new FormControl(patient ? patient.socialSecurityNumber : null, ssnValidator),
        });

        return formGroup.controls;
    }

    getPatientInsuranceFormControls() {
        let plan = this.case.patientInsurance;

        let formGroup = new FormGroup({
            name: new FormControl(plan ? plan.planName : '', [Validators.required]),
            planId: new FormControl(plan ? plan.planId : ''),
            group: new FormControl(plan ? plan.groupNumber : ''),
            policyHolderName: new FormControl(plan ? plan.policyHolderName : ''),
            policyHolderDOB: new FormControl(plan ? plan.policyHolderDOB : ''),
            planType: new FormControl(plan ? plan.planType : '', Validators.required),
            planOrder: new FormControl(plan ? (plan.isPrimary == null ? '' : (plan.isPrimary ? 1 : 0)) : '', Validators.required),
            planPhone: new FormControl(plan ? plan.planPhone : '', phoneValidator),
        });

        return plan && (plan.planId || !plan.patientId) ? formGroup.controls : [];
    }

    getPhysicianFormControls() {
        let physician = this.case.physician;
        let facility = this.case.facility;

        let phone = facility && facility.contactInfos ? facility.contactInfos.find(x => x.contactMethod == ContactMethod.phone) : null;
        let fax = facility && facility.contactInfos ? facility.contactInfos.find(x => x.contactMethod == ContactMethod.fax) : null;
        let email = facility && facility.contactInfos ? facility.contactInfos.find(x => x.contactMethod == ContactMethod.email) : null;

        let formGroup = new FormGroup({
            hasPhysician: new FormControl(physician ? '1' : '', Validators.required),
            officeName: new FormControl(email ? email.name : '', Validators.required),
            officeEmail: new FormControl(email ? email.contactString : '', [Validators.required, Validators.email]),
            facilityName: new FormControl(facility ? facility.name : ''),
            tax: new FormControl(facility ? facility.groupTaxId : ''),
            address: new FormControl(facility ? facility.address.streetAddress : ''),
            zipCode: new FormControl(facility ? facility.address.zipCode : '', Validators.required),
            officePhone: new FormControl(phone ? phone.contactString : '', Validators.required),
            officeFax: new FormControl(fax ? fax.contactString : '', Validators.required),
        });

        return formGroup.controls;
    }

    getClinicalFormControls() {
        let formGroup = new FormGroup({
            primaryDiagnosticCode: new FormControl(this.case.diagnosis ? this.case.diagnosis.code : '', [Validators.required]),
        });

        return formGroup.controls;
    }

    getPrescriptionFormControls() {
        let prescription = this.case.prescription;
        let formGroup = new FormGroup({
            medication: new FormControl(prescription && prescription.product ? prescription.product.id : '', [Validators.required]),
            dosage: new FormControl(prescription ? prescription.dosage : null, [Validators.required]),
            quantity: new FormControl(prescription ? prescription.quantity : null, [Validators.required, numberValidator, isPositive]),
            physicianSignatureDate: new FormControl(prescription ? prescription.physicianSignatureDate : null, prescription && prescription.physicianSignature ? [Validators.required, isFilled] : null),
            refillsNumber: new FormControl(prescription ? prescription.refillsNumber : null, numberValidator),
            signature: new FormControl(prescription
                ? (prescription.physicianSignature == false
                    ? 1
                    : (prescription.physicianSignature == true ? 0 : null))
                : null, [Validators.required, isFilled]),
        });

        return formGroup.controls;
    }

    getNextStackHandler() {
        this.router.navigate(['/document-data-entry']);
        if (this.documentBatchId) {
            this.dataEntryService.setDocumentBatchCompleted(Number(this.documentBatchId)).subscribe(
                (response) => {
                    this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
                    this.resetFields();
                },
                (error) => throwError(error)
            );
        }
    }

    resetFields() {
        this.cases = [];
        this.case = null;
        this.loadingCases = false;
        this.missedInfo = null;
        this.contactString = '';
        this.patient = null;
        this.documentBatchId = '';
        this.settings = this.getSettingsObject();
    }

    getSettingsObject() {
        return {
            activeTab: '',
            patient: {
                isValid: null,
                validate: {
                    invalid: [],
                    requiredBlank: [],
                    desiredBlank: [],
                },
                fields: PatientFormFields,
                form: 'patientForm'
            },
            insurance: {
                isValid: null,
                validate: {
                    invalid: [],
                    requiredBlank: [],
                    desiredBlank: [],
                },
                fields: InsuranceFormFields,
                form: 'insuranceForm'
            },
            physician: {
                isValid: null,
                validate: {
                    invalid: [],
                    requiredBlank: [],
                    desiredBlank: [],
                },
                fields: PhysicianFormFields,
                form: 'physicianForm'
            },
            clinical: {
                isValid: null,
                validate: {
                    invalid: [],
                    requiredBlank: [],
                    desiredBlank: [],
                },
                fields: ClinicalFormFields,
                form: 'clinicalForm'
            },
            prescription: {
                isValid: null,
                validate: {
                    invalid: [],
                    requiredBlank: [],
                    desiredBlank: [],
                },
                fields: PrescriptionFormFields,
                form: 'prescriptionForm'
            },
        };
    }
}
