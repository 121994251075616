import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { Store } from '@ngrx/store';
import { getUserRole } from '../../shared/helpers/utils';
import { IUser } from '../../shared/interfaces/user.interface';
import { User } from '../../shared/models/models';
import * as UserAction from '../../store/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router,
    private store: Store<any>,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const user = new User();
    const isAuthenticated = await this.oktaAuth.isAuthenticated();
    let userState;

    this.store.select('user').subscribe((res: IUser) => {
      userState = res;
    });

    if (isAuthenticated) {
      if (!userState) {
        const currentUser = await this.oktaAuth.getUser();
        user.name = currentUser.name;
        user.role = getUserRole(currentUser);
        user.email = currentUser.email;
        this.store.dispatch(new UserAction.SetUser(user));
      } else {
        user.name = userState.name;
        user.role = userState.role;
      }

      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    await this.oktaAuth.login(state.url);
    this.router.navigate(['/login']);

    return false;
  }
}
