import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { RescheduleTaskComponent } from '../reschedule/reschedule.component';

@Component({
    selector: 'app-reschedule-task',
    templateUrl: './exhausted.component.html',
    styleUrls: ['../reschedule/reschedule.component.scss', './exhausted.component.scss'],
})
export class ExhaustedTaskComponent implements OnInit {
    @Output() scheduleFollowUp = new EventEmitter();
    @Output() escalateToSupervisor = new EventEmitter();
    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog) { }

    rescheduleTask(data) {
        let dialogRef;
        dialogRef = this.dialog.open(RescheduleTaskComponent, { data });
        const dialog: any = dialogRef.componentInstance;
        dialog.scheduleFollowUp.subscribe((resp) => {
            this.scheduleFollowUp.emit(resp);
        });
    }

    escalateToSupervisorHandler() {
        this.escalateToSupervisor.emit();
    }

    ngOnInit(): void { }
}
