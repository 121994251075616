import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuthService } from '@okta/okta-angular';
import { IUser } from '../../shared/interfaces/interfaces';
import { Roles } from '../../shared/enums/enums';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user: IUser;
  roles = Roles;

  constructor(
    public oktaAuth: OktaAuthService,
    private store: Store<any>,
  ) {
  }

  ngOnInit(): void {
    this.store.select('user').subscribe((state) => {
      this.user = state;
    });
  }
}
