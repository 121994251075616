<div class="queue-wrapper">
    <div *ngIf="!queueList && !intakeQueueList" class="dashboard-loader-container">
        <app-loader></app-loader>
    </div>
    <div *ngIf="queueList || intakeQueueList">
        <h2 *ngIf="queueList && queueList.length">
            Patient Management Queues
        </h2>
        <app-queue-item *ngFor="let queue of queueList" [data]="queue" [viewRoute]="'queue-' + queue.id"
            [beginRoute]="'document-' + queueIDs[queue.id]">
        </app-queue-item>
        <h2 *ngIf="intakeQueueList && intakeQueueList.length">
            Intake Queues
        </h2>
        <app-queue-item *ngFor="let queue of intakeQueueList" [data]="queue" [viewRoute]="'queue-' + queueIDs[queue.id]"
            [beginRoute]="'document-' + queueIDs[queue.id]">
        </app-queue-item>
    </div>
</div>