<h2>Physician</h2>
<div class="details-wrapper">
  <ng-scrollbar class="custom-scrollbar case-scrollbar">
    <div class="sub-section consent">
      <div class="header">
        Physician
      </div>
      <app-editable-aside-field [fieldId]="'physician'" (editOutside)="openPhysicianEditDialog('physician')"
        [editMode]="false">
        <div text class="detail-item">
          <h3>HCP Name</h3>
          <p>{{ physician.firstName | empty }} {{ physician.lastName | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>NPI</h3>
          <p>{{ physician.npi | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>HCP Active?</h3>
          <p>{{ physician.hcpActive | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>HCP Specialty</h3>
          <p>{{ physician.hcpSpecialty | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Physician ID</h3>
          <p>{{ physician.id | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>DEA</h3>
          <p>{{ physician.dea | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>SLN</h3>
          <p>{{ physician.sln | empty }}</p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section consent">
      <div class="header">
        Office Info
      </div>
      <app-editable-aside-field [fieldId]="'officeInfo'" (editOutside)="openPhysicianEditDialog('office')"
        [editMode]="false">
        <div text class="detail-item">
          <h3>Office Name</h3>
          <p>{{ facility.name | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Office ID</h3>
          <p>{{ facility.id | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Office Address</h3>
          <p>{{ facility.address?.streetAddress | empty }},
            {{ facility.address?.city | empty }},
            {{ facility.address?.zipCode | empty }}
          </p>
        </div>
        <div text class="detail-item">
          <h3>Office Phone</h3>
          <p>{{ officePhone | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Office Fax</h3>
          <p>{{ officeFax | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section">
      <div class="header">
        Contact
      </div>
      <app-editable-aside-field [fieldId]="'contact'" [disableSave]="mobileForm.invalid"
        (saveData)="savePhysicianData($event, ['contactInfos', 'mobile', ContactMethod.phone, ContactType.mobile])">
        <div text class="detail-item">
          <h3>Office Contact</h3>
          <p>{{ mobileForm.value.mobile | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="mobileForm">
            <div class="input-row input-item" [class]="
            mobileForm.controls['mobile'].invalid &&
            (mobileForm.controls['mobile'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="secondPhone">Home Phone (2nd)</label>
              <input mask="(000) 000-0000" placeholder="(_ _ _) _ _ _ - _ _ _ _" id="secondPhone" maxlength="50"
                formControlName="mobile" />
            </div>
          </form>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'email'" [disableSave]="emailForm.invalid"
        (saveData)="savePhysicianData($event, ['contactInfos', 'email', ContactMethod.email, ContactType.work])">
        <div text class="detail-item">
          <h3>Office Email</h3>
          <p>{{ emailForm.value.email | empty }}</p>
        </div>

        <div inputs class="detail-item">
          <form [formGroup]="emailForm">
            <div class="input-row input-item" [class]="
            emailForm.controls['email'].invalid &&
            (emailForm.controls['email'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="email">Email</label>
              <input id="email" maxlength="50" formControlName="email" matInput />
            </div>
          </form>
        </div>
      </app-editable-aside-field>
    </div>
  </ng-scrollbar>
</div>