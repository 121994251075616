import {
  IUser,
  ISearchOptions,
  ISearchAdvancedOptions
} from '../interfaces/interfaces';

export class User implements IUser {
  name: string = '';
  role: string = '';
  img: string = '';
  email: string = '';
}

// for future needs
export class AdvancedSearchOptions implements ISearchAdvancedOptions {
  field: string = '';
  exact: boolean = false;
  search: string = '';
}

export class SearchOptions implements ISearchOptions {
  durationValue: string = 'today';
  searchValue: string = '';
  statusValue: string = 'all';
  isAdvanced: boolean = false;
  date: { startDate: string; endDate: string } = { startDate: '', endDate: '' };
  advancedOptions: ISearchAdvancedOptions[] = [new AdvancedSearchOptions()];
}
