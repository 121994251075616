import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, TaskOutcome } from 'src/app/shared/enums/enums';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
    selector: 'app-task-followup-benefit',
    templateUrl: './followup-benefit.component.html',
    styleUrls: ['../task.component.scss', './followup-benefit.component.scss'],
})
export class FollowupBenefitTaskComponent implements OnInit {
    @Input() selectedTask;
    @Input() case;
    @Output() minimizeTaskHandler = new EventEmitter();
    @Output() setSelectedTaskHandler = new EventEmitter();
    @Output() rescheduleTaskHandler = new EventEmitter();
    @Output() submitTaskHandler = new EventEmitter();
    interpolatedDescription;
    followUpForm: FormGroup;
    contactMethods = {
        phone: null,
        fax: null,
        email: null,
    };

    constructor() {
        this.followUpForm = new FormGroup({
            contact: new FormControl(null, [Validators.required]),
            proceed: new FormControl(null, [Validators.required]),
            completed: new FormControl(null, [Validators.required]),
            note: new FormControl(null, [Validators.required]),
        });
    }

    minimizeTask(boolean) {
        this.selectedTask.formData = this.followUpForm.getRawValue();
        this.minimizeTaskHandler.emit(boolean);
    }

    setSelectedTask(task) {
        this.setSelectedTaskHandler.emit(task);
    }

    submitTask() {
        const data = {
            queueItemId: this.case.id,
            queueTaskId: this.selectedTask.id,
            taskOutcome: null,
            outcomeNote: this.followUpForm.value.note,
        };
        if (
            this.followUpForm.value.contact == 0 &&
            this.followUpForm.value.proceed == 0 &&
            this.followUpForm.value.completed == 0
        ) {
            data.taskOutcome = TaskOutcome.success;
        } else if (this.followUpForm.value.proceed == 1) {
            data.taskOutcome = TaskOutcome.failure;
        } else {
            data.taskOutcome = TaskOutcome.repeat;
        }
        this.submitTaskHandler.emit(data);
    }

    rescheduleTask() {
        const data = {
            attemptNumber: this.selectedTask.attemptNumber,
            task: this.selectedTask.task,
            outcomeNote: this.followUpForm.value.note,
            case: this.case,
        };
        this.rescheduleTaskHandler.emit(data);
    }

    ngOnInit(): void {
        this.interpolatedDescription =
            this.case.facility && this.case.physician
                ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject())
                : this.selectedTask.description;
        if (this.selectedTask.formData) {
            this.followUpForm.patchValue(this.selectedTask.formData);
        }
    }

    getDescriptionTemplateObject() {
        let facilityPhone =
            this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
                ? this.case.facility.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
                : null;
        let facilityName = this.case.facility?.name || '';
        let physicianName = `${this.case.physician?.firstName || ''} ${this.case.physician?.lastName || ''}`;

        this.contactMethods.phone = formatPhone(facilityPhone?.contactString);
        this.contactMethods.fax =
            this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
                ? formatPhone(
                    this.case.facility.contactInfos.find((contact) => contact.contactMethod == ContactMethod.fax)
                        ?.contactString
                )
                : null;
        this.contactMethods.email =
            this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
                ? this.case.facility.contactInfos.find((contact) => contact.contactMethod == ContactMethod.email)
                    ?.contactString
                : null;

        return {
            facilityPhone: `<a href="#" class="highlighted">${formatPhone(facilityPhone?.contactString)}</a>`,
            facilityName: `<span class="highlighted">${facilityName}</span>`,
            physicianName: `<span class="highlighted">Dr. ${physicianName}</span>`,
        };
    }
}
