import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupervisorDisableQueueComponent } from './disable/disable.component';
import { trigger, state, style, transition, animate, group, query, stagger, keyframes } from '@angular/animations';

@Component({
    selector: 'app-supervisor-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['../territories/territories.component.scss', './settings.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 0 })),
            transition(':leave', [
                style({ opacity: 1 }),

                group([animate('300ms ease-in-out', style({ opacity: '0' }))]),
            ]),
            transition(':enter', [
                style({ opacity: 0 }),

                group([animate('300ms ease-in-out', style({ opacity: '1' }))]),
            ]),
        ]),
    ],
})
export class SupervisorSettingsComponent implements OnInit {
    @Input() userCanPull: string;
    @Input() active: string;
    @Output() settingsSaved = new EventEmitter();
    saved = {
        pull: false,
        status: false
    }
    constructor(public dialog: MatDialog) {
    }

    saveSettings(saved) {
        let model = {
            active: this.active,
            userCanPull: this.userCanPull
        }
        this.settingsSaved.emit(model);
        this.saved[saved] = true;
        setTimeout(()=> {
            this.saved[saved] = false;
        }, 2000);
    }

    changePull() {
        this.saveSettings("pull");
    }

    changeStatus() {
        if (this.active == 'disable') {
            let dialogRef = this.dialog.open(SupervisorDisableQueueComponent, {
                data: {},
                panelClass: 'removeDialog'
            });
            const disableSub = dialogRef.componentInstance.queueDisabled.subscribe(() => {
                this.saveSettings("status");
            });
            const enableSub = dialogRef.componentInstance.queueEnabled.subscribe(() => {
                this.active = 'enable'
            });
            dialogRef.afterClosed().subscribe(() => {
                disableSub.unsubscribe();
                enableSub.unsubscribe();
            });
        }
        else {
            this.saveSettings("status");
        }
    }

    ngOnInit(): void { }
}
