<div class="search-form">
    <h2>Search Cases</h2>
    <div class="input-item search">
        <img src="../../../../../assets/icons/icon-search.svg" alt="search" />
        <input matInput type="text" [(ngModel)]="searchOptions.searchValue"
            placeholder="Search by Patient, Case ID, Payer, etc." (input)="openModal()" />
        <button mat-button *ngIf="searchOptions.searchValue" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchOptions.searchValue = ''">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <button class="primary-btn" (click)="goToSearchPage()">Search</button>
    <!-- <button (click)="searchOptions.isAdvanced = true" class="primary-btn invert">Advanced Search</button> -->
    <div class="suggest-wrapper">
        <div class="suggest-item" *ngFor="let case of suggestedCases" (click)="openCase(case.id)">
            <b>{{case.patient}}</b><span>Case #{{case.id}}</span>
        </div>
    </div>
</div>
<!-- <div *ngIf="searchOptions.isAdvanced">
    <mat-button-toggle-group
        #group="matButtonToggleGroup"
        name="status"
        aria-label="Status"
        [(ngModel)]="searchOptions.statusValue"
        (change)="showCasesHandler()"
    >
        <mat-button-toggle value="all">All Cases</mat-button-toggle>
        <mat-button-toggle value="open">Open Cases</mat-button-toggle>
        <mat-button-toggle value="closed">Closed Cases</mat-button-toggle>
    </mat-button-toggle-group>
</div>
<div *ngIf="searchOptions.isAdvanced">
    <h3>Date</h3>
    <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDate" [(ngModel)]="searchOptions.date.startDate" />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDate" [(ngModel)]="searchOptions.date.endDate" />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>
    <h3>Fields</h3>
    <div class="advanced-search-item" *ngFor="let searchItem of searchOptions.advancedOptions">
        <mat-form-field appearance="fill">
            <mat-label>Field</mat-label>
            <mat-select [(ngModel)]="searchItem.field" [name]="searchItem.field">
                <mat-option *ngFor="let caseItem of caseColumns" [value]="caseItem">
                    {{ caseItem }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-button-toggle-group
            #group="matButtonToggleGroup"
            [name]="searchItem.field + '-exact'"
            aria-label="Exact"
            [(ngModel)]="searchItem.exact"
        >
            <mat-button-toggle [value]="false">Contains</mat-button-toggle>
            <mat-button-toggle [value]="true">Exact</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field class="example-form-field">
            <mat-label>Search term</mat-label>
            <input matInput type="text" [(ngModel)]="searchItem.search" />
            <button
                mat-button
                *ngIf="searchItem.search"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchItem.search = ''"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <button (click)="addSearchHandler()">Add Search</button>

    <button (click)="searchOptions.isAdvanced = false; clearSearchHandler()">Close</button>

    <button (click)="showCasesHandler()">Apply</button>
</div> -->