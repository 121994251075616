<div class="task-wrapper">
    <div class="top-block">
        <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
        <h2>{{ selectedTask.task }}</h2>
        <div class="description">
            <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
            <p [innerHtml]="interpolatedDescription"></p>
        </div>
        <div class="buttons-block">
            <button class="collapse" (click)="minimizeTask(true)">
                <mat-icon>remove</mat-icon>
            </button>
            <button class="close" (click)="setSelectedTask(null)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task-block" [formGroup]="followUpForm">
        <div class="task-item contact-outcome">
            <h3>Contact Outcome</h3>
            <div class="outcome-item">
                <div class="input-item toggle">
                    <mat-button-toggle-group formControlName="contact">
                        <mat-button-toggle [value]="0"> Yes </mat-button-toggle>
                        <mat-button-toggle [value]="1"> No </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <span>Successfully contacted payer?</span>
            </div>
            <div class="outcome-item">
                <div class="input-item toggle">
                    <mat-button-toggle-group formControlName="received" [disabled]="followUpForm.value.contact == null">
                        <mat-button-toggle [value]="0"> Yes </mat-button-toggle>
                        <mat-button-toggle [value]="1"> No </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <span>Did payer receive the Prior Authorization Form from the HCP?</span>
            </div>
        </div>
        <div class="task-item paOutcome">
            <h3>Prior Authorization Outcome</h3>
            <div class="input-item toggle">
                <mat-button-toggle-group formControlName="paOutcome" [disabled]="followUpForm.value.received == null">
                    <mat-button-toggle [value]="0"> In Process </mat-button-toggle>
                    <mat-button-toggle [value]="1"> Approved </mat-button-toggle>
                    <mat-button-toggle [value]="2"> Denied </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="task-item" *ngIf="followUpForm.value.paOutcome == 1">
            <h3>Prior Authorization Details</h3>
            <div class="form-row">
                <div class="input-item">
                    <label>Authorization #</label>
                    <input formControlName="paId" />
                </div>
            </div>
            <div class="form-row date-row">
                <div class="input-item">
                    <label for="startDate">PA Valid Through</label>
                    <div class="date-input">
                        <input matInput [matDatepicker]="startDate" formControlName="paStartDate" placeholder="MM/DD/YY"
                            (focus)="startDate.open()" (click)="startDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="startDate" tabindex="-1"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </div>
                </div>
                <div class="devider">&mdash;</div>
                <div class="input-item">
                    <div class="date-input">
                        <input matInput [matDatepicker]="endDate" formControlName="paEndDate" placeholder="MM/DD/YY"
                            [min]="followUpForm.value.paStartDate" (focus)="endDate.open()" (click)="endDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="endDate" tabindex="-1"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="task-item outcome-note">
            <h3>
                <mat-icon>assignment</mat-icon>Outcome Note
            </h3>
            <textarea matInput formControlName="note"></textarea>
        </div>
    </div>
    <div class="task-controls">
        <button mat-button class="primary-btn invert reschedule" (click)="rescheduleTask()"
            [disabled]="followUpForm.value.contact == null || followUpForm.value.paOutcome == 2">
            <mat-icon>schedule</mat-icon>Reschedule
        </button>
        <button mat-button class="primary-btn invert done" (click)="submitTask()" [disabled]="
                !(
                    followUpForm.value.paOutcome == 2 ||
                    (followUpForm.value.paOutcome == 1 && followUpForm.value.paId && followUpForm.value.paStartDate && followUpForm.value.paEndDate)
                )
            ">
            <mat-icon>done</mat-icon>Submit
        </button>
    </div>
</div>