import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { enumToArray, getSuggestedCases } from '../../../helpers/utils';
import { CaseColumns } from '../../../enums/enums';
import { SearchOptions, AdvancedSearchOptions } from 'src/app/shared/models/models';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
})
export class SearchFieldComponent implements OnInit {
    searchOptions = new SearchOptions();
    suggestedCases = [];
    constructor(public dialog: MatDialog, private router: Router) { }

    caseColumns = enumToArray(CaseColumns);

    openModal() {
        if (this.searchOptions.searchValue.length >= 3) {
            this.dialog.open(SearchModalComponent, {
                data: { searchValue: this.searchOptions.searchValue },
                panelClass: 'searchDialog',
            });
        }
    }

    ngOnInit(): void {
        this.suggestedCases = getSuggestedCases();
    }

    addSearchHandler() {
        // this.addSearch.emit();
    }

    goToSearchPage() {
        this.router.navigate(['search'], { state: { searchValue: this.searchOptions.searchValue, cases: [] } });
    }

    clearSearchHandler() {
        this.searchOptions = new SearchOptions();
        // this.clearSearch.emit();
    }

    showCasesHandler() {
        // this.showCases.emit({
        //     search: this.searchOptions.searchValue,
        //     status: this.searchOptions.statusValue,
        // });
    }

    openCase(caseId) {
        this.router.navigate(['/case'], { queryParams: { id: caseId } })
    }
}
