<!--<div class="notification">
    <p>
        <img src="../../../../../assets/icons/icon-warning.svg" alt="warning" /> Based on recent stats, we recommend
        <b> adding a case manager </b> to this queue.
    </p>
</div>-->

<table [hidden]="!_users.length" mat-table [dataSource]="dataSource" matSort matSortActive="rank" matSortDirection="asc">
    <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Managers</th>
        <td mat-cell *matCellDef="let user">
            <div class="user-name" [class.inactive]="!user.active">
                <div class="user-img">
                    <mat-icon>account_circle</mat-icon>
                </div>
                {{ user.userName }}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="avgTasks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Avg. tasks / day</th>
        <td mat-cell *matCellDef="let user">{{ user.avgTasks }}</td>
    </ng-container>
    <ng-container matColumnDef="tasksOnTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasks on Time</th>
        <td mat-cell *matCellDef="let user">{{ user.tasksOnTime }}</td>
    </ng-container>
    <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rank</th>
        <td mat-cell *matCellDef="let user">
            <div class="user-rank">
                <img *ngIf="user.rank < user.prevRank" src="../../../../../assets/icons/icon-arrow-up.svg" alt="up" />
                <img *ngIf="user.rank > user.prevRank" src="../../../../../assets/icons/icon-arrow-down.svg"
                    alt="down" />
                <span [style]="user.rank == user.prevRank ? 'margin-left: 28px' : null">{{ user.rank }}</span>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef>
            <button class="primary-btn" (click)="addUser()">
                <mat-icon>add_circle</mat-icon>Add User
            </button>
        </th>
        <td mat-cell *matCellDef="let user">
            <button class="remove-button" (click)="removeUser(user.id)">
                <mat-icon>close</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let user; columns: columnsToDisplay"></tr>
</table>
<app-manage-empty-queue *ngIf="!_users.length" (addFunction)="addUser()" type="users"></app-manage-empty-queue>