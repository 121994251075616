import { Pipe, PipeTransform } from '@angular/core';
import { formatPhone } from '../helpers/utils';

@Pipe({
    name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
    transform(input: any[]): any {
        return formatPhone(input);
    }
}
