import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-case-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    @Input() phaseList;
    @Input() shownPhase;
    @Output() showPhase = new EventEmitter();

    showPhaseHandler(phase) {
        this.showPhase.emit(phase);
    }

    constructor() {}

    ngOnInit(): void {}
}
