<form [formGroup]="prescriptionForm" novalidate (ngSubmit)="submitHandler()" (keydown.enter)="$event.preventDefault()">
    <div class="aside-wrapper">
        <h2>Prescription</h2>
        <div class="form-row full-row">
            <div [class]="
                    prescriptionForm.controls['medication'].invalid && 
                    (prescriptionForm.controls['medication'].touched || settings.isValid == false)
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>{{ prescriptionFormFields.medication }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="medication" name="medication" placeholder="Select" id="medication"
                        disableOptionCentering panelClass="dropdown-item" #medication>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let product of products" [value]="product.id">
                            <b>{{product.name}}</b>&nbsp;<span>{{product.code}} </span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="input-item" [ngClass]="{
                'input-error': prescriptionForm.controls['dosage'].invalid && 
                (prescriptionForm.controls['dosage'].touched || settings.isValid == false),
                'input-success': prescriptionForm.controls['dosage'].valid && submitted
            }">
                <label>{{ prescriptionFormFields.dosage }}</label>
                <input name="dosage" id="dosage" formControlName="dosage" />
            </div>
        </div>
        <div class="form-row">
            <div class="input-item" [ngClass]="{
                    'input-error': prescriptionForm.controls['quantity'].invalid &&
                    (prescriptionForm.controls['quantity'].touched || settings.isValid == false),
                    'input-success': prescriptionForm.controls['quantity'].valid && submitted
                }">
                <label for="quantity">{{ prescriptionFormFields.quantity }}</label>
                <input name="quantity" id="quantity" formControlName="quantity" />
            </div>
        </div>
        <div class="form-row">
            <div [class]="
                    prescriptionForm.controls['signature'].invalid && 
                    (prescriptionForm.controls['signature'].touched || settings.isValid == false)
                        ? 'input-item input-error select'
                        : 'input-item select'
                ">
                <label>* {{ prescriptionFormFields.signature }}</label>
                <mat-form-field appearance="fill">
                    <mat-select formControlName="signature" name="signature" placeholder="Select" id="signature"
                        disableOptionCentering (selectionChange)="selectSignature()" panelClass="dropdown-item">
                        <mat-option>--</mat-option>
                        <mat-option [value]="0">Yes</mat-option>
                        <mat-option [value]="1">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row date-row" *ngIf="prescriptionForm.value.signature == 0">
            <div [class]="
            prescriptionForm.controls['physicianSignatureDate'].invalid &&
                    (prescriptionForm.controls['physicianSignatureDate'].touched || settings.isValid == false || submitted)
             ? 'input-item input-error' : 'input-item'
                ">
                <label for="physicianSignatureDate">* {{ prescriptionFormFields.physicianSignatureDate }}</label>
                <div class="date-input">
                    <input matInput [matDatepicker]="physicianSignatureDate" id="physicianSignatureDate"
                        formControlName="physicianSignatureDate" placeholder="MM/DD/YY" [min]="disableDate"
                        (focus)="physicianSignatureDate.open()" (click)="physicianSignatureDate.open()" />
                    <mat-datepicker-toggle matSuffix [for]="physicianSignatureDate" tabindex="-1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #physicianSignatureDate></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="input-item" [ngClass]="{
                    'input-error': prescriptionForm.controls['refillsNumber'].invalid &&
                    (prescriptionForm.controls['refillsNumber'].touched || settings.isValid == false),
                    'input-success': prescriptionForm.controls['refillsNumber'].valid && submitted
                }">
                <label for="refillsNumber">{{ prescriptionFormFields.refillsNumber }}</label>
                <input name="refillsNumber" id="refillsNumber" formControlName="refillsNumber" />
            </div>
        </div>
    </div>
    <button type="submit" class="next-button next-stack" #nextBtn (keydown.enter)="submitHandler()">
        <b>Complete</b>
    </button>
</form>