<div class="task-wrapper">
    <div class="task-title">
        <h2>Prior Authorization</h2>
    </div>
    <div class="task-description-wrapper">
        <div class="task-description">
            <div class="task-description-item" style="width: 200px">
                <h5>Outcome</h5>
                <p>Approved</p>
            </div>
            <div class="task-description-item">
                <h5>Phase Started</h5>
                <p>{{started | date: "M/d/yyyy \'at\' h:mm aaaaa\'m\'" | empty}}</p>
            </div>
            <div class="task-description-item">
                <h5>Phase Completed</h5>
                <p>{{completed | date: "M/d/yyyy \'at\' h:mm aaaaa\'m\'" | empty}}</p>
            </div>
            <div class="task-description-item">
                <h5>Time in Phase</h5>
                <p>{{timeInPhase | empty}} (Avg. is 2 days)</p>
            </div>
        </div>
    </div>
</div>
<div class="prior-wrapper">
    <div class="prior-item" *ngFor="let item of data; index as i">
        <h2>
            PA #{{ data.length - i }}
            <span *ngIf="isDateInFuture(item.endDate)">Expires {{ item.endDate | date: 'MM/dd/yyyy' }}</span>
        </h2>

        <div class="form-row">
            <div class="input-item">
                <label>Authorization #</label>
                <input [(ngModel)]="data[i].authorizationNumber" disabled />
            </div>
        </div>

        <div class="form-row date-row">
            <div class="input-item">
                <label for="startDate">{{isDateInFuture(item.endDate) ? "Start Date" : "PA Valid Through"}}</label>
                <div class="date-input">
                    <input matInput [matDatepicker]="startDate" [(ngModel)]="data[i].startDate" placeholder="MM/DD/YY"
                        disabled />
                    <mat-datepicker-toggle matSuffix [for]="startDate" tabindex="-1"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </div>
            </div>
            <div class="devider" *ngIf="!isDateInFuture(item.endDate)">&mdash;</div>
            <div class="input-item" *ngIf="!isDateInFuture(item.endDate)">
                <div class="date-input">
                    <input matInput [matDatepicker]="endDate" [(ngModel)]="data[i].endDate" placeholder="MM/DD/YY"
                        disabled />
                    <mat-datepicker-toggle matSuffix [for]="endDate" tabindex="-1"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </div>
            </div>
        </div>
    </div>
</div>
