import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { ContactMethod, TaskOutcome, PatientSegmentation } from 'src/app/shared/enums/enums';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';
import { CaseService } from '../../../services/case.service';

@Component({
  selector: 'app-task-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../task.component.scss', './welcome.component.scss'],
})
export class WelcomeTaskComponent implements OnInit {
  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();
  welcomeForm: FormGroup;
  interpolatedDescription;
  interpolatedWelcomeScript;

  @Input() case;
  @Input() selectedTask;

  constructor(public caseService: CaseService) {
    this.welcomeForm = new FormGroup({
      contact: new FormControl(null, [Validators.required]),
      successful: new FormControl(null, [Validators.required]),
      segmentation: new FormControl(null, [Validators.required]),
      kit: new FormControl(null, [Validators.required]),
      note: new FormControl(null, [Validators.required]),
    });
  }

  minimizeTask(boolean) {
    this.selectedTask.formData = this.welcomeForm.getRawValue();
    this.minimizeTaskHandler.emit(boolean);
  }

  submitTask() {
    this.updatePatient(this.submitTaskData.bind(this), throwError);
  }

  submitTaskData() {
    let contactPatient = this.welcomeForm.value.contact == '0';
    let contactSuccessful = this.welcomeForm.value.successful == '0';
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: contactPatient && contactSuccessful ? TaskOutcome.success : TaskOutcome.failure,
      outcomeNote: this.welcomeForm.value.note
    };
    
    this.submitTaskHandler.emit(data);
  }

  updatePatient(successCallback, errorCallback) {
    let patientData = {
      patient: {
        ...this.case.patient
      }
    };
    patientData.patient.experienced = this.welcomeForm.value.segmentation ? this.welcomeForm.value.segmentation == PatientSegmentation.experienced : null;
    this.caseService.updatePatient(patientData).subscribe((response) => successCallback(response), error => errorCallback(error));
  }

  setSelectedTask(task) {
    this.setSelectedTaskHandler.emit(task);
  }

  rescheduleTask() {
    const data = {
      attemptNumber: this.selectedTask.attemptNumber,
      outcomeNote: this.welcomeForm.value.note,
      task: this.selectedTask.task,
      case: this.case,
      defaultType: ContactMethod.text
    };
    this.rescheduleTaskHandler.emit(data);
  }

  ngOnInit(): void {
    this.interpolatedDescription = interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject());
    this.interpolatedWelcomeScript = interpolateTemplate(this.selectedTask.script, this.getWelcomeScriptTemplateObject());
    this.selectedTask.formData = {
      ...this.selectedTask.formData,
      segmentation: this.selectedTask.formData?.segmentation || (this.case.patient.experienced != null
        ? (this.case.patient.experienced ? PatientSegmentation.experienced.toString() : PatientSegmentation.naive.toString()) : null),
    };
    this.welcomeForm.patchValue(this.selectedTask.formData);
  }

  getDescriptionTemplateObject() {
    let patientName = `${this.case.patient.firstName || ''} ${this.case.patient.lastName || ''}`;
    let phone = this.case.patient.contactInfos && this.case.patient.contactInfos.length
      ? this.case.patient.contactInfos.find((contact) => contact.contactMethod == ContactMethod.phone)
      : null;
    let genderPronoun = this.case.patient.gender == 0 ? 'her' : (this.case.patient.gender == 1 ? 'him' : 'them');
    return {
      patientName: `<span class="highlighted">${patientName}</span>`,
      patientPhone: `<a href="#" class="highlighted">${formatPhone(phone?.contactString)}</a>`,
      genderPronoun: genderPronoun
    };
  }

  getWelcomeScriptTemplateObject() {
    return {
      patientName: `${this.case.patient.firstName || ''}`,
      newLine: '<br><br>',
      caseManagerName: 'Heather'
    }
  }
}
