<div class="navigation">
    <ul>
        <li (click)="showPhaseHandler('')" [class.active]="!shownPhase">
            <img *ngIf="shownPhase" src="../../../../../../assets/icons/icon-activity.svg" />
            <img *ngIf="!shownPhase" src="../../../../../../assets/icons/icon-activity-active.svg" />
            <span>Activity</span>
        </li>
        <li
            *ngFor="let phase of phaseList"
            (click)="showPhaseHandler(phase.id)"
            [class.active]="shownPhase == phase.id"
            [class.disabled]="phase.disabled"
            [class]="phase.id"
        >
            <img
                *ngIf="shownPhase == phase.id; else active"
                [src]="'../../../../../assets/icons/' + phase.id + '-active.svg'"
            />
            <ng-template #active>
                <img [src]="'../../../../../assets/icons/' + phase.id + '.svg'" />
            </ng-template>
            <span>{{ phase.phase }}</span>
        </li>
    </ul>
</div>
