<div mat-dialog-title>
    <h1><img alt="age" src="../../../../../../assets/icons/icon-age-red.svg" />Reschedule</h1>
    <button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <p>
        You’re creating a <b>Follow-up</b> for this <b>{{data.task}}</b>
    </p>
    <div class="dialog-body" [formGroup]="rescheduleForm">
        <div class="input-item toggle">
            <label>Follow-up Creation</label>
            <mat-button-toggle-group formControlName="creation">
                <mat-button-toggle value="automatic"> Automatic </mat-button-toggle>
                <mat-button-toggle value="manual"> Manual </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="attempt-time">
            <div class="input-item">
                <label for="attemptDate">Next Attempt Date</label>
                <div class="date-input">
                    <input matInput [matDatepicker]="attemptDate" id="attemptDate" formControlName="attemptDate" [min]="currentDate"
                        placeholder="MM/DD/YY" (focus)="attemptDate.open()" (click)="attemptDate.open()"
                        [disabled]="rescheduleForm.value.creation == 'automatic'" />
                    <mat-datepicker-toggle matSuffix [for]="attemptDate" tabindex="-1"></mat-datepicker-toggle>
                    <mat-datepicker #attemptDate></mat-datepicker>
                </div>
            </div>
            <div class="input-item">
                <label for="attemptTime">Next Attempt Time</label>
                <div class="date-input">
                    <input [ngxTimepicker]="attemptTime" id="attemptTime" formControlName="attemptTime"
                        [disabled]="rescheduleForm.value.creation == 'automatic'" />
                    <ngx-material-timepicker #attemptTime></ngx-material-timepicker>
                </div>
            </div>
        </div>
        <div class="input-item toggle attempt-type">
            <label>Next Attempt Type</label>
            <mat-button-toggle-group formControlName="attemptType"
                [disabled]="rescheduleForm.value.creation == 'automatic'">
                <mat-button-toggle value="0">
                    <mat-icon>call</mat-icon> Call
                </mat-button-toggle>
                <mat-button-toggle value="3">
                    <mat-icon>textsms</mat-icon> Text
                </mat-button-toggle>
                <mat-button-toggle value="2">
                    <mat-icon>email</mat-icon> Email
                </mat-button-toggle>
                <mat-button-toggle value="1">
                    <mat-icon>print</mat-icon> Fax
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="back" mat-dialog-close (click)="rescheduleTask(data)">
        <img alt="back" src="../../../../../../assets/icons/icon-age-blue.svg" />Schedule Follow-Up
    </button>
</div>