<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
    <td mat-cell *matCellDef="let case">{{ case.id }}</td>
  </ng-container>
  <ng-container matColumnDef="task">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
    <td mat-cell *matCellDef="let case">{{ case.task }}</td>
  </ng-container>
  <ng-container matColumnDef="physician">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Physician</th>
    <td mat-cell *matCellDef="let case">{{ case.physician }}</td>
  </ng-container>
  <ng-container matColumnDef="pharmacy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy</th>
    <td mat-cell *matCellDef="let case">{{ case.pharmacy }}</td>
  </ng-container>
  <ng-container matColumnDef="payer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer</th>
    <td mat-cell *matCellDef="let case">{{ case.payer }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status * Reason</th>
    <td mat-cell *matCellDef="let case">
      {{ case.status }} * <i>{{ case.reason }}</i>
    </td>
  </ng-container>
  <ng-container matColumnDef="due">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Task Due</th>
    <td mat-cell *matCellDef="let case">{{ case.due | date:'E, MMM d' }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let case; columns: columnsToDisplay" routerLink="/case" [queryParams]="{id: case.id}" (click)="selectCase(case)"></tr>
</table>
