import { ActionReducerMap } from '@ngrx/store';

import * as user from './user/user.reducers';
import * as selectedCase from './case/case.reducers';
import * as document from './document/document.reducers';

import { IUser, ICase } from '../shared/interfaces/interfaces';
import { IDocument } from '../features/intake/document/document.model'

export interface State {
    user: IUser;
    case: ICase;
    document: { documentList: [IDocument] };
}

export const reducers: ActionReducerMap<State> = {
    user: user.reducer,
    case: selectedCase.reducer,
    document: document.reducer,
};
