<div class="history-wrapper">
  <div class="timeline">
    <ul>
      <li *ngFor="let item of historyTimeline" [class.active]="item.status == 'Current'"
        [class.complete]="item.status == 'Complete'">
        <h5>{{ item.phase }}</h5>
        <div>
          <b>{{ item.spent }}</b> Est: {{ item.estimate }}
        </div>
      </li>
    </ul>
  </div>

  <h2>History</h2>

  <div class="filters">
    <mat-form-field appearance="fill">
      <mat-label>
        <mat-icon *ngIf="historyType.value && historyType.value.length">check_box</mat-icon>
        Type
        {{ historyType.value && historyType.value.length ? "(" + historyType.value.length + ")" : null }}
      </mat-label>
      <mat-select [formControl]="historyType" multiple disableOptionCentering panelClass="dropdown-item filters"
        (selectionChange)="selectionChanged('type')">
        <mat-select-trigger></mat-select-trigger>
        <mat-option *ngFor="let item of historyTypes" [value]="item.type">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>
        <mat-icon *ngIf="historyPhase.value && historyPhase.value.length">check_box</mat-icon>
        Phases
        {{ historyPhase.value && historyPhase.value.length ? "(" + historyPhase.value.length + ")" : null }}
      </mat-label>
      <mat-select [formControl]="historyPhase" multiple disableOptionCentering #phaseFilter
        panelClass="dropdown-item phase-filters filters" (selectionChange)="selectionChanged('phase')">
        <mat-select-trigger></mat-select-trigger>
        <mat-option *ngFor="let phase of historyPhaseList" [value]="phase">{{ phase }}</mat-option>
        <div class="filter-controls">
          <button class="clear" (click)="clearFilter()" [disabled]="historyPhase.value?.length==0">Clear</button>
          <button class="primary-btn" (click)="applyAllFilter()"
            [disabled]="historyPhase.value?.length == historyPhaseList.length">Select All</button>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="history-tasks">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="createDate"
      [hidden]="!dataSource?.filteredData?.length" matSortDirection="desc">
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.contactMethod == 0">call</mat-icon>
          <mat-icon *ngIf="element.contactMethod == 1">print</mat-icon>
          <mat-icon *ngIf="element.contactMethod == 2">email</mat-icon>
          <mat-icon *ngIf="element.contactMethod == 3">textsms</mat-icon>
          <img *ngIf="element.contactMethod == 4" src="../../../../../../assets/icons/icon-snail-mail.svg" alt="mail" />
          {{ element.task
          }}<ng-container *ngIf="element.attemptNumber">, attempt {{ element.attemptNumber + 1 }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">{{ element.createDate | date: "M/d/yyyy" }}</td>
      </ng-container>
      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
        <td mat-cell *matCellDef="let element">{{ element.completed | date: "M/d/yyyy" }}</td>
      </ng-container>
      <ng-container matColumnDef="outcome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Success</th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="element.outcome == 0" src="../../../../../../assets/icons/icon-check.svg" alt="success" />
          <img *ngIf="element.outcome == 1" src="../../../../../../assets/icons/icon-error-check.svg" alt="failure" />
          <mat-icon *ngIf="element.outcome != 0 && element.outcome != 1">query_builder</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="isExpanded(element.id)">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="!isExpanded(element.id)">keyboard_arrow_down</mat-icon>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail">
            <div class="example-element-body">
              <div class="left-side">
                <div class="info-item">
                  <b>Started</b>
                  <span>{{ element.createDate | date: "M/d/yyyy" }}</span>
                </div>
                <div class="info-item">
                  <b>Due</b>
                  <span>{{ element.due | date: "M/d/yyyy" | empty }}</span>
                </div>
                <div class="info-item">
                  <b>Direction</b>
                  <span>{{ directionNames[element.direction] }}</span>
                </div>
                <div class="info-item">
                  <b>Method</b>
                  <span>{{ contactMethodNames[element.contactMethod] }}</span>
                </div>
                <div class="info-item">
                  <b>Entity</b>
                  <span>{{ targetNames[element.target] }}</span>
                </div>
              </div>
              <div class="right-side">
                <div class="updated-container" *ngIf="element.updatedBy && !element.automated">
                  <div class="user-img">
                    <img
                      [src]="element.updatedBy.userImageS3Url || 'http://reserverecruitment.co.za/images/usericon.png'"
                      alt="user" />
                  </div>
                  <i>Updated by</i>&nbsp;<span class="updated-name">{{element.updatedBy | fullName:false}}</span>
                </div>
                <b>Notes</b>
                <h5 *ngIf="element.outcomeNote && element.outcomeNote.trim()">{{ element.completed |
                  date: "M/d/yyyy',' hh:mmaaaaa" }}
                </h5>
                <p>
                  {{ element.outcomeNote }}
                </p>
                <div class="attachment-buttons">
                  <button *ngFor="let attachment of element.attachments" (click)="openModal(element, attachment)">
                    {{ attachment.linkText }}
                    <mat-icon>launch</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="example-element-footer">
                <b>Attachments</b>
                <div class="attachments">
                    <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank">
                        <mat-icon>attach_file</mat-icon>AMDwyer_Rx-9-15-2020.pdf
                    </a>
                    <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank">
                        <mat-icon>attach_file</mat-icon>AMDwyer_SignIn-9-15-2020.pdf
                    </a>
                </div>
            </div> -->
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="statusChange">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="change-status">
            <div class="image-item">
              <img src="../../../../../../assets/icons/pill-blue.svg" alt="status" />
            </div>
            <div class="status-item">
              <h6>Status Type</h6>
              <p>Case</p>
            </div>
            <div class="time-item">
              <h6>Time</h6>
              <p>{{ element.createDate | date: "M/d/yyyy 'at' hh:mmaaaaa" | empty }}</p>
            </div>
            <div class="case-item">
              <h6>Case Status</h6>
              <p>{{ element.status | empty }}</p>
            </div>
            <div class="substatus-item">
              <h6>SubStatus</h6>
              <p>{{ element.subStatus | empty }}</p>
            </div>
            <div class="reason-item">
              <h6>Reason</h6>
              <p>{{ element.subStatusReason | empty }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: ['statusChange']; when: isStatusChange" class="status-change-row"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay; when: isActivity" class="example-element-row"
        [class.example-expanded-row]="isExpanded(row.id)" (click)="toggleRow(row.id)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isActivity"
        [class.hidden]="!isExpanded(row.id)" class="example-detail-row"></tr>
    </table>
    <div class="empty-history-row" *ngIf="!dataSource?.filteredData?.length">
      <div class="empty-history-container">
        <img src="../../../../../../assets/images/empty-history.svg" />
        <div class="empty-history-header">No History</div>
        <div class="empty-history-description-container">
          <div class="empty-history-description">This case doesn't have any tasks yet.</div>
          <div class="empty-history-description">Tasks and status changes will show here once completed.</div>
        </div>
      </div>
    </div>
  </div>
</div>