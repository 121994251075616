import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICaseInfo } from 'src/app/shared/interfaces/interfaces';
import { QueueIDs } from '../../enums/enums';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    case: ICaseInfo;
    queueID: number;
    documentData = {
        count: null,
        type: null,
    };
    constructor(private store: Store<any>) {}

    async ngOnInit() {
        this.store.select('case').subscribe((state) => (this.case = state));
        this.store.select('document').subscribe((state) => {
            this.documentData.count = state.documentsCount;
            const queueType = window.location.pathname.replace('/document-', '');
            this.queueID = QueueIDs[queueType];
        });
    }
}
