import { Action } from '@ngrx/store'
import { IUser } from "../../shared/interfaces/interfaces"

export enum UserTypes {
    SET_USER = 'SET_USER',
}

export class SetUser implements Action {
    readonly type = UserTypes.SET_USER
    constructor(public payload: IUser){}
}

export type UserActions = SetUser