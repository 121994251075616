<form (ngSubmit)="submitHandler()">
    <div class="missed-info">
        <h2>Missing Information</h2>
        <h3>Request Includes</h3>
        <h4 *ngIf="missedInfo.requiredBlank && missedInfo.requiredBlank.length > 0">Required Information</h4>
        <ul>
            <li *ngFor="let item of missedInfo.requiredBlank">{{ item }}</li>
        </ul>
        <h4 *ngIf="missedInfo.desiredBlank && missedInfo.desiredBlank.length > 0">Desired Information</h4>
        <ul>
            <li *ngFor="let item of missedInfo.desiredBlank">{{ item }}</li>
        </ul>

    </div>
    <div class="missed-info-buttons">
        <div class="missed-info-buttons-container">
            <button type="button" class="primary-btn invert missed-cancel" (click)="cancelHandler()">
                <b>Cancel</b>
            </button>
            <button type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()">
                <b>Next Stack</b>
            </button>
        </div>
    </div>
</form>