import { Pipe, PipeTransform } from '@angular/core';
import { ContactMethod } from '../enums/enums';
import { IContactInfo } from '../interfaces/interfaces';

@Pipe({ name: 'fax' })
export class FaxPipe implements PipeTransform {
    transform(contactInfos: IContactInfo[]): string {
        let contact = contactInfos.find(x => x.primary && x.contactMethod == ContactMethod.fax && x.contactString);
        if (contact) {
            let fax = contact.contactString;
            let firstPart = fax.substring(0, 3);
            let secondPart = fax.substring(3, 6);
            let thirdPart = fax.substring(6);
            return `(${firstPart}) ${secondPart}-${thirdPart}`;
        }

        return '';
    }
}