import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-empty-queue',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
})
export class EmptyQueueComponent implements OnInit {
    @Input() type: string;

    constructor() {}

    ngOnInit(): void {}
}
