<div id="notes">
  <div class="notes-head" (click)="showNotes = !showNotes">
    <span>Notes (2)</span>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
  <div class="notes-body" *ngIf="showNotes">
    <ng-scrollbar class="custom-scrollbar">
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 11:40pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office and spoke to the office admin. Dr out to
          lunch, asked to call back after 1pm.
        </p>
      </div>
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 1:15pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office transferred directly to physician an
          confirmed PA completion & submission
        </p>
      </div>
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 11:40pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office and spoke to the office admin. Dr out to
          lunch, asked to call back after 1pm.
        </p>
      </div>
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 1:15pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office transferred directly to physician an
          confirmed PA completion & submission
        </p>
      </div>
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 11:40pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office and spoke to the office admin. Dr out to
          lunch, asked to call back after 1pm.
        </p>
      </div>
      <div class="note-item">
        <div class="note-head">
          <b>Logan O'Neal</b>
          <span>7/23/2020 at 1:15pm</span>
        </div>
        <p>
          Called Dr. Fedelm's office transferred directly to physician an
          confirmed PA completion & submission
        </p>
      </div>
    </ng-scrollbar>
  </div>
  <div class="notes-send" *ngIf="showNotes">
    <mat-form-field>
      <textarea matInput></textarea>
    </mat-form-field>
    <button class="primary-btn">Save</button>
  </div>
</div>
