export enum PatientFormFields {
    firstName = 'First Name',
    middleName = 'Middle Initial',
    lastName = 'Last Name',
    dateOfBirth = 'Date of Birth',
    gender = 'Gender',
    zipCode = 'ZIP Code',
    streetAddress = 'Address',
    addressExtension = 'Address Line 2',
    contactString = 'Patient Phone',
    contactType = 'Patient Phone Type',
    phoneType = 'Home, Mobile, or Work',
    email = 'Email',
    bestTimeToContact = 'Best Time to Contact',
    bestMethodToContact = 'Best Method to Contact',
    hipaaConsent = 'HIPAA Consent',
    hipaaConsentSignatureDate = 'HIPAA Consent Signature Date',
    socialSecurityNumber = 'SSN',
}

export enum InsuranceFormFields {
    name = 'Plan Name',
    planId = 'Policy Number',
    group = 'Group',
    policyHolderName = 'Policy Holder Name',
    policyHolderDOB = 'Policy Holder DOB',
    planType = 'Plan Type',
    planOrder = 'Secondary Plan?',
    medicalInsurance = 'Does the patient have insurance?',
    searchMedicalInsurancePlan = 'Select Medical Insurance Plan',
    payerName = 'Payer Name',
    planPhone = 'Plan Phone',
}

export enum PhysicianFormFields {
    hasPhysician = 'Physician',
    officeName = 'Office Contact Name',
    officeEmail = 'Contact Email',
    facilityName = 'Facility Name',
    tax = 'Group Tax ID',
    address = 'Address',
    zipCode = 'ZIP Code',
    officePhone = 'Office Phone',
    officeFax = 'Office fax',
}

export enum ClinicalFormFields {
    primaryDiagnosticCode = 'Primary Diagnosis Code',
}

export enum PrescriptionFormFields {
    medication = 'Medication',
    dosage = 'Dosage',
    quantity = 'Quantity',
    refills = 'Refills',
    prescriptionStart = 'Prescription Start',
    prescriptionEnd = 'Prescription End',
    notes = 'Rx Notes',
    signature = 'Physician Signature Included?',
    physicianSignatureDate = 'Physician Signature Date',
    refillsNumber = 'Number of Refills'
}
