export interface IDocument {
    id: number;
    documentBatchId: number;
    patient: any;
    patientId: null | number;
    documentType: null | string | number;
    rawPNGKey: string;
    thumbnailPNGKey: string;
    deleteReason: number | string;
    isDeleted: boolean;
    isUncertain: boolean;
    uncertainReason: number | string;
    otherText?: string;
    source?: string;
}

export interface IBatch {
    createDate: string;
    documentImages: IDocument[];
    id: number;
    queueStatus: number;
}

export interface IDocumentOptions {
    documentList: IDocument[];
    selectedDocument: number;
    currentDocumentOrder: number;
    zoomPercent: number;
    rotate: number;
    grid: boolean;
}

export interface IPatientsRequestData {
    skip?: number;
    take?: number;
    search: string;
}

export interface IDeletedImage {
    deleteReason: number;
    deleteReasonString: string;
}

export interface IUncertainImage {
    uncertainReason: number;
    uncertainReasonString: string;
}
export interface IDocumentUpdateRequest {
    documentType: number;
    patientId: number | null;
    otherDocumentTypeName?: string;
}
export interface IUnlockDocument {
    queueId: number;
    itemIds: number[];
}

export class Document implements IDocument {
    id: number;
    documentBatchId: number;
    patient: any;
    patientId: null | number;
    documentType: null | string;
    rawPNGKey: string;
    thumbnailPNGKey: string;
    deleteReason: number | string;
    isDeleted: boolean;
    isUncertain: boolean;
    uncertainReason: number | string;
    otherText: string;
    source?: string;
}

export class DocumentOptions implements IDocumentOptions {
    documentList: IDocument[] = [new Document()];
    selectedDocument: number;
    currentDocumentOrder = 1;
    zoomPercent = 1;
    rotate = 0;
    grid = false;
}
