import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhysicianFormFields } from '../form.model';
import { DataEntryFormService } from '../form.service';
import { throwError } from 'rxjs';
import { ContactMethod, ContactType } from 'src/app/shared/enums/enums';

@Component({
  selector: 'app-intake-document-physician-form',
  templateUrl: './physician.component.html',
  styleUrls: ['../form.component.scss', './physician.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)',
  },
})
export class PhysicianComponent implements OnInit, AfterViewInit {
  @Input() settings;
  @Output() submitForm = new EventEmitter();
  @Output() validateForm = new EventEmitter();
  @Output() updateCase = new EventEmitter();

  physicianForm: FormGroup;
  facilityForm: FormGroup;
  physicians = [];
  facilities = [];
  focusedSearch = 0;
  physicianFacilities = false;
  facilitiesSearch = false;
  physicianFormFields = PhysicianFormFields;

  physicianOptions = {
    newPhysician: false,
    selectedPhysician: null,
    newFacility: false,
    selectedFacility: null,
    isEditedFacility: false,
  };
  submitted = false;

  // @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
  @ViewChild('searchPhysician') searchPhysician: ElementRef;
  @ViewChild('searchFacility') searchFacility: ElementRef;
  @ViewChild('physicianSearchList') physicianSearchList: ElementRef;
  @ViewChild('facilitySearchList') facilitySearchList: ElementRef;
  @ViewChild('officeName') officeName: ElementRef;
  @ViewChild('facilityName') facilityName: ElementRef;
  @ViewChild('physicianFacility') physicianFacility: ElementRef;

  caseInner;
  suggestedPhysicians = [];
  suggestedFacilities = [];
  @Input() contactString = '';

  @Input()
  get case() {
    return this.caseInner;
  }

  set case(newCase) {
    if (!this.caseInner && newCase) {
      this.caseInner = newCase;
      this.physicianOptions.selectedPhysician = this.caseInner.physician;
      this.physicianOptions.selectedFacility = this.caseInner.facility;
      this.facilityForm.patchValue(this.getFacilityFormObject(this.caseInner.facility));
      this.facilityForm.patchValue({hasPhysician: this.caseInner.physician ? '1' : ''});
      const hasFacilities = this.hasPhysicianFacilities(this.caseInner.physician);
      this.setPhysicianFacilityVisibility(
        !this.caseInner.facility && hasFacilities,
        !this.caseInner.facility && !hasFacilities
      );
    }
  }

  constructor(private dataEntryService: DataEntryFormService) {
    this.physicianForm = new FormGroup({
      searchPhysician: new FormControl(''),
      searchFacility: new FormControl(''),
      physicianName: new FormControl('', Validators.required),
      npi: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
      specialty: new FormControl('', Validators.required),
    });

    this.facilityForm = new FormGroup({
      hasPhysician: new FormControl('', Validators.required),
      officeName: new FormControl('', Validators.required),
      officeEmail: new FormControl('', [Validators.required, Validators.email]),
      facilityName: new FormControl(''),
      tax: new FormControl(''),
      address: new FormControl(''),
      zipCode: new FormControl('', Validators.required),
      officePhone: new FormControl('', Validators.required),
      officeFax: new FormControl('', Validators.required),
    });
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (
      this.physicianFacilities &&
      (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')
    ) {
      event.preventDefault();
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        this.focusedSearch === this.physicianFacility.nativeElement.children.length - 1
          ? (this.focusedSearch = 0)
          : (this.focusedSearch = this.focusedSearch + 1);
      }
      if (event.key === 'ArrowUp') {
        this.focusedSearch === 0
          ? (this.focusedSearch = this.physicianFacility.nativeElement.children.length - 1)
          : (this.focusedSearch = this.focusedSearch - 1);
      }
      if (event.key === 'Enter') {
        this.selectFacility(this.physicianOptions.selectedPhysician.addresses[this.focusedSearch], true);
      }
    }
  }

  selectPhysician(physician) {
    this.physicianOptions.selectedPhysician = physician;
    this.physicianOptions.selectedFacility = null;
    this.physicianOptions.newFacility = false;
    this.facilityForm.patchValue({hasPhysician: physician ? '1' : ''});
    this.clearSearchPhysician();

    setTimeout(() => {
      this.focusedSearch = 0;
      const hasFacilities = this.hasPhysicianFacilities(physician);
      this.setPhysicianFacilityVisibility(hasFacilities, !hasFacilities);

      if (!this.physicianFacility) {
        this.searchPhysician.nativeElement.focus();
      }
    }, 0);
  }

  // selectNewPhysician() {
  //     this.physicianOptions.newPhysician = true;
  //     this.clearSearchPhysician();
  //     this.focusedSearch = 0;
  // }
  clearSearchPhysician() {
    this.physicianForm.patchValue({
      searchPhysician: '',
    });
  }

  setPhysicianFacilityVisibility(showPhysicitianFacilities, showSearch) {
    this.physicianFacilities = showPhysicitianFacilities;
    this.facilitiesSearch = showSearch;
  }

  showFacilitiesSearch() {
    this.setPhysicianFacilityVisibility(false, true);
  }

  hasPhysicianFacilities(physician) {
    return physician && physician.addresses && physician.addresses.length > 0;
  }

  selectFacility(facility, isAddress) {
    this.physicianOptions.selectedFacility = isAddress ? this.getFacilityFromNpiAddress(facility) : facility;
    this.facilityForm.patchValue(this.getFacilityFormObject(this.physicianOptions.selectedFacility));
    this.clearSearchFacility();
    this.physicianOptions.newFacility = false;

    const hasFacilities = this.hasPhysicianFacilities(this.physicianOptions.selectedPhysician);
    this.setPhysicianFacilityVisibility(!facility && hasFacilities, !facility && !hasFacilities);
    this.physicianOptions.isEditedFacility = false;

    setTimeout(() => {
      if (this.officeName && this.officeName.nativeElement) {
        this.officeName.nativeElement.focus();
      } else if (this.searchFacility && this.searchFacility.nativeElement) {
        this.searchFacility.nativeElement.focus();
      }
    }, 0);
  }

  selectNewFacility() {
    this.physicianOptions.newFacility = true;
    this.clearSearchFacility();

    setTimeout(() => {
      this.facilityName.nativeElement.focus();
    }, 0);
  }

  clearSearchFacility() {
    this.physicianForm.patchValue({
      searchFacility: '',
    });
  }

  editFacility() {
    this.physicianOptions.isEditedFacility = !this.physicianOptions.isEditedFacility;

    if (!this.physicianOptions.isEditedFacility) {
      this.physicianOptions.selectedFacility = this.getFacilityCaseObject();
    }
  }

  exitEditFacility() {
    this.physicianOptions.isEditedFacility = !this.physicianOptions.isEditedFacility;
    this.facilityForm.patchValue(this.getFacilityFormObject(this.physicianOptions.selectedFacility));
  }

  searchPhysicians() {
    this.clearSearchFacility();

    if (this.physicianForm.value.searchPhysician && this.physicianForm.value.searchPhysician.length >= 3) {
      const data = {search: this.physicianForm.value.searchPhysician};
      this.dataEntryService.searchPhysicians(data).subscribe(
        (response) => {
          this.physicians = response;
        },
        (error) => throwError(error)
      );
    }
  }

  searchFacilities() {
    this.clearSearchPhysician();

    if (this.physicianForm.value.searchFacility && this.physicianForm.value.searchFacility.length >= 3) {
      const data = {search: this.physicianForm.value.searchFacility};
      this.dataEntryService.searchFacilities(data).subscribe(
        (response) => {
          this.facilities = response;
        },
        (error) => throwError(error)
      );
    }
  }

  searchNavigate(event, search) {
    const searchList = 'physician' ? this.physicianSearchList : this.facilitySearchList;

    if (this.physicianForm.value.searchPhysician.length || this.physicianForm.value.searchFacility.length) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        event.preventDefault();
        this.focusedSearch === searchList.nativeElement.children.length - 1
          ? (this.focusedSearch = 0)
          : (this.focusedSearch = this.focusedSearch + 1);
        // this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusedSearch === 0
          ? (this.focusedSearch = searchList.nativeElement.children.length - 1)
          : (this.focusedSearch = this.focusedSearch - 1);
        // this.scrollbar.scrollTo({ top: this.focusedSearch * 151 });
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        if (search === 'physician') {
          this.selectPhysician(this.physicians[this.focusedSearch]);
        } else {
          this.selectFacility(this.facilities[this.focusedSearch], false);
        }
      }
    }
  }

  submitHandler(activeTab = 'clinical') {
    this.submitted = true;
    this.saveFacility().then(this.savePhysician.bind(this)).then(this.saveCase.bind(this));
    this.validateForm.emit({formControls: this.facilityForm.controls, tab: 'physician'});

    if (!this.settings.validate.invalid.length && this.settings.validate.requiredBlank.length) {
      return this.switchTab(false, activeTab);
    } else if (!this.settings.validate.invalid.length && this.settings.validate.desiredBlank.length) {
      return this.switchTab(true, activeTab);
    } else if (!this.settings.validate.invalid.length) {
      return this.switchTab(true, activeTab);
    }
  }

  switchTab(isValid, activeTab = 'clinical') {
    const data = {
      activeTab,
      physician: {
        isValid,
      },
    };

    this.submitForm.emit(data);
  }

  saveFacility() {
    if (this.physicianOptions.selectedFacility || this.physicianOptions.newFacility) {
      const data = {
        facility: this.getFacilityCaseObject(),
      };
      if (this.physicianOptions.selectedFacility && this.physicianOptions.selectedFacility.id) {
        this.dataEntryService.updateFacility(data).subscribe(
          (response) => {
          },
          (error) => throwError(error)
        );

        return Promise.resolve();
      } else {
        return new Promise((resolve) =>
          this.dataEntryService.createFacility(data).subscribe(
            (response) => {
              if (!this.physicianOptions.selectedFacility) {
                this.physicianOptions.selectedFacility = this.getFacilityCaseObject();
              }
              this.physicianOptions.selectedFacility.id = response.value;
              resolve(response);
            },
            (error) => throwError(error)
          )
        );
      }
    }

    return Promise.resolve();
  }

  savePhysician() {
    if (this.physicianOptions.selectedPhysician) {
      const data = {
        physician: this.getPhysicianCaseObject(),
      };
      if (!this.physicianOptions.selectedPhysician.id) {
        return new Promise((resolve) =>
          this.dataEntryService.createPhysician(data).subscribe(
            (response) => {
              this.physicianOptions.selectedPhysician.id = response.value;
              resolve(response);
            },
            (error) => throwError(error)
          )
        );
      } else {
        this.dataEntryService.updatePhysician(data).subscribe(
          (response) => {
          },
          (error) => throwError(error)
        );
        return Promise.resolve();
      }
    }

    return Promise.resolve();
  }

  saveCase() {
    this.caseInner.physicianId = this.physicianOptions.selectedPhysician
      ? this.physicianOptions.selectedPhysician.id
      : null;

    this.caseInner.physician = this.physicianOptions.selectedPhysician;

    this.caseInner.facilityId = this.physicianOptions.selectedFacility
      ? this.physicianOptions.selectedFacility.id
      : null;

    this.caseInner.facility = this.physicianOptions.selectedFacility ? this.getFacilityCaseObject() : null;

    if (this.caseInner.facility && this.caseInner.physician && this.caseInner.physician.addresses) {
      const index = this.caseInner.physician.addresses.findIndex((x) => x.id === this.caseInner.facilityId);
      const facilityAddress = this.getFacilityAddressObject(this.caseInner.facility);
      if (index > -1) {
        this.caseInner.physician.addresses[index] = facilityAddress;
      } else {
        this.caseInner.physician.addresses.push(facilityAddress);
      }
    }

    this.updateCase.emit(this.caseInner);

    const data = {
      caseManagementQueueItem: {
        id: this.caseInner.id,
        patientId: this.caseInner.patientId,
        patientInsuranceId: this.caseInner.patientInsuranceId,
        physicianId: this.caseInner.physicianId,
        facilityId: this.caseInner.facilityId,
        diagnosisId: this.caseInner.diagnosisId,
        prescriptionId: this.caseInner.prescriptionId,
      },
    };

    return new Promise((resolve) =>
      this.dataEntryService.updateCase(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => throwError(error)
      )
    );
  }

  ngOnInit(): void {
    const data = {take: 0, contactString: this.contactString};

    this.dataEntryService.getSuggestedPhysicians(data).subscribe(
      (response) => {
        this.suggestedPhysicians = response;
      },
      (error) => throwError(error)
    );

    this.dataEntryService.getSuggestedFacilities(data).subscribe(
      (response) => {
        this.suggestedFacilities = response;
      },
      (error) => throwError(error)
    );
  }

  ngAfterViewInit() {
    this.searchPhysician.nativeElement.focus();
  }

  getFacilityFormObject(facility) {
    const phone = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.phone) : null;
    const fax = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.fax) : null;
    const email = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.email) : null;

    if (this.physicianOptions.selectedFacility) {
      this.physicianOptions.selectedFacility.city = facility ? facility.address.city : '';
      this.physicianOptions.selectedFacility.state = facility ? facility.address.state : '';
    }

    return {
      facilityName: facility ? facility.name : '',
      address: facility ? facility.address.streetAddress : '',
      tax: facility ? facility.groupTaxId : '',
      zipCode: facility ? facility.address.zipCode : '',
      officePhone: phone ? phone.contactString : '',
      officeFax: fax ? fax.contactString : '',
      officeName: email ? email.name : '',
      officeEmail: email ? email.contactString : '',
    };
  }

  getFacilityFromNpiAddress(address) {
    const contactInfos = [];

    if (address.telephoneNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.telephoneNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.phone,
        contactType: ContactType.mobile,
      });
    }

    if (address.faxNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.faxNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.fax,
        contactType: ContactType.mobile,
      });
    }

    if (address.email) {
      contactInfos.push({
        name: address.contactName,
        contactString: address.email,
        primary: true,
        contactMethod: ContactMethod.email,
        contactType: ContactType.mobile,
      });
    }

    return {
      id: address.id,
      name: address.name,
      address: {
        streetAddress: address.address1,
        addressExtension: address.address2,
        zipCode: address.postalCode,
        state: address.state,
        city: address.city,
      },
      groupTaxId: address.groupTaxId,
      contactInfos,
    };
  }

  getFacilityCaseObject() {
    const contactInfos = [];

    if (this.facilityForm.value.officePhone) {
      contactInfos.push({
        name: '',
        contactString: ('' + this.facilityForm.value.officePhone).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.phone,
        contactType: ContactType.mobile,
      });
    }

    if (this.facilityForm.value.officeFax) {
      contactInfos.push({
        name: '',
        contactString: ('' + this.facilityForm.value.officeFax).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.fax,
        contactType: ContactType.mobile,
      });
    }

    if (this.facilityForm.value.officeEmail) {
      contactInfos.push({
        name: this.facilityForm.value.officeName,
        contactString: this.facilityForm.value.officeEmail,
        primary: true,
        contactMethod: ContactMethod.email,
        contactType: ContactType.mobile,
      });
    }

    return {
      id: this.physicianOptions.selectedFacility ? this.physicianOptions.selectedFacility.id : 0,
      name: this.facilityForm.value.facilityName,
      address: {
        streetAddress: this.facilityForm.value.address,
        addressExtension: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.addressExtension
          : null,
        zipCode: this.facilityForm.value.zipCode,
        state: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.state
          : null,
        city: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.city
          : null,
      },
      groupTaxId: this.facilityForm.value.tax,
      contactInfos,
    };
  }

  getFacilityAddressObject(facility) {
    const phone = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.phone);
    const fax = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.fax);
    const email = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.email);

    return {
      id: facility.id,
      telephoneNumber: phone ? phone.contactString : '',
      faxNumber: fax ? fax.contactString : '',
      email: email ? email.contactString : '',
      contactName: email ? email.name : '',
      name: facility.name,
      address1: facility.address.streetAddress,
      address2: facility.address.addressExtension,
      postalCode: facility.address.zipCode,
      city: facility.address.city,
      state: facility.address.state,
      groupTaxId: facility.groupTaxId,
    };
  }

  getPhysicianCaseObject() {
    let physicianFacilities = [];

    const specialityId =
      this.physicianOptions.selectedPhysician && this.physicianOptions.selectedPhysician.id
        ? this.physicianOptions.selectedPhysician.physicianSpecialityId
        : this.physicianOptions.selectedPhysician
        ? 0
        : null;

    const speciality = specialityId != null ? {id: specialityId, name: null} : null;

    if (this.physicianOptions.selectedPhysician) {
      physicianFacilities = this.physicianOptions.selectedPhysician.addresses
        .filter((x) => x.id)
        .map((x) => x.id);
      if (
        this.physicianOptions.selectedFacility &&
        !physicianFacilities.includes(this.physicianOptions.selectedFacility.id)
      ) {
        physicianFacilities.push(this.physicianOptions.selectedFacility.id);
      }
      if (
        speciality &&
        this.physicianOptions.selectedPhysician.taxonomies.length &&
        this.physicianOptions.selectedPhysician.taxonomies[0].desc
      ) {
        speciality.name = this.physicianOptions.selectedPhysician.taxonomies[0].desc;
      }
    }

    return {
      id: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.id : 0,
      firstName: this.physicianOptions.selectedPhysician
        ? this.physicianOptions.selectedPhysician.firstName
        : null,
      lastName: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.lastName : null,
      npi: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.npi : null,
      physicianSpecialityId: specialityId,
      physicianSpeciality: speciality,
      physicianFacilities,
    };
  }
}
