import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-supervisor-disable-queue',
    templateUrl: './disable.component.html',
    styleUrls: ['./disable.component.scss'],
})
export class SupervisorDisableQueueComponent implements OnInit {
    @Output() queueDisabled = new EventEmitter();
    @Output() queueEnabled = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) public data) { }

    disableQueue() {
        this.queueDisabled.emit();
    }

    enableQueue() {
        this.queueEnabled.emit();
    }

    ngOnInit(): void { }
}
