<!--Section NOTES-->
<div class="notes-section-header">
    <h2>Notes <span>({{data.count}})</span></h2>
    <div class="filter-section">
        <button type="button" class="filter" mat-button (click)="showFilter(true)">
            <mat-icon>filter_alt</mat-icon><span>Filter</span>
            <span *ngIf="filter.count">&nbsp;({{filter.count}})</span>
        </button>
        <div class="filter-header">
            <div class="filter-wrapper" *ngIf="filter.show">
                <div class="filter-title">
                    <h5>FILTER BY ENTITY</h5>
                    <button (click)="closeFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class=" filter-body">
                    <div class="checkbox-item filter-checkbox" *ngFor="let type of dataTypes">
                        <mat-checkbox [(ngModel)]="filter.value[type]">{{
                            type
                            }}</mat-checkbox>
                    </div>
                </div>
                <div class="filter-controls">
                    <button class="clear" (click)="clearFilter()">Clear</button>
                    <div class="primary-btn" (click)="applyFilter()">Filter</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="details-wrapper">
    <ng-scrollbar class="custom-scrollbar case-scrollbar">
        <div *ngIf="!data?.items?.length" class="empty-notes-container">
            <img src="../../../../../../../assets/images/empty-notes.svg">
            <div class="empty-notes-header">No Notes</div>
            <div class="empty-notes-description">When you complete an outcome note in a task for this case, it will show
                here.</div>
        </div>
        <div class="note-item" *ngFor="let detail of data.items">
            <h3>{{detail.phase}}</h3>
            <div *ngFor="let task of detail.notes">
                <div class="note-description">
                    <img *ngIf="task.icon.isImage" [src]="task.icon.value" />
                    <mat-icon *ngIf="!task.icon.isImage" class="blue">{{task.icon.value}}</mat-icon>
                    <h4>
                        {{contactMethodNames[task.contactMethod]}}
                        {{task.direction === directionNames['In'] ? 'from' : 'to'}}
                        {{dataTypeNames[task.target]}}
                    </h4>
                    <p>
                        {{task.outcomeNote}}
                    </p>
                    <b>{{task.task}}</b>
                    <span>{{task.completed | date:"M/d/yyyy',' hh:mmaaaaa"}}</span>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>