import { Component, Input, OnInit } from '@angular/core';
import { TargetNames, TaskContactMethodNames, TaskDirectionNames } from 'src/app/shared/enums/enums';
import { deepCopy, enumToArray } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-section-notes',
  templateUrl: './section-notes.component.html',
  styleUrls: ['./section-notes.component.scss', '../aside-sections.scss']
})
export class SectionNotesComponent implements OnInit {

  @Input()
  get initialData() {
    return this._initialData;
  }
  set initialData(data) {
    if ((!this._initialData && data)
      || (this._initialData && data && this._initialData.count != data.count)) {
      this._initialData = data;
      this.data = data;
      this.applyFilter();
    }
  }

  data;
  _initialData;


  contactMethodNames = TaskContactMethodNames;
  dataTypeNames = TargetNames;
  dataTypes = enumToArray(TargetNames);
  directionNames = TaskDirectionNames;
  filter = {
    show: false,
    count: 0,
    previousValue: {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    },
    value: {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    },
  };
  constructor() { }

  ngOnInit(): void {
    this.data = this.initialData;
  }

  showFilter(isShown) {
    this.filter.show = isShown;
  }
  closeFilter() {
    this.populateFilter(this.filter.value, this.filter.previousValue);
    this.showFilter(false);
  }
  applyFilter() {
    this.populateFilter(this.filter.previousValue, this.filter.value);
    this.filterValues();
    this.showFilter(false);
  }
  clearFilter() {
    this.filter.value = {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    };

    this.populateFilter(this.filter.previousValue, this.filter.value);
    this.filterValues();
    this.showFilter(false);
  }

  getFilters() {
    let result = [];
    for (let filter in this.filter.value) {
      if (this.filter.value[filter]) {
        result.push(filter);
      }
    }

    return result;
  }

  filterValues() {
    let filters = this.getFilters();
    this.filter.count = filters.length;
    if (!filters?.length) {
      this.data = this.initialData;
    }
    else {
      this.data = {
        count: 0,
        items: []
      };
      this.initialData.items.forEach(element => {
        let notes = element.notes.filter(note => filters.includes(TargetNames[note.target]));
        if (notes.length) {
          this.data.items.push({
            phase: element.phase,
            notes: notes
          });
          this.data.count += notes.length;
        }
      });
    }
  }

  populateFilter(initFilterValue, newFilterValue) {
    initFilterValue.Patient = newFilterValue.Patient;
    initFilterValue.Payer = newFilterValue.Payer;
    initFilterValue.Physician = newFilterValue.Physician;
    initFilterValue.Pharmacy = newFilterValue.Pharmacy;
  }
}
